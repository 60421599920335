import React, { Component } from 'react';
import { Button } from "glic-ui-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
import _ from 'lodash';
import { ReviewPopUp } from './ReviewPopUp';

export class ReviewHealthHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFlag: '',
            lifeId: ''
        }
    }

    componentDidMount() {
        this.update();
    }

    getClearLifeId = () => {
        return {
            lifeId: ''
        }
    }

    update = () => {
        if (this.props.id && this.props.id) {
            this.setState({ ...this.getClearLifeId(), lifeId: this.props.id })
            this.setState({ isFlag: this.props.isFlaged })
        }
    }

    handleOpen = (entity, index) => {
        let life_id = entity.life_id
        document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
        // this.setState({ isFlag: true, lifeId: life_id})
        this.props.isFlag1(true);
        this.setState({ isFlag: true, lifeId: life_id })
        setTimeout(() => {
            const el = document.getElementById("view-button")
            if (el) {
                el.focus();
            }
        }, 500);
    }

    handleClose = () => {
        document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
        this.props.isFlag1(false);
        this.setState({ isFlag: false })
        this.props.chevron(false);
    }

    render() {
        // console.log("this.state.isFlag-", this.state.isFlag);
        return (
            <div className="container">
                <div class="row">
                    <div class="col-lg-9">
                        <h2 style = {{fontSize:"24px"}} class="hugo-blue pt-4 pb-2">Health history:</h2>
                        {_.get(this.props, "prefillData.eoi_data.health_question_info.life_entities", null) && _.get(this.props, "prefillData.eoi_data.health_question_info.life_entities", "").map((entity, index) => {
                            return (
                                <div class="row hh-section">
                                    <div class = "col-sm-8 pt-3">
                                    <p>{entity.life_name}</p>
                                    </div>
                                    <div class ="col-sm-4">
                                    <Button id="view-button" type="outlined" color="navy" size="large" onClick={() => this.handleOpen(entity, index)}>
                                        View
                            </Button>
                            </div>
                                </div>)
                        })}
                        <hr/>
                        {this.state.isFlag ?
                            <ReviewPopUp lifeId={this.state.lifeId}  {...this.props} handleClose={() => this.handleClose()} />
                            : null}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(ReviewHealthHistory);

import React, { Component } from "react";
import FocusTrap from "focus-trap-react";

// const restrictShiftTabKeyNav = (event) => {
//     const { keyCode } = event;
//     if (event.shiftKey && keyCode === 9) {
//         event.preventDefault();
//     }
// }

// const restrictTabKeyNav = (event) => {
//     const { keyCode } = event;
//     if (keyCode === 9) {
//         event.preventDefault();
//     }
// }
class SaveForLater extends Component {

    render() {
        return (
            <>
                <FocusTrap>
                    <div class="popup-bg">
                        <div class="popup-box small">
                            <div class="popup-header">
                                <button class="close hidden-text">
                                    <i class="fas fa-times"><span>close button</span></i>
                                </button>
                            </div>
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h2 class="h3">We'll be here for you!</h2>
                                        <h3 class="h6">We'll save your progress but you're well on your way to a decision. Would you like to continue?</h3>
                                    </div>
                                </div>
                                <div class="field-group mt-5">
                                    <div class="item-1"><button id="continue-button" class="btn btn-outline" onClick={this.props.continueEOI}>Continue EOI</button></div>
                                    <div class="item-2"><button class="btn btn-primary" onClick={this.props.leave}>Leave</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>
            </>
        );
    }
}
export default SaveForLater;
import React, { Component } from 'react';
// import { Button } from "glic-ui-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
import ReviewKnockOutQ from './ReviewKnockOutQ';
import ReviewHealthQ from './ReviewHealthQ';
import ReviewHeightWeight from './HeightWeight';
import _ from "lodash";
import FocusTrap from "focus-trap-react";

export class ReviewPopUp extends Component {
    state = {
        lifeId: "",
        hasBaseQuestn: ""
    }
    componentDidMount() {
        let lifeId = _.get(this.props, "lifeId")
        this.setState({ lifeId: lifeId, hasBaseQuestn: this.props.prefillData.eoi_data.health_question_info.has_base_question })
    }
    render() {
        return (
            <FocusTrap>
                <div class="popup-review">
                    <div class="popup-header">
                        <button aria-label='Close' class="close hidden-text" onClick={this.props.handleClose}>
                            <i class="fas fa-times"><span>close button</span></i>
                        </button>
                    </div>
                    <main className="knockout">
                        {/* <ReviewKnockOutQ lifeId={_.get(this.props, "lifeId")} {...this.props}></ReviewKnockOutQ> */}
                        <ReviewKnockOutQ lifeId={_.get(this.props, "lifeId")}  {...this.props}></ReviewKnockOutQ>
                        {/* {_.get(this.props, "this.props.prefillData.eoi_data.health_question_info.has_base_question") === "true" ?
            <ReviewHeightWeight lifeId={_.get(this.props, "lifeId")} {...this.props}></ReviewHeightWeight> : null } */}
                        {this.state.hasBaseQuestn ? <ReviewHeightWeight lifeId={_.get(this.props, "lifeId")} {...this.props}></ReviewHeightWeight> : null}
                        <ReviewHealthQ lifeId={_.get(this.props, "lifeId")} {...this.props}></ReviewHealthQ>
                    </main>
                </div>
            </FocusTrap>
        )
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(ReviewPopUp);

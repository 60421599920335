import React, { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../../store/HomePage/HomePageAction';
import { imagePath } from '../../../utils/sharedFunction'
import _ from "lodash";

class UnderWritingPage extends Component {

    componentDidMount() {
        document.title = "Underwriting Confirmation | Group EOI - Guardian";
    }

    downloadPDF = (e) => {
        e.preventDefault();
        this.props.downLoadPDFData(this.props.prefillData, true);
    }
    render() {
        return (
            <>

                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="intro">
                                    <h1 class="h3">We're sending your application to our Underwriting team.</h1>
                                    <h2 className="pt-2 h6">
                                        What’s next?
                                    </h2>
                                    <p className="lead mt-3">Your application requires further review by our Underwriters. Once we have a decision regarding your application we will let you know.</p>
                                    <p className="lead mt-3">In the meantime, we recommend you download your electronic Evidence of Insurability (eEOI) form.</p>
                                    <div className="field-group mt-5">
                                        <button className="btn btn-primary" onClick={(e) => this.downloadPDF(e)}><i className="fa fa-download pr-2"></i> Your eEOI form </button>
                                    </div>
                                </div>
                                {((_.get(this.props, "prefillData.eoi_data.workday_group_ind", "") === "Y") || (_.get(this.props, "groupInfoResponse.eoi_data.workday_group_ind", "") === "Y")) ? "" :
                                    <>
                                        <div className="data-box">
                                            <h3 className="h6">Stay connected anywhere you go</h3>
                                            <p className="lead">Use <a href="https://www.guardiananytime.com" target="_blank" rel="noopener noreferrer">Guardian Anytime</a> to file and check claims, view your benefits, and access your ID card.</p>
                                        </div>
                                        <h4 className="pt-3 h6">Thank you for choosing Guardian!</h4>
                                    </>
                                }
                                {/* <button class="btn btn-secondary mt-4" onclick="">Return to Plansource</button> */}
                            </div>
                        </div>
                    </div>
                    <img src={imagePath("./images/landingpage-illustration.svg")} className="illustration-bg" alt="" />
                </main>
            </>
        )
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(UnderWritingPage);



import React, { Component } from 'react';
import { coverageDetails } from '../../utils/constant';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
// import AlertBanner from '../../components/AlertBanner/AlertBanner';
import { Button } from "glic-ui-components";
import moment from "moment";
import _ from "lodash";
import { scrollUp } from '../../utils/sharedFunction';
// import { red } from '@material-ui/core/colors';

class ReviewDetails extends Component {
  constructor(props) {
        super(props);
        this.state = {
          displayMember: false,
          displaySpouse: false,
          displayChild: false,
          memberName: "",
          spouseName: "",
          childName: "",
          memberAddress1: "",
          memberAddress2: "",
          primaryPhoneNumber:"",
          secondaryPhoneNumber:"",
          whoGetCovered:"",
          coverageIcon:"",
          memberData: {},
          spouseData: {},
          childData: [],
          checkEmployeeCovered: "",
          checkSpouseCovered: "",
          showUpMember: true,
          showDownSpouse: true, 
          childCollapse: [],
          childShow:{
                showDownChild : true,
            },

          dropdownValue: "",
          childDownList :[],
          childNameList :[],
          childGenderList: [],
          childDobList: [],

          child_election: [{
              coverage_name: "basic_life",
              child_covered: false,
              child_excess_amount: null,
              child_insured_amount: null,
          }, {
              coverage_name: "voluntary_life",
              child_covered: false,
              child_excess_amount: null,
              child_insured_amount: null,
          },
          {
            coverage_name: "cancer",
            child_covered: false,
            child_excess_amount: null,
            child_insured_amount: null,
        }
          ],
          child_electionsList: [],
          coverageElections: [
              {
                  coverage_name: "basic_life",
                  health_question: null,
                  employee_covered: false,
                  employee_excess_amount: null,
                  employee_insured_amount: null,
                  spouse_covered: false,
                  spouse_excess_amount: null,
                  spouse_insured_amount: null,
                  member_only: false

              },
              {
                  coverage_name: "voluntary_life",
                  employee_covered: false,
                  employee_excess_amount: null,
                  employee_insured_amount: null,
                  spouse_covered: false,
                  spouse_excess_amount: null,
                  spouse_insured_amount: null,
                  member_only: false
              },
              {
                  coverage_name: "long_term_disability",
                  employee_covered: false,
                  employee_excess_amount: null,
                  employee_insured_amount: null,
                  member_only: true
              },
              {
                  coverage_name: "short_term_disability",
                  employee_covered: false,
                  employee_excess_amount: null,
                  employee_insured_amount: null,
                  member_only: true
              },
              {
                  coverage_name: "voluntary_long_term_disability",
                  employee_covered: false,
                  employee_excess_amount: null,
                  employee_insured_amount: null,
                  member_only: true
              },
              {
                coverage_name: "cancer",
                health_question: null,
                employee_covered: false,
                employee_excess_amount: null,
                employee_insured_amount: null,
                spouse_covered: false,
                spouse_excess_amount: null,
                spouse_insured_amount: null,
                member_only: false

            },
            {
                coverage_name: "basic_critical_illness",
                employee_covered: false,
                employee_excess_amount: null,
                employee_insured_amount: null,
                member_only: true
            },
            {
                coverage_name: "voluntary_critical_illness",
                employee_covered: false,
                employee_excess_amount: null,
                employee_insured_amount: null,
                member_only: true
            }],
            employeeCovered:false,
              spouseCovered:false,
              childCovered:false,
              gettingCovered : "",
              coverageSvg: null,
              employeeFlag: false,
              spouseFlag: false,
              childFlag: false

    }

  }

  componentDidMount() {
    scrollUp();
    let member_name = "";
    let spouse_name = "";
    //let child_name = "";
    let member_address1 = "";
    let member_address2 = "";
    let childElectionsList = [];
    let childElection = this.state.child_election;
    let child_DownList = [];
    let childNames = [];
    let childDobs = [];
    let childGenders = [];
    let primary_phone = "";
    let secondary_phone = "";
    let getCovered = null;
    // let coverageSvg=null;

    let coverage_elections = null;
    let memberdata = null;
    let spousedata = null;
    let childdata = null;

    const coverages = _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections",{});
    const disabilityCoverages = ["short_term_disability", "long_term_disability", "voluntary_long_term_disability", "voluntary_short_term_disability" ]
    let coverageElected = []
    let allEmployeeStatus = []
    let allSpouseStatus = []
    let allChildrenStatus = []
    for (const coverage in coverages){
      let disabilityCoverageElectedStatus = false
      let employeeCoveredStatus = false
      let spouseCoveredStatus = false
      let childCoveredStatus = false
      // console.log("coverages[coverage]",coverages[coverage]);
      if(disabilityCoverages.includes(coverage)){
          
          disabilityCoverageElectedStatus = coverages[coverage] && coverages[coverage].elected && coverages[coverage].elected
      }
      else{
           employeeCoveredStatus = coverages[coverage] && coverages[coverage].employee_covered && coverages[coverage].employee_covered
           spouseCoveredStatus = coverages[coverage] && coverages[coverage].spouse_covered && coverages[coverage].spouse_covered
           childCoveredStatus = coverages[coverage] && coverages[coverage].child_covered && coverages[coverage].child_covered 
      }
      
      // console.log(employeeCoveredStatus,spouseCoveredStatus,childCoveredStatus,disabilityCoverageElectedStatus)
      if(employeeCoveredStatus === true || spouseCoveredStatus === true || childCoveredStatus ===true || disabilityCoverageElectedStatus === true){
          coverageElected.push(coverage)
      }
      allEmployeeStatus.push(employeeCoveredStatus)
      allEmployeeStatus.push(disabilityCoverageElectedStatus)
      allSpouseStatus.push(spouseCoveredStatus)
      allChildrenStatus.push(childCoveredStatus)
    }

  //     if(allEmployeeStatus.includes(true))
  //     {
  //       this.setState({coverageSvg : "./images/Employer-Gender-Neutral.svg"})
  //     }
  //     if(allSpouseStatus.includes(true) || allChildrenStatus.includes(true))
  //     {
  //       this.setState({coverageSvg : "./images/family-icon.svg"})
  //     }
  // }

    if(_.get(this.props,"trustedFlag",""))
    {
      coverage_elections = _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections",{});
      memberdata = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", {});
      spousedata = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", {});
      childdata = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
    }
    else
    {
      coverage_elections = _.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections",{});
      memberdata = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", {});
      spousedata = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", {});
      childdata = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
    }

    let coverageElectionsList =  this.state.coverageElections; 

    let memberCheck = Object.keys(memberdata);

    if(memberdata !== null  && memberCheck.length > 0 && memberdata.first_name)
    {
      this.setState({employeeFlag : true});
      let middile_initial_forMember =  memberdata.hasOwnProperty('middle_initial') ? ((memberdata.middle_initial === null) ||(memberdata.middle_initial === undefined) || (memberdata.middle_initial === "")) ? " ": memberdata.middle_initial  : " ";
      member_name = memberdata.first_name + " "+ middile_initial_forMember + " "+ memberdata.last_name;
      member_address1 = ((memberdata.address_line1 != null) ? memberdata.address_line1 : " ") + " " + ((memberdata.address_line2 != null) ? memberdata.address_line2 : " " );
      member_address2 = memberdata.city + " , " + memberdata.state + " " + memberdata.zip_code;
        if(memberdata.primary_phone_type === "H")
        {
          primary_phone = (memberdata.home_phone1 !== null) ? "("+memberdata.home_phone1+") "+memberdata.home_phone2+" - "+memberdata.home_phone3 : null;
        }
        else if(memberdata.primary_phone_type === "C")
        {
          primary_phone = (memberdata.cell_phone1 !== null) ? "("+memberdata.cell_phone1+") "+memberdata.cell_phone2+" - "+memberdata.cell_phone3 : null;
        }

        if(memberdata.secondary_phone_type === "C")
        {
          secondary_phone = (memberdata.cell_phone1 !== null) ? "("+memberdata.cell_phone1+") "+memberdata.cell_phone2+" - "+memberdata.cell_phone3 : null;
        }
        else if(memberdata.secondary_phone_type === "H")
        {
          secondary_phone = (memberdata.home_phone1 !== null) ? "("+memberdata.home_phone1+") "+memberdata.home_phone2+" - "+memberdata.home_phone3 : null;
        }


      if(coverage_elections !== undefined && coverage_elections !== null) 
      {
        if(coverage_elections.basic_life !== null){
        coverageElectionsList[0].employee_covered =  coverage_elections.basic_life.employee_covered;
        coverageElectionsList[0].employee_excess_amount =  coverage_elections.basic_life.employee_excess_amount;
        coverageElectionsList[0].employee_insured_amount =  coverage_elections.basic_life.employee_insured_amount;
        }
        if(coverage_elections.voluntary_life !== null){
          coverageElectionsList[1].employee_covered =  coverage_elections.voluntary_life.employee_covered;
          coverageElectionsList[1].employee_excess_amount =  coverage_elections.voluntary_life.employee_excess_amount;
          coverageElectionsList[1].employee_insured_amount =  coverage_elections.voluntary_life.employee_insured_amount;
        }
        if(coverage_elections.long_term_disability  !== null){
        coverageElectionsList[2].employee_covered =  coverage_elections.long_term_disability.elected;
        coverageElectionsList[2].employee_excess_amount =  coverage_elections.long_term_disability.excess_amount;
        coverageElectionsList[2].employee_insured_amount =  coverage_elections.long_term_disability.insured_amount;
        }
        if(coverage_elections.short_term_disability !== null){
        coverageElectionsList[3].employee_covered =  coverage_elections.short_term_disability.elected;
        coverageElectionsList[3].employee_excess_amount =  coverage_elections.short_term_disability.excess_amount;
        coverageElectionsList[3].employee_insured_amount =  coverage_elections.short_term_disability.insured_amount;
        }
        if(coverage_elections.voluntary_long_term_disability !== null){
        coverageElectionsList[4].employee_covered =  coverage_elections.voluntary_long_term_disability.elected;
        coverageElectionsList[4].employee_excess_amount =  coverage_elections.voluntary_long_term_disability.excess_amount;
        coverageElectionsList[4].employee_insured_amount =  coverage_elections.voluntary_long_term_disability.insured_amount;
        }
        // Adding cancer , critical illness & voluntary ilness for trusted flow only
        // for non-trusted we need to implement
        if(_.get(this.props,"trustedFlag",""))
        {
          if(coverage_elections.cancer !== null){
          coverageElectionsList[5].employee_covered =  coverage_elections.cancer.employee_covered;
          coverageElectionsList[5].employee_excess_amount =  coverage_elections.cancer.employee_excess_amount;
          coverageElectionsList[5].employee_insured_amount =  coverage_elections.cancer.employee_insured_amount;
          }
          if(coverage_elections.basic_critical_illness !== null) {
          coverageElectionsList[6].employee_covered =  coverage_elections.basic_critical_illness.employee_covered;
          coverageElectionsList[6].employee_excess_amount =  coverage_elections.basic_critical_illness.employee_excess_amount;
          coverageElectionsList[6].employee_insured_amount =  coverage_elections.basic_critical_illness.employee_insured_amount;
          }
          if(coverage_elections.voluntary_critical_illness !== null){
          coverageElectionsList[7].employee_covered =  coverage_elections.voluntary_critical_illness.employee_covered;
          coverageElectionsList[7].employee_excess_amount =  coverage_elections.voluntary_critical_illness.employee_excess_amount;
          coverageElectionsList[7].employee_insured_amount =  coverage_elections.voluntary_critical_illness.employee_insured_amount;
          }
        }
      }
    }

    let spouseCheck = spousedata ? Object.keys(spousedata) : [];
    if(spousedata !== null && spouseCheck.length > 0)
    {
      this.setState({spouseFlag : true});
      let spouse_middleName = spousedata.hasOwnProperty('middle_initial') ? ((spousedata.middle_initial === null) || (spousedata.middle_initial === undefined)  || (spousedata.middle_initial === "")) ? " ": spousedata.middle_initial  :" "
      spouse_name = spousedata.first_name + " "+ spouse_middleName  + " "+ spousedata.last_name;

      if(coverage_elections !== undefined && coverage_elections !== null) 
      {
        if(coverage_elections.basic_life !== null){
          coverageElectionsList[0].spouse_covered =  coverage_elections.basic_life.spouse_covered;
          coverageElectionsList[0].spouse_excess_amount =  coverage_elections.basic_life.spouse_excess_amount;
          coverageElectionsList[0].spouse_insured_amount =  coverage_elections.basic_life.spouse_insured_amount;
        }
        if(coverage_elections.voluntary_life !== null){
          coverageElectionsList[1].spouse_covered =  coverage_elections.voluntary_life.spouse_covered;
          coverageElectionsList[1].spouse_excess_amount =  coverage_elections.voluntary_life.spouse_excess_amount;
          coverageElectionsList[1].spouse_insured_amount =  coverage_elections.voluntary_life.spouse_insured_amount;
        }
        
        if(_.get(this.props,"trustedFlag",""))
        {
          if(coverage_elections.cancer !== null){
            coverageElectionsList[5].spouse_covered =  coverage_elections.cancer.spouse_covered;
            coverageElectionsList[5].spouse_excess_amount =  coverage_elections.cancer.spouse_excess_amount;
            coverageElectionsList[5].spouse_insured_amount =  coverage_elections.cancer.spouse_insured_amount;
          }
        }
      }
    }

    let childCheck = Object.keys(childdata);
    let child_middleName = "";
    if(childdata !== null && childCheck.length > 0 )
    {
      this.setState({childFlag : true});
      //child_name = childdata.first_name + " "+ childdata.middle_initial + " "+ childdata.last_name;
    
      if (childdata.length > 0) {
        childdata.map(child => {
           child_middleName = child.hasOwnProperty('middle_initial') ? ((child.middle_initial === null)|| (child.middle_initial === undefined) || (child.middle_initial === "")) ?  " ": child.middle_initial  : " "
            childNames.push(child.first_name + " "+ child_middleName + " "+ child.last_name);
            childDobs.push(child.dob);
            childGenders.push(child.gender);
            if(coverage_elections !== undefined && coverage_elections !== null) {
              if(coverage_elections.basic_life !== null){
                childElection[0].child_covered =  coverage_elections.basic_life.child_covered;
                childElection[0].child_excess_amount =  coverage_elections.basic_life.child_excess_amount;
                childElection[0].child_insured_amount =  coverage_elections.basic_life.child_insured_amount;
              }
              if(coverage_elections.voluntary_life !== null){
                childElection[1].child_covered =  coverage_elections.voluntary_life.child_covered;
                childElection[1].child_excess_amount =  coverage_elections.voluntary_life.child_excess_amount;
                childElection[1].child_insured_amount =  coverage_elections.voluntary_life.child_insured_amount;
              }
                if(_.get(this.props,"trustedFlag",""))
                {
                  if(coverage_elections.cancer !== null){
                  childElection[2].child_covered =  coverage_elections.cancer.child_covered;
                  childElection[2].child_excess_amount =  coverage_elections.cancer.child_excess_amount;
                  childElection[2].child_insured_amount =  coverage_elections.cancer.child_insured_amount;
                  }
                }
            }
            
            childElectionsList.push(childElection);
            child_DownList.push(this.state.childShow);
            return child;
        })
      }
    }
  
  let check_employee_covered = coverageElectionsList.every(item => item.employee_covered === false);
  let check_spouse_covered = coverageElectionsList.every(a => a.spouse_covered === false);

    this.setState({ 
      memberName: member_name,
      spouseName: spouse_name, 
      memberAddress1 : member_address1,
      memberAddress2: member_address2,
      memberData: memberdata,
      spouseData: spousedata,
      childData: childdata,
      coverageElections : coverageElectionsList ,
      child_electionsList: childElectionsList,
      childNameList: childNames,
      childDobList: childDobs,
      childGenderList: childGenders,
      checkEmployeeCovered: check_employee_covered,
      checkSpouseCovered: check_spouse_covered,
      childDownList :child_DownList,
      primaryPhoneNumber: primary_phone,
      secondaryPhoneNumber: secondary_phone,
      whoGetCovered: getCovered,
      displayMember:allEmployeeStatus.includes(true),                    
      displaySpouse:allSpouseStatus.includes(true),
      displayChild:allChildrenStatus.includes(true),
  })

  }
  

  collapse = (ele,rel) => {
    let showUpFlag = false;
    if(rel ==="member"){
        showUpFlag = this.state.showUpMember;
        if(showUpFlag === true){
            this.setState({ showUpMember : false});
        }
        else{
            this.setState({ showUpMember : true});
        }
    }
   else if(rel ==="spouse"){
        showUpFlag = this.state.showDownSpouse;
        if(showUpFlag === true){
            this.setState({ showDownSpouse : false});
        }
        else{
            this.setState({ showDownSpouse : true});
        }
    }
    else{
      if (this.state.childCollapse.includes(rel)) {
        let removeId = this.state.childCollapse.filter(id => id !== rel)
        this.setState({
          childCollapse: removeId
        })
      }
      else {
        this.setState({
          childCollapse: [...this.state.childCollapse, rel]
        })
      }
        // const childList = this.state.childDownList;
        // const filteredChildList  = childList[rel] ;
        // let filteredobj = filteredChildList;
        // console.log(childList)
        // console.log(filteredChildList)
        // console.log
        // if(filteredobj.showDownChild === true){
        //     filteredobj.showDownChild = false;
        //     childList[rel]= filteredobj ;
        //     this.setState({ childDownList : childList});
        // }
        // else {
        //     filteredobj.showDownChild = true;
        //     childList[rel]= filteredobj ;
        //     this.setState({ childDownList : childList});
        // }
    }
}
    editInfo = () => {
      _.set(this.props, "prefillData.eoi_data.benefit_coverage_info_status", "ACTIVE");
             _.set(this.props, "prefillData.eoi_data.health_question_info_status","");
            _.set(this.props, "prefillData.eoi_data.confirm_submit_status","")
            if(this.props.trustedFlag === true){
               this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
             }
        this.props.history.push({
          pathname: `/eoi/contact`,
          state: {"previousPage":"review"}})
      }

    
        
        
      handleInfo = () => {
        // let whoCovered = _.get(this.props, "whoCovered",  "");
        // // if(this.props.whoCovered.includes("Me")){
        //   const {trustedFlag} = this.props;
        //   if(trustedFlag) {
        //       _.set(this.props, "prefillData.eoi_data.benefit_coverage_info_status", "ACTIVE");
        //       _.set(this.props, "prefillData.eoi_data.health_question_info_status","");
        //       _.set(this.props, "prefillData.eoi_data.confirm_submit_status","")
        //       if(this.props.trustedFlag === true){
        //           this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""),'/eoi/benefits')
        //       }
        //       // this.props.history.push(`/eoi/benefits`)
        //   }else {
        //   _.set(this.props,"dataRestoration",_.get(this.props,"prefillData"))
        //   _.set(this.props, "nonTrustedPageStatus.add_benefit_coverage_info_status", "ACTIVE");
        //   _.set(this.props, "nonTrustedPageStatus.health_question_info_status","");
        //   _.set(this.props, "nonTrustedPageStatus.confirm_submit_status","")
        //   //_.set(this.props,"")
        //   let p = _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information")
        //   _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information",p)
        //   _.set(this.props, "nonTrustedData.eoi_data.health_question_info",_.get(this.props,"prefillData.eoi_data.health_question_info"))
        //   _.set(this.props, "nonTrustedData.eoi_data.health_authorization",_.get(this.props,"prefillData.eoi_data.health_authorization"))
        //   console.log(this.props)
        //   this.props.toRestoreData(_.get(this.props,"prefillData"),`/eoi/tellUsMore`)
        //   //updatePersonalInfoInNonTrusted()
        _.set(this.props,"dataRestoration",_.get(this.props,"prefillData"))
        _.set(this.props, "nonTrustedPageStatus.add_benefit_coverage_info_status", "ACTIVE");
        _.set(this.props, "nonTrustedPageStatus.health_question_info_status","");
        _.set(this.props, "nonTrustedPageStatus.confirm_submit_status","");

        let p = _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information")
          _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information",p)
          _.set(this.props, "nonTrustedData.eoi_data.health_question_info",_.get(this.props,"prefillData.eoi_data.health_question_info"))
          _.set(this.props, "nonTrustedData.eoi_data.health_authorization",_.get(this.props,"prefillData.eoi_data.health_authorization"))
          this.props.toRestoreData(_.get(this.props,"prefillData"),`/eoi/tellUsMore`)
          this.props.history.push({
            pathname: `/eoi/tellUsMore`,
            state: {"previousPage":"review"}})
        } 
          
      
        handleSpouse =() =>{
          _.set(this.props,"dataRestoration",_.get(this.props,"prefillData"))
          _.set(this.props, "nonTrustedPageStatus.add_benefit_coverage_info_status", "ACTIVE");
          _.set(this.props, "nonTrustedPageStatus.health_question_info_status","");
          _.set(this.props, "nonTrustedPageStatus.confirm_submit_status","");
  
          let p = _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information")
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information",p)
            _.set(this.props, "nonTrustedData.eoi_data.health_question_info",_.get(this.props,"prefillData.eoi_data.health_question_info"))
            _.set(this.props, "nonTrustedData.eoi_data.health_authorization",_.get(this.props,"prefillData.eoi_data.health_authorization"))
            this.props.toRestoreData(_.get(this.props,"prefillData"),`/eoi/whoAreWeCovering`)
       
            this.props.history.push({
             
              pathname: `/eoi/whoAreWeCovering`,
              state: {"previousPage":"review"}})
          }
          handleChild =() =>{
            _.set(this.props,"dataRestoration",_.get(this.props,"prefillData"))
            _.set(this.props, "nonTrustedPageStatus.add_benefit_coverage_info_status", "ACTIVE");
            _.set(this.props, "nonTrustedPageStatus.health_question_info_status","");
            _.set(this.props, "nonTrustedPageStatus.confirm_submit_status","");
    
            let p = _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information")
              _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information",p)
              _.set(this.props, "nonTrustedData.eoi_data.health_question_info",_.get(this.props,"prefillData.eoi_data.health_question_info"))
              _.set(this.props, "nonTrustedData.eoi_data.health_authorization",_.get(this.props,"prefillData.eoi_data.health_authorization"))
              this.props.toRestoreData(_.get(this.props,"prefillData"),`/eoi/whoAreWeCovering`)
         
            this.props.history.push({
             
              pathname: `/eoi/whoAreWeCovering`,
              state: {"previousPage":"review"}})
          }
  
        
          

    render() {


      const {trustedFlag} = this.props ;
      // console.log(_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state"),"state")
     

        return (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-md-10">
                    <div className="row">
                      <ul className="col-lg-10 accordion" id="accordion">
                      {this.state.displayMember ? 
                        <li className="accordion-card">
                          <div className="accordion-card-header" id="headingOne">
                            <h3 id="coverageHeading" class="h6">Me - {this.state.memberName}</h3>
                            <button
                              className="close hidden-text"
                              type="button"
                              data-toggle="collapse"
                              aria-labelledby='coverageHeading'
                              data-target="#collapseOne"
                              aria-expanded={this.state.showUpMember}
                              aria-controls="collapseOne"
                              onClick={(e)=>this.collapse(e,"member")}
                            >
                            <i className={this.state.showUpMember ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
                            </button>
                          </div>
                          <div id="collapseOne" className={this.state.showUpMember ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordion">
                        {this.state.checkEmployeeCovered? null :
                        <div className="light-blue-container">
                          {this.state.coverageElections.map((coverage, index) => {
                          return (<div className="accordion-card-body">
                        {coverage.employee_covered ?
                          <div>
                            <div className="flex-accordion-header">
                              <img
                                // src = "./images/critical-illness-red.svg"
                                src={coverageDetails[coverage.coverage_name].url}
                                alt=""
                                className="data-icon mt-1 pr-1"
                                width="24px"
                                height="19px"
                              />
                              <h4 class="h6" htmlFor={coverage.coverage_name}>
                              {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "NY" ?
					                      <>{(coverageDetails[coverage.coverage_name].value === "Basic Critical Illness")  ? 'Specified Disease' : (coverageDetails[coverage.coverage_name].value === "Voluntary Critical Illness")  ? 'Voluntary Specified Disease' : coverageDetails[coverage.coverage_name].value }</> :<>{ coverageDetails[coverage.coverage_name].value}</> 
                              }
                             </h4>
                            </div>
                            <div className="resp-accordion-text">
                              <div className="accordion-content-container">
                                <p className="font-weight-bold">Current insured</p>
                                <p>${coverage.employee_insured_amount ? coverage.employee_insured_amount : '0'}</p>
                              </div>
                              <div className="accordion-content-container">
                                <p className="font-weight-bold">Additional requested</p>
                                <p>${coverage.employee_excess_amount ? coverage.employee_excess_amount : '0'}</p>
                              </div>
                            </div> 
                            </div> : null }
                            </div>)
                          })
                          }
                          </div>}
                            <div className="accordion-card-body">
                              <div className="white-bg-container">                                
                                <div className={trustedFlag ? "flex-accordion-text dark-gray" : "flex-accordion-text"}> 
                                  <div className="accordion-content-container">
                                    <p className="font-weight-bold">SSN</p>
                                    <p>*****{this.state.memberData.ssn3}</p>
                                  </div>
                                  <div className="accordion-content-container">
                                    <p className="font-weight-bold">Gender</p>
                                    {this.state.memberData.gender === "F" ? <p>Female</p> : <p>Male</p> }
                                  </div>
                                </div>
                                <div className={trustedFlag ? "flex-accordion-text dark-gray" : "flex-accordion-text"}>                                  
                                  <div className="accordion-content-container">
                                    <p className="font-weight-bold">Date of birth</p>
                                    <p>{moment(this.state.memberData.dob).format('MM/DD/YYYY')}</p>
                                  </div>
                                  {/* <div className="accordion-content-container">
                                    <p className="font-weight-bold">Place of birth(state)</p>
                                    <p>{this.state.memberData.state}</p>
                                  </div> */}
                                </div>
                                <div className={trustedFlag ? "flex-accordion-text dark-gray" : "flex-accordion-text"}>
                                  <div className="accordion-content-container">
                                    <p className="font-weight-bold">Address</p>
                                    <p>{this.state.memberAddress1}</p>
                                    <p>{this.state.memberAddress2}</p>
                                  </div>
                                  </div>
                                <div className="flex-accordion-text">
                                  
                                {this.state.primaryPhoneNumber ? 
                                  <div className="accordion-content-container">
                                    <p className="font-weight-bold">Primary phone</p>
                                    {this.state.memberData.primary_phone_type === "H" ? <p>Home</p> : <p>Mobile</p>}
                                    {/* <p>({this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone1}) {this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone2} - {this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone3}</p> */}
                                    {/* <p>({this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone1}) {this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone2} - {this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone3}</p> */}
                                    <p>{this.state.primaryPhoneNumber}</p>
                                  </div>:null}
                                  {this.state.secondaryPhoneNumber ? 
                                  <div className="accordion-content-container">
                                    <p className="font-weight-bold">Secondary phone</p>
                                    {this.state.memberData.secondary_phone_type === "H" ? <p>Home</p> : <p>Mobile</p>}
                                    {/* <p>({this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone1}) {this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone2} - {this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone3}</p> */}
                                    {/* <p>({this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone1}) {this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone2} - {this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone3}</p> */}
                                    <p>{this.state.secondaryPhoneNumber}</p>
                                  </div>:null}
                                </div>
                                <div className="accordion-content-indent">
                                  <div className="accordion-content-container pb-2">
                                    <p className="font-weight-bold">Email address</p>
                                    <p>{this.state.memberData.email_address}</p>
                                  </div>
                                  <div className="accordion-content-container">
                                    <p className="font-weight-bold">
                                      Preferred method of communication
                                    </p>
                                    {this.state.memberData.contact_method === "P" ? <p>Primary phone</p> : this.state.memberData.contact_method === "S" ?<p>Secondary phone</p> : <p>Email</p>}
                                  </div>
                                </div>
                              </div>
                             
                              {!trustedFlag ? 
                                <Button type="outlined" color="navy" className="m-3 ml-5" onClick ={this.handleInfo}>
                                  Edit personal information
                                </Button> :  <Button type="outlined" color="navy" className="m-3 ml-5" onClick ={this.editInfo}>
                                  Edit personal information
                                    </Button>}
                              <div className="ml-5 w-75">
                              { trustedFlag ? <>
                                  <p>See something that’s not right?</p>
                                <p>
                                  Contact your plan admin to make updates. This will not
                                  impact your submission.
                                </p> </> :<>
                                <p>If you edit your personal information, you'll need to re-answer the health questions</p>
                                </>
                                }
                              </div>
                            </div>
                          </div>
                        </li> : " "}
                        {this.state.displaySpouse ? 
                        <li className="accordion-card">
                          <div className="accordion-card-header" id="headingTwo">
                            <h3 id="coverageHeadingSpouse" class="h6">My spouse - {this.state.spouseName}</h3>
                            <button
                              className="close hidden-text"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-labelledby='coverageHeadingSpouse'
                              aria-expanded={this.state.showDownSpouse}
                              aria-controls="collapseTwo"
                              onClick={(e)=>this.collapse(e,"spouse")}
                            >
                            <i className={this.state.showDownSpouse ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
                            </button>
                          </div>
                          <div id="collapseTwo" className={this.state.showDownSpouse ? "collapse show" : "collapse"} aria-labelledby="headingTwo" data-parent="#accordion">
                        {this.state.checkSpouseCovered ? null :
                        <>
                          {this.state.coverageElections.map((coverage, index) => {
                          return (<>
                        {coverage.spouse_covered ?
                        <div className="accordion-card-body">
                          <div className="light-blue-container">
                            <div className="flex-accordion-header">
                            <img
                                // src = "./images/critical-illness-red.svg"
                                src={coverageDetails[coverage.coverage_name].url}
                                alt=""
                                className="data-icon mt-1 pr-1 "
                                width="24px"
                                height="19px"                                
                              />
                              {/* <h6 htmlFor={coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</h6> */}
                              <h4 class="h6" htmlFor={coverage.coverage_name}>
                              {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "NY" ?
					                      <>{(coverageDetails[coverage.coverage_name].value === "Basic Critical Illness")  ? 'Specified Disease' : (coverageDetails[coverage.coverage_name].value === "Voluntary Critical Illness")  ? 'Voluntary Specified Disease' : coverageDetails[coverage.coverage_name].value }</> :<>{ coverageDetails[coverage.coverage_name].value}</> 
                              }
                             </h4>
                            </div>
                            <div className="resp-accordion-text">
                              <div className="accordion-content-container">
                                <p className="font-weight-bold">Current insured</p>
                                <p>${coverage.spouse_insured_amount ? coverage.spouse_insured_amount : '0'}</p>
                              </div>
                              <div className="accordion-content-container">
                                <p className="font-weight-bold">Additional requested</p>
                                <p>${coverage.spouse_excess_amount ? coverage.spouse_excess_amount : '0'}</p>
                              </div>
                            </div> 
                            </div></div>: null }
                            </>)
                          })
                          }
                          </>}

                          <div className="accordion-card-body">
                              <div className="white-bg-container">
                                <div className={trustedFlag ? "flex-accordion-text dark-gray" : "flex-accordion-text"}>
                                  <div className="accordion-content-container">
                                    <p className="font-weight-bold">Gender</p>
                                    {this.state.spouseData.gender === "F" ? <p>Female</p> : <p>Male</p> }
                                  </div>
                                  <div className="accordion-content-container">
                                    <p className="font-weight-bold">Date of Birth</p>
                                    <p>{moment(this.state.spouseData.dob).format('MM/DD/YYYY')}</p>
                                  </div>
                                </div>
                              </div>
                              {!trustedFlag ? 
                                <Button type="outlined" color="navy" className="m-3 ml-5" onClick ={this.handleSpouse}>
                                  Edit personal information
                                </Button> : null}
                              <div className="ml-5 w-75">
                              { trustedFlag ? <>
                                  <p>See something that’s not right?</p>
                                <p>
                                  Contact your plan admin to make updates. This will not
                                  impact your submission.
                                </p> </> :<>
                                <p>If you edit your personal information, you'll need to re-answer the health questions</p>
                                </>
                                }
                              </div>
                            </div>
                          </div>
                        </li> : " "}
    {/* {this.props.healthQData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child !== null ?  */}
                    {this.state.displayChild && this.state.child_electionsList.map((childElection, outerIndex) =>(
                        <li className="accordion-card">
                          <div className="accordion-card-header" id={"headingThree"+outerIndex}>
                            <h3 id="coverageHeadingChild" class="h6">My child - {this.state.childNameList[outerIndex]}</h3>
                            <button
                              className="close hidden-text"
                              type="button"
                              data-toggle="collapse"
                              data-target={"#collapseThree"}
                              aria-labelledby='coverageHeadingChild'
                              aria-expanded={this.state.childCollapse.includes(outerIndex)}
                              aria-controls={"collapseThree"}
                              onClick={(e)=>this.collapse(e,outerIndex)}
                            >
                          <i className={this.state.childCollapse.includes(outerIndex) ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
                            </button>
                          </div>
                          <div id={"collapseThree"} className={this.state.childCollapse.includes(outerIndex) ? "collapse show " : "collapse "} aria-labelledby={"headingThree"+outerIndex} data-parent="#accordion">
                        <>
                        
                        {childElection.map((coverage, index) => {
                        return (<>
                        {coverage.child_covered ?
                          <div className="accordion-card-body">
                          <div className="light-blue-container">
                            <div className="flex-accordion-header">
                            <img
                                // src = "./images/critical-illness-red.svg"
                                src={coverageDetails[coverage.coverage_name].url}
                                alt=""
                                className="data-icon mt-1 pr-1"
                                width="24px"
                                height="19px"
                              />
                              <h4 class="h6" htmlFor={"child"+outerIndex+index + coverage.coverage_name}>
                              {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "NY" ?
					                      <>{(coverageDetails[coverage.coverage_name].value === "Basic Critical Illness")  ? 'Specified Disease' : (coverageDetails[coverage.coverage_name].value === "Voluntary Critical Illness")  ? 'Voluntary Specified Disease' : coverageDetails[coverage.coverage_name].value }</> :<>{ coverageDetails[coverage.coverage_name].value}</> 
                              }
                             </h4>
                            </div>
                            <div className="resp-accordion-text">
                              <div className="accordion-content-container">
                                <p className="font-weight-bold">Current insured</p>
                                <p>${coverage.child_insured_amount ? coverage.child_insured_amount : '0'}</p>
                              </div>
                              <div className="accordion-content-container">
                                <p className="font-weight-bold">Additional requested</p>
                                <p>${coverage.child_excess_amount ? coverage.child_excess_amount : '0'}</p>
                              </div>
                            </div> 
                            </div> </div>: null }
                            </>)
                          })
                          }
                              <div className="white-bg-container">
                                <div className={trustedFlag ? "flex-accordion-text dark-gray" : "flex-accordion-text"}>
                                  <div className="accordion-content-container">
                                    <p className="font-weight-bold">Gender</p>
                                    {this.state.childGenderList[outerIndex] === "F" ? <p>Female</p> : <p>Male</p> }
                                  </div>
                                  <div className="accordion-content-container">
                                    <p className="font-weight-bold">Date of Birth</p>
                                    <p>{moment(this.state.childDobList[outerIndex]).format('MM/DD/YYYY')}</p>
                                  </div>
                                </div>
                              </div>
                                {!trustedFlag ? 
                                <Button type="outlined" color="navy" className="m-3 ml-5" onClick ={this.handleChild}>
                                  Edit personal information
                                </Button> : null}
                              <div className="ml-5 w-75">
                              { trustedFlag ? <>
                                  <p>See something that’s not right?</p>
                                <p>
                                  Contact your plan admin to make updates. This will not
                                  impact your submission.
                                </p> </> :<>
                                <p>If you edit your personal information, you'll need to re-answer the health questions</p>
                                </>
                                }
                              </div>
                       
                            </>
                          </div>
                          </li> ))}
                        {/* </li> : " "} */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
          </>
        )
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(ReviewDetails);
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
import _ from "lodash";
import CoverageList from '../../components/ReviewBenefit/CoverageList';
import WhoIsGettingCovered from '../../components/ReviewBenefit/WhoIsGettingCovered';
import ReviewBenefitsPopup from './ReviewBenefitsPopup';
import SaveForLater from '../../components/SaveForLater/SaveForLater';

class ReviewBenefit extends Component {
   
    state = {
        coveragesElected : [],
        gettingCovered : "",
        gettingCoveredDetails:{},
        employeeName : "",
        spouseName : "",
        childrenNames : "",
        employeeCovered: false,
        spouseCovered: false,
        childCovered: false,
        showBenefitPopup : false,
        isSaved:false,
        isOpened:false
    }
    onBackClick = () => {
        this.props.history.push(`/eoi/homePage`)
    }

    handleClick = () => {
        _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.review_benefits_status","COMPLETED")
        _.set(this.props,"prefillData.eoi_data.benefit_coverage_info.confirm_contact_status","ACTIVE")
      this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""),'/eoi/contact')
        //this.props.history.push(`/eoi/contact`)
    }
    componentDidMount() {
        document.title = "Review the basics | Group EOI - Guardian";
        
        this.props.saveEoiData(this.props.prefillData); 

        const coverages = JSON.parse(JSON.stringify(_.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections",{})))
        const personalInfo = JSON.parse(JSON.stringify(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information",{})))
        let employee_name=""
        if(_.get(personalInfo,"employee.middle_initial","") && _.get(personalInfo,"employee.middle_initial","").length>0){
            employee_name = _.get(personalInfo,"employee.first_name","") +' '+_.get(personalInfo,"employee.middle_initial","")+' '+_.get(personalInfo,"employee.last_name","")
        }
        else{
            employee_name = _.get(personalInfo,"employee.first_name","") +' '+_.get(personalInfo,"employee.last_name","")
        }
        let spouse_name=""
        if(_.get(personalInfo,"spouse.middle_initial","") && _.get(personalInfo,"spouse.middle_initial","").length>0){
            spouse_name = _.get(personalInfo,"spouse.first_name","") +' '+_.get(personalInfo,"spouse.middle_initial","")+' '+_.get(personalInfo,"spouse.last_name","")
        }
        else{
            spouse_name = _.get(personalInfo,"spouse.first_name","") +' '+_.get(personalInfo,"spouse.last_name","")
        }
        
        const children = _.get(personalInfo,"child","")
        const disabilityCoverages = ["short_term_disability", "long_term_disability", "voluntary_long_term_disability", "voluntary_short_term_disability" ]
        let coverageElected = []
        let allEmployeeStatus = []
        let allSpouseStatus = []
        let allChildrenStatus = []
        //let allElectedStatus
        

        for (const coverage in coverages){
            let disabilityCoverageElectedStatus = false
            let employeeCoveredStatus = false
            let spouseCoveredStatus = false
            let childCoveredStatus = false
            if(disabilityCoverages.includes(coverage)){
                
                disabilityCoverageElectedStatus = coverages[coverage] && coverages[coverage].elected && coverages[coverage].elected
            }
            else{
                 employeeCoveredStatus = coverages[coverage] && coverages[coverage].employee_covered && coverages[coverage].employee_covered
                 spouseCoveredStatus = coverages[coverage] && coverages[coverage].spouse_covered && coverages[coverage].spouse_covered
                 childCoveredStatus = coverages[coverage] && coverages[coverage].child_covered && coverages[coverage].child_covered 
            }
            
            //console.log(employeeCoveredStatus,spouseCoveredStatus,childCoveredStatus,disabilityCoverageElectedStatus)
            if(employeeCoveredStatus === true || spouseCoveredStatus === true || childCoveredStatus ===true || disabilityCoverageElectedStatus === true){
                coverageElected.push(coverage)
            }
            allEmployeeStatus.push(employeeCoveredStatus)
            allEmployeeStatus.push(disabilityCoverageElectedStatus)
            allSpouseStatus.push(spouseCoveredStatus)
            allChildrenStatus.push(childCoveredStatus)
        }

        this.setState({
            coveragesElected : coverageElected,
            employeeCovered: allEmployeeStatus.includes(true),
            spouseCovered:allSpouseStatus.includes(true),
            childCovered:allChildrenStatus.includes(true),
            employeeName : employee_name,
            spouseName : spouse_name,
            childrenNames : children
        })

        
    }
    gettingCovered = () => {
        const { employeeCovered, spouseCovered, childCovered } = this.state

        // let childKey =""
        //     if(childrenNames && childrenNames.length === 1) childKey = "child"
        //     else childKey = "children"

         if(employeeCovered ===true && spouseCovered===true && childCovered===true){
            return "Me, my spouse, my child(ren)"
            
         }
         else if(employeeCovered ===true && spouseCovered===true && childCovered===false){
             return "Me, my spouse"
            
         }
         else if(employeeCovered ===true && spouseCovered===false && childCovered===false){
             return "Me"
            
         }
         else if(employeeCovered ===false && spouseCovered===true && childCovered===false){
             return "My spouse"
             
         }
         else if(employeeCovered ===false && spouseCovered===false && childCovered===true){
             return "My child(ren)"
            
        }
         else if(employeeCovered ===true && spouseCovered===false && childCovered===true){
             return "Me, my child(ren)"
            
        }
         else if(employeeCovered ===false && spouseCovered===true && childCovered===true){
             return "My spouse, my child(ren)"
            
        }
         
    }
    saveForLater = () => {
        this.setState({isSaved:true});
        setTimeout(() => {
            const el = document.getElementById("continue-button")
            if (el) {
                el.focus();
            }
        }, 500);
    }
    continueEOI = () => {
        this.setState({isSaved:false});
    }
    leave = () => {
        this.props.history.push({ pathname: `/eoi/success`})
    }
    onBenefitPopup() {
        this.setState({ showBenefitPopup: !this.state.showBenefitPopup });
    } 
    onClosepopup = () => {
        //console.log("this.state", this.state);
        this.setState({ showBenefitPopup: !this.state.showBenefitPopup });
    } 


    render() {
        let headingLabel = "Spouse";
        let contentlabel = "This includes marital spouse, domestic partner and/or civil union.";
        return (
            <>
            {this.state.isSaved === true ? <SaveForLater continueEOI={()=>this.continueEOI()} leave = {()=> this.leave()}/> : null}
            {this.state.showBenefitPopup ?
                 <ReviewBenefitsPopup headingLabel={headingLabel} contentlabel={contentlabel} closepopup={this.onClosepopup} />
                 :
                <main>
                    <div className="container-fluid">
                        <div className="prev-link">
                            <button className="btn btn-link" onClick={() => this.onBackClick()}><i className="fa fa-arrow-left"></i>Back</button>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="intro">
                                    <h1 style = {{fontSize:'32px'}}>Review the basics</h1>
                                    <p className="lead-small mb-4">Here are the benefits and people you’ll be working through today.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <CoverageList coveragesElected = {this.state.coveragesElected} 
                                            checkState = {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state")}/>
                                <WhoIsGettingCovered
                                    onBenefitPopup={this.onBenefitPopup.bind(this)}
                                    gettingCovered = {this.gettingCovered()} 
                                    employeeCovered = {this.state.employeeCovered}
                                    spouseCovered = {this.state.spouseCovered}
                                    childCovered = {this.state.childCovered}
                                    employeeName = {this.state.employeeName}
                                    spouseName = {this.state.spouseName}
                                    childrenNames = {this.state.childrenNames}
                                />
                                <hr />
                                <div className="field-group">
                                    <button className="btn btn-link" onClick={()=>this.saveForLater()}>Save for later</button>
                                    <button className="btn btn-primary float-right" onClick={() => this.handleClick()}>Next</button>
                                </div>
                            </div> 
                        </div>

                        

                    </div>
                    {this.state.spouseCovered && <div className="tips-container-benefit">
                        <div className="tip-box">
                        <div className="heading">Spouse</div>
                        This includes marital spouse, domestic partner and/or civil union.
                        </div>
                    </div> }
                </main >
            }
            </>
        );
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(ReviewBenefit);
//export default ReviewBenefit;
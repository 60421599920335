import React, { Component } from 'react';
import { coverageDetails } from '../../utils/constant';
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
import _ from "lodash";
import AlertBanner from '../../components/AlertBanner/AlertBanner';
import { scrollUp } from '../../utils/sharedFunction';


class SelectMemberCoverage extends Component {
    state = {
        coverage_elections: [
            {
                coverage_name: "basic_life",
                employee_covered: false,
                employee_excess_amount: null,
                employee_insured_amount: null,
                spouse_covered: false,
                spouse_excess_amount: null,
                spouse_insured_amount: null,
                child_covered: false,
                child_excess_amount: null,
                child_insured_amount: null,
                ceaErr: false,
                ciaErr: false,
                ceazErr: false,
                eeaErr: false,
                eiaErr: false,
                eeazErr: false,
                seaErr: false,
                siaErr: false,
                seazErr: false,
                member_only: false
            },
            {
                coverage_name: "voluntary_life",
                employee_covered: false,
                employee_excess_amount: null,
                employee_insured_amount: null,
                spouse_covered: false,
                spouse_excess_amount: null,
                spouse_insured_amount: null,
                child_covered: false,
                child_excess_amount: null,
                child_insured_amount: null,
                ceaErr: false,
                ciaErr: false,
                ceazErr: false,
                eeaErr: false,
                eiaErr: false,
                eeazErr: false,
                seaErr: false,
                siaErr: false,
                seazErr: false,
                member_only: false
            },
            {
                coverage_name: "long_term_disability",
                employee_covered: false,
                employee_excess_amount: null,
                employee_insured_amount: null,
                eeaErr: false,
                eiaErr: false,
                eeazErr: false,
                member_only: true
            },
            {
                coverage_name: "short_term_disability",
                employee_covered: false,
                employee_excess_amount: null,
                employee_insured_amount: null,
                eeaErr: false,
                eiaErr: false,
                eeazErr: false,
                member_only: true
            },
            {
                coverage_name: "voluntary_long_term_disability",
                employee_covered: false,
                employee_excess_amount: null,
                employee_insured_amount: null,
                eeaErr: false,
                eiaErr: false,
                eeazErr: false,
                member_only: true
            }],
        dropdownValue: "",
        bannerFlag: false,

    }
    componentDidMount() {
        scrollUp()
        let coverageElectionsList = this.state.coverage_elections;
        let dropdown = _.get(this.props, "whoCovered");
        let coverageElections = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections", {});
        //console.log(coverageElections);
        if (coverageElections.basic_life !== undefined) {
            coverageElectionsList[0].employee_covered = coverageElections.basic_life.employee_covered;
            coverageElectionsList[0].employee_excess_amount = coverageElections.basic_life.employee_excess_amount;
            coverageElectionsList[0].employee_insured_amount = coverageElections.basic_life.employee_insured_amount;
            coverageElectionsList[0].spouse_covered = coverageElections.basic_life.spouse_covered;
            coverageElectionsList[0].spouse_excess_amount = coverageElections.basic_life.spouse_excess_amount;
            coverageElectionsList[0].spouse_insured_amount = coverageElections.basic_life.spouse_insured_amount;
            coverageElectionsList[0].child_covered = coverageElections.basic_life.child_covered;
            coverageElectionsList[0].child_excess_amount = coverageElections.basic_life.child_excess_amount;
            coverageElectionsList[0].child_insured_amount = coverageElections.basic_life.child_insured_amount;

            coverageElectionsList[1].employee_covered = coverageElections.voluntary_life.employee_covered;
            coverageElectionsList[1].employee_excess_amount = coverageElections.voluntary_life.employee_excess_amount;
            coverageElectionsList[1].employee_insured_amount = coverageElections.voluntary_life.employee_insured_amount;
            coverageElectionsList[1].spouse_covered = coverageElections.voluntary_life.spouse_covered;
            coverageElectionsList[1].spouse_excess_amount = coverageElections.voluntary_life.spouse_excess_amount;
            coverageElectionsList[1].spouse_insured_amount = coverageElections.voluntary_life.spouse_insured_amount;
            coverageElectionsList[1].child_covered = coverageElections.voluntary_life.child_covered;
            coverageElectionsList[1].child_excess_amount = coverageElections.voluntary_life.child_excess_amount;
            coverageElectionsList[1].child_insured_amount = coverageElections.voluntary_life.child_insured_amount;

            coverageElectionsList[2].employee_covered = coverageElections.long_term_disability.elected;
            coverageElectionsList[2].employee_excess_amount = coverageElections.long_term_disability.excess_amount;
            coverageElectionsList[2].employee_insured_amount = coverageElections.long_term_disability.insured_amount;

            coverageElectionsList[3].employee_covered = coverageElections.short_term_disability.elected;
            coverageElectionsList[3].employee_excess_amount = coverageElections.short_term_disability.excess_amount;
            coverageElectionsList[3].employee_insured_amount = coverageElections.short_term_disability.insured_amount;

            coverageElectionsList[4].employee_covered = coverageElections.voluntary_long_term_disability.elected;
            coverageElectionsList[4].employee_excess_amount = coverageElections.voluntary_long_term_disability.excess_amount;
            coverageElectionsList[4].employee_insured_amount = coverageElections.voluntary_long_term_disability.insured_amount;

            this.setState({
                coverage_elections: coverageElectionsList,
                dropdownValue: dropdown,
            })
        }
        //console.log(coverageElectionsList)
        this.setState({ dropdownValue: dropdown })
    }

    onPrevious = () => {
        this.props.history.push(`/eoi/whoAreWeCovering`);
    }

    onCheckBoxChange = (e, index, relInd) => {
        let banner_flag = this.state.bannerFlag;
        let coverageElectionsList = this.state.coverage_elections;
        let filterObj = coverageElectionsList[index];
        if (relInd === "member") {
            if (filterObj.employee_covered) {
                filterObj.employee_covered = false;
                filterObj.employee_excess_amount = null;
                filterObj.employee_insured_amount = null;
            }
            else {
                filterObj.employee_covered = true;
                banner_flag = false;
                filterObj.eeaErr = false;
                filterObj.eiaErr = false;
                filterObj.eeazErr = false;
            }
        }
        if (relInd === "spouse") {
            if (filterObj.spouse_covered) {
                filterObj.spouse_covered = false;
                filterObj.spouse_excess_amount = null;
                filterObj.spouse_insured_amount = null;
            }
            else {
                filterObj.spouse_covered = true;
                banner_flag = false;
                filterObj.seaErr = false;
                filterObj.siaErr = false;
            }
        }
        if (relInd === "child") {
            if (filterObj.child_covered) {
                filterObj.child_covered = false;
                filterObj.child_excess_amount = null;
                filterObj.child_insured_amount = null;
            }
            else {
                filterObj.child_covered = true;
                banner_flag = false;
                filterObj.seaErr = false;
                filterObj.siaErr = false;
            }
        }

        coverageElectionsList[index] = filterObj;
        //console.log(coverageElectionsList);
        this.setState({ coverage_elections: coverageElectionsList, bannerFlag: banner_flag })

    }

    onTextBoxChange = (e, index, fieldInd) => {
        let coverageElectionsList = this.state.coverage_elections;
        let filterObj = coverageElectionsList[index];
        if (fieldInd === "eea") {
            filterObj.employee_excess_amount = e.floatValue;
        }
        if (fieldInd === "eia") {
            filterObj.employee_insured_amount = e.floatValue;
        }
        if (fieldInd === "eeaz") {
            filterObj.employee_insured_amount = e.floatValue;
        }
        if (fieldInd === "sea") {
            //console.log('sea')
            filterObj.spouse_excess_amount = e.floatValue;
            //console.log(filterObj);
        }
        if (fieldInd === "seaz") {
            filterObj.spouse_insured_amount = e.floatValue;
        }
        if (fieldInd === "sia") {
            filterObj.spouse_insured_amount = e.floatValue;
        }
        if (fieldInd === "cea") {
            filterObj.child_excess_amount = e.floatValue;
        }
        if (fieldInd === "cia") {
            filterObj.child_insured_amount = e.floatValue;
        }
        if (fieldInd === "ceaz") {
            filterObj.child_insured_amount = e.floatValue;
        }
        coverageElectionsList[index] = filterObj;
        this.setState({ coverage_elections: coverageElectionsList })
    }

    handleFieldBlur = (e, index, fieldInd) => {
        let coverageElectionsList = this.state.coverage_elections;
        let filterObj = coverageElectionsList[index];
        if (fieldInd === "eea" && (filterObj.employee_excess_amount !== null || filterObj.employee_excess_amount === 0)) {
            filterObj.eeaErr = false;
        }
        if (fieldInd === "eea" && (filterObj.employee_excess_amount !== 0 || filterObj.employee_excess_amount > 0)) {
            filterObj.eeazErr = false;
        }
        if (fieldInd === "eia" && filterObj.employee_insured_amount !== null) {
            filterObj.eiaErr = false;
        }
        if (fieldInd === "sea" && (filterObj.spouse_excess_amount !== null || filterObj.spouse_excess_amount === 0)) {
            filterObj.seaErr = false;
        }
        if (fieldInd === "sea" && (filterObj.spouse_excess_amount !== 0 || filterObj.spouse_excess_amount > 0)) {
            filterObj.seazErr = false;
        }
        if (fieldInd === "sia" && filterObj.spouse_insured_amount !== null) {
            filterObj.siaErr = false;
        }
        if (fieldInd === "cea" && (filterObj.child_excess_amount !== null || filterObj.child_excess_amount === 0)) {
            filterObj.ceaErr = false;
        }
        if (fieldInd === "cea" && (filterObj.child_excess_amount !== 0 || filterObj.child_excess_amount > 0)) {
            filterObj.ceazErr = false;
        }
        if (fieldInd === "cia" && filterObj.child_insured_amount !== null) {
            filterObj.ciaErr = false;
        }
        coverageElectionsList[index] = filterObj;
        this.setState({ coverage_elections: coverageElectionsList })
    }

    handleClose = () => {
        this.setState({
            bannerFlag: false
        });
    }

    handleNext = () => {
        let dropDown = this.state.dropdownValue;
        let showErr = false;
        let showBanner = this.state.bannerFlag;
        let coverageElectionsList = this.state.coverage_elections;
        if ((dropDown === "Me")) {
            coverageElectionsList.map(cov => {
                if (cov.employee_covered && (cov.employee_excess_amount === undefined || cov.employee_excess_amount === null)) {
                    //console.log("eea");
                    cov.eeaErr = true;
                    showErr = true;
                }
                if (cov.employee_covered && (cov.employee_insured_amount === undefined || cov.employee_insured_amount === null)) {
                    //console.log('eia');
                    cov.eiaErr = true;
                    showErr = true;
                }
                if (cov.employee_covered && (cov.employee_excess_amount === 0)) {
                    cov.eeazErr = true;
                    showErr = true;
                }
                return cov
            })
        }

        if ((dropDown === "My spouse")) {
            coverageElectionsList.map(cov => {
                if (!cov.member_only && cov.spouse_covered && (cov.spouse_excess_amount === undefined || cov.spouse_excess_amount === null)) {
                    //console.log("sea err");
                    cov.seaErr = true;
                    showErr = true;
                }
                if (!cov.member_only && cov.spouse_covered && (cov.spouse_insured_amount === undefined || cov.spouse_insured_amount === null)) {
                    //console.log("sia err");
                    cov.siaErr = true;
                    showErr = true;
                }
                if (!cov.member_only && cov.spouse_covered && (cov.spouse_excess_amount === 0)) {
                    // console.log(cov.spouse_excess_amount,"amount")
                    cov.seazErr = true;
                    showErr = true;
                    //  console.log(cov.seazErr,"vlaue",cov.spouse_excess_amount <=0)
                }
                return cov
            })
        }


        if ((dropDown === "My child")) {
            coverageElectionsList.map(cov => {
                if (!cov.member_only && cov.child_covered && (cov.child_excess_amount === undefined || cov.child_excess_amount === null)) {
                    cov.ceaErr = true;
                    showErr = true;
                }
                if (!cov.member_only && cov.child_covered && (cov.child_insured_amount === undefined || cov.child_insured_amount === null)) {
                    cov.ciaErr = true;
                    showErr = true;
                }
                if (!cov.member_only && cov.child_covered && (cov.child_excess_amount === 0)) {
                    cov.ceazErr = true;
                    showErr = true;
                }
                return cov
            })
        }

        if ((dropDown === "Me")) {
            if (coverageElectionsList.filter(cov => cov.employee_covered).length === 0) {
                //console.log('me');
                showErr = true;
                showBanner = true;
                window.scrollTo(0, 0);
            }
        }
        if ((dropDown === "My spouse")) {
            if (coverageElectionsList.filter(cov => cov.spouse_covered).length === 0) {
                showErr = true;
                showBanner = true;
                window.scrollTo(0, 0);
            }
        }

        if ((dropDown === "My child")) {
            if (coverageElectionsList.filter(cov => cov.child_covered).length === 0) {
                showErr = true;
                showBanner = true;
                window.scrollTo(0, 0);
            }
        }

        this.setState({ coverage_elections: coverageElectionsList, bannerFlag: showBanner })

        let basic_life = coverageElectionsList[0];
        let voluntary_life = coverageElectionsList[1];
        let long_term_disability = coverageElectionsList[2];
        let short_term_disability = coverageElectionsList[3];
        let voluntary_long_term_disability = coverageElectionsList[4];

        if (!showErr) {


            //console.log("api call for create case", coverageElectionsList);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life", basic_life);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life", voluntary_life);

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.long_term_disability.elected", long_term_disability.employee_covered);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.long_term_disability.excess_amount", long_term_disability.employee_excess_amount);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.long_term_disability.insured_amount", long_term_disability.employee_insured_amount);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.short_term_disability.elected", short_term_disability.employee_covered);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.short_term_disability.excess_amount", short_term_disability.employee_excess_amount);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.short_term_disability.insured_amount", short_term_disability.employee_insured_amount);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_long_term_disability.elected", voluntary_long_term_disability.employee_covered);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_long_term_disability.excess_amount", voluntary_long_term_disability.employee_excess_amount);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_long_term_disability.insured_amount", voluntary_long_term_disability.employee_insured_amount);
        }

        if (dropDown === "My spouse" || dropDown === "My child") {
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.employee_covered", false);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.employee_excess_amount", null);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.employee_insured_amount", null);

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.employee_covered", false);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.employee_excess_amount", null);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.employee_insured_amount", null);

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.long_term_disability.elected", false);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.long_term_disability.insured_amount", null);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.long_term_disability.excess_amount", null);

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.short_term_disability.elected", false);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.short_term_disability.insured_amount", null);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.short_term_disability.excess_amount", null);

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_long_term_disability.elected", false);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_long_term_disability.insured_amount", null);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_long_term_disability.excess_amount", null);

        }
        if (dropDown === "Me" || dropDown === "My spouse") {
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.child_covered", false);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.child_excess_amount", null);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.child_insured_amount", null);

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.child_covered", false);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.child_excess_amount", null);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.child_insured_amount", null);
        }

        if (dropDown === "Me" || dropDown === "My child") {
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.spouse_covered", false);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.spouse_excess_amount", null);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.spouse_insured_amount", null);

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.spouse_covered", false);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.spouse_excess_amount", null);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.spouse_insured_amount", null);
        }
        if (!showErr) {
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.group.number", _.get(this.props, "groupInfoResponse.eoi_data.group_info.group_id", ""));
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.group.class_id", "0001");
            _.set(this.props, "nonTrustedData.eoi_data.authenticated", false);
            if (_.get(this.props, "dataRestoration.eoi_data.case_id")) {
                _.set(this.props, "nonTrustedData.eoi_data.case_id", _.get(this.props, "dataRestoration.eoi_data.case_id"));
            }
            _.set(this.props, "nonTrustedData.eoi_data.case_id", "");
            _.set(this.props, "nonTrustedPageStatus.add_benefit_coverage_info_status", "COMPLETED");
            _.set(this.props, "nonTrustedPageStatus.health_question_info_status", "START");
            _.set(this.props, "nonTrustedData.eoi_data.group_info", _.get(this.props, "groupInfoResponse.eoi_data.group_info"))

            this.props.createCase(_.get(this.props, "nonTrustedData", ""));

            this.props.history.push(`/eoi/mib`);

        }
    }

    render() {
        //console.log(this.state.coverage_elections)
        let check = _.get(this.props, "nonTrustedData.eoi_data.admin_group_indicator")
        let memCov = _.get(this.props, "nonTrustedData.eoi_data.admin_group_coverages")
        return (
            <>
                {this.state.bannerFlag === true ? <AlertBanner
                    errorMessage="Please select at least one coverage and fill out all required fields."
                    handleClose={() => this.handleClose()} /> : null}
                <main>
                    <div className="container-fluid">
                        <div className="prev-link">
                            <button className="btn btn-link" onClick={this.onPrevious}><i className="fa fa-arrow-left"></i>Back</button>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="intro">
                                    <h3>Select your coverage</h3>
                                </div>
                                {this.state.dropdownValue === "Me" && this.state.coverage_elections.map((coverage, index) => {
                                    return (
                                        <div className="col-lg-10">
                                            <div className="row">
                                                <div className="checkbox">
                                                    {check === "N" || check === undefined ?
                                                        <> <input type="checkbox" checked={coverage.employee_covered} onChange={(e) => this.onCheckBoxChange(e, index, "member")} id={coverage.coverage_name} name={coverage.coverage_name} />
                                                            <label htmlFor={coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</label>
                                                        </> : memCov.employee.map((cov) => (
                                                            cov === coverageDetails[coverage.coverage_name].value ?
                                                                <> <input type="checkbox" checked={coverage.employee_covered} onChange={(e) => this.onCheckBoxChange(e, index, "member")} id={coverage.coverage_name} name={coverage.coverage_name} />
                                                                    <label htmlFor={coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</label></> : null
                                                        ))
                                                    }
                                                </div>
                                                {coverage.employee_covered ?
                                                    <div className="data-box checkbox-data">
                                                        <div className="form-group">
                                                            <label htmlFor={coverage.coverage_name + "-current"}>Current insured amount ($)</label>
                                                            <NumberFormat thousandSeparator={true} decimalSeparator={false} maxLength={14} className="form-control" onValueChange={(values) => this.onTextBoxChange(values, index, "eia")} onBlur={(e) => this.handleFieldBlur(e, index, "eia")} value={coverage.employee_insured_amount} id={coverage.coverage_name + "-current"} type="text" placeholder="E.g., 5,000" />
                                                            {coverage.eiaErr ? <span className="error"><i className="fas fa-exclamation-circle"></i>Amount is required</span> : null}                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor={coverage.coverage_name + "-requested"}>Requested additional amount ($)</label>
                                                            <NumberFormat thousandSeparator={true} decimalSeparator={false} maxLength={14} className="form-control" onValueChange={(values) => this.onTextBoxChange(values, index, "eea")} onBlur={(e) => this.handleFieldBlur(e, index, "eea")} value={coverage.employee_excess_amount} id={coverage.coverage_name + "-requested"} type="text" placeholder="E.g., 5,000" />
                                                            {coverage.eeaErr ? <span className="error"><i className="fas fa-exclamation-circle"></i>Amount is required</span> : null}
                                                            {coverage.eeazErr && coverage.eeaErr === false ? <span className="error"><i className="fas fa-exclamation-circle"></i>Zero is not a valid amount</span> : null}

                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    )
                                })
                                }
                                {this.state.dropdownValue === "My spouse" && this.state.coverage_elections.map((coverage, index) => {

                                    return (
                                        !coverage.member_only ?
                                            <div className="col-lg-10">
                                                <div className="row">
                                                    <div className="checkbox">
                                                        {check === "N" || check === undefined ?
                                                            <>  <input type="checkbox" checked={coverage.spouse_covered} onChange={(e) => this.onCheckBoxChange(e, index, "spouse")} id={coverage.coverage_name} name={coverage.coverage_name} />
                                                                <label htmlFor={coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</label>
                                                            </>
                                                            : memCov?.spouse?.map((cov) => (
                                                                cov === coverageDetails[coverage.coverage_name].value ?
                                                                    <>  <input type="checkbox" checked={coverage.spouse_covered} onChange={(e) => this.onCheckBoxChange(e, index, "spouse")} id={coverage.coverage_name} name={coverage.coverage_name} />
                                                                        <label htmlFor={coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</label> </> : null
                                                            ))
                                                        }
                                                    </div>
                                                    {coverage.spouse_covered ?
                                                        <div className="data-box checkbox-data">
                                                            <div className="form-group">
                                                                <label htmlFor={coverage.coverage_name + "-current"}>Current insured amount ($)</label>
                                                                <NumberFormat thousandSeparator={true} decimalSeparator={false} maxLength={14} className="form-control" onValueChange={(values) => this.onTextBoxChange(values, index, "sia")} onBlur={(e) => this.handleFieldBlur(e, index, "sia")} value={coverage.spouse_insured_amount} id={coverage.coverage_name + "-current"} type="text" placeholder="E.g., 5,000" />
                                                                {coverage.siaErr ? <span className="error"><i className="fas fa-exclamation-circle"></i>Amount is required</span> : null}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor={coverage.coverage_name + "-requested"}>Requested additional amount ($)</label>
                                                                <NumberFormat thousandSeparator={true} decimalSeparator={false} maxLength={14} className="form-control" onValueChange={(values) => this.onTextBoxChange(values, index, "sea")} onBlur={(e) => this.handleFieldBlur(e, index, "sea")} value={coverage.spouse_excess_amount} id={coverage.coverage_name + "-requested"} type="text" placeholder="E.g., 5,000" />
                                                                {coverage.seaErr ? <span className="error"><i className="fas fa-exclamation-circle"></i>Amount is required</span> : null}
                                                                {coverage.seazErr && coverage.seaErr === false ? <span className="error"><i className="fas fa-exclamation-circle"></i>Zero is not a valid amount</span> : null}
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div> : null
                                    )
                                })
                                }
                                {this.state.dropdownValue === "My child" && this.state.coverage_elections.map((coverage, index) => {

                                    return (
                                        !coverage.member_only ?
                                            <div className="col-lg-10">
                                                <div className="row">
                                                    <div className="checkbox">
                                                        {check === "N" || check === undefined ?
                                                            <> <input type="checkbox" checked={coverage.child_covered} onChange={(e) => this.onCheckBoxChange(e, index, "child")} id={coverage.coverage_name} name={coverage.coverage_name} />
                                                                <label htmlFor={coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</label>
                                                            </> :
                                                            memCov?.child?.map((cov) => (
                                                                cov === coverageDetails[coverage.coverage_name].value ?
                                                                    <>  <input type="checkbox" checked={coverage.child_covered} onChange={(e) => this.onCheckBoxChange(e, index, "child")} id={coverage.coverage_name} name={coverage.coverage_name} />
                                                                        <label htmlFor={coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</label></> : null
                                                            ))
                                                        }
                                                    </div>
                                                    {coverage.child_covered ?
                                                        <div className="data-box checkbox-data">
                                                            <div className="form-group">
                                                                <label htmlFor={coverage.coverage_name + "-current"}>Current insured amount ($)</label>
                                                                <NumberFormat thousandSeparator={true} decimalSeparator={false} maxLength={14} className="form-control" onValueChange={(values) => this.onTextBoxChange(values, index, "cia")} onBlur={(e) => this.handleFieldBlur(e, index, "cia")} value={coverage.child_insured_amount} id={coverage.coverage_name + "-current"} type="text" placeholder="E.g., 5,000" />
                                                                {coverage.ciaErr ? <span className="error"><i className="fas fa-exclamation-circle"></i>Amount is required</span> : null}
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor={coverage.coverage_name + "-requested"}>Requested additional amount ($)</label>
                                                                <NumberFormat thousandSeparator={true} decimalSeparator={false} maxLength={14} className="form-control" onValueChange={(values) => this.onTextBoxChange(values, index, "cea")} onBlur={(e) => this.handleFieldBlur(e, index, "cea")} value={coverage.child_excess_amount} id={coverage.coverage_name + "-requested"} type="text" placeholder="E.g., 5,000" />
                                                                {coverage.ceaErr ? <span className="error"><i className="fas fa-exclamation-circle"></i>Amount is required</span> : null}
                                                                {coverage.ceazErr && coverage.ceaErr === false ? <span className="error"><i className="fas fa-exclamation-circle"></i>Zero is not a valid amount</span> : null}

                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div> : null
                                    )
                                })
                                }




                                <hr />
                                <div className="field-group float-right">
                                    <button className="btn btn-primary" onClick={this.handleNext}>Next</button>
                                </div>
                            </div>
                        </div>


                    </div>

                </main>
            </>
        );
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(SelectMemberCoverage);
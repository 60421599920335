import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import * as EoiStore from './HomePage/HomePageReducer'

export default function configureStore(history, initialState) {

  const loadState = () => {
    try {
      const serializedState = sessionStorage.getItem('state');
      if(serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (e) {
      return undefined;
    }
  };
  const persistedState = loadState();
  const reducers = {
    eoiStore : EoiStore.reducer,
    // persistedState
  };
  const middleware = [
    thunk
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers
  });

  return createStore(
    rootReducer,
    persistedState,

    //initialState,

    compose(applyMiddleware(...middleware), ...enhancers)
  );
}

import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../../store/HomePage/HomePageAction';
import _ from "lodash";
import {chooseNextQuestion,scrollUp,imagePath} from '../../../utils/sharedFunction'
class HealthHistory extends Component {
    handleContinue = () => {
        const baseQuestion = _.get(this.props,"prefillData.eoi_data.health_question_info.base_question",{})
        if(baseQuestion && baseQuestion.length>0){
            this.props.assignTotalQuestion(baseQuestion.length)

        }
        if(_.get(this.props, "prefillData.eoi_data.health_question_info.health_history_status","")==="ACTIVE"){
            _.set(this.props, "prefillData.eoi_data.health_question_info.health_history_status","COMPLETED")
        // this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
        }
        //this.props.questionStatus(0)
        chooseNextQuestion(this.props)
        // const lifeEntities=_.get(this.props,"prefillData.eoi_data.health_question_info.life_entities",[])
        // console.log(lifeEntities.length)
        // let conditionInfo;
        // const baseQuestion = _.get(this.props,"prefillData.eoi_data.health_question_info.base_question")
        // baseQuestion.map(question => {
        //     if(question.question_id === "1"){
        //         conditionInfo = question.condition_info_required
        //     }
        //     return question
        // })
        // this.props.assignTotalQuestion(baseQuestion.length)
        // // if(lifeEntities.length === 1){
        // //     this.props.assignTemplateAndPath("memberOnly")
        // // }
        // const activeQuestion = _.get(this.props,"activeQuestion","")
        // console.log(activeQuestion,"sss",_.get(this.props,"activeQuestion",""))
        // if(lifeEntities.length === 1 && conditionInfo === true){
        //     this.props.history.push({
        //             pathname: `/eoi/question/${activeQuestion}`,
        //             state: "memberOnly"
        //     })
        // }
        // if(lifeEntities.length > 1 && conditionInfo === true){
        //     this.props.history.push({
        //         pathname: `/eoi/question/${activeQuestion}`,
        //         state: "family"
        //     })
        // }
        
    }
    componentDidMount() {
        scrollUp()
        if(_.get(this.props, "prefillData.eoi_data.health_question_info.health_history_status","")!=="COMPLETED"){
            _.set(this.props, "prefillData.eoi_data.health_question_info.health_history_status","ACTIVE")
            if(this.props.trustedFlag === true){
                this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
            }
        }

        this.props.questionStatus(0)
        this.props.conditionStatus(0)
        this.props.refluxStatus(0)
        this.props.conditionLifeStatus(0)

        this.props.saveEoiData(this.props.prefillData); 
    }
    render() {
        return (
            <>
                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="intro">
                                    <h3>Your health history</h3>
                                    <p className="lead">We’re going to ask a series of health questions. Depending on your answers, we may need some additional details.</p>
                                    <div className="text-center-mobile">
                                        <button className="btn btn-primary mt-4" onClick={()=>this.handleContinue()}>Continue</button>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="col-lg-6 text-center">
                                <img src={imagePath("./images/health-history-illustration.svg")} className="illustration" alt="illustration" />
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(HealthHistory);

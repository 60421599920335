import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../../store/HomePage/HomePageAction';
import AlertBanner from "../../../components/AlertBanner/AlertBanner";
import _ from 'lodash';
import { scrollUp, getQuestionDescriptionWithBold } from '../../../utils/sharedFunction';
import SaveForLater from '../../../components/SaveForLater/SaveForLater';

export class CriticalIllnessKnockOutQ extends Component {
    constructor(props) {
        super(props)
        this.state = {
          alertBanner: false,
          KnockOutQuestions: [],
          error: "",
          error1: "",
          entities:[],previousPage:"",
          lifeID: 0,
          heading:"",
          coveragesElected:[],
          isSaved:false,
          isOpened:false
        }
      }
      componentDidMount() {
        //console.log(this.props, "HEREEEEE")
        if(_.get(this.props, "prefillData.eoi_data.health_question_info.knockout_status ","")!=="COMPLETED"){
          _.set(this.props, "prefillData.eoi_data.health_question_info.knockout_status ","ACTIVE")
          this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
        }
        let KnockOutQuestions = _.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.critical_illness_knockout_question", []);
         //console.log("did mount", KnockOutQuestions)
         //console.log(this.props.prefillData, "ssssssssss")
        KnockOutQuestions && KnockOutQuestions.map(entity=>{
          entity.life_entities_kq.map(life => {
            if(life.knock_out_answer === true || life.knock_out_answer === false ){
              life.knock_out_answer = String(life.knock_out_answer)
            }
            return life
          })
          return entity
        })
        const coverages = JSON.parse(JSON.stringify(_.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections",{})))
        const disabilityCoverages = ["short_term_disability", "long_term_disability", "voluntary_long_term_disability", "voluntary_short_term_disability" ]
        let coverageElected = []
        let allEmployeeStatus = []
        let allSpouseStatus = []
        let allChildrenStatus = []
        for (const coverage in coverages){
          let disabilityCoverageElectedStatus = false
          let employeeCoveredStatus = false
          let spouseCoveredStatus = false
          let childCoveredStatus = false
          if(disabilityCoverages.includes(coverage)){           
              disabilityCoverageElectedStatus = coverages[coverage] && coverages[coverage].elected && coverages[coverage].elected
          }
          else{
               employeeCoveredStatus = coverages[coverage] && coverages[coverage].employee_covered && coverages[coverage].employee_covered
               spouseCoveredStatus = coverages[coverage] && coverages[coverage].spouse_covered && coverages[coverage].spouse_covered
               childCoveredStatus = coverages[coverage] && coverages[coverage].child_covered && coverages[coverage].child_covered 
          }
          if(employeeCoveredStatus === true || spouseCoveredStatus === true || childCoveredStatus ===true || disabilityCoverageElectedStatus === true){
              coverageElected.push(coverage)
          }
         coverageElected.map((elect,index) =>{
         if(elect === "voluntary_critical_illness" ||  coverage === "basic_critical_illness" ){ 
              if(elect  !== "voluntary_critical_illness"){
               this.setState ({heading :'Specified Disease '})
              }
            }
            return elect;
          })
          // console.log(coverages)
          allEmployeeStatus.push(employeeCoveredStatus)
          allEmployeeStatus.push(disabilityCoverageElectedStatus)
          allSpouseStatus.push(spouseCoveredStatus)
          allChildrenStatus.push(childCoveredStatus)
      }
      this.setState({
        coveragesElected : coverageElected
      })
        //this.setState({ KnockOutQuestions: KnockOutQuestions })
        let previousPage = _.get(this.props, "location.state.previousPage");
        let previous_state = ''
        if (previousPage === "review") {
          previous_state = true;
        }
        let lifeId = _.get(this.props, "location.state.lifeId")
        this.setState({ KnockOutQuestions: KnockOutQuestions, previousPage: previous_state, lifeID: lifeId})   
        scrollUp();
      }
      handleClose() {
        this.setState({ alertBanner: false })
      }
      radioButton = (e) => {
        let statusArray = [];
    
        let KnockOutQuestions = [...this.state.KnockOutQuestions];
        KnockOutQuestions.map((knockq, index) => {
          knockq.life_entities_kq.map(entity => {
            if ("yes" + knockq.question_id + entity.life_id === e.target.id) {
              entity.knock_out_answer = e.target.value
            }
            if ("no" + knockq.question_id + entity.life_id === e.target.id) {
              entity.knock_out_answer = e.target.value
            }
            return entity
          }
          )
          return knockq
        })
        this.setState({ KnockOutQuestions })
        this.state.KnockOutQuestions.map(entity=>{
          entity.life_entities_kq.map(life=>{
            statusArray.push(life.knock_out_answer)
            return life
          })
          return entity
        })
        if (statusArray.includes(undefined) === false) {
          this.setState({
            alertBanner: false
          })
        }
      }
      changeToBoolean = (stringValue) => {
        if(stringValue === "true"){
          return true
        }
        else{
          return false
        }
      }
      changeToString = (boolVal) => {
        if(boolVal === true){
          return "true"
        }
        else{
          return "false"
        }
      }
      handleNext = () => {
        let statusArray = [];
        this.state.KnockOutQuestions.map(entity=>{
          entity.life_entities_kq.map(life=>{
            statusArray.push(life.knock_out_answer)
            return life
          })
          return entity
        })
       //console.log("first status",statusArray)
        if (statusArray.includes(undefined) === true) {
          //console.log("handle next")
          this.state.KnockOutQuestions.map(entity=>{
            if(entity.life_entities_kq.length===1){
              this.setState({error1:"Please select an answer."})
            }else if(entity.life_entities_kq.length>1){
              this.setState({error1:"Please select an answer for each member of your family."})
            }
            
            return entity
          })
          this.setState({ alertBanner: true })
          
        }
         else
            {
              let finalQData = [...this.state.KnockOutQuestions]
              finalQData && finalQData.map(entity=>{
                    entity.life_entities_kq.map(life => {
                  life.knock_out_answer = this.changeToBoolean(life.knock_out_answer)
                  return life
              })
              return entity
            })
              //console.log("bool save",this.state.KnockOutQuestions)
               _.set(this.props, "prefillData.eoi_data.health_question_info.knockout_question.critical_illness_knockout_question", this.state.KnockOutQuestions)
              //  _.set(this.props, "prefillData.eoi_data.health_question_info.knockout_question_status","COMPLETED")
              //  _.set(this.props, "prefillData.eoi_data.health_question_info.height_weight_status","ACTIVE")
              //console.log("status array",statusArray)
              //  if(statusArray.includes("true")){
            // _.set(this.props, "prefillData.eoi_data.health_question_info_status","START")
            if(_.get(this.props, "prefillData.eoi_data.health_question_info.criticallIlness_status ","")==="ACTIVE"){
              _.set(this.props, "prefillData.eoi_data.health_question_info.criticallIlness_status ","COMPLETED")
            //   //this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
            }
            const previousPage = _.get(this.props,"location.state.previousPage")
           // this.props.updatecriticalknockoutData(_.get(this.props, "prefillData", ""),this.props,previousPage);
            //this.props.history.push(`/eoi/heightAndweight`)
              // }else{
              //   //console.log("homepage")
              //   this.props.history.push(`/eoi/homePage`)
              // }
              if(this.state.previousPage){
                this.props.isFlag1(true);
                const { id } = this.props.match.params;
                if (id && id) {
                  this.props.chevron(true);
                }
            this.props.updatecriticalknockoutData(_.get(this.props, "prefillData", ""),this.props,previousPage);
            this.props.history.push(`/eoi/review/${id}`)
          }else {
            this.props.updatecriticalknockoutData(_.get(this.props, "prefillData", ""),this.props,previousPage);
          }
          }
    
      }
    
      onPrevious = (e) => {
        let previousURL = _.get(this.props,"location.state.previousPage")
        // console.log("criticalKnockOutQpreviousURL",previousURL)
        if(previousURL === "review") {
          this.props.history.push('/eoi/review')
          }
        else {
          this.props.history.push('/eoi/homePage')
        }
      }
      getQuestionDescription = (questionDesc) => {
    
        let splitArrayResult = getQuestionDescriptionWithBold(questionDesc)
        if (splitArrayResult && splitArrayResult.length === 2 && splitArrayResult[0].length > 0 && splitArrayResult[1].length > 0) {
          return (<p class="pt-3"><strong>{splitArrayResult[0]}</strong>{splitArrayResult[1]}</p>)
        }
        else {
          return <p class="pt-3">{questionDesc}</p>
        }
    
      }
    saveForLater = () => {
        this.setState({isSaved:true});
    }
    continueEOI = () => {
        this.setState({isSaved:false});
    }
    leave = () => {
        this.props.history.push({ pathname: `/eoi/success` })
    }
    render() {
      const check =  _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.source", "") === "GWDY"
        return (
            <>
            {/* <AlertBanner /> */}
            {this.state.isSaved === true ? <SaveForLater continueEOI={()=>this.continueEOI()} leave = {()=> this.leave()}/> : null}
            {this.state.alertBanner === true ?
          <AlertBanner
            handleClose={() => this.handleClose()}
            
            errorMessage=
            {
              // this.state.error.length > 1 && this.state.error, 
              this.state.error1.length > 1 && this.state.error1
            }
          /> : null}
        
            <main className="knockout">
            {this.state.KnockOutQuestions && this.state.KnockOutQuestions.map((knockq, index) => (
            <div key={index}>
            {index === 0?
              <div className="row question-bg gray-bg">
               
              <div className="container-fluid">
                  <div className="prev-link">
                    <button className="btn btn-link" onClick={(e) => this.onPrevious(e)}><i className="fa fa-arrow-left"></i>Back</button>
                  </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                   
                  <h6>You're requesting {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") !== "NY" ? "Critical Illness " : this.state.heading === '' ? 'Voluntary Specified Disease ' : this.state.heading } coverage.</h6>
                     
                    <p className="pt-3">

                      {knockq.question_desc && this.getQuestionDescription(knockq.question_desc)}
                    </p>
                  </div>
                </div>
              </div>
              </div> :               
              <div className="question-bg-body">
                <div className="container">

                  <div className="row">
                    <div className="col-lg-6">
                      <p className="pt-3">
                      {knockq.question_desc && this.getQuestionDescription(knockq.question_desc)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
         }

<div className="container">
                <div className="row">
                  <div className="col-lg-6">{knockq.life_entities_kq.map((entity, ind) => (
                 this.state.previousPage?
               <>{entity.life_id ===this.state.lifeID?
                    <div className="question-box-border" key={ind}>
                      <fieldset className="form-group">
                       
                        <div className="radio">
                          <input type="radio" id={"yes" + knockq.question_id + entity.life_id}
                            onChange={(e) => this.radioButton(e)}
                            value={true}
                            checked={entity.knock_out_answer === "true"}
                            name={knockq.question_id + entity.life_id} />
                          <label htmlFor={"yes" + knockq.question_id + entity.life_id}>
                            <span>Yes</span>
                          </label>
                        </div>
                        <div className="radio">
                          <input type="radio" id={"no" + knockq.question_id + entity.life_id}
                            onChange={(e) => this.radioButton(e)} value={false}
                            checked={entity.knock_out_answer === "false"}
                            // checked={this.state.knockOutAnswer==="no"}
                            name={knockq.question_id + entity.life_id} />
                          <label htmlFor={"no" + knockq.question_id + entity.life_id}>
                            <span>No</span>
                          </label>
                        </div>
                      </fieldset>
                     
                    </div>:null}</>:
                     <div className="question-box-border" key={ind}>
                     <fieldset className="form-group">
                       {knockq.life_entities_kq.length > 1 ?
                         <legend className="data-label"><p>{entity.life_desc.toLowerCase() === "employee"?"Me": check === true ? entity.life_name.split(", ").slice(-1) : entity.life_name.split(", ")[1]}</p></legend>
                         : null}
                       <div className="radio">
                         <input type="radio" id={"yes" + knockq.question_id + entity.life_id}
                           onChange={(e) => this.radioButton(e)}
                           value={true}
                           checked={entity.knock_out_answer === "true"}
                           name={knockq.question_id + entity.life_id} />
                         <label htmlFor={"yes" + knockq.question_id + entity.life_id}>
                           <span>Yes</span>
                         </label>
                       </div>
                       <div className="radio">
                         <input type="radio" id={"no" + knockq.question_id + entity.life_id}
                           onChange={(e) => this.radioButton(e)} value={false}
                           checked={entity.knock_out_answer === "false"}
                           // checked={this.state.knockOutAnswer==="no"}
                           name={knockq.question_id + entity.life_id} />
                         <label htmlFor={"no" + knockq.question_id + entity.life_id}>
                           <span>No</span>
                         </label>
                       </div>
                     </fieldset>
                    
                   </div>
                  ))}

                    {this.state.KnockOutQuestions.length === (index + 1) ?

                      <div className="field-group table-header-group"><hr />
                        <button className="btn btn-link" onClick={()=>this.saveForLater()}>Save for later</button>
                        <button className="btn btn-primary float-right" onClick={() => this.handleNext()}>{_.get(this.props,"location.state.previousPage") === "review" ?
                                    "Return to review page":"Next"}</button>

                      </div> : null
                    }
                  </div>
                </div>
              </div>
              </div>
            ))}
            </main>
          </>
        )
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(CriticalIllnessKnockOutQ);
import axios from "axios";
import _ from "lodash";
import { trackPromise } from "react-promise-tracker";

import {
    ASSIGN_TEMPLATE,
    ASSIGN_TRUSTED_STATUS,
    CHANGE_PAGE_STATUS,
    CHANGE_WELCOME_BACK_STATUS_TO_FALSE,
    CLEAR_GET_GROUP_INFO_DATA,
    CLEAR_PHYSICIAN_STATE_CITY_DETAILS,
    CONDITION_STATUS, LIFE_STATUS,
    GET_GROUP_INFO,
    GET_KNOCKOUT_DATA,
    GET_PREFILL_DATA,
    GET_RECAPTCHA_RESP_VALUE,
    GET_STATE_CITY,
    GET_EMAIL_VALIDATE,
    LINK,
    QUESTION_STATUS,
    REFLUX_STATUS,
    SAVE_WHO_COVRED,
    SET_REVIEW_PAGE_BYTEDATA,
    STORE_PREFILL_ID,
    TOTAL_QUESTION,
    DISPLAY_MEDIA_ICONS,
    RESTORE
} from "../../utils/actionTypes";
import { history } from "../../utils/constant";
import {
    chooseNextCondition,
    chooseNextQuestion,
    moveToHomePage,
    moveToReflexiveQuestion,
} from "../../utils/sharedFunction";

export const actionCreators = {
    saveEoiData: (prefillData) => async (dispatch, getState) => {

        dispatch({
            type: CHANGE_PAGE_STATUS,
            prefillData
        })
    },
    toRestoreData: (data, path) => async (dispatch, getState) => {
        dispatch({
            type: RESTORE,
            data
        })
        if (path) {
            history.push(path)
        }
    },
    saveBooleanFun: (prefillData) => async (dispatch, getState) => {
        let boolValue = false;
        dispatch({
            type: "BOOLEAN_VALUE",
            boolValue
        })
    },
    getStateandCity: (zipCode, pageInd = '') => (dispatch, getState) => {
        trackPromise(
            axios.post('/getStateCity', { "zipCode": zipCode })
                .then((responseJson) => {
                    // console.log(responseJson)
                    let stateCityResp = responseJson.data.response;

                    if (stateCityResp.status) {

                        stateCityResp = {};
                        stateCityResp["cityFlag"] = "N";
                        // console.log(stateCityResp)

                        dispatch({
                            type: GET_STATE_CITY,
                            stateCityResp,
                            pageInd

                        });
                    } else {
                        stateCityResp["cityFlag"] = "Y";

                        dispatch({
                            type: GET_STATE_CITY,
                            stateCityResp,
                            pageInd
                        });
                    }

                })
                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                }));
    },

    getEmailAddress: (emailId) => (dispatch, getState) => {
        trackPromise(
            axios.post('/getEmailValidate', { "emailId": emailId })
                .then((responseJson) => {
                    // console.log(responseJson)
                    let emailValidateResp = responseJson.data.response;
                    console.log("emailValidateRespAction", emailValidateResp)
                    dispatch({
                        type: GET_EMAIL_VALIDATE,
                        emailValidateResp
                    });

                })
                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                }));
    },

    getprefillLink: (values) => (dispatch, getState) => {
        //console.log("aaaaaaa")
        trackPromise(
            axios.post('/getprefillLink', values)

                .then((response) => {
                    //console.log(response)
                    let saveResponse = response;
                    let link = saveResponse.data.response;
                    dispatch({
                        type: LINK,
                        link
                    });
                })
                .catch((err) => {
                    console.log(err);

                }));
    },

    getprefillInfo: (prefillId, path) => async (dispatch, getState) => {

        trackPromise(
            axios.post('/getPrefill', { "prefillId": prefillId })
                .then((responseJson) => {
                    let prefillData = responseJson.data.response;
                    dispatch({
                        type: GET_PREFILL_DATA,
                        prefillData
                    });

                    if (path) {
                        history.push(path)
                    }
                })
                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                }));
    },
    getprefillInfov2: (prefillId, updateID, activeToken) => (dispatch, getState) => {
        //console.log('inside api')
        trackPromise(
            axios.post('/getPrefillv2', { "prefillId": prefillId, "updateId": updateID, headers: { "jwt-token": activeToken } })
                .then((responseJson) => {
                    let prefillData = responseJson.data.response;
                    // let source = prefillData.eoi_data.benefit_coverage_info.eoi_info.source;
                    console.log("JSON", responseJson, responseJson.status)
                    if (prefillData.status !== 500 || prefillData.status !== 401) {
                        dispatch({
                            type: GET_PREFILL_DATA,
                            prefillData
                        });
                        dispatch({
                            type: STORE_PREFILL_ID,
                            prefillId, updateID
                        });
                    }
                    else if (prefillData.status === 401) {
                        //console.log("axios intercepotrs", error)
                        localStorage.clear()
                        sessionStorage.clear()
                        let href = window.location.origin;
                        let url = href + `/grpeoiua/eoi/unauthorized`
                        window.location.href = url;
                    }
                    // else if (responseJson.status === 404  && source === "GWDY" ){
                    //     let href = window.location.origin;
                    //     let url = href + `/grpeoiua/eoi/workdaynobenefits`
                    //     window.location.href = url;
                    // }
                    else if (prefillData.status === 500) {
                        //console.log("axios intercepotrs", error)
                        localStorage.clear()
                        sessionStorage.clear()
                        let href = window.location.origin;
                        let url = href + `/grpeoiua/eoi/errorPage`;
                        window.location.href = url;
                    }

                })
                .catch((err) => {
                    console.log(err.message);
                    //need to map the error page.
                }));
    },

    downLoadApproveDecline: (eoiData) => (dispatch, getState) => {
        axios.post('/approveOrDecision', { "eoi_data": eoiData })
            .then((response) => {
                let byteDataForPDF = response && response.data && response.data.response;
                dispatch({
                    type: SET_REVIEW_PAGE_BYTEDATA,
                    byteDataForPDF
                });

            })
            .catch((err) => {
                console.log(err);
                //need to map the error page.
            });

    },
    //download pdf 
    downLoadPDFData: (eoiData, isSignRequired) => (dispatch, getState) => {
        axios.post('/getpdfDownloadData', { "eoi_data": eoiData, "isSignRequired": isSignRequired })
            .then((response) => {
                let byteDataForPDF = response && response.data && response.data.response;
                dispatch({
                    type: SET_REVIEW_PAGE_BYTEDATA,
                    byteDataForPDF
                });

            })
            .catch((err) => {
                console.log(err);
                //need to map the error page.
            });

    },
    submitAndSign: (eoiData) => (dispatch, getState) => {
        // console.log(eoiData)
        axios.post('/getDecisionLetter', {
            "eoi_data": eoiData
        })
            .then((responseJson) => {
                let knockoutData = responseJson.data.response;
                dispatch({
                    type: GET_KNOCKOUT_DATA,
                    knockoutData
                });
                //let underwriter_status = responseJson.data.response.eoi_data.underwriter_status;
                let underwriter_status = responseJson.data.response.eoi_data.benefit_coverage_info.eoi_info.uw_status;

                //console.log("jjjjj", responseJson);
                let trustedFlag = responseJson.data.response.eoi_data.authenticated;
                //console.log("trusted flag", trustedFlag);
                //console.log("underwriter_status", underwriter_status);
                let uwStatus;
                if (underwriter_status === "APPROVED" && trustedFlag === true) {
                    uwStatus = "APPROVED"
                    dispatch({
                        type: DISPLAY_MEDIA_ICONS,
                        uwStatus
                    })
                    history.push(`/eoi/decisionApproved`)
                } else if (underwriter_status === "DECLINED") {
                    uwStatus = "DECLINED"
                    dispatch({
                        type: DISPLAY_MEDIA_ICONS,
                        uwStatus
                    })
                    history.push(`/eoi/decisionDeclined`)
                } else if (underwriter_status === "REFERRED") {
                    uwStatus = "REFERRED"
                    dispatch({
                        type: DISPLAY_MEDIA_ICONS,
                        uwStatus
                    })
                    history.push(`/eoi/decisionUnderWriting`)
                }

            })
            .catch((err) => {
                console.log(err);
                //need to map the error page.
            });
    },
    hideMediaIcons: () => (dispatch, getState) => {
        let uwStatus = ""
        dispatch({
            type: DISPLAY_MEDIA_ICONS,
            uwStatus
        })
    },
    getGroupInfo: (groupId, showRecaptchaError) => (dispatch, getState) => {
        trackPromise(
            axios.post('/getgroupInfo', { "groupId": groupId })
                .then((responseJson) => {
                    let groupInfoResponse = responseJson.data.response;
                    // sessionStorage.setItem("eoi_token", JSON.stringify(responseJson.data.accessToken));
                    dispatch({
                        type: GET_GROUP_INFO,
                        groupInfoResponse,
                        showRecaptchaError
                    });
                })
                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                }));
    },
    clearGetGroupInfoData: () => (dispatch, getState) => {
        dispatch({
            type: CLEAR_GET_GROUP_INFO_DATA,

        })
    },
    clearPhysisicanStateCityDetails: () => (dispatch, getState) => {
        dispatch({
            type: CLEAR_PHYSICIAN_STATE_CITY_DETAILS,

        })
    },
    clearResponseValue: () => (dispatch, getState) => {
        dispatch({
            type: "CLEAR_RESPONSE_VALUE",

        })
    },
    assignPath: (trustedPathStatus) => (dispatch, getState) => {
        dispatch({
            type: ASSIGN_TRUSTED_STATUS,
            trustedPathStatus
        })
    },

    isFlag1: (boolValue) => (dispatch, getState) => {
        //let boolValue = false;
        dispatch({
            type: "BOOLEAN_KEY",
            boolValue
        })
    },

    chevron: (boolChevron) => (dispatch, getState) => {
        dispatch({
            type: "BOOLEAN_CHEVRON",
            boolChevron
        })
    },

    rLid: (refLid) => (dispatch, getState) => {
        dispatch({
            type: "NUM_RLID",
            refLid
        })
    },
    cCount: (conCount) => (dispatch, getState) => {
        dispatch({
            type: "COUNT_CONDITION",
            conCount
        })
    },
    bClick: (back) => (dispatch, getState) => {
        dispatch({
            type: "CLICK_BROWSER",
            back
        })
    },

    cButton: (clicked) => (dispatch, getState) => {
        dispatch({
            type: "CONTACT_BUTTON",
            clicked
        })
    },
    eInfo: (info) => (dispatch, getState) => {
        dispatch({
            type: "EDIT_INFO",
            info
        })

    },
    workdayCheck: (workday) => (dispatch, getState) => {
        dispatch({
            type: "WORKDAY_CHECK",
            workday
        })
    },
    rCount: (refCount) => (dispatch, getState) => {
        dispatch({
            type: "COUNT_REFLEX",
            refCount
        })

    },
    updateprefillData: (eoiData, prefillId, path) => (dispatch, getState) => {
        //console.log(prefillId,eoiData)
        trackPromise(
            axios.post('/updateprefill', { "eoi_data": eoiData, "prefillId": prefillId })
                .then((responseJson) => {
                    //console.log("aaaaaa")
                    // let saveResponse = response.data;
                    let prefillData = responseJson.data.response
                    let zipCode = prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip_code
                    if (path && path === '/eoi/contact') {
                        if (zipCode) {
                            trackPromise(
                                axios.post('/getStateCity', { "zipCode": zipCode })
                                    .then((responseJson) => {
                                        let stateCityResp = responseJson.data.response;
                                        ////console.log("stateCityResp", stateCityResp);
                                        //let cityFlag = "";
                                        //console.log("stateCityResp.status", stateCityResp.status);
                                        if (stateCityResp.status) {
                                            ////console.log("stateCityResp in if", stateCityResp);
                                            stateCityResp = {};
                                            stateCityResp["cityFlag"] = "N";
                                            //console.log("stateCityResp in if", stateCityResp);
                                            dispatch({
                                                type: GET_STATE_CITY,
                                                stateCityResp,
                                                //pageInd

                                            });
                                            history.push(path)
                                        } else {
                                            stateCityResp["cityFlag"] = "Y";
                                            //console.log("in else", stateCityResp);
                                            dispatch({
                                                type: GET_STATE_CITY,
                                                stateCityResp,
                                                //pageInd
                                            });
                                            history.push(path)
                                        }
                                    }))

                        }
                        else {
                            history.push(path)
                        }
                    }
                    else if (path && path !== '/eoi/contact') {
                        history.push(path)
                    }
                    //console.log(saveResponse);


                })

                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                }));

    },

    updatecriticalknockoutData: (eoiData, props, previousPage) => (dispatch, getState) => {
        // //console.log(prefillId)
        trackPromise(
            axios.post('/updatecriticalknockout', { "eoi_data": eoiData }, { timeout: 180000 })
                .then((response) => {
                    //console.log("aaaaaa")
                    //let saveResponse = response.data;
                    // if (path) {
                    //     history.push(path)
                    // }
                    ////console.log(saveResponse);
                    let knockoutData = response.data.response;
                    //console.log("sssssssssssssss")
                    dispatch({
                        type: GET_KNOCKOUT_DATA,
                        knockoutData
                    });
                    //console.log("cccccccccccccccc")
                    if (previousPage !== "review") {
                        if (_.get(knockoutData, "eoi_data.health_question_info.knockout_question.cancer_knockout_question") !== null
                            && _.get(knockoutData, "eoi_data.health_question_info.knockout_question.cancer_knockout_question") !== undefined
                            && _.get(knockoutData, "eoi_data.health_question_info.knockout_question.cancer_knockout_question").length > 0) {
                            history.push(`/eoi/cancerKnockOutQ`);
                        }
                        else if (_.get(knockoutData, "eoi_data.health_question_info.knockout_question.other_knockout_question") !== null
                            && _.get(knockoutData, "eoi_data.health_question_info.knockout_question.other_knockout_question") !== undefined
                            && _.get(knockoutData, "eoi_data.health_question_info.knockout_question.other_knockout_question").length > 0) {
                            history.push(`/eoi/otherKnockOutQ`);
                        }
                        else if (knockoutData.eoi_data.health_question_info.has_base_question) {
                            //console.log("111")
                            history.push('/eoi/heightAndweight');
                        } else {
                            //console.log("333",getState().eoiStore.prefillData,props.prefillData)
                            Object.assign(props.prefillData, getState().eoiStore.prefillData)
                            //console.log(props)
                            moveToHomePage(props)
                            // history.push(`/eoi/homePage`);
                        }
                    }
                })

                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                    //console.log('in API error')
                    //console.log(err.code)
                    //console.log(err.message)
                    //console.log(err.stack)
                }));

    },

    updatecancerknockoutData: (eoiData, props, previousPage) => (dispatch, getState) => {
        // //console.log(prefillId)
        trackPromise(
            axios.post('/updatecancerknockout', { "eoi_data": eoiData })
                .then((response) => {
                    //console.log("aaaaaa")
                    //let saveResponse = response.data;
                    // if (path) {
                    //     history.push(path)
                    // }
                    ////console.log(saveResponse);
                    let knockoutData = response.data.response;
                    // console.log("knockoutData", knockoutData)
                    dispatch({
                        type: GET_KNOCKOUT_DATA,
                        knockoutData
                    });
                    if (previousPage !== "review") {
                        if (_.get(knockoutData, "eoi_data.health_question_info.knockout_question.other_knockout_question") !== null
                            && _.get(knockoutData, "eoi_data.health_question_info.knockout_question.other_knockout_question") !== undefined
                            && _.get(knockoutData, "eoi_data.health_question_info.knockout_question.other_knockout_question").length > 0) {
                            history.push(`/eoi/otherKnockOutQ`);
                        }
                        else if (knockoutData.eoi_data.health_question_info.has_base_question) {
                            //console.log("111")
                            history.push('/eoi/heightAndweight');
                        } else {
                            //console.log("333",getState().eoiStore.prefillData,props.prefillData)
                            Object.assign(props.prefillData, getState().eoiStore.prefillData)
                            //console.log(props)
                            moveToHomePage(props)
                            // history.push(`/eoi/homePage`);
                        }

                    }
                })

                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                }));

    },

    updateotherKnockoutData: (eoiData, props, previousPage) => (dispatch, getState) => {
        // //console.log(prefillId)
        trackPromise(
            axios.post('/updateotherknockout', { "eoi_data": eoiData })
                .then((response) => {
                    //console.log("aaaaaa")
                    //let saveResponse = response.data;
                    // if (path) {
                    //     history.push(path)
                    // }
                    ////console.log(saveResponse);
                    let knockoutData = response.data.response;
                    dispatch({
                        type: GET_KNOCKOUT_DATA,
                        knockoutData
                    });
                    if (previousPage !== "review") {
                        // //console.log(knockoutData.eoi_data.health_question_info.has_base_question)
                        if (knockoutData.eoi_data.health_question_info.has_base_question) {
                            //console.log("111")
                            history.push('/eoi/heightAndweight');
                        } else {
                            //console.log("222")
                            //console.log(getState().eoiStore)
                            Object.assign(props.prefillData, getState().eoiStore.prefillData)
                            moveToHomePage(props)
                            // history.push(`/eoi/homePage`);
                        }
                    }
                })

                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                }));

    },

    storePrefillId: (prefillId) => (dispatch, getState) => {
        //console.log("aRVINF",prefillId)
        dispatch({
            type: STORE_PREFILL_ID,
            prefillId
        })
    },
    changeWelcomeBackStatus: () => (dispatch, getState) => {
        dispatch({
            type: CHANGE_WELCOME_BACK_STATUS_TO_FALSE,

        })
    },


    assignTemplateAndPath: (template) => (dispatch, getState) => {
        dispatch({
            type: ASSIGN_TEMPLATE,
            template
        })
    },
    questionStatus: (currentQuestion, props, path) => (dispatch, getState) => {
        // console.log(currentQuestion, props)
        dispatch({
            type: QUESTION_STATUS,
            currentQuestion
        })
        if (path === "review") {
            //Object.assign(props.activeQuestion,currentQuestion)
            //console.log(props)
            let a = Object.assign({}, props)
            a.activeQuestion = currentQuestion
            // console.log(a)
            chooseNextQuestion(a)
        }

    },
    conditionStatus: (currentCondition) => (dispatch, getState) => {
        dispatch({
            type: CONDITION_STATUS,
            currentCondition
        })
    },
    conditionLifeStatus: (activeLife) => (dispatch, getState) => {
        dispatch({
            type: LIFE_STATUS,
            activeLife
        })
    },
    refluxStatus: (currentReflux) => (dispatch, getState) => {
        dispatch({
            type: REFLUX_STATUS,
            currentReflux
        })
    },
    assignTotalQuestion: (totalQ) => (dispatch, getState) => {
        //console.log(totalQ,"qqqqqqq")
        dispatch({
            type: TOTAL_QUESTION,
            totalQ
        })
    },

    createCase: (eoi_data, path) => (dispatch, getState) => {
        let knockoutData = {}
        dispatch({
            type: GET_KNOCKOUT_DATA,
            knockoutData
        });
        trackPromise(
            axios.post('/createcase', { "eoi_data": eoi_data })
                .then((responseJson) => {
                    //console.log("create case api call")
                    knockoutData = responseJson.data.response;
                    //console.log(responseJson);
                    dispatch({
                        type: GET_KNOCKOUT_DATA,
                        knockoutData
                    });
                    if (path) {
                        history.push(path);
                    }
                    // if(path=== '/eoi/review'){
                    //     history.push(path);
                    // }
                    // else{
                    //     history.push(`/eoi/mib`)
                    // }

                })

                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                }));

    },
    submitMib: (eoi_data, path) => (dispatch, getState) => {
        trackPromise(
            axios.post('/submitmib', { "eoi_data": eoi_data }, { timeout: 180000 })
                .then((responseJson) => {
                    // console.log("submitmib api call", responseJson)
                    let knockoutData = responseJson.data.response;
                    //console.log(responseJson);
                    if (knockoutData.status !== 500) {
                        //console.log("1111111111",knockoutData)
                        dispatch({
                            type: GET_KNOCKOUT_DATA,
                            knockoutData
                        });

                        if (knockoutData) {
                            history.push(path);
                        }
                    }


                })

                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                    //console.log('in API error')
                    //console.log(err.code)
                    //console.log(err.message)
                    //console.log(err.stack)
                }));

    },
    whoareCovered: (whoCovered) => async (dispatch, getState) => {

        dispatch({
            type: SAVE_WHO_COVRED,
            whoCovered
        })
    },
    saveHeightWeight: (eoiData) => async (dispatch, getState) => {
        let saveReq = {
            //"source": "ADPN",
            "eoi_data": eoiData,
            //"action": ""
        };

        //let life_entities = _.get(this.props,"prefillData.eoi_data.health_question_info.life_entities", []);

        //console.log("saveReq", saveReq);
        trackPromise(
            axios.post('/updateHeightWeight', saveReq)
                .then((responseJson) => {
                    let knockoutData = responseJson.data.response;

                    if (knockoutData.eoi_data.health_question_info.has_base_question) {
                        dispatch({
                            type: GET_KNOCKOUT_DATA,
                            knockoutData
                        });
                        // history.push('/eoi/healthHistory');
                    }
                })
                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                }));
    },

    getKnockOutData: (eoiData) => async (dispatch, getState) => {
        //axios('/getPrefill?encPrefill='+prefillId)
        //console.log("knock action",eoiData)
        trackPromise(
            axios.post('/getknockout', { "eoi_data": eoiData }, { timeout: 180000 })
                .then((responseJson) => {
                    let knockoutData = responseJson.data.response;

                    //console.log("get knockout",knockoutData)
                    dispatch({
                        type: GET_KNOCKOUT_DATA,
                        knockoutData
                    });
                    //console.log(_.get(knockoutData,"eoi_data"))
                    if (_.get(knockoutData, "eoi_data.benefit_coverage_info.eoi_info.source") !== null &&
                        _.get(knockoutData, "eoi_data.health_question_info.has_knockout_question") === true) {
                        if (_.get(knockoutData, "eoi_data.health_question_info.knockout_question.critical_illness_knockout_question") !== null
                            && _.get(knockoutData, "eoi_data.health_question_info.knockout_question.critical_illness_knockout_question") !== undefined
                            && _.get(knockoutData, "eoi_data.health_question_info.knockout_question.critical_illness_knockout_question").length > 0
                        ) {
                            history.push(`/eoi/criticalKnockOutQ`);
                        } else if (_.get(knockoutData, "eoi_data.health_question_info.knockout_question.cancer_knockout_question") !== null
                            && _.get(knockoutData, "eoi_data.health_question_info.knockout_question.cancer_knockout_question") !== undefined
                            && _.get(knockoutData, "eoi_data.health_question_info.knockout_question.cancer_knockout_question").length > 0
                        ) {
                            history.push(`/eoi/cancerKnockOutQ`);
                        } else if (_.get(knockoutData, "eoi_data.health_question_info.knockout_question.other_knockout_question") !== null
                            && _.get(knockoutData, "eoi_data.health_question_info.knockout_question.other_knockout_question") !== undefined
                            && _.get(knockoutData, "eoi_data.health_question_info.knockout_question.other_knockout_question").length > 0) {
                            history.push(`/eoi/otherKnockOutQ`);
                        }
                    } else if (_.get(knockoutData, "eoi_data.health_question_info.has_base_question") === true) {
                        history.push(`/eoi/heightAndweight`)
                    }
                })
                // .then((responseJson)=>{
                //     let knockoutData = responseJson.data.response

                //     updateprefillData(responseJson.data.response)
                // })
                .catch((err) => {
                    console.log(err);
                    // console.log('in API error')
                    // console.log(err.code)
                    // console.log(err.message)
                    // console.log(err.stack)
                    //need to map the error page.
                }));
    },
    initiateUW: (eoiData) => async (dispatch, getState) => {
        //axios('/getPrefill?encPrefill='+prefillId)
        //console.log("knock action",eoiData)
        trackPromise(
            axios.post('/initiateuw', {
                "eoi_data": eoiData
            })
                .then((responseJson) => {
                    let knockoutData = responseJson.data.response;

                    //console.log("get initiate",knockoutData)
                    if (knockoutData.status !== 500) {
                        dispatch({
                            type: GET_KNOCKOUT_DATA,
                            knockoutData
                        });
                    }
                    //console.log(_.get(knockoutData,"eoi_data"))

                })
                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                }));
    },
    setRecaptchaErrorFlag: (showRecaptchaError) => async (dispatch, getState) => {
        let responseValue = "success";
        if (showRecaptchaError === true) {
            responseValue = "fail"
        }
        dispatch({
            type: GET_RECAPTCHA_RESP_VALUE,
            responseValue,
            showRecaptchaError
        });
    },
    verifyRecaptcha: (value) => async (dispatch, getState) => {
        let responseValue = "fail", showRecaptchaError = false;
        await axios.post('/recaptcha', { "recaptchaValue": value })
            .then((responseJson) => {
                let isRespSuccess = _.get(responseJson, "data.response.success", false);
                if (isRespSuccess) {
                    responseValue = "success";
                    showRecaptchaError = false;
                } else {
                    showRecaptchaError = true;
                }
                dispatch({
                    type: GET_RECAPTCHA_RESP_VALUE,
                    responseValue,
                    showRecaptchaError
                });
            })
    },
    addPhysicianData: (value) => async (dispatch, getState) => {
        //console.log("AddPhysInn", value);
        trackPromise(
            axios.post('/addPhysician', { "eoi_data": value })
                .then((responseJson) => {
                    //console.log("ADDPhys-Resp", responseJson);
                    let knockoutData = _.get(responseJson, "data.response", null);
                    if (knockoutData.status !== 500) {
                        //console.log("1111111111",knockoutData)
                        dispatch({
                            type: GET_KNOCKOUT_DATA,
                            knockoutData
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    //need to map the error page.
                }));

    },
    caConstentData: (value) => async (dispatch, getState) => {
        trackPromise(
            axios.post('/consent', { "ca_consent": value })
                .then((response) => {
                    let byteData = response.data.response.file_content;
                    let resFileName = response.data.response.file_name;
                    var fileName = response.data.response.file_name;
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        var byteCharacters = atob(byteData);
                        var byteNumbers = new Array(byteCharacters.length);
                        for (var i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var byteArray = new Uint8Array(byteNumbers);
                        var blob = new Blob([byteArray], { type: 'application/pdf' });
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    }
                    else { // much easier if not IE
                        let link = document.createElement('a');
                        link.href = 'data:application/octet-stream;base64,' + byteData;
                        link.download = resFileName;
                        link.click();
                    }
                })
                .catch((err) => {
                    console.log(err);
                }));

    },

    saveAnswerBaseQuestion: (value, flag, props) => (dispatch, getState) => {
        trackPromise(
            axios.post('/saveAnswerBaseQuestion', { "eoi_data": value }, { timeout: 180000 })
                .then(responseJson => {
                    let knockoutData = _.get(responseJson, "data.response", null);
                    //console.log('knockoutData.status' + knockoutData.status)
                    if (knockoutData.status !== 500) {
                        //console.log("1111111111",knockoutData)
                        dispatch({
                            type: GET_KNOCKOUT_DATA,
                            knockoutData
                        });
                        //if(flag){
                        //console.log(flag)
                        if (props.location.state.previousPage === "reviewno") {
                            Object.assign(props.prefillData, getState().eoiStore.prefillData)
                        }
                        else if (flag === true) {
                            Object.assign(props.prefillData, getState().eoiStore.prefillData)
                            //console.log(props,getState().eoiStore.prefillData)
                            chooseNextCondition(props)
                        }
                        else {
                            Object.assign(props.prefillData, getState().eoiStore.prefillData)
                            chooseNextQuestion(props)
                        }
                        //}

                    }

                })
                .catch(error => {
                    console.log(error);
                    //console.log('in API error')
                    //console.log(error.code)
                    //console.log(error.message)
                    //console.log(error.stack)
                }))
    },
    saveConditionQuestion: (value, props) => (dispatch, getState) => {
        //console.log(value)
        trackPromise(
            axios.post('/saveConditionQuestion', { "eoi_data": value })
                .then(responseJson => {
                    let knockoutData = _.get(responseJson, "data.response", null);
                    if (knockoutData.status !== 500) {
                        //console.log("1111111111",knockoutData)
                        dispatch({
                            type: GET_KNOCKOUT_DATA,
                            knockoutData
                        });
                        //console.log(getState())
                        //console.log(props.prefillData,getState().eoiStore.prefillData)
                        Object.assign(props.prefillData, getState().eoiStore.prefillData)
                        //props.prefillData = getState().eoiStore.prefillData
                        //console.log(props)
                        moveToReflexiveQuestion(props)

                    }

                }))

    },
    saveAnswerBaseQuestionYesNo: (value, flag, props) => (dispatch, getState) => {
        //console.log("SSS")
        // console.log(value)

        trackPromise(
            axios.post('/saveAnswerBaseQuestion', { "eoi_data": value })
                .then(responseJson => {
                    let knockoutData = _.get(responseJson, "data.response", null);
                    // console.log(knockoutData.status,knockoutData,"status Data")

                    if (knockoutData.status !== 500) {
                        trackPromise(
                            axios.post('/saveConditionQuestion', { "eoi_data": knockoutData })
                                .then(responseJson => {
                                    let knockoutData = _.get(responseJson, "data.response", null);
                                    if (knockoutData.status !== 500) {
                                        // console.log("1111111111",knockoutData)
                                        dispatch({
                                            type: GET_KNOCKOUT_DATA,
                                            knockoutData
                                        });
                                        //console.log(getState())
                                        if (props.location.state.previousPage === "reviewno") {
                                            Object.assign(props.prefillData, getState().eoiStore.prefillData)
                                        }
                                        else if (flag === true) {
                                            Object.assign(props.prefillData, getState().eoiStore.prefillData)
                                            // console.log(props,getState().eoiStore.prefillData,"checking")
                                            chooseNextCondition(props)
                                        }
                                        else {
                                            Object.assign(props.prefillData, getState().eoiStore.prefillData)
                                            chooseNextQuestion(props)
                                        }
                                        // //console.log(props.prefillData,getState().eoiStore.prefillData)
                                        // Object.assign(props.prefillData,getState().eoiStore.prefillData)
                                        // //props.prefillData = getState().eoiStore.prefillData
                                        // //console.log(props)
                                        //moveToReflexiveQuestionFromYesNo(getState().eoiStore)

                                    }

                                }))
                        //saveReflexQuestion(knockoutData,getState().eoiStore)

                    }

                }))

    },
    saveReflexQuestion: (value, props, previousPage, rflid) => (dispatch, getState) => {
        // console.log("rflid", rflid)
        trackPromise(
            axios.post('/saveReflexiveqstn', { "eoi_data": value })
                .then(responseJson => {
                    let knockoutData = _.get(responseJson, "data.response", null);
                    if (knockoutData.status !== 500) {
                        //console.log("1111111111",knockoutData)
                        dispatch({
                            type: GET_KNOCKOUT_DATA,
                            knockoutData
                        });
                        //console.log(getState())
                        //console.log(props.prefillData,getState().eoiStore.prefillData)
                        Object.assign(props.prefillData, getState().eoiStore.prefillData)
                        // moveToReflexiveQuestion(props)
                        if (previousPage === "reviewEdit") {
                            history.push({
                                pathname: `/eoi/review/${rflid}`
                            })
                        } else {
                            moveToReflexiveQuestion(props)
                        }

                    }

                }))

    }

};

import React, { Component } from "react";

import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AlertBanner from "../../../components/AlertBanner/AlertBanner";
import { actionCreators } from "../../../store/HomePage/HomePageAction";
import {
    getActiveQuestionData,
    getQuestionDescriptionWithBold,
    moveToPreviousQuestion,
    scrollUp,
} from "../../../utils/sharedFunction";
import Warning from "../../../components/Warning/Warning";
import SaveForLater from "../../../components/SaveForLater/SaveForLater";


class HealthMemberQ extends Component {
    state = {
        questionData: {},
        checkedCondition: '',
        displayBanner: false,
        checkedNoneOfTheAbove: false,
        previousState: false,
        lifeID: 0,
        paramId: '',
        isSaved: false,
        isOpened: false
    }

    componentDidMount() {
        let questionDataTemp = getActiveQuestionData(this.props)
        let questionData = JSON.parse(JSON.stringify(questionDataTemp))
        let lifeId = _.get(this.props, "location.state.lifeId");
        let previousPage = _.get(this.props, "location.state.previousPage");
        let previous_state = ''
        let memberLifeEntities = [];
        let noneOfAbove = ''

        if (previousPage === "review") {
            previous_state = true;
            questionData.health_condition.map((condition, index) => {
                condition.life_entities_dq.map((life, index) => {
                    if (life.life_id === lifeId && life.answer_condition_info && condition.condition_name === "None of the above") {
                        noneOfAbove = true;
                    }
                    return life
                })
                return condition
            })

        }


        this.setState({
            questionData: questionData,
            checkedNoneOfTheAbove: noneOfAbove,
            previousState: previous_state,
            lifeID: lifeId,
            memberLifeEntities: memberLifeEntities,
        })

        scrollUp()

    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.params !== this.props.match.params) {
            this.setState({
                questionData: {},
                checkedCondition: '',
                displayBanner: false
            })
            let questionDataTemp = getActiveQuestionData(this.props)
            let questionData = JSON.parse(JSON.stringify(questionDataTemp))
            this.setState({
                questionData: questionData,
            })
            scrollUp()
            window.addEventListener("popstate", () => {
                questionData.question_id === 1 ?
                    this.handlePrevious()
                    :
                    this.props.history.goForward();
                this.props.bClick(true);
            })
        }
    }

    handleBrowser = () => {
        this.props.bClick(false);
    }

    handleCheck = (e) => {
        this.setState({
            checkedCondition: e.target.checked,
            displayBanner: false
        })
        let questionData = { ...this.state.questionData }
        let noneOfAbove = ''
        questionData.health_condition.map((condition, index) => {

            condition.life_entities_dq.map(lifeEntity => {
                // if(index === this.state.lifeID){
                if (this.state.previousState) {
                    // console.log(lifeEntity, "ENTITY")
                    if (condition.condition_id === e.target.id && lifeEntity.life_id === this.state.lifeID) {
                        lifeEntity.answer_condition_info = e.target.checked
                    }
                    if (questionData.health_condition.length.toString() === e.target.id && lifeEntity.life_id === this.state.lifeID) {
                        if (condition.condition_id !== questionData.health_condition.length.toString()) {
                            lifeEntity.answer_condition_info = false
                        }
                    }
                    if (questionData.health_condition.length.toString() !== e.target.id && lifeEntity.life_id === this.state.lifeID) {
                        if (condition.condition_id === questionData.health_condition.length.toString()) {
                            if (lifeEntity.answer_condition_info === true) {
                                lifeEntity.answer_condition_info = false
                            }
                        }
                    }
                    if (this.state.previousState) {
                        // console.log(condition.condition_name)
                        if (lifeEntity.life_id === this.state.lifeID && lifeEntity.answer_condition_info && condition.condition_name === "None of the above") {
                            noneOfAbove = true
                        } else {
                            noneOfAbove = false
                        }
                    }
                } else {
                    if (condition.condition_id === e.target.id) {
                        lifeEntity.answer_condition_info = e.target.checked
                    }
                    if (questionData.health_condition.length.toString() === e.target.id) {
                        if (condition.condition_id !== questionData.health_condition.length.toString()) {
                            lifeEntity.answer_condition_info = false
                        }
                    }
                    if (questionData.health_condition.length.toString() !== e.target.id) {
                        if (condition.condition_id === questionData.health_condition.length.toString()) {
                            if (lifeEntity.answer_condition_info === true) {
                                lifeEntity.answer_condition_info = false
                            }
                        }
                    }
                }
                return lifeEntity
            })

            return condition
        })

        this.setState({
            questionData,
            checkedNoneOfTheAbove: noneOfAbove,
        })

    }
    saveForLater = () => {
        this.setState({ isSaved: true });
    }
    continueEOI = () => {
        this.setState({ isSaved: false });
    }
    leave = () => {
        this.props.history.push({ pathname: `/eoi/success` })
    }
    handleNext = () => {
        // if(this.state.previousState && this.state.checkedNoneOfTheAbove){
        //     this.props.history.push({
        //         pathname: '/eoi/review',
        //         state: {"previousPage":"review"}})
        // } else {
        let answerInfoArray = [];
        let noChecked = 0;
        if (this.state.previousState) {

            // let check =  _.get(this.props,"countCondition");
            // console.log(check,"checkValue")
            noChecked = document.querySelectorAll('input[type="checkbox"]:checked').length;
            // console.log(noChecked,"NOChecked");
            this.props.cCount(noChecked)
            // _.set(this.props,"isCount",noChecked)
            // console.log(this.props,"props")
            // console.log(this.props.countCondition,"nocount")
            this.state.questionData.health_condition.map((condition, index) => {
                condition.life_entities_dq.map(lifeEntity => {
                    if (this.state.previousState && lifeEntity.life_id === this.state.lifeID) {
                        answerInfoArray.push(lifeEntity.answer_condition_info)
                    }
                    return lifeEntity
                })
                return condition
            })
        } else {
            this.state.questionData.health_condition.map((condition, index) => {
                condition.life_entities_dq.map(lifeEntity => {
                    answerInfoArray.push(lifeEntity.answer_condition_info)
                    return lifeEntity

                })
                return condition
            })
        }

        if (answerInfoArray.includes(true) === false) {
            this.setState({
                displayBanner: true
            })
        } else {
            let noneOfAbove = [];
            //this.props.history.push(`/eoi/letsMoveOn`)
            this.state.questionData.health_condition.map((condition, index) => {
                condition.life_entities_dq.map(lifeEntity => {
                    if (this.state.previousState && lifeEntity.life_id === this.state.lifeID) {
                        if (condition.condition_id === this.state.questionData.health_condition.length.toString()) {
                            //noneOfAbove.push(lifeEntity.answer_condition_info)
                            if (lifeEntity.answer_condition_info === true) {
                                noneOfAbove = true
                            }
                            else {
                                noneOfAbove = false
                            }
                        }
                        if (lifeEntity.answer_condition_info === false) {
                            if (lifeEntity.disclosure_types) {
                                lifeEntity.disclosure_types.map(disclosure => {
                                    if (disclosure.answer_disclosure_info === true) {
                                        disclosure.answer_disclosure_info = false
                                    }
                                    if (disclosure.answer_disclosure_info_value) {
                                        disclosure.answer_disclosure_info_value = ""
                                    }
                                    return disclosure
                                })
                            }
                        }
                    } else if (this.state.previousState === false || this.state.previousState === "") {

                        if (condition.condition_id === this.state.questionData.health_condition.length.toString()) {
                            //noneOfAbove.push(lifeEntity.answer_condition_info)
                            if (lifeEntity.answer_condition_info === true) {
                                noneOfAbove = true
                            }
                            else {
                                noneOfAbove = false
                            }
                        }
                        if (lifeEntity.answer_condition_info === false) {
                            if (lifeEntity.disclosure_types) {
                                lifeEntity.disclosure_types.map(disclosure => {
                                    if (disclosure.answer_disclosure_info === true) {
                                        disclosure.answer_disclosure_info = false
                                    }
                                    if (disclosure.answer_disclosure_info_value) {
                                        disclosure.answer_disclosure_info_value = ""
                                    }
                                    return disclosure
                                })
                            }
                        }
                    }
                    return lifeEntity
                })
                return condition
            })

            let baseQuestionDet = _.get(this.props, "prefillData.eoi_data.health_question_info.base_question", [])
            let question = _.get(this.props, "location.pathname", "").split('/')[3]
            baseQuestionDet.map(eachQuestion => {
                if (eachQuestion.question_id === question) {
                    Object.assign(eachQuestion, this.state.questionData)
                    Object.assign(eachQuestion.question_page_status = "ACTIVE")
                }
                else {
                    Object.assign(eachQuestion.question_page_status = "INACTIVE")
                }
                return eachQuestion
            })
            _.set(this.props, "prefillData.eoi_data.health_question_info.base_question", baseQuestionDet)
            //console.log(baseQuestionDet)
            // if(this.state.previousState){
            //     _.set(this.props,"location.state.previousPage","review")
            // }
            if (this.state.previousState) {
                if (noneOfAbove) {
                    this.props.isFlag1(true);

                    const { id } = this.props.match.params;
                    if (id && id) {
                        this.props.chevron(true);
                    }
                    this.props.history.push(`/eoi/review/${id}`)
                    // this.props.history.push(`/eoi/review`)
                    _.set(this.props, "history.location.state.previousPage", "reviewno")
                    this.props.saveAnswerBaseQuestion(_.get(this.props, "prefillData", {}), noneOfAbove === true, this.props)
                } else {
                    _.set(this.props, "history.location.state.previousPage", "review")
                    this.props.saveAnswerBaseQuestion(_.get(this.props, "prefillData", {}), noneOfAbove === false, this.props)
                }
            }
            else {
                this.props.saveAnswerBaseQuestion(_.get(this.props, "prefillData", {}), noneOfAbove === false, this.props)
            }



        }
    }


    handleClose = () => {
        this.setState({
            displayBanner: false
        })
    }
    getQuestionDescription = (questionDesc) => {

        let splitArrayResult = getQuestionDescriptionWithBold(questionDesc)
        //console.log(splitArrayResult,questionDesc)
        if (splitArrayResult && splitArrayResult.length === 2 && splitArrayResult[0].length > 0 && splitArrayResult[1].length > 0) {
            return (<p className="pt-3"><strong>{splitArrayResult[0]}</strong>{splitArrayResult[1]}</p>)
        }
        else {
            return <p className="pt-3">{questionDesc}</p>
        }

    }
    handlePrevious = () => {
        moveToPreviousQuestion(this.props)
    }
    render() {
        const { questionData } = this.state
        return (
            <>
                {this.state.isSaved === true ? <SaveForLater continueEOI={() => this.continueEOI()} leave={() => this.leave()} /> : null}
                {this.props.backClick === true ? <Warning handleBrowser={() => this.handleBrowser()} /> : null}
                {this.state.displayBanner === true ?
                    <AlertBanner handleClose={() => this.handleClose()} errorMessage="Please select an answer" />
                    :
                    null}
                <main className="knockout">
                    <div className="row question-bg gray-bg">
                        <div className="container-fluid">
                            <div className="prev-link">
                                <button className="btn btn-link" onClick={() => this.handlePrevious()}>
                                    <i className="fa fa-arrow-left"></i>
                                    {_.get(this.props, "activeQuestion", "") === 1 ? "Back" : "Previous question"}
                                </button>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <h1 className="q-heading h3">Question {questionData.question_id}/{_.get(this.props, "totalQuestion", "")}</h1>
                                    {questionData.question_desc &&
                                        this.getQuestionDescription(questionData.question_desc)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        {questionData && questionData.health_condition && questionData.health_condition.map((condition, index) => (
                            <div className="row" key={index}>
                                <div className="col-lg-10">
                                    <div className="from-group check-box-border">
                                        {this.state.previousState ?
                                            <div className="checkbox">
                                                {condition.life_entities_dq.map((life, index) => (

                                                    this.state.previousState && life.life_id === this.state.lifeID ?
                                                        <>
                                                            <input type="checkbox" id={condition.condition_id} key={index}
                                                                checked={life.answer_condition_info === true}
                                                                onChange={(e) => this.handleCheck(e)}
                                                            />
                                                        </> : null
                                                ))}
                                                <label htmlFor={condition.condition_id}>
                                                    <h5 className={questionData.health_condition.length.toString() === condition.condition_id && condition.condition_name === "None of the above" ? "blue-shade" : ''}>{condition.condition_name}</h5>
                                                </label>
                                            </div> :
                                            <div className="checkbox">
                                                {condition.life_entities_dq.map((life, index) => (
                                                    <>
                                                        <input type="checkbox" id={condition.condition_id} key={index}
                                                            checked={life.answer_condition_info === true}
                                                            onChange={(e) => this.handleCheck(e)}
                                                        />
                                                    </>
                                                ))}


                                                <label htmlFor={condition.condition_id}>
                                                    <span style ={{marginBottom:"-12px"}} className={questionData.health_condition.length.toString() === condition.condition_id && condition.condition_name === "None of the above h5" ? "blue-shade" : ''} class="h5">{condition.condition_name}</span>
                                                </label>
                                            </div>
                                        }
                                        
                                        <p className="check-box-paragraph">
                                            {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "NY" ? null : condition.condition_desc}
                                        </p>
                                        {condition.condition_name.includes("Consultation in hospital or health care facility") ?
                                            <p className="check-box-paragraph" style={{ color: "#00728d", fontWeight: "bold" }}>Do not include routine checkups unless there are existing or newly diagnosed conditions that have not been reported in the previous questions
                                            </p> : null}

                                        {condition.condition_name.includes("Prescribed medications") ?
                                            <p className="check-box-paragraph" style={{ color: "#00728d", fontWeight: "bold" }}>Do not include medication that you have already disclosed as treatment for conditions in the previous questions
                                            </p> : null}

                                        {condition.condition_name.includes("Diagnostic testing") ?
                                            <p className="check-box-paragraph" style={{ color: "#00728d", fontWeight: "bold" }}>Do not include routine testing unless there are existing or newly diagnosed conditions that have not been reported in the previous questions
                                            </p> : null}
                                        {condition.condition_name.includes("Acquired Immune Deficiency Syndrome (AIDS) or AIDS Related Complex (ARC)") ?
                                            <p className="check-box-paragraph" style={{ marginTop: "5px", color: "black" }}>Test results received at an anonymous counseling and test site need not be revealed on this application, nor do the results of a home test kit.
                                            </p> : null}


                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="row">
                            <div className="col-lg-10">
                                <hr />
                                <div className="field-group table-header-group">
                                    {_.get(this.props, "trustedFlag", "") === true ?
                                        <button className="btn btn-link" onClick={() => this.saveForLater()}>
                                            Save for later
                                        </button>
                                        :
                                        null
                                    }
                                    <button className="btn btn-primary float-right" onClick={() => this.handleNext()}>
                                        {this.state.previousState === true && this.state.checkedNoneOfTheAbove === true ? "Return to review page" : "Next"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

// export default HealthMemberQ;
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(HealthMemberQ);

import React from "react";
import ReactDOM from "react-dom";

import axios from "axios";
import throttle from "lodash/throttle";
import { usePromiseTracker } from "react-promise-tracker";
import { Provider } from "react-redux";
import { Router } from "react-router";

import App from "./App";
import {
  history,
  store,
  SUB_DOMAIN
} from "./utils/constant";
import {
  authHeader,
  getJunction,
  imagePath,
} from "./utils/sharedFunction";

// const baseUrl = document.getElementsByTagName('base')[0] && document.getElementsByTagName('base')[0].getAttribute('href');
// const junction = window.location.pathname.split("/");
// const history = createBrowserHistory({ basename: baseUrl+junction[1] });
// const store = configureStore(history);
// Test01

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

store.subscribe(throttle(() => {
  saveState(store.getState());
}, 1000));
const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress &&   
    <div className="spinner-backdrop">
      <div className="spinner-modal">
          <img className="spinner-icon"
            src={imagePath("./images/spinner-web.svg")}
            alt="Loading Icon" />
          <div className="spinner-text">
              Please wait while your request is being processed
          </div>
      </div>
    </div>
  );  
}
axios.interceptors.request.use(
  requestConfig => {
    // console.log("a",requestConfig)
      requestConfig.headers.eoitoken = authHeader();
      requestConfig.headers.junction = getJunction();
      requestConfig.url = SUB_DOMAIN+requestConfig.url
      //  console.log(requestConfig)
    return requestConfig;
  },
  error => {console.log("error",error);
    Promise.reject(error)
    }
);
 
axios.interceptors.response.use(
    responseJSON => {
      // if(responseJSON.data.response.status === 500 || responseJSON.data.response.status === 401 || responseJSON.data.response.status === 404 || responseJSON.data.response.status === 403){
      //   // console.log("axios intercepotrs",responseJSON.data.response )
      //   localStorage.clear()
      //   sessionStorage.clear()
      //   let href = window.location.origin;
      //   let url = href+`/grpeoiua/eoi/errorPage`
      //   window.location.href = url;
      // }
      // else{
      // console.log(responseJSON)
      // if(responseJSON.config.url.includes('/getStateCity') === false && responseJSON.config.url.includes('/getPrefillv2') === false){
      //   if(responseJSON.data.response.status === 500 || responseJSON.data.response.status === 401 || 
      //     responseJSON.data.response.status === 404 || responseJSON.data.response.status === 403 || responseJSON.data.response.status === 400){
      //       console.log("axios intercepotrs",responseJSON.data.response )
      //       localStorage.clear()
      //       sessionStorage.clear()
      //       let href = window.location.origin;
      //       let url = href+`/grpeoiua/eoi/errorPage`
      //       window.location.href = url;
      //     }
      //     else{
      //       return responseJSON
      //     }
      // }
      // else{
        return responseJSON
      // }
        
    },
    error => {
      //<ErrorPage></ErrorPage>
      // console.log("axios intercepotrs", error)
      // localStorage.clear()
      // sessionStorage.clear()
      // let href = window.location.origin;
      // let url = href+`/grpeoiua/eoi/errorPage`
      // window.location.href = url;
      return Promise.reject(error);
   }
)


ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
      <LoadingIndicator/>
    </Router>
  </Provider>,
  document.getElementById('root'));


export const CHANGE_PAGE_STATUS ='CHANGE_PAGE_STATUS';
export const GET_PREFILL_DATA = 'GET_PREFILL_DATA';
export const ASSIGN_TRUSTED_STATUS = "ASSIGN_TRUSTED_STATUS";
export const STORE_PREFILL_ID = 'STORE_PREFILL_ID';
export const LINK = 'LINK';
export const CHANGE_WELCOME_BACK_STATUS_TO_FALSE = 'CHANGE_WELCOME_BACK_STATUS_TO_FALSE';
export const GET_STATE_CITY = 'GET_STATE_CITY';
export const GET_EMAIL_VALIDATE = 'GET_EMAIL_VALIDATE';
export const GET_GROUP_INFO='GET_GROUP_INFO';
export const CLEAR_GET_GROUP_INFO_DATA ='CLEAR_GET_GROUP_INFO_DATA';
export const RECAPTCHA_ERROR = 'RECAPTCHA_ERROR';
export const ASSIGN_TEMPLATE = 'ASSIGN_TEMPLATE';
export const QUESTION_STATUS = 'QUESTION_STATUS';
export const CONDITION_STATUS = 'CONDITION_STATUS';
export const COUNT_CONDITION = 'COUNT_CONDITION';
export const CLICK_BROWSER = 'CLICK_BROWSER';
export const COUNT_REFLEX = 'COUNT_REFLEX';
export const CONTACT_BUTTON = ' COUNT_BUTTON';
export const LIFE_STATUS = 'LIFE_STATUS';
export const REFLUX_STATUS = 'REFLUX_STATUS';
export const TOTAL_QUESTION = 'TOTAL_QUESTION';
export const SAVE_COVERAGE_DETAILS='SAVE_COVERAGE_DETAILS';
export const SAVE_WHO_COVRED = 'SAVE_WHO_COVRED';
export const GET_KNOCKOUT_DATA = 'GET_KNOCKOUT_DATA';
export const UPDATE_KNOCKOUT_DATA = 'UPDATE_KNOCKOUT_DATA';
export const GET_RECAPTCHA_RESP_VALUE = 'GET_RECAPTCHA_RESP_VALUE';
export const SET_REVIEW_PAGE_BYTEDATA = 'SET_REVIEW_PAGE_BYTEDATA';
export const CLEAR_PHYSICIAN_STATE_CITY_DETAILS ="CLEAR_PHYSICIAN_STATE_CITY_DETAILS";
export const DISPLAY_MEDIA_ICONS = "DISPLAY_MEDIA_ICONS";
export const EDIT_INFO = 'EDIT_INFO';
export const RESTORE = "RESTORE";
export const WORKDAY_CHECK = "WORKDAY_CHECK";
export const SAVE_CA_CONSTENT_DATA = "SAVE_CA_CONSTENT_DATA";

import React, { Component } from "react";

import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Warning from "../../../components/Warning/Warning";
import AlertBanner from "../../../components/AlertBanner/AlertBanner";
import { actionCreators } from "../../../store/HomePage/HomePageAction";
import {
    checkConditionPerIndividualIsChecked,
    getActiveQuestionData,
    getQuestionDescriptionWithBold,
    moveToPreviousQuestion,
    scrollUp,
} from "../../../utils/sharedFunction";
import SaveForLater from "../../../components/SaveForLater/SaveForLater";

class HealthFamilyQ extends Component {
    state = {
        questionData: {},
        checkedCondition: '',
        displayBanner: false,
        isSaved: false,
        isOpened: false
    }

    getInitialData = () => {
        let questionDataTemp = getActiveQuestionData(this.props)
        let questionData = JSON.parse(JSON.stringify(questionDataTemp))
        //console.log(questionData)

        // questionData.question_page_status = "ACTIVE"
        this.setState({
            questionData: questionData
        })
        //console.log(questionData,this.state.questionData)
        scrollUp()
    }
    componentDidMount() {
        this.getInitialData()
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.params !== this.props.match.params) {
            this.setState({
                questionData: {},
                checkedCondition: '',
                displayBanner: false
            })
            let questionDataTemp = getActiveQuestionData(this.props)
            let questionData = JSON.parse(JSON.stringify(questionDataTemp))
            this.getInitialData()
            window.addEventListener("popstate", () => {
                questionData.question_id === 1 ?
                    this.handlePrevious()
                    :
                    this.props.history.goForward();
                this.props.bClick(true);
            })
        }
    }
    handleBrowser = () => {
        this.props.bClick(false);
    }
    handleCheck = (e) => {
        this.setState({
            checkedCondition: e.target.checked
        })
        let questionData = { ...this.state.questionData }
        //let questionData = JSON.parse(JSOn.stringify())
        questionData.health_condition.map((condition, index) => {
            condition.life_entities_dq.map(lifeEntity => {
                if (condition.condition_id + lifeEntity.life_id === e.target.id) {
                    lifeEntity.answer_condition_info = e.target.checked
                }
                if (this.state.questionData.health_condition.length + lifeEntity.life_id === e.target.id) {
                    if (condition.condition_id + lifeEntity.life_id !== this.state.questionData.health_condition.length + lifeEntity.life_id
                        //&& e.target.name === "None of the above"
                    ) {
                        lifeEntity.answer_condition_info = false
                    }
                }
                if (this.state.questionData.health_condition.length + lifeEntity.life_id !== e.target.id) {
                    if (condition.condition_id + lifeEntity.life_id === this.state.questionData.health_condition.length + lifeEntity.life_id) {
                        if (lifeEntity.answer_condition_info === true
                            //&& e.target.name !== "None of the above" 
                        ) {
                            if (e.target.name === lifeEntity.life_desc + lifeEntity.life_id) {
                                lifeEntity.answer_condition_info = false
                            }
                        }
                    }
                }
                return lifeEntity
            })
            return condition
        })

        let finalLifeStatus = checkConditionPerIndividualIsChecked(this.state.questionData)
        if (finalLifeStatus.every(data => data === true) === true) {
            this.setState({
                displayBanner: false
            })
        }
        this.setState({
            questionData
        })

    }
    handleNext = () => {
        // this.state.questionData.health_condition.map((condition,index) => {
        //         condition.life_entities_dq.map(lifeEntity => {
        //             answerInfoArray.push(lifeEntity.answer_condition_info)
        //             return lifeEntity
        //         })
        //         return condition
        // })
        let finalLifeStatus = checkConditionPerIndividualIsChecked(this.state.questionData)
        // const lifeEntityLength = this.state.questionData.health_condition[0]["life_entities_dq"].length
        // let finalLifeStatus = []
        // for(let i = 0;i<lifeEntityLength;i++){
        //     //console.log(i)
        //     let eachLifeStatus = []
        //     this.state.questionData.health_condition.map((condition,index) => {
        //         //console.log(index)
        //         //console.log(condition.life_entities_dq[i])
        //         eachLifeStatus.push(condition.life_entities_dq[i].answer_condition_info)
        //         //answerInfoArray.push(condition.life_entities_dq[i].answer_condition_info)
        //         return condition
        //     })
        //     //console.log(eachLifeStatus)
        //     if(eachLifeStatus.includes(true)){
        //         finalLifeStatus.push(true)
        //     }
        //     else{
        //         finalLifeStatus.push(false)
        //     }
        // }

        if (finalLifeStatus.includes(false) === true) {
            this.setState({
                displayBanner: true
            })
        }
        else {
            let noneOfAbove = [];
            this.state.questionData.health_condition.map((condition, index) => {
                condition.life_entities_dq.map(lifeEntity => {
                    if (condition.condition_id + lifeEntity.life_id === this.state.questionData.health_condition.length + lifeEntity.life_id) {
                        noneOfAbove.push(lifeEntity.answer_condition_info)
                    }
                    if (lifeEntity.answer_condition_info === false) {
                        if (lifeEntity.disclosure_types) {
                            lifeEntity.disclosure_types.map(disclosure => {
                                if (disclosure.answer_disclosure_info === true) {
                                    disclosure.answer_disclosure_info = false
                                }
                                if (disclosure.answer_disclosure_info_value) {
                                    disclosure.answer_disclosure_info_value = ""
                                }
                                return disclosure
                            })
                        }
                    }
                    return lifeEntity
                })
                return condition
            })

            let baseQuestionDet = _.get(this.props, "prefillData.eoi_data.health_question_info.base_question", [])
            let question = _.get(this.props, "location.pathname", "").split('/')[3]
            baseQuestionDet.map(eachQuestion => {
                //console.log(eachQuestion,this.state.questionData)
                if (eachQuestion.question_id === question) {
                    Object.assign(eachQuestion, this.state.questionData)
                    Object.assign(eachQuestion.question_page_status = "ACTIVE")
                }
                else {
                    Object.assign(eachQuestion.question_page_status = "INACTIVE")
                }
                //console.log(eachQuestion)
                return eachQuestion
            })
            _.set(this.props, "prefillData.eoi_data.health_question_info.base_question", baseQuestionDet)

            // if(noneOfAbove.includes(false)){
            //     //this.props.history.push(`/eoi/letsMoveOn`)
            //     chooseNextCondition(this.props)            
            // }
            // else{
            //     //this.props.conditionStatus(0)
            //     chooseNextQuestion(this.props)


            //  }

            this.props.saveAnswerBaseQuestion(_.get(this.props, "prefillData", {}), noneOfAbove.includes(false) || noneOfAbove.includes(undefined), this.props)


        }
    }
    handleClose = () => {
        this.setState({
            displayBanner: false
        })
    }
    getQuestionDescription = (questionDesc) => {

        let splitArrayResult = getQuestionDescriptionWithBold(questionDesc)
        if (splitArrayResult && splitArrayResult.length === 2 && splitArrayResult[0].length > 0 && splitArrayResult[1].length > 0) {
            return (<p className="pt-3"><strong>{splitArrayResult[0]}</strong>{splitArrayResult[1]}</p>)
        }
        else {
            return <p className="pt-3">{questionDesc}</p>
        }
        // return <p className="pt-3">{questionDesc}</p>

    }
    handlePrevious = () => {
        moveToPreviousQuestion(this.props)
    }
    saveForLater = () => {
        this.setState({ isSaved: true });
    }
    continueEOI = () => {
        this.setState({ isSaved: false });
    }
    leave = () => {
        this.props.history.push({
            pathname: `/eoi/success`
        })
    }
    render() {
        const { questionData } = this.state
        const check = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.source", "") === "GWDY"
        return (
            <>
                {this.state.isSaved === true ? <SaveForLater continueEOI={() => this.continueEOI()} leave={() => this.leave()} /> : null}
                {this.props.backClick === true ? <Warning handleBrowser={() => this.handleBrowser()} /> : null}
                {this.state.displayBanner === true ?
                    <AlertBanner handleClose={() => this.handleClose()} errorMessage="Please select an answer for each member of your family." />
                    :
                    null
                }
                <main className="knockout">
                    <div className="row question-bg gray-bg">
                        <div className="container-fluid">
                            <div className="prev-link">
                                <button className="btn btn-link" onClick={() => this.handlePrevious()}><i className="fa fa-arrow-left"></i>
                                    {_.get(this.props, "activeQuestion", "") === 1 ? "Back" : "Previous question"}
                                </button>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <h3 className="q-heading">Question {questionData.question_id}/{_.get(this.props, "totalQuestion", "")}</h3>
                                    {questionData.question_desc &&
                                        this.getQuestionDescription(questionData.question_desc)
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        {questionData && questionData.health_condition && questionData.health_condition.map((condition, index) => (
                            <div className="row" key={index}>
                                <div className="col-lg-7">
                                    <div className="checkbox from-group check-box-border" >
                                        <h5 className={questionData.health_condition.length.toString() === condition.condition_id && condition.condition_name === "None of the above" ? "blue-shade" : ''}>{condition.condition_name}</h5>

                                        {condition.condition_desc ?
                                            <p>
                                                {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "NY" ? null : condition.condition_desc}
                                            </p>
                                            :
                                            null}
                                        {condition.condition_name.includes("Consultation in hospital or health care facility") ?
                                            <p className="check-box-paragraph" style={{ color: "#00728d", fontWeight: "bold", marginLeft: "-31px" }}>Do not include routine checkups unless there are existing or newly diagnosed conditions that have not been reported in the previous questions
                                            </p> : null}
                                        {condition.condition_name.includes("Prescribed medications") ?
                                            <p className="check-box-paragraph" style={{ color: "#00728d", fontWeight: "bold", marginLeft: "-31px" }}>Do not include medication that you have already disclosed as treatment for conditions in the previous questions
                                            </p> : null}
                                        {condition.condition_name.includes("Diagnostic testing") ?
                                            <p className="check-box-paragraph" style={{ color: "#00728d", fontWeight: "bold", marginLeft: "-31px" }}>Do not include routine testing unless there are existing or newly diagnosed conditions that have not been reported in the previous questions
                                            </p> : null}
                                        {condition.condition_name.includes("Acquired Immune Deficiency Syndrome (AIDS) or AIDS Related Complex (ARC)") ?
                                            <p className="check-box-paragraph" style={{ marginTop: "5px", color: "black", marginLeft: "-31px" }}>Test results received at an anonymous counseling and test site need not be revealed on this application, nor do the results of a home test kit.
                                            </p> : null}
                               
                                        <div className="checkbox-align">
                                            {condition.life_entities_dq.map((life, index) => (
                                                <>
                                                    <input key={index} type="checkbox" name={life.life_desc + life.life_id} id={condition.condition_id + life.life_id}
                                                        onChange={(e) => this.handleCheck(e)}
                                                        checked={life.answer_condition_info === true} />
                                                    <label htmlFor={condition.condition_id + life.life_id}>
                                                        <p>{life.life_desc.toLowerCase() === "employee" ? "Me" : check === true ? life.life_name.split(", ").splice(-1) : life.life_name.split(", ")[1]}</p>
                                                    </label>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="row">
                            <div className="col-lg-10">
                                <hr />
                                <div className="field-group">
                                    {_.get(this.props, "trustedFlag", "") === true ?
                                        <button className="btn btn-link" onClick={() => this.saveForLater()}>
                                            Save for later
                                        </button>
                                        :
                                        null
                                    }
                                    <button className="btn btn-primary float-right" onClick={() => this.handleNext()}>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </>
        );
    }
}

// export default HealthFamilyQ;
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(HealthFamilyQ);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/HomePage/HomePageAction';
import _ from "lodash";
import JustAMoment from '../../components/Processing/JustAMoment';
import { scrollUp } from '../../utils/sharedFunction';
import SaveForLater from '../../components/SaveForLater/SaveForLater';


class esign extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkBoxValue: false,
            showLoader: false,
            employeeCovered:false,
            childCovered:false,
            childEligible: false,
            date: new Date().getFullYear(),
            isSaved:false,
            isOpened:false
        }
    }
    componentDidMount() {
        const coverages = _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections",{})
        let child_info = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
        let childState = _.get(this.props, "prefillData.eoi_data.group_info.group_situs_state");
        let child_eligible = this.state.childEligible;
        if (_.get(this.props, "prefillData.eoi_data.esign_spouse_status", "") !== "COMPLETED") {
            _.set(this.props, "prefillData.eoi_data.esign_spouse_status", "ACTIVE")
            if (this.props.trustedFlag === true) {
                this.props.updateprefillData(_.get(this.props, "prefillData", ""), _.get(this.props, "prefillId", ""))
            }
            //this.props.updateprefillData(_.get(this.props, "prefillData", ""), _.get(this.props, "prefillId", ""))
        }
        if(this.props.showMediaIcons === "APPROVED" || this.props.showMediaIcons === "DECLINED" || this.props.showMediaIcons === "REFERRED"){
            this.props.hideMediaIcons()
        }
            child_info.map((child) => {
                let currentAge = this.state.date - parseInt(child.dob);
                if(!child_eligible){
                if (currentAge >= 18) {
                    child_eligible = true;
                } else if (currentAge >= 15 && childState === "NC") {
                    child_eligible = true;
                } else {
                    child_eligible = false;
                }    
            }
            return child_eligible;
            })
        const disabilityCoverages = ["short_term_disability", "long_term_disability", "voluntary_long_term_disability", "voluntary_short_term_disability" ]
        let coverageElected = []
        let allEmployeeStatus = []
        let allChildrenStatus = []
       
        for (const coverage in coverages){
            let disabilityCoverageElectedStatus = false
            let employeeCoveredStatus = false
            let childCoveredStatus = false

            if(disabilityCoverages.includes(coverage)){
                
                disabilityCoverageElectedStatus = coverages[coverage] && coverages[coverage].elected && coverages[coverage].elected
            }
            else{
                 employeeCoveredStatus = coverages[coverage] && coverages[coverage].employee_covered && coverages[coverage].employee_covered
                 childCoveredStatus = coverages[coverage] && coverages[coverage].child_covered && coverages[coverage].child_covered 
                }            
            if(employeeCoveredStatus === true || childCoveredStatus ===true  || disabilityCoverageElectedStatus === true){
                coverageElected.push(coverage)
            }
            allEmployeeStatus.push(employeeCoveredStatus)
            allEmployeeStatus.push(disabilityCoverageElectedStatus)
            allChildrenStatus.push(childCoveredStatus)          
        }

        this.setState({childEligible:child_eligible,
            employeeCovered: allEmployeeStatus.includes(true),
            childCovered:allChildrenStatus.includes(true),
        })
        scrollUp();
    }
    downloafPDF = (e) => {
        e.preventDefault();
        this.props.downLoadPDFData(this.props.prefillData, false);
    }

    onSubmit = () => {
 if (this.state.childCovered === true && this.state.childEligible === true ) { //need to check for child
            if(this.state.employeeCovered){
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.rx_auth", true);
            }
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.rx_auth", true);
            if (_.get(this.props, "prefillData.eoi_data.esign_spouse_status", "") === "ACTIVE") {
                _.set(this.props, "prefillData.eoi_data.esign_spouse_status", "COMPLETED")
                if(this.props.trustedFlag === true){
                    this.props.updateprefillData(_.get(this.props, "prefillData", ""), _.get(this.props, "prefillId", ""))
                }
            }
            this.props.history.push(`/eoi/dependentChild`);
        }
        else{
             this.setState({ showLoader: true });
        _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.rx_auth", true);
        if (_.get(this.props, "prefillData.eoi_data.esign_spouse_status", "") === "ACTIVE") {
            _.set(this.props, "prefillData.eoi_data.esign_spouse_status", "COMPLETED")
        }
        // console.log(this.props.prefillData)
        this.props.submitAndSign(this.props.prefillData);
    }
    }

    onChangeBox = (e) => {
        //console.log("document.alue", document.getElementById("checks_1").checked);
        if (document.getElementById("checks_1").checked) {
            this.setState({ "checkBoxValue": true });
        } else {
            //console.log("in false");
            this.setState({ "checkBoxValue": false });
        }
    }
    saveForLater = () => {
        this.setState({isSaved:true});
    }
    continueEOI = () => {
        this.setState({isSaved:false});
    }
    leave = () => {
        this.props.history.push({ pathname: `/eoi/success` })
    }
    render() {
        //console.log("this.props", this.props);
        let firstName = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.first_name", "");
        let lastName = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.last_name", "");
        let spouseName = firstName + " " + lastName;

        return (
            <>
                {this.state.isSaved === true ? <SaveForLater continueEOI={()=>this.continueEOI()} leave = {()=> this.leave()}/> : null}
                {this.state.showLoader ? <JustAMoment /> :
                    <main>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-5">
                                    <div class="intro">
                                        <h3>Dependent signature</h3>
                                        <p>We need your approval and signature before we can decide on your coverage.
                                        </p>
                                        <p><strong>Important:</strong> This section must only be completed by the individual whose name appears below.
                                        </p>
                                    </div>
                                    <div class="data-box checkbox">
                                        <input type="checkbox" id="checks_1" onChange={(e) => this.onChangeBox(e)} />
                                        <label htmlFor="checks_1" class="mb-0">
                                            By checking this box, I, {spouseName}, agree:
                                        </label>
                                    </div>
                                    <ul class="e-sign">
                                        <li class="pt-0">
                                            That the Evidence of Insurability form, enrollment form, and all other required forms bearing my signature will be signed using an electronic signature which will be of the same legal effect, validity or enforceability as if signed using a pen and ink
                                        </li>
                                        <li>
                                            I have read the <a href="/#" onClick={(e) => this.downloafPDF(e)}>Evidence of Insurability form</a> and all other included forms, including my responses, and all disclosures
                                        </li>
                                        <li>
                                            That the Evidence of Insurability form is considered a part of the policy 
                                            {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "NY" ? " and certificate " :" "}
                                             of group insurance through which I am applying for coverage
                                        </li>
                                        <li>
                                            That to the best of my knowledge and belief the answers and information I provided on the Evidence of Insurability form
                                            {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") !== "NY" ? " and any other required " :" "}
                                            is true, accurate and complete
                                        </li>
                                        <li>
                                            To receive all required documents, including the Evidence of Insurability form, and any documents signed by me, at the email address that I have provided.
                                        </li>
                                    </ul>
                                    <hr />
                                    <div class="field-group">
                                        {_.get(this.props, "trustedFlag", "") === true ?
                                            <button className="btn btn-link" onClick={()=>this.saveForLater()}>
                                                Save for later
                                            </button>
                                            :
                                            null
                                        }
                                        <button class="btn btn-secondary float-right" disabled={!this.state.checkBoxValue}
                                            onClick={() => this.onSubmit()}>
                                              {this.props.whoCovered.includes("children") || this.state.childEligible ?
                                                "Sign & continue" : "Sign & submit"}
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </main>
                }
            </>
        );
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(esign);
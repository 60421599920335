

import React, { Component } from 'react';
import moment from "moment";

import WhoAreWeCoveringCompressView from './WhoAreWeCoveringCompressView';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
import {alphaRegex} from '../../utils/constant';
import RemovePopup from './RemovePopup';
import AlertBanner from '../../components/AlertBanner/AlertBanner';
import {scrollUp} from '../../utils/sharedFunction';
import _ from "lodash";
import ReviewBenefitsPopup from '../ReviewBenefitsNCoverages/ReviewBenefitsPopup';
import WhoAreWeCoveringNonTrusted from './WhoAreWeCoveringFormNonTrusted';

class WhoAreWeCovering extends Component {
    state = {
        showBenefitPopup:false,
        calendarError:false,
        blurError:false,
        wasOpened:false,
        whoAreWeCovering:{
            first_name:'',
            last_name:'',
            middle_initial:'',
            dob:null,
            gender:undefined,
            relationShip:'',
        },
        firstNameError:false,
        lastNameError:false,
        dobError:false,
        genderError:false,
        openForm:false,
        addOrEditHeader:"",
        addOrEditStatus:"",
        toAddLink:[],
        display:"",
        dropdownError:"",
        allCoveredDetails:[],
        dropdownData:"",
        addLinkError:[],
        bannerFlag:false,
        editIndex: '',
        showPopup : ''
    }

    componentDidMount () {
            this.props.saveBooleanFun(); //Dummy prop change
         //console.log(_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information",""),_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information","").length)
         if(_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information","")){
            let tempAllCoveredDetails = []
            let dropdownData = _.get(this.props,"whoCovered",undefined)
            
            let dobToSet = null ;
            let spouseDetails = _.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",{})
            //console.log(spouseDetails);
            if(!this.spouseIsEmpty(spouseDetails) && spouseDetails.first_name!==undefined &&spouseDetails.first_name!==null){
                //console.log("in sposue");
                dobToSet = moment(spouseDetails.dob).format('MM/DD/YYYY');
                spouseDetails.dob = dobToSet ;
            }

            let childArray = _.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child",[])
            
            //console.log(childArray.length);
            if(childArray){
                 childArray.map(childData => {
                dobToSet = moment(childData.dob).format('MM/DD/YYYY');
                childData.dob = dobToSet;
                return childData;
            })}
            let spouseFlag =  this.spouseIsEmpty(spouseDetails)
             this.setState({dropdownData:dropdownData})
            //console.log("drop down data",dropdownData);
            //console.log(spouseFlag)
            //console.log(spouseDetails,spouseDetails.length)
            //console.log(_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",{}))
            
            if(spouseFlag === false && spouseDetails.first_name!==undefined && spouseDetails.first_name!==null){
                //console.log("1")
                let spouseDet = _.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",{})
                spouseDet.relationShip = "Spouse"
                tempAllCoveredDetails = [...tempAllCoveredDetails,spouseDet]
                //console.log(tempAllCoveredDetails)
                // this.setState({
                //     allCoveredDetails:[...this.state.allCoveredDetails,_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",{})]
                // })
            }
            if(_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child",[]).length>0){
                                //console.log(tempAllCoveredDetails)

                childArray && childArray.map(child => {
                    child.relationShip="Child"
                    tempAllCoveredDetails = [...tempAllCoveredDetails,child]
                    // this.setState({
                    //     allCoveredDetails:[...this.state.allCoveredDetails,child]
                    // })
                    return child
                })
            }
            //console.log(tempAllCoveredDetails)
            if(tempAllCoveredDetails.length>0){
                this.setState({
                    allCoveredDetails:tempAllCoveredDetails
                })
            }
            if(dropdownData === "Me, my spouse, and my child(ren)" || dropdownData === "My child" 
                || dropdownData === "Me and my child" || dropdownData === "My spouse and child" ){
                this.setState({toAddLink:["Add child"]})
            }
         }
        scrollUp()
    }
    spouseIsEmpty = (spouseDetails) => {
        for(var i in spouseDetails)return false;
        return true;
    }

    handleShow = (e) =>{ 
        e.preventDefault()
        document.body.style.overflow = 'hidden';
        this.setState({
            showPopup : true
        })
        scrollUp()
    }

    handleDateChange = (date) => {
        if (this.state.wasOpened) {
            let whoAreWeCovering = {...this.state.whoAreWeCovering}
            whoAreWeCovering["dob"] = moment(date).format('MM/DD/YYYY')
            this.setState({
                whoAreWeCovering,
                blurError:false,
                
            })
            
        }
        if(this.state.wasOpened){
            this.setState({
                dobError:false
            })
        }
       
        if(JSON.stringify(date)===null || JSON.stringify(date)==="null" ){
            
            this.setState({
                calendarError:true,
                //whoAreWeCovering
            })
        }
        else{
            this.setState({
                calendarError:false
            })
        }
    };

    handleDateBlur = (e) => {

        let currentDate = new Date();
        let givenDate = new Date(e.target.value);
        let yearDiff = currentDate.getFullYear() - givenDate.getFullYear()
        if(Number(currentDate) < Number(givenDate) || yearDiff >= 100) { 
            this.setState({
                 blurError:true,
                 calendarError: true,
             })
        }

        if(this.state.calendarError===false){
            let whoAreWeCovering = {...this.state.whoAreWeCovering}
            whoAreWeCovering["dob"] = e.target.value
            this.setState({
                whoAreWeCovering,
            })
        }
        if(this.state.calendarError===true && e.target.value.length!==0){
            // if(this.state.invalidDateErrorOnNext===true){
            this.setState({
                blurError:true
            })
            //}
        }
        else{
            this.setState({
                blurError:false,
            })
        }

        if(this.state.calendarError === false){
            if(e.target.value && this.state.dobError === true){
                this.setState({dobError:false})
            }
        }  
              

    };

    handleOnOpen = () => {
        this.setState({
            wasOpened:true
        })
    };

    handleOnClose = () => {
        this.setState({
            wasOpened:false 
        })
    };

    handleNameChange = (e,type) => {
        //console.log("SSSSSSSs")
        let whoAreWeCovering = {...this.state.whoAreWeCovering}
        if(type === "middle_initial"){
            if (e.target.value === '' || alphaRegex.test(e.target.value)) {
                whoAreWeCovering[type] = e.target.value
                this.setState({
                    whoAreWeCovering
                })
            }
        }
        else{
            whoAreWeCovering[type] = e.target.value
            this.setState({
                whoAreWeCovering
            })
        }

    }
    handleFieldBlur = (e,type) => {
        //console.log("VVVVVVVVVVVv")
        e.preventDefault()
        const {first_name,last_name,dob,gender} = this.state.whoAreWeCovering
        if(type === "first_name"){if(first_name.trim().length>0)this.setState({firstNameError:false})}
        if(type === "last_name"){if(last_name.trim().length>0)this.setState({lastNameError:false})}
         if(type === "dob"){if(dob)this.setState({dobError:false})}
        if(type === "gender"){if(gender)this.setState({genderError:false})}
        

    }
    addOrEditSave = (e) => {
        e.preventDefault()
        const {addOrEditStatus,allCoveredDetails,whoAreWeCovering} = this.state
        const {first_name,last_name,dob,gender} = whoAreWeCovering
        
        this.setState({InvalidDateErrorOnNext:true})
        if(first_name.trim().length<=0)this.setState({firstNameError:true})
        if(last_name.trim().length<=0)this.setState({lastNameError:true})
        let currentDate = new Date();
        let givenDate = new Date(dob);
        let yearDiff = currentDate.getFullYear() - givenDate.getFullYear();
        //console.log("current date :",dob,currentDate,givenDate);  
        //console.log(Number(currentDate) < Number(givenDate));
        if(Number(currentDate) < Number(givenDate) || yearDiff >= 100) { 
            this.setState({
                 blurError:true,
                 calendarError: true,
             })
        }
        if(!dob || this.state.calendarError === true){
            this.setState({dobError:true})
        }
        if(!gender || gender==="Select")this.setState({genderError:true})
        //console.log(dob,this.state.calendarError,gender)
         if(first_name.trim().length>0 && last_name.trim().length>0 && dob  && !this.state.calendarError===true && this.state.dobError  === false && gender && gender!=="Select" ){
            this.setState({
                openForm:false,
            })
            if(addOrEditStatus === "add"){

                this.setState({
                    allCoveredDetails:[...allCoveredDetails,whoAreWeCovering]
                })

            }
            if (addOrEditStatus === "edit") {
                let tempAllCoveredDetails = allCoveredDetails;
                tempAllCoveredDetails.splice(this.state.editIndex, 1, whoAreWeCovering);
                this.setState({
                    allCoveredDetails: tempAllCoveredDetails,
                    addOrEditStatus: "",
                    editIndex: ''
                })

            }
            this.setState({
                whoAreWeCovering:{
                    first_name:'',
                    middle_initial:'',
                    last_name:'',
                    dob:null,
                    gender:undefined
                },
                firstNameError:false,
                lastNameError:false,
                dobError:false,
                genderError:false,
                blurError:false,
                calendarError:false
            })
        }
        
    }

    addSpouseOrChild = (selectedPerson) => {
        
        if(this.state.openForm === false){
            if(selectedPerson === "Add spouse"){
                let whoAreWeCovering = {...this.state.whoAreWeCovering}
                whoAreWeCovering["relationShip"] = "Spouse"
                let tempAddLink = [...this.state.toAddLink]
                tempAddLink = tempAddLink.filter(eachData => eachData !== selectedPerson)
                
                this.setState({
                    whoAreWeCovering,
                    toAddLink:tempAddLink
                })
            }
            if(selectedPerson === "Add child"){
                let whoAreWeCovering = {...this.state.whoAreWeCovering}
                whoAreWeCovering["relationShip"] = "Child"
                this.setState({
                    whoAreWeCovering
                })
            }
            this.setState({
            addOrEditHeader:selectedPerson,
            addOrEditStatus:"add"
            })
        }
        this.setState({
            openForm:true,
            bannerFlag:false,            
            addLinkError:[]
        })
       
    }
    handleCancel = () => {
        
        if(this.state.addOrEditStatus === "edit"){
            //this.setState({whoAreWeCovering : whoAreWeCovering})
        }
        this.setState({
            openForm:false,
            editIndex : '',
            whoAreWeCovering:{
                first_name:'',
                middle_initial:'',
                last_name:'',
                dob:null,
                gender:undefined
            },
            firstNameError:false,
            lastNameError:false,
            dobError:false,
            genderError:false,
            blurError:false,
            calendarError:false,
            showPopup : false      
        })
        if(this.state.addOrEditStatus === "add" &&  this.state.whoAreWeCovering.relationShip==="Spouse"  && this.state.dropdownData === "Me and my spouse"){
                this.setState({
                    toAddLink:["Add spouse"]
                })
        }
        if(this.state.addOrEditStatus === "add" && this.state.whoAreWeCovering.relationShip==="Spouse"  && this.state.dropdownData === "Me, my spouse, and my child(ren)"){
                this.setState({
                    toAddLink:["Add spouse",...this.state.toAddLink]
                })
        }
        // if(this.state.whoAreWeCovering.relationShip==="Child"  && this.state.dropdownData === "Me, my spouse, and my child(ren)"){
        //         this.setState({
        //             toAddLink:["Add spouse",...this.state.toAddLink]
        //         })
        // }
        if(this.state.addOrEditStatus === "add" &&  this.state.whoAreWeCovering.relationShip==="Spouse"  && this.state.dropdownData === "My spouse"){
                this.setState({
                    toAddLink:["Add spouse"]
                })
        }
        if(this.state.addOrEditStatus === "add" &&  this.state.whoAreWeCovering.relationShip==="Spouse"  && this.state.dropdownData === "My spouse and child"){
                this.setState({
                    toAddLink:["Add spouse",...this.state.toAddLink]
                })
        }
        this.setState({
            addOrEditStatus: ""
        })
        document.body.style.overflow = 'auto';
    }
    
    handleEdit = (index) => {
        const { allCoveredDetails } = this.state
        //console.log("index value ", index);
        let editDetail = allCoveredDetails[index];
        let editHeader = "Edit child";
        if(editDetail.relationShip === "Spouse"){
            editHeader = "Edit spouse";
        }  
        //console.log("editing Object", editDetail);
        this.setState({
            addOrEditStatus : "edit",
            editIndex : index,
            openForm : true,
            addOrEditHeader : editHeader,
            whoAreWeCovering : editDetail
        })
    }

    handleRemove = (e) => {
        e.preventDefault()
        const {addOrEditStatus,allCoveredDetails} = this.state
        
              if (addOrEditStatus === "edit") {
                let tempAllCoveredDetails = allCoveredDetails;
                tempAllCoveredDetails.splice(this.state.editIndex, 1);
                this.setState({
                    allCoveredDetails: tempAllCoveredDetails,
                    addOrEditStatus: "",
                    editIndex: '',
                    showPopup : false,
                    openForm : false,
                })

            }
            this.setState({
                whoAreWeCovering:{
                    first_name:'',
                    middle_initial:'',
                    last_name:'',
                    dob:null,
                    gender:undefined
                },
                firstNameError:false,
                lastNameError:false,
                dobError:false,
                genderError:false,
                blurError:false,
                calendarError:false
            })

        if(this.state.addOrEditStatus === "edit" &&  this.state.whoAreWeCovering.relationShip==="Spouse"  && this.state.dropdownData === "Me and my spouse"){
                this.setState({
                    toAddLink:["Add spouse"]
                })
            }
        if(this.state.addOrEditStatus === "edit" && this.state.whoAreWeCovering.relationShip==="Spouse"  && this.state.dropdownData === "Me, my spouse, and my child(ren)"){
                this.setState({
                    toAddLink:["Add spouse",...this.state.toAddLink]
                })
        }
        
        if(this.state.addOrEditStatus === "edit" &&  this.state.whoAreWeCovering.relationShip==="Spouse"  && this.state.dropdownData === "My spouse"){
                this.setState({
                    toAddLink:["Add spouse"]
                })
        }
        if(this.state.addOrEditStatus === "edit" &&  this.state.whoAreWeCovering.relationShip==="Spouse"  && this.state.dropdownData === "My spouse and child"){
                this.setState({
                    toAddLink:["Add spouse",...this.state.toAddLink]
                })
        }
            this.setState({
                addOrEditStatus: ""
            })
            document.body.style.overflow = 'auto';
     }

    onPrevious=()=>{
        this.props.history.push(`/eoi/tellUsMore`)
    }

    handleDropDown =(e)=>{
        this.setState({
            dropdownData: e.target.value,
            addLinkError:[],
            openForm : false,
            editIndex :'',
            whoAreWeCovering:{
                first_name:'',
                middle_initial:'',
                last_name:'',
                dob:null,
                gender:undefined
            },
            firstNameError:false,
            lastNameError:false,
            dobError:false,
            genderError:false,
            blurError:false,
            calendarError:false
        })
       
        if(e.target.value !==""){
            this.setState({dropdownError:""})
        }
        
        if(e.target.value ==="Select"){
            this.setState({bannerFlag :false})
            this.setState({toAddLink:[],display:"Select",allCoveredDetails:[]})}

        if(e.target.value ==="Me"){
            // this.setState({bannerFlag :false})
            this.setState({toAddLink:[],display:"Me",allCoveredDetails:[]})}

        
        if(e.target.value ==="Me and my spouse"){
            
            let a =this.state.allCoveredDetails.filter(item => item.relationShip !== "Child")
            let tempAddLink = [];
            let spouseExist = false;
            let childExist = false
            if(this.state.allCoveredDetails.length>0){
                this.state.allCoveredDetails.map(data => {
                    if(data.relationShip ==="Spouse"){
                        spouseExist = true
                    }
                    if(data.relationShip ==="Child" ){
                        childExist = true
                    }
                    return data;
                })
                if(spouseExist === true && childExist === false){
                    tempAddLink = []
                }
                if(spouseExist === false && childExist === true){
                    tempAddLink = ["Add spouse"]
                }
                if(spouseExist === true && childExist === true){
                    tempAddLink = []
                }
            }
            else{
                tempAddLink = ["Add spouse"]
            }
        this.setState({toAddLink:tempAddLink,display:"Me and my spouse",allCoveredDetails:a})

        }
        if(e.target.value === "Me and my child"){
            //console.log(this.state.allCoveredDetails)
            let onlyChild =this.state.allCoveredDetails.filter(item => item.relationShip !== "Spouse")
            //console.log(onlyChild)
            this.setState({toAddLink:["Add child"],display:"Me and my child(ren)",allCoveredDetails:onlyChild})
        }
        if(e.target.value ==="Me, my spouse, and my child(ren)"){
            // let tempAddLink = [];
            // if(this.state.allCoveredDetails.length>0){
            //     this.state.allCoveredDetails.map(data => {
            //         if(data.relationShip ==="Spouse"){
            //             tempAddLink = ["Add child"]
            //         }
            //         if(data.relationShip ==="Child"){
            //             tempAddLink = ["Add spouse","Add child"]
            //         }
            //         return data;
            //     })
            // }
            // else{
            //     tempAddLink = ["Add spouse", "Add child"]
            // }
            let tempAddLink = [];
            let spouseExist = false;
            let childExist = false
                        //console.log(this.state.allCoveredDetails)

            if(this.state.allCoveredDetails.length>0){
                //console.log("aaa")
                this.state.allCoveredDetails.map(data => {
                    // if(data.relationShip ==="Spouse"){
                    //     tempAddLink = ["Add child"]
                    // }
                    // if(data.relationShip ==="Child"){
                    //     tempAddLink = ["Add spouse","Add child"]
                    // }
                    if(data.relationShip ==="Spouse"){
                        spouseExist = true
                    }
                    if(data.relationShip ==="Child" ){
                        childExist = true
                    }
                    return data;
                })
                //console.log(spouseExist,childExist,"EEEEEEEEEE")

                if(spouseExist === true && childExist === false){
                    tempAddLink = ["Add child"]
                }
                if(spouseExist === false && childExist === true){
                    tempAddLink = ["Add spouse","Add child"]
                }
                if(spouseExist === true && childExist === true){
                    tempAddLink = ["Add child"]
                }
            }
            else{
                                //console.log("aaa")

                tempAddLink = ["Add spouse", "Add child"]
            }
            this.setState({toAddLink:tempAddLink,display:"Me, my spouse, and my child(ren)"})
        }
        if(e.target.value === "My spouse and child" ){
            // let tempAddLink = [];
            // if(this.state.allCoveredDetails.length>0){
            //     this.state.allCoveredDetails.map(data => {
            //         if(data.relationShip ==="Spouse"){
            //             tempAddLink = ["Add child"]
            //         }
            //         if(data.relationShip ==="Child"){
            //             tempAddLink = ["Add spouse","Add child"]
            //         }
            //         return data;
            //     })
            // }
            // else{
            //     tempAddLink = ["Add spouse", "Add child"]
            // }
            let tempAddLink = [];
            let spouseExist = false;
            let childExist = false
                        //console.log(this.state.allCoveredDetails)

            if(this.state.allCoveredDetails.length>0){
                //console.log("aaa")
                this.state.allCoveredDetails.map(data => {
                    // if(data.relationShip ==="Spouse"){
                    //     tempAddLink = ["Add child"]
                    // }
                    // if(data.relationShip ==="Child"){
                    //     tempAddLink = ["Add spouse","Add child"]
                    // }
                    if(data.relationShip ==="Spouse"){
                        spouseExist = true
                    }
                    if(data.relationShip ==="Child" ){
                        childExist = true
                    }
                    return data;
                })
                //console.log(spouseExist,childExist,"EEEEEEEEEE")
                if(spouseExist === true && childExist === false){
                    tempAddLink = ["Add child"]
                }
                if(spouseExist === false && childExist === true){
                    tempAddLink = ["Add spouse","Add child"]
                }
                if(spouseExist === true && childExist === true){
                    tempAddLink = ["Add child"]
                }
            }
            else{
                                //console.log("aaa")

                tempAddLink = ["Add spouse", "Add child"]
            }
            this.setState({toAddLink:tempAddLink,display:"My spouse and child(ren)"})
        }
        if(e.target.value ==="My spouse"){
            let onlySpouse =this.state.allCoveredDetails.filter(item => item.relationShip !== "Child")
            let tempAddLink = [];
            let spouseExist = false;
            let childExist = false
            if(this.state.allCoveredDetails.length>0){
                this.state.allCoveredDetails.map(data => {
                    if(data.relationShip ==="Spouse"){
                        spouseExist = true
                    }
                    if(data.relationShip ==="Child" ){
                        childExist = true
                    }
                    return data;
                })
                if(spouseExist === true && childExist === false){
                    tempAddLink = []
                }
                if(spouseExist === false && childExist === true){
                    tempAddLink = ["Add spouse"]
                }
                if(spouseExist === true && childExist === true){
                    tempAddLink = []
                }
            }
            else{
                tempAddLink = ["Add spouse"]
            }
            this.setState({toAddLink:tempAddLink,display:"My spouse",allCoveredDetails:onlySpouse})
        }if(e.target.value ==="My child"){
            let onlyChild =this.state.allCoveredDetails.filter(item => item.relationShip !== "Spouse")
            this.setState({toAddLink:["Add child"],display:"My child",allCoveredDetails:onlyChild})
        }

        if(e.target.value !== "Select"){
            this.setState({bannerFlag:false,})
        }
    }
    handleNext =()=>{
        let spouseExist = false;
        let childExist = false

        if(this.state.dropdownData === "Me, my spouse, and my child(ren)" || this.state.dropdownData === "My spouse and child"){
            if(this.state.allCoveredDetails.length>0){
                
                    this.state.allCoveredDetails.map(data => {
                        if(data.relationShip ==="Spouse"){
                            spouseExist = true
                        }
                        if(data.relationShip ==="Child" ){
                            childExist = true
                        }
                        return data;
                    })
                }
        }
        
        if(this.state.toAddLink.length === 0 && this.state.display ==="Select" ){
            this.setState({dropdownError:"Select who will be covered"})
            this.setState({bannerFlag :true,})
        }
        else if(!this.state.dropdownData){
            this.setState({dropdownError:"Select who will be covered"})
            this.setState({bannerFlag :true})  
        }
        else if(this.state.dropdownData === "Me and my spouse" && this.state.allCoveredDetails.length<=0){
            //if(this.state.allCoveredDetails.length<=0){
                this.setState({
                    addLinkError : ["Add a spouse"],
                    bannerFlag:true,
                })
           // }
        }
        else if(this.state.dropdownData === "Me, my spouse, and my child(ren)" && this.state.allCoveredDetails.length<=0){
            //if(this.state.allCoveredDetails.length<=0){
                this.setState({
                    addLinkError : ["Add a spouse","Add a child"],
                    bannerFlag:true,
                })
            //}
        }
        else if(this.state.dropdownData === "My spouse and child" && this.state.allCoveredDetails.length<=0){
            //if(this.state.allCoveredDetails.length<=0){
                this.setState({
                    addLinkError : ["Add a spouse","Add a child"],
                    bannerFlag:true,
                })
            //}
        }
            
        else if(spouseExist === true && childExist === false){
                    this.setState({
                        addLinkError : ["Add a child"],
                        bannerFlag:true,
                    })
                    
        }
        else if(spouseExist === false && childExist === true){
                    this.setState({
                        addLinkError : ["Add a spouse"],
                        bannerFlag:true,
                    })
        }

        else if(this.state.dropdownData === "My spouse" && this.state.allCoveredDetails.length<=0){
            //if(this.state.allCoveredDetails.length<=0){
                this.setState({
                    addLinkError : ["Add a spouse"],
                    bannerFlag:true,
                })
            //}
        }
        else if(this.state.dropdownData === "My child" && this.state.allCoveredDetails.length<=0){
            //if(this.state.allCoveredDetails.length<=0){
                this.setState({
                    addLinkError : ["Add a child"],
                    bannerFlag:true,
                })
            //}
        }
        else if(this.state.dropdownData === "Me and my child" && this.state.allCoveredDetails.length<=0){
            //if(this.state.allCoveredDetails.length<=0){
                this.setState({
                    addLinkError : ["Add a child"],
                    bannerFlag:true,
                })
            //}
        }
        else{
            let childArray = []
            //console.log(this.state.allCoveredDetails,this.state.dropdownData)
            if(this.state.dropdownData === "Me, my spouse, and my child(ren)" || this.state.dropdownData === "My spouse and child"){
                //console.log("111111111111111")
                this.state.allCoveredDetails.map(data => {
                    if(data.relationShip === "Spouse"){
                        // console.log(_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse"))
                        // console.log(compareNestedObject(_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",{}),data))
                        _.set(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",data)
                        _.set(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.dob",moment(data.dob).format('YYYY-MM-DD'))

                    }
                    if(data.relationShip === "Child"){
                       childArray.push(data)
                    }
                    return data
                })
            }
            else if(this.state.dropdownData === "Me and my spouse" || this.state.dropdownData === "My spouse"){
                this.state.allCoveredDetails.map(data => {
                    //if(data.relationShip === "Spouse"){
                        // console.log(_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse"))
                        // console.log(compareNestedObject(_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",{}),data))
                        _.set(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",data)
                        _.set(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.dob",moment(data.dob).format('YYYY-MM-DD'))
                        childArray = []
                        return data
                })
                
            }
            else if(this.state.dropdownData === "Me and my child" || this.state.dropdownData === "My child"){
                this.state.allCoveredDetails.map(data => {
                         //console.log("3333333333333")
                         _.set(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",{mib_auth: false, electronic_consent: false})
                        childArray.push(data)
                        return data
                })
                
            }
            else if(this.state.dropdownData === "Me"){
                //console.log("4444444444444")
                _.set(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",{})
                childArray = []
            }
            

            childArray.length > 0 && childArray.map((childData,index) => {
                childData.sequence = index+1
                childData.dob = moment(childData.dob).format('YYYY-MM-DD');
                return childData;
            })
            //console.log("child array:",childArray);
            if(childArray){
                _.set(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child",childArray);
            }
            else{
                _.set(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child",[]);
            }
            
            //console.log(this.state.dropdownData)
            
            //console.log(this.props)
            this.props.whoareCovered(this.state.dropdownData);
            if(this.state.dropdownData === "Me" || this.state.dropdownData === "My spouse" || (this.state.dropdownData === "My child" && childArray.length === 1)){
                this.props.history.push('/eoi/selectCoverageOnlyPerson')
            }else {
                this.props.history.push('/eoi/selectYourCoverage')
            }            
        }
        

    }

       
    handleClose = () => {
        this.setState({
            bannerFlag : false
        });
    }
    onBenefitPopup = () => {
        this.setState({ showBenefitPopup: !this.state.showBenefitPopup });
    }

    onClosepopup = () => {
        //console.log("this.state", this.state);
        this.setState({ showBenefitPopup: !this.state.showBenefitPopup });
    }   

    render() {
        //console.log(this.state.allCoveredDetails,this.state.allCoveredDetails.length);
        let check = _.get(this.props,"nonTrustedData.eoi_data.admin_group_indicator")
        console.log(check,"ll",this.props)
        let memCov = _.get(this.props,"nonTrustedData.eoi_data.admin_group_coverages")
        let headingLabel = "Spouse";
        let contentlabel = "This includes marital spouse, domestic partner and/or civil union.";
        return (
            <>
            {this.state.showBenefitPopup ?
                 <ReviewBenefitsPopup headingLabel={headingLabel} contentlabel={contentlabel} closepopup={this.onClosepopup} />
                 : <>
            {this.state.bannerFlag === true? 
            <AlertBanner 
                handleClose ={() =>this.handleClose()}
                errorMessage="Please fill out all required fields."/> 
            :null}
            {this.state.showPopup === true ? <RemovePopup
             handleClosePopup ={this.handleCancel}
             handleRemove = {this.handleRemove}
             removeFamilyName = {this.state.whoAreWeCovering.first_name}
             ></RemovePopup> : null}
            <main>
                <div className="container-fluid">
                    <div className="prev-link">
                        <button className="btn btn-link" onClick={(e)=>this.onPrevious(e)}><i className="fa fa-arrow-left"></i>Back</button>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-7">
                            <div className="intro">
                                <h3>Who are we covering?</h3>
                            </div>
                            <div className="form-group">
                                <label htmlFor="eoi-coverage">I want coverage for</label>
                                {check === "N" || check ===undefined ? 
                                <select className="form-control" id="eoi-coverage" value={this.state.dropdownData}
                                onChange={(e)=>this.handleDropDown(e)}>
                                    <option value="Select" >Select</option>
                                    <option value="Me">Me</option>
                                    <option value="Me and my spouse">Me, my spouse</option>
                                    <option value="Me and my child">Me, my child(ren)</option>
                                    <option value="Me, my spouse, and my child(ren)">Me, my spouse, my child(ren)</option>
                                    <option value="My spouse">My spouse</option>
                                    <option value="My child">My child(ren)</option>
                                    <option value="My spouse and child">My spouse, my child(ren)</option>
                                </select> :  <select className="form-control" id="eoi-coverage" value={this.state.dropdownData}
                                onChange={(e)=>this.handleDropDown(e)}>
                                    <option value="Select" >Select</option>
                                    {(memCov?.employee?.length > 0) ? <option value="Me">Me</option> : null}
                                    {(memCov?.employee?.length > 0 && memCov?.spouse?.length > 0) ? <option value="Me and my spouse">Me, my spouse</option> : null}
                                    {(memCov?.employee?.length > 0 && memCov?.child?.length > 0) ? <option value="Me and my child">Me, my child(ren)</option> : null}
                                    {memCov?.employee?.length > 0 && memCov?.spouse?.length > 0 && memCov?.child?.length > 0 ?<option value="Me, my spouse, and my child(ren)">Me, my spouse, my child(ren)</option> : null}
                                    {memCov?.spouse?.length > 0 ? <option value="My spouse">My spouse</option> : null}
                                    {memCov?.child?.length > 0 ? <option value="My child">My child(ren)</option> : null}
                                    {memCov?.spouse?.length > 0 && memCov?.child?.length > 0 ? <option value="My spouse and child">My spouse, my child(ren)</option> : null}
                                </select>                         
                            } 
                                {this.state.dropdownError.length>1 ?
                                    <span className="error"><i className="fas fa-exclamation-circle"></i>{this.state.dropdownError}</span>
                                    :
                                    null
                                }
                            </div>
                            
                            { this.state.dropdownData && this.state.dropdownData !==  "Select" && this.state.dropdownData !== "Me"   &&  
                            <p className="pb-1">Tell us about your family.
                                { this.state.dropdownData && (this.state.dropdownData === "Me and my spouse"
                                || this.state.dropdownData === "Me, my spouse, and my child(ren)" || 
                                this.state.dropdownData === "My spouse and child" ||
                                this.state.dropdownData === "My spouse") &&
                                <div className="tips-info">
                                    <div className ="itext" onClick={() => this.onBenefitPopup()}>
                                            <i className="fas fa-info-circle"></i>
                                    </div>
                                </div> }
                            </p>}
                            <div className="row">
                                {this.state.allCoveredDetails.length>0 && this.state.allCoveredDetails.map((eachDetail,index) => (
                                    index === this.state.editIndex ?
                                    (this.state.addOrEditStatus === "edit" && this.state.openForm ?
                                        <WhoAreWeCoveringNonTrusted
                                            
                                            handleNameChange={this.handleNameChange}
                                            handleShow ={this.handleShow}
                                            handleFieldBlur={this.handleFieldBlur}
                                            handleDateChange={this.handleDateChange}
                                            handleDateBlur={this.handleDateBlur}
                                            handleOnOpen={this.handleOnOpen}
                                            handleOnClose={this.handleOnClose}
                                            showPopup = {this.state.showPopup}
                                            whoAreWeCovering={this.state.whoAreWeCovering}
                                            addOrEditSave={this.addOrEditSave}
                                            addOrEditHeader={this.state.addOrEditHeader}
                                            handleCancel={this.handleCancel}
                                            addOrEditStatus={this.state.addOrEditStatus}
                                            firstNameError={this.state.firstNameError}
                                            lastNameError={this.state.lastNameError}
                                            dobError={this.state.dobError}
                                            genderError={this.state.genderError}
                                            blurError={this.state.blurError}
                                        ></WhoAreWeCoveringNonTrusted> : null)
                                    :
                                    <WhoAreWeCoveringCompressView key={index}
                                        whoAreWeCovering = {eachDetail}
                                        handleEdit={this.handleEdit}
                                        index={index}
                                    ></WhoAreWeCoveringCompressView>
                                ))}
                                {this.state.addOrEditStatus !== "edit" && this.state.openForm ?
                                    <WhoAreWeCoveringNonTrusted
                                        
                                        handleNameChange ={this.handleNameChange}
                                        handleFieldBlur = {this.handleFieldBlur}
                                        handleDateChange = {this.handleDateChange}
                                        handleDateBlur = {this.handleDateBlur}
                                        handleOnOpen = {this.handleOnOpen}
                                        handleOnClose = {this.handleOnClose}
                                        whoAreWeCovering = {this.state.whoAreWeCovering}
                                        addOrEditSave = {this.addOrEditSave}
                                        addOrEditHeader = {this.state.addOrEditHeader}
                                        handleCancel = {this.handleCancel}

                                        firstNameError = {this.state.firstNameError}
                                        lastNameError = {this.state.lastNameError}
                                        dobError = {this.state.dobError}
                                        genderError = {this.state.genderError}
                                        blurError = {this.state.blurError}
                                    ></WhoAreWeCoveringNonTrusted>
                                :
                                    null
                                }
                            </div>
                            
                            { this.state.toAddLink !=="Me" && this.state.toAddLink && this.state.toAddLink.length>0 && this.state.toAddLink.map((link,index) =>(
                                <> <button className="btn btn-link blue-link" onClick={()=>this.addSpouseOrChild(link)}>+ {link}</button><br/>
                                {this.state.addLinkError.length>0?
                                <><span className="addlinkerror">{this.state.addLinkError[index] && <i className="fas fa-exclamation-circle"></i>}{this.state.addLinkError[index]}</span> <br/></>:null
                                 } 
                                
                                </> 

                            ))}
                            <hr />
                            <div className="field-group float-right">
                                <button className="btn btn-primary" disabled={this.state.openForm} onClick={()=>this.handleNext()}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.dropdownData && (this.state.dropdownData === "Me and my spouse"
                || this.state.dropdownData === "Me, my spouse, and my child(ren)" || 
                this.state.dropdownData === "My spouse and child" ||
                this.state.dropdownData === "My spouse") &&
                <div className="tips-container"  style={{top: "120px"}}>
                    <div className="tip-box">
                    <div className="heading">Spouse</div>
                    This includes marital spouse, domestic partner and/or civil union.
                    </div>
                </div>}
            </main>
            </>}
        </>
        );
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(WhoAreWeCovering);


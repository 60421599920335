import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';

class GetLink extends Component {
    handleClick = () => {
        //console.log(this.state);
        this.props.getprefillLink(this.state);
    }
    onChangeJson = (e) => {
        //console.log("In changes", JSON.parse(e.target.value));

        this.setState({"json" : JSON.parse(e.target.value)});

    }
    onChange = (e) => {
        //console.log("In changes", e.target.value);
        this.setState({"groupId" : e.target.value});
    }
    render() {
        return (
            
            <main>
                    <div className="container moveon-page">
                        <div className="col-lg-12 ">
                           
                        <div className="row form-group">
                                <div className="col-md-10 col-sm-9">
                                    <label htmlFor="first-name">Group Id</label>
                                    <input className="form-control" 
                                    onChange = {this.onChange}
                                    id="first-name" type="text" />
                                </div>

                                <div className="col-md-10 col-sm-9">
                                    <label htmlFor="first-name">Request Json</label>
                                    <input className="form-control" 
                                     onChange = {this.onChangeJson}
                                     id="first-name" type="text" />
                                </div>
                                <div className="col-md-10 col-sm-9">
                                    <a href={this.props.link}>{this.props.link}</a>
                                </div>

                                <button className="btn btn-primary mt-4" onClick={() => this.handleClick()}>Submit</button>

                        </div>
                    </div>
                    </div>
                </main>
        );
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(GetLink);
//export default ReviewBenefit;
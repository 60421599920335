import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUpFirst: false,
            showUpSecond: true,
            showUpThird: true,
            showUpFourth: true,
            showupFifth: true,
            flag: false
        }
    }
    collapse = (e, txt) => {

        let showUpFlag = false;
        if (txt === "one") {
            showUpFlag = this.state.showUpFirst
            if (showUpFlag === true) {
                this.setState({ showUpFirst: false });
            }
            else {
                this.setState({ showUpFirst: true });
            }
        } else if (txt === "two") {
            showUpFlag = this.state.showUpSecond;
            if (showUpFlag === true) {
                this.setState({ showUpSecond: false });
            }
            else {
                this.setState({ showUpSecond: true });
            }
        } else if (txt === "three") {
            showUpFlag = this.state.showUpThird;
            if (showUpFlag === true) {
                this.setState({ showUpThird: false });
            }
            else {
                this.setState({ showUpThird: true });
            }
        }
        else if (txt === "four") {
            showUpFlag = this.state.showUpFourth;
            if (showUpFlag === true) {
                this.setState({ showUpFourth: false });
            }
            else {
                this.setState({ showUpFourth: true });
            }
        }
        else if (txt === "five") {
            showUpFlag = this.state.showupFifth;
            if (showUpFlag === true) {
                this.setState({ showupFifth: false });
            }
            else {
                this.setState({ showupFifth: true });
            }
        }
    }
    
    render() {
        //console.log("trusted", this.props.trustedFlag);
        return (
            <>
            {this.props.trustedFlag === true?
                <div className="popup">
                    <div className="popup-header">
                        <button className="close hidden-text" onClick={this.props.handleClose}>
                            <i className="fas fa-times"><span>close button</span></i>
                        </button>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="intro">
                                    <h3>Evidence of Insurability - FAQ</h3>
                                </div>
                                <div className="row">
                                    <ul className="col-lg-10 accordion" id="accordion">{ }
                                        <li className="accordion-card">
                                            <div className="accordion-card-header" id="headingOne">
                                                <h6>Why do I need to complete Evidence of Insurability (EOI)?
                                                </h6>
                                                <button className="close hidden-text" type="button" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne" onClick={(e) => this.collapse(e, "one")}>
                                                    <i className={this.state.showUpFirst ? "fas fa-chevron-up" : "fas fa-chevron-down"}><span>Collapse button</span></i>
                                                </button>
                                            </div>
                                            <div id="collapseOne" className={this.state.showUpFirst ? "collapse show" : "collapse"} aria-labelledby="headingOne"
                                                data-parent="#accordion">
                                                <div className="accordion-card-body">
                                                    <p>Most group insurance offers a certain amount of guaranteed coverage. EOI may be required if you want more than that amount, you’re currently enrolled and want more insurance, or you didn’t elect coverage when you were hired. </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="accordion-card">
                                            <div className="accordion-card-header" id="headingTwo">
                                                <h6>Is my information kept secure and confidential?
                                                </h6>
                                                <button className="close hidden-text" type="button" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="true"
                                                    aria-controls="collapseTwo" onClick={(e) => this.collapse(e, "two")}>
                                                    <i className={this.state.showUpSecond ? "fas fa-chevron-down" : "fas fa-chevron-up"}><span>Collapse button</span></i>
                                                </button>
                                            </div>
                                            <div id="collapseTwo" className={this.state.showUpSecond ? "collapse" : "collapse show"} aria-labelledby="headingTwo"
                                                data-parent="#accordion">
                                                <div className="accordion-card-body">
                                                    <p>Yes. We take our responsibility to protect your personal information very seriously. Our website is secure, and your information is confidential and never shared with your employer.</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="accordion-card">
                                            <div className="accordion-card-header" id="headingThree">
                                                <h6>Can I get approved online?
                                                </h6>
                                                <button className="close hidden-text" type="button" data-toggle="collapse"
                                                    data-target="#collapseThree" aria-expanded="true"
                                                    aria-controls="collapseThree" onClick={(e) => this.collapse(e, "three")}>
                                                    <i className={this.state.showUpThird ? "fas fa-chevron-down" : "fas fa-chevron-up"}><span>Collapse button</span></i>
                                                </button>
                                            </div>
                                            <div id="collapseThree" className={this.state.showUpThird ? "collapse" : "collapse show"} aria-labelledby="headingThree"
                                                data-parent="#accordion">
                                                <div className="accordion-card-body">
                                                    <p>Yes, we can review your information online and make a decision.</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li className="accordion-card">
                                            <div className="accordion-card-header" id="headingFour">
                                                <h6>What if I’m referred to Underwriting?
                                                </h6>
                                                <button className="close hidden-text" type="button" data-toggle="collapse"
                                                    data-target="#collapseFour" aria-expanded="true"
                                                    aria-controls="collapseFour" onClick={(e) => this.collapse(e, "four")}>
                                                    <i className={this.state.showUpFourth ? "fas fa-chevron-down" : "fas fa-chevron-up"}><span>Collapse button</span></i>
                                                </button>
                                            </div>
                                            <div id="collapseFour" className={this.state.showUpFourth ? "collapse" : "collapse show"} aria-labelledby="headingFour"
                                                data-parent="#accordion">
                                                <div className="accordion-card-body">
                                                    <p>When we refer an application to Underwriting, it means we need a little more information before making a decision. We make most decisions within 2 days after receiving the information. </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="accordion-card">
                                            <div className="accordion-card-header" id="headingFive">
                                                <h6>What if I’m declined for coverage?
                                                </h6>
                                                <button className="close hidden-text" type="button" data-toggle="collapse"
                                                    data-target="#collapseFive" aria-expanded="true"
                                                    aria-controls="collapseFive" onClick={(e) => this.collapse(e, "five")}>
                                                    <i className={this.state.showupFifth ? "fas fa-chevron-down" : "fas fa-chevron-up"}><span>Collapse button</span></i>
                                                </button>
                                            </div>
                                            <div id="collapseFive" className={this.state.showupFifth ? "collapse" : "collapse show"} aria-labelledby="headingFive"
                                                data-parent="#accordion">
                                                <div className="accordion-card-body">
                                                    <p>Not everyone can be approved for insurance coverage. If you’re declined for coverage, your declination letter explains why and what to do if you have additional questions.   </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-10 field-group text-center">
                                    <button className="btn btn-primary" onClick={this.props.handleClose}>Close</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                :
                <div class="popup">
                    <div class="popup-header">
                        <button class="close hidden-text" onClick={this.props.handleClose}>
                            <i class="fas fa-times"><span>close button</span></i>
                        </button>
                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="intro">
                                    <h3>Evidence of Insurability - FAQ</h3>
                                </div>
                                <div class="row">
                                    <ul class="col-lg-10 accordion" id="accordion">{ }
                                        <li class="accordion-card">
                                            <div class="accordion-card-header" id="headingOne">
                                                <h6>Why do I need to complete Evidence of Insurability (EOI)?
                                                </h6>
                                                <button class="close hidden-text" type="button" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne" onClick={(e) => this.collapse(e, "one")}>
                                                    <i class={this.state.showUpFirst ? "fas fa-chevron-up" : "fas fa-chevron-down"}><span>Collapse button</span></i>
                                                </button>
                                            </div>
                                            <div id="collapseOne" class={this.state.showUpFirst ? "collapse show" : "collapse"} aria-labelledby="headingOne"
                                                data-parent="#accordion">
                                                <div class="accordion-card-body">
                                                    <p>Most group insurance offers a certain amount of guaranteed coverage. EOI may be required if you want more than that amount, you’re currently enrolled and want more insurance, or you didn’t elect coverage when you were hired. </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="accordion-card">
                                            <div class="accordion-card-header" id="headingTwo">
                                                <h6>Is my information kept secure and confidential?
                                                </h6>
                                                <button class="close hidden-text" type="button" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="true"
                                                    aria-controls="collapseTwo" onClick={(e) => this.collapse(e, "two")}>
                                                    <i class={this.state.showUpSecond ? "fas fa-chevron-down" : "fas fa-chevron-up"}><span>Collapse button</span></i>
                                                </button>
                                            </div>
                                            <div id="collapseTwo" class={this.state.showUpSecond ? "collapse" : "collapse show"} aria-labelledby="headingTwo"
                                                data-parent="#accordion">
                                                <div class="accordion-card-body">
                                                    <p>Yes. We take our responsibility to protect your personal information very seriously. Our website is secure, and your information is confidential and never shared with your employer.</p>
                                                </div>
                                            </div>
                                        </li>

                                        <li class="accordion-card">
                                            <div class="accordion-card-header" id="headingThree">
                                                <h6>What happens after I complete my health questions and hit submit?
                                                </h6>
                                                <button class="close hidden-text" type="button" data-toggle="collapse"
                                                    data-target="#collapseThree" aria-expanded="true"
                                                    aria-controls="collapseThree" onClick={(e) => this.collapse(e, "three")}>
                                                    <i class={this.state.showUpThird ? "fas fa-chevron-down" : "fas fa-chevron-up"}><span>Collapse button</span></i>
                                                </button>
                                            </div>
                                            <div id="collapseThree" class={this.state.showUpThird ? "collapse" : "collapse show"} aria-labelledby="headingThree"
                                                data-parent="#accordion">
                                                <div class="accordion-card-body">
                                                    <p>Once you answer the health questions, we do the rest. We’ll review your answers and email you if we need more information. </p>
                                                </div>
                                            </div>
                                        </li>

                                        <li class="accordion-card">
                                            <div class="accordion-card-header" id="headingFour">
                                                <h6>How will I know if I am approved for coverage?
                                                </h6>
                                                <button class="close hidden-text" type="button" data-toggle="collapse"
                                                    data-target="#collapseFour" aria-expanded="true"
                                                    aria-controls="collapseFour" onClick={(e) => this.collapse(e, "four")}>
                                                    <i class={this.state.showUpFourth ? "fas fa-chevron-down" : "fas fa-chevron-up"}><span>Collapse button</span></i>
                                                </button>
                                            </div>
                                            <div id="collapseFour" class={this.state.showUpFourth ? "collapse" : "collapse show"} aria-labelledby="headingFour"
                                                data-parent="#accordion">
                                                <div class="accordion-card-body">
                                                    <p>
                                                        If you’re approved, we’ll email you within 2 days to let you know when your coverage begins.  </p>
                                                </div>
                                            </div>
                                        </li>

                                        <li class="accordion-card">
                                            <div class="accordion-card-header" id="headingFive">
                                                <h6>What if I’m declined for coverage?
                                                </h6>
                                                <button class="close hidden-text" type="button" data-toggle="collapse"
                                                    data-target="#collapseFive" aria-expanded="true"
                                                    aria-controls="collapseFive" onClick={(e) => this.collapse(e, "five")}>
                                                    <i class={this.state.showupFifth ? "fas fa-chevron-down" : "fas fa-chevron-up"}><span>Collapse button</span></i>
                                                </button>
                                            </div>
                                            <div id="collapseFive" class={this.state.showupFifth ? "collapse" : "collapse show"} aria-labelledby="headingFive"
                                                data-parent="#accordion">
                                                <div class="accordion-card-body">
                                                    <p>Not everyone can be approved for insurance coverage. If you’re declined for coverage, your declination letter explains why and what to do if you have additional questions.   </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-10 field-group text-center">
                                    <button class="btn btn-primary" onClick={this.props.handleClose}>Close</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            }
            </>
        )
    }
}
//export default FAQ;
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(FAQ);




// import React,{ Component } from "react";

// class FAQ extends Component{
//     constructor(props){
//         super(props);
//        this.state={
//            showUpFirst:true,
//            showUpSecond:true,
//            showUpThird:true,
//            showUpFourth:true,
//            showupFifth:true,
//            flag:false
//        }
//     }
// collapse=(e,txt)=>{
    
//     let showUpFlag =false;
// if(txt==="one"){
//     showUpFlag = this.state.showUpFirst
//     if(showUpFlag === true){
//         this.setState({ showUpFirst : false});
//     }
//     else{
//         this.setState({ showUpFirst : true});
//     }
// }else if(txt==="two"){
//     showUpFlag = this.state.showUpSecond;
//     if(showUpFlag === true){
//         this.setState({ showUpSecond : false});
//     }
//     else{
//         this.setState({ showUpSecond : true});
//     }
// }else if(txt==="three"){
//     showUpFlag = this.state.showUpThird;
//     if(showUpFlag === true){
//         this.setState({ showUpThird : false});
//     }
//     else{
//         this.setState({ showUpThird : true});
//     }
// }
// else if(txt==="four"){
//     showUpFlag = this.state.showUpFourth;
//     if(showUpFlag === true){
//         this.setState({ showUpFourth : false});
//     }
//     else{
//         this.setState({ showUpFourth : true});
//     }
// }
// else if(txt==="five"){
//     showUpFlag = this.state.showupFifth;
//     if(showUpFlag === true){
//         this.setState({ showupFifth : false});
//     }
//     else{
//         this.setState({ showupFifth : true});
//     }
// }
// }
// handleClose=()=>{

// }
//     render(){
//         return(
//             <>
//         <div class="popup">
//             <div class="popup-header">
//                 <button class="close hidden-text"onClick={this.props.handleClose}>
//                     <i class="fas fa-times"><span>close button</span></i>
//                 </button>
//             </div>
            
//             <div class="container">
//                 <div class="row">
//                     <div class="col-lg-9">
//                         <div class="intro">
//                             <h3>Evidence of Insurability - FAQ</h3>
//                         </div>
//                         <div class="row">
//                             <ul class="col-lg-10 accordion" id="accordion">{}
//                                 <li class="accordion-card">
//                                     <div class="accordion-card-header" id="headingOne">
//                                         <h6>Why do I need to complete Evidence of Insurability (EOI)?
//                                         </h6>
//                                         <button class="close hidden-text" type="button" data-toggle="collapse"
//                                             data-target="#collapseOne" aria-expanded="true"
//                                             aria-controls="collapseOne" onClick={(e)=>this.collapse(e,"one")}>
//                                             <i class={this.state.showUpFirst?"fas fa-chevron-up":"fas fa-chevron-down"}><span>Collapse button</span></i>
//                                         </button>
//                                     </div>
//                                     <div id="collapseOne" class={this.state.showUpFirst?"collapse show":"collapse"} aria-labelledby="headingOne"
//                                         data-parent="#accordion">
//                                         <div class="accordion-card-body">
//                                             <p>Most group insurance offers a certain amount of guaranteed coverage. EOI may be required if you want more than that amount, you’re currently enrolled and want more insurance, or you didn’t elect coverage when you were hired. </p>
//                                         </div>
//                                     </div>
//                                 </li>
//                                 <li class="accordion-card">
//                                     <div class="accordion-card-header" id="headingTwo">
//                                         <h6>Is my information kept secure and confidential?
//                                         </h6>
//                                         <button class="close hidden-text" type="button" data-toggle="collapse"
//                                             data-target="#collapseTwo" aria-expanded="true"
//                                             aria-controls="collapseTwo" onClick={(e)=>this.collapse(e,"two")}>
//                                             <i class={this.state.showUpSecond?"fas fa-chevron-down":"fas fa-chevron-up"}><span>Collapse button</span></i>
//                                         </button>
//                                     </div>
//                                     <div id="collapseTwo" class={this.state.showUpSecond?"collapse":"collapse show"} aria-labelledby="headingTwo"
//                                         data-parent="#accordion">
//                                         <div class="accordion-card-body">
//                                             <p>Yes. We take our responsibility to protect your personal information very seriously. Our website is secure, and your information is confidential and never shared with your employer.</p>
//                                         </div>
//                                     </div>
//                                 </li>
//                                 <li class="accordion-card">
//                                     <div class="accordion-card-header" id="headingThree">
//                                         <h6>Can I get approved online?
//                                         </h6>
//                                         <button class="close hidden-text" type="button" data-toggle="collapse"
//                                             data-target="#collapseThree" aria-expanded="true"
//                                             aria-controls="collapseThree" onClick={(e)=>this.collapse(e,"three")}>
//                                             <i class={this.state.showUpThird?"fas fa-chevron-down":"fas fa-chevron-up"}><span>Collapse button</span></i>
//                                         </button>
//                                     </div>
//                                     <div id="collapseThree" class={this.state.showUpThird?"collapse":"collapse show"} aria-labelledby="headingThree"
//                                         data-parent="#accordion">
//                                         <div class="accordion-card-body">
//                                             <p>Yes, we can review your information online and make a decision.</p>
//                                         </div>
//                                     </div>
//                                 </li>
//                                 <li class="accordion-card">
//                                     <div class="accordion-card-header" id="headingFour">
//                                         <h6>What if I’m referred to Underwriting?
//                                         </h6>
//                                         <button class="close hidden-text" type="button" data-toggle="collapse"
//                                             data-target="#collapseFour" aria-expanded="true"
//                                             aria-controls="collapseFour" onClick={(e)=>this.collapse(e,"four")}>
//                                             <i class={this.state.showUpFourth?"fas fa-chevron-down":"fas fa-chevron-up"}><span>Collapse button</span></i>
//                                         </button>
//                                     </div>
//                                     <div id="collapseFour" class={this.state.showUpFourth?"collapse":"collapse show"} aria-labelledby="headingFour"
//                                         data-parent="#accordion">
//                                         <div class="accordion-card-body">
//                                             <p>When we refer an application to Underwriting, it means we need a little more information before making a decision. We make most decisions within 2 days after receiving the information. </p>
//                                         </div>
//                                     </div>
//                                 </li>
//                                 <li class="accordion-card">
//                                     <div class="accordion-card-header" id="headingFive">
//                                         <h6>What if I’m declined for coverage?
//                                         </h6>
//                                         <button class="close hidden-text" type="button" data-toggle="collapse"
//                                             data-target="#collapseFive" aria-expanded="true"
//                                             aria-controls="collapseFive" onClick={(e)=>this.collapse(e,"five")}>
//                                             <i class={this.state.showupFifth?"fas fa-chevron-down":"fas fa-chevron-up"}><span>Collapse button</span></i>
//                                         </button>
//                                     </div>
//                                     <div id="collapseFive" class={this.state.showupFifth?"collapse":"collapse show"} aria-labelledby="headingFive"
//                                         data-parent="#accordion">
//                                         <div class="accordion-card-body">
//                                             <p>Not everyone can be approved for insurance coverage. If you’re declined for coverage, your declination letter explains why and what to do if you have additional questions.   </p>
//                                         </div>
//                                     </div>
//                                 </li>
//                             </ul>
//                         </div>
//                         <div class="col-lg-10 field-group text-center">
//                             <button class="btn btn-primary" onClick={this.props.handleClose}>Close</button>
//                         </div>
//                     </div>


//                 </div>
//             </div>
//         </div>

//     </>
//         )
//     }
// }
// export default FAQ
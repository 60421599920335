import React, { Component } from "react";

import _ from "lodash";
import NumberFormat from "react-number-format";
import FocusTrap from "focus-trap-react";

class PhysicianForm extends Component {

    render() {
        return (
            <>
                <FocusTrap>

                    <div className="popup-bg">
                        <div role="dialog" aria-label="Add Physician Dialogue" aria-labelledby="add-physician-dialog-title" className="popup-box big">
                            <div className="popup-header">
                                <button className="close hidden-text" onClick={this.props.handleCancelPopup}>
                                    <i className="fas fa-times"><span>close button</span></i>
                                </button>
                            </div>
                            <div className="container">
                                <div className="row">

                                    <div className="col-md-12">
                                        {this.props.addOrEditStatus === "add" ? <h3 id="add-physician-dialog-title">Add physician</h3> :
                                            <h3>Edit physician information</h3>}
                                        <hr className="d-md-block d-sm-none" />

                                        <div className="col-md-10">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input invalid aria-describedby="name-error" className={`form-control ${_.get(this.props, "errorValidation.nameError", "") ? 'invalid' : ''}`}
                                                    onChange={(e) => this.props.handleDataChange(e, "name")} value={this.props.physician_data.name} maxLength={60} id="name" type="text"
                                                />
                                                <p id="name-error" aria-live="polite">
                                                    {_.get(this.props, "errorValidation.nameError", "") ?
                                                        <span className="error"><i role="img" aria-label="error" class="fas fa-exclamation-circle"></i>{this.props.errorValidation.nameError}</span> : null}
                                                </p>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="specialty">Specialty</label>
                                                <input invalid aria-describedby="speciality-error" className={`form-control ${_.get(this.props, "errorValidation.specialityError", "") ? 'invalid' : ''}`}
                                                    onChange={(e) => this.props.handleDataChange(e, "speciality")}
                                                    placeholder="E.g., Primary Care Physician, Cardiologist" value={this.props.physician_data.speciality} maxLength={60} id="specialty" type="text" />
                                                <p id="speciality-error" aria-live="polite">
                                                    {_.get(this.props, "errorValidation.specialityError", "") ?
                                                        <span className="error"><i role="img" aria-label="error" class="fas fa-exclamation-circle"></i>{this.props.errorValidation.specialityError}</span> : null}
                                                </p>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="address">Address</label>
                                                <input invalid aria-describedby="address-error" className={`form-control ${_.get(this.props, "errorValidation.addressLine1Error", "") ? 'invalid' : ''}`}
                                                    onChange={(e) => this.props.handleDataChange(e, "addressLine1")} value={this.props.physician_data.addressLine1} maxLength={30} id="address" type="text" />
                                                <p id="address-error" aria-live="polite">
                                                    {_.get(this.props, "errorValidation.addressLine1Error", "") ?
                                                        <span className="error"><i role="img" aria-label="error" class="fas fa-exclamation-circle"></i>{this.props.errorValidation.addressLine1Error}</span> : null}
                                                </p>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="optional">Suite, Apt <span>(optional)</span></label>
                                                <input className="form-control" onChange={(e) => this.props.handleDataChange(e, "addressLine2")} value={this.props.physician_data.addressLine2} maxLength={30} id="optional" type="text" />
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-sm-6">
                                                    <label htmlFor="zip">Zip</label>
                                                    <input required invalid aria-describedby="zip-error" className={`form-control ${_.get(this.props, "errorValidation.zipError", "") || this.props.zipErrMsg || _.get(this.props, "errorValidation.zipFormat", "") ? 'invalid' : ''}`}
                                                        maxLength={this.props.zipMaxLength} onChange={(e) => this.props.changeZip(e)} onBlur={(e) => this.props.blurZip(e)} value={this.props.physician_data.zip} id="zip" type="text" />
                                                    <p id="zip-error" aria-live="polite">
                                                        {_.get(this.props, "errorValidation.zipError", "") ?
                                                            <span className="error"><i role="img" aria-label="error" class="fas fa-exclamation-circle"></i>{this.props.errorValidation.zipError}</span> :
                                                            _.get(this.props, "errorValidation.zipFormat", "") ?
                                                                <span className="error"><i role="img" aria-label="error" class="fas fa-exclamation-circle"></i>{this.props.errorValidation.zipFormat}</span> :
                                                                _.get(this.props, "zipErrMsg", "") ?
                                                                    <span className="error"><i role="img" aria-label="error" class="fas fa-exclamation-circle"></i>{this.props.zipErrMsg}</span> : null}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-9 form-group">
                                                    <label htmlFor="eoi-city">City</label>
                                                    <select className={`form-control ${this.props.cityName.length <= 0 ? 'placeholder' : ''}`}
                                                        id="eoi-city" disabled={this.props.cityName.length === 0} value={this.props.physician_data.city} onChange={(e) => this.props.handleDataChange(e, "city")} >
                                                        {this.props.cityName.length === 0 ? <option disabled selected >Select</option> :
                                                            this.props.cityName.length === 1 ? this.props.cityName.map((city, index) => (
                                                                <option value={city} key={index}>{city}</option>
                                                            )) :
                                                                this.props.cityName.map((city, index) => (
                                                                    <option value={city} key={index}>{city}</option>
                                                                ))}
                                                    </select>
                                                </div>
                                                <div className="col-sm-3 form-group">
                                                    <label htmlFor="state">State</label>
                                                    <input className="form-control" id="state" type="text" value={this.props.stateProvCode} disabled />
                                                </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-sm-6">
                                                    <label htmlFor="phone">Phone</label>
                                                    <NumberFormat format="###-###-####"
                                                        className={`form-control ${_.get(this.props, "errorValidation.phoneError", "") || _.get(this.props, "errorValidation.phoneLengthError", "") ? 'invalid' : ''}`}
                                                        onChange={(e) => this.props.handleDataChange(e, "phone")} invalid aria-describedby="phone-error" value={this.props.physician_data.phone} id="phone" type="text" />
                                                    <p id="phone-error" aria-live="polite">
                                                        {_.get(this.props, "errorValidation.phoneError", "") ?
                                                            <span className="error"><i role="img" aria-label="error" class="fas fa-exclamation-circle"></i>{this.props.errorValidation.phoneError}</span> : null}

                                                        {_.get(this.props, "errorValidation.phoneLengthError", "") ?
                                                            <span className="error"><i role="img" aria-label="error" class="fas fa-exclamation-circle"></i>{this.props.errorValidation.phoneLengthError}</span> : null}
                                                    </p>

                                                </div>

                                            </div>
                                            {/* {this.props.addOrEditStatus === "edit" ? 
                                            <div className="checkbox">
                                                <input type="checkbox" checked={this.props.isDeleteChecked} onChange={this.props.handleDeleteCheckBox} id="checks_2" />
                                                <label htmlFor="checks_2">Delete this physician</label>
                                            </div> : null} */}
                                        </div>


                                        <hr />
                                        <div className="field-group mt-5">
                                            <button className="btn btn-outline" onClick={this.props.handleCancelPopup}>Cancel</button>
                                            <button className="btn btn-primary float-right" onClick={this.props.addOrEditSave}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </FocusTrap>

            </>
        );
    }
}

export default PhysicianForm;

import React, { Component } from 'react';
import { imagePath } from '../../utils/sharedFunction';
import { actionCreators } from "../../store/HomePage/HomePageAction";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class SystemDown extends Component {
    render() {
        this.props.workdayCheck(true);
        return (
            <>
                <main>
                    <div className="container" style={{ textAlign: "center" }}>
                        <div className="row">
                            <div className="col-lg-6 col-md-8">
                                <div className="intro">
                                    <h3>We're sorry, an error has occurred and we are working to fix the problem</h3>
                                    <h6 className="pt-2" style={{ color: '#00728D' }}>
                                        Instead, you can try one of the following options:
                                    </h6>
                                    <h6 className="pt-2" style={{ color: '#00728D' }}>
                                        Refresh the page, this may help 
                                    </h6>
                                    <h6 className="pt-2" style={{ color: '#00728D',marginTop:"-11px",marginLeft:"-11px" }}>
                                        Come back and try again later
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src={imagePath("./images/landingpage-illustration.svg")} className="illustration-bg" alt="illustration-bg" />
                </main>
            </>
        )
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch)) (SystemDown);

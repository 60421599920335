import React, { Component } from 'react';
import moment from "moment";

import WhoAreWeCoveringCompressView from '../AddBenefitsNCoverages/WhoAreWeCoveringCompressView';
import WhoAreWeCoveringForm from '../AddBenefitsNCoverages/WhoAreWeCoveringForm';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
import { alphaRegex } from '../../utils/constant';
import RemovePopup from '../AddBenefitsNCoverages/RemovePopup';
import AlertBanner from '../../components/AlertBanner/AlertBanner';
import { scrollUp } from '../../utils/sharedFunction';
import _ from "lodash";
import ReviewBenefitsPopup from '../ReviewBenefitsNCoverages/ReviewBenefitsPopup';
import { parseISO } from 'date-fns';

class ReviewWhoAreWeCovering extends Component {
    state = {
        showBenefitPopup: false,
        calendarError: [],
        blurError: [],
        wasOpened: false,
        employeeCovered: false,
        spouseCovered: false,
        childCovered: false,
        whoAreWeCovering: [],
        firstNameError: [],
        lastNameError: [],
        dobError: [],
        genderError: [],
        openForm: true,
        addOrEditHeader: [],
        addOrEditStatus: [],
        toAddLink: [],
        display: "",
        dropdownError: "",
        allCoveredDetails: [],
        dropdownData: "",
        addLinkError: [],
        bannerFlag: false,
        editIndex: '',
        showPopup: '',
        closeForm:[],
        prevData:[],
        isVisible:[],
        allData:false,
    }
    validateAttr(fields) {
        let openForm = false;
        if (fields.first_name.trim().length <= 0) {
            openForm = true;
        }
        else if (fields.last_name.trim().length <= 0) {
            openForm = true;
        }
        else if (fields.dob === undefined || fields.dob === null) {
            openForm = true;
        }
        else if (fields.dob !== undefined || fields.dob !== null) {
            let currentDate = new Date();
            let givenDate = new Date(fields.dob);
            let yearDiff = currentDate.getFullYear() - givenDate.getFullYear();

            if (Number(currentDate) < Number(givenDate) || yearDiff >= 100) {
                openForm = true;
            }

        }
        else if (!fields.gender || fields.gender === "Select" || fields.gender !== undefined) { openForm = true; }
        else { openForm = false };

        return openForm;
    }
    isNotEmpty(value) {
        if(value != null && typeof value == "object"){
            if(value.toString() === 'Invalid Date'){
                return false;
            }else {
                return true;
            }
        }
       return !!value;
    }
    componentDidMount() {
        this.props.saveBooleanFun(); //Dummy prop change
        // prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employe
        const coverages = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections", {})
        const disabilityCoverages = ["short_term_disability", "long_term_disability", "voluntary_long_term_disability", "voluntary_short_term_disability"]
        let coverageElected = []
        let allEmployeeStatus = []
        let allSpouseStatus = []
        let allChildrenStatus = []
        //let allElectedStatus


        for (const coverage in coverages) {
            let disabilityCoverageElectedStatus = false
            let employeeCoveredStatus = false
            let spouseCoveredStatus = false
            let childCoveredStatus = false
            if (disabilityCoverages.includes(coverage)) {

                disabilityCoverageElectedStatus = coverages[coverage] && coverages[coverage].elected && coverages[coverage].elected
            }
            else {
                employeeCoveredStatus = coverages[coverage] && coverages[coverage].employee_covered && coverages[coverage].employee_covered
                spouseCoveredStatus = coverages[coverage] && coverages[coverage].spouse_covered && coverages[coverage].spouse_covered
                childCoveredStatus = coverages[coverage] && coverages[coverage].child_covered && coverages[coverage].child_covered
            }

            //console.log(employeeCoveredStatus,spouseCoveredStatus,childCoveredStatus,disabilityCoverageElectedStatus)
            if (employeeCoveredStatus === true || spouseCoveredStatus === true || childCoveredStatus === true || disabilityCoverageElectedStatus === true) {
                coverageElected.push(coverage)
            }
            allEmployeeStatus.push(employeeCoveredStatus)
            allEmployeeStatus.push(disabilityCoverageElectedStatus)
            allSpouseStatus.push(spouseCoveredStatus)
            allChildrenStatus.push(childCoveredStatus)
        }

        this.setState({
            displayMember: allEmployeeStatus.includes(true),
            displaySpouse: allSpouseStatus.includes(true),
            displayChild: allChildrenStatus.includes(true)
        });

        if (_.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information", "")) {
            let tempAllCoveredDetails = []

            let dropdownData = _.get(this.props, "whoCovered", undefined)

            let dobToSet = null;
            let spouseDetails = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", {})
            // console.log(spouseDetails, "spouse");
            if (!this.spouseIsEmpty(spouseDetails) && spouseDetails.first_name !== undefined && spouseDetails.first_name !== null) {
                //console.log("in sposue");
                // console.log(spouseDetails.dob, "dob spouse")
                if (spouseDetails.dob === undefined || spouseDetails.dob === null) {
                    spouseDetails.dob = null;
                } else {
                    dobToSet = moment(spouseDetails.dob).format('YYYY-MM-DD');
                    dobToSet = parseISO(dobToSet);
                    //dobToSet = parseISO(spouseDetails.dob);
                    spouseDetails.dob = dobToSet;
                }               
            }

            let childArray = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", [])

            if (childArray) {
                childArray.map(childData => {
                    if (childData.dob === undefined || spouseDetails.dob === null) {
                        childData.dob = dobToSet;
                    } else {
                        dobToSet = moment(childData.dob).format('YYYY-MM-DD');
                        dobToSet = parseISO(dobToSet);
                       // dobToSet = parseISO(childData.dob);
                        childData.dob = dobToSet;
                    }
                    return childData;
                })
            }
            // let spouseFlag = this.spouseIsEmpty(spouseDetails)
            this.setState({ dropdownData: dropdownData })
            // console.log(this.state.displaySpouse, this.state.displayChild, "llll")
            //Mani Changes
            //if (spouseFlag === false && spouseDetails.first_name !== undefined && spouseDetails.first_name !== null && allSpouseStatus.includes(true)) {
                if (allSpouseStatus.includes(true)) {
                //console.log("1")
                let spouseDet = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", {})
                spouseDet.relationShip = "Spouse"
                
                tempAllCoveredDetails = [...tempAllCoveredDetails, spouseDet]
                //console.log(tempAllCoveredDetails)
                // this.setState({
                //     allCoveredDetails:[...this.state.allCoveredDetails,_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",{})]
                // })
            }
            // console.log(this.state.displayChild,"child",childArray)
            //Mani Changes
            //if (childArray !== null && (_.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []).length > 0) && (allChildrenStatus.includes(true))) {
            if ((allChildrenStatus.includes(true))) {
                childArray && childArray.map(child => {
                    child.relationShip = "Child"
                    tempAllCoveredDetails = [...tempAllCoveredDetails, child]
                    // this.setState({
                    //     allCoveredDetails:[...this.state.allCoveredDetails,child]
                    // })
                    return child
                })

            }
            // console.log("tempAllCoveredDetails",tempAllCoveredDetails)
            if (tempAllCoveredDetails.length > 0) {
                let i=0; 
                let genderError = this.state.genderError;
                let lastNameError = this.state.lastNameError;
                let dobError = this.state.dobError;
                let firstNameError = this.state.firstNameError;
                let blurError = this.state.blurError;
                let calendarError = this.state.calendarError;
                for(i=0;i<tempAllCoveredDetails.length;i++){

                        genderError[i] = false;
                        lastNameError[i]= false;
                        dobError[i] =false;
                        firstNameError[i] = false;
                        blurError[i] = false;
                        calendarError[i] = false;

                    this.setState({ firstNameError,lastNameError,dobError,calendarError,genderError,blurError});        
                }
               let prevData = _.cloneDeep(tempAllCoveredDetails); 
               let isVisible  = prevData.map( (data) =>{
                   if((this.isNotEmpty(data.first_name) && this.isNotEmpty(data.last_name) && this.isNotEmpty(data.dob) && this.isNotEmpty(data.gender))){
                       return true
                   } 
                   return false;
                })
                let allData = isVisible.indexOf(false) > -1;
                this.setState({
                    allCoveredDetails: tempAllCoveredDetails, prevData,isVisible ,allData                            
                })
            }
            if (dropdownData === "Me, my spouse, and my child(ren)" || dropdownData === "My child"
                || dropdownData === "Me and my child" || dropdownData === "My spouse and child") {
                this.setState({ toAddLink: ["Add child"] })
            }
        }

        scrollUp()
    }

    spouseIsEmpty = (spouseDetails) => {
        for (var i in spouseDetails) return false;
        return true;
    }

    handleShow = (e) => {
        e.preventDefault()
        document.body.style.overflow = 'hidden';
        this.setState({
            showPopup: true
        })
        scrollUp()
    }

    handleDateChange = (index,date) => {
        if (this.state.wasOpened) {
            let whoAreWeCovering =  [...this.state.whoAreWeCovering]
            // console.log(moment(date).format('MM/DD/YYYY'))
            whoAreWeCovering[index]["dob"] = moment(date).format('MM/DD/YYYY')
            const blurError = [...this.state.blurError];
            blurError[index] = false;
            this.setState({
                whoAreWeCovering,
                blurError,
            })
        }
        if (this.state.wasOpened) {
            let dobError = this.state.dobError;
            dobError[index] = false;
            this.setState({
                dobError
            })
        }

        let calendarError = this.state.calendarError;
        if (JSON.stringify(date) === null || JSON.stringify(date) === "null") {
            calendarError[index] = true;
            this.setState({
                calendarError,
                //whoAreWeCovering
            })
        }
        else {
            calendarError[index] = false;
            this.setState({
                calendarError
            })
        }
    };

    handleDateBlur = (index,e) => {
        let currentDate = new Date();
        let givenDate = new Date(e.target.value);
        let yearDiff = currentDate.getFullYear() - givenDate.getFullYear()
        let calendarError = this.state.calendarError;
        if (Number(currentDate) < Number(givenDate) || yearDiff >= 100) {
            let blurError = this.state.blurError;
            blurError[index] = false;
            calendarError[index] = true;
            this.setState({
                blurError,
                calendarError,
            })
        }

        if (calendarError[index] === false) {
            let whoAreWeCovering = [...this.state.whoAreWeCovering]
            whoAreWeCovering[index]["dob"] = e.target.value
            this.setState({
                whoAreWeCovering,
            })
        }
        let blurError = this.state.blurError;
        if (calendarError[index] === true && e.target.value.length !== 0) {
            blurError[index] = true;
            this.setState({
                blurError
            })
        
        }
        else {
            blurError[index] = false;
            this.setState({
                blurError,
            })
        }

        if (calendarError[index] === false) {
            let dobError = this.state.dobError;
           
            if (e.target.value && dobError[index] === true) {
                dobError[index] = false;
                this.setState({ dobError })
            }
        }


    };

    handleOnOpen = () => {
        this.setState({
            wasOpened: true
        })
    };

    handleOnClose = () => {
        this.setState({
            wasOpened: false
        })
    };

    handleNameChange = (index,e, type) => {
        let whoAreWeCovering = [...this.state.whoAreWeCovering];      
        if (type === "middle_initial" || type === "gender") {
            if (e.target.value === '' || alphaRegex.test(e.target.value)) {
                whoAreWeCovering[index][type] = e.target.value
                this.setState({
                    whoAreWeCovering
                })
            }
        }
        else {
            whoAreWeCovering[index][type] = e.target.value
            this.setState({
                whoAreWeCovering
            })
        }

    }
    handleFieldBlur = (index,e,type) => {
        e.preventDefault();
        const { first_name, last_name, dob, gender } = this.state.whoAreWeCovering[index]
        let firstNameError = this.state.firstNameError;
        let dobError = this.state.dobError;
        let genderError = this.state.genderError;
        let lastNameError = this.state.lastNameError;
        //Mani changes
        if (type === "first_name") { if (first_name !== undefined && first_name!==null && first_name.trim().length > 0){
            firstNameError[index] = false;
        } this.setState({ firstNameError }) }
        //Mani changes
        if (type === "last_name") { if (last_name !== undefined && last_name!==null && last_name.trim().length > 0) {
            lastNameError[index] = false; }
            this.setState({ lastNameError }) }
        if (type === "dob") { if (dob){
            dobError[index] = false;
            this.setState({ dobError }) 
        }  }
        if (type === "gender") { if (gender){
            genderError[index] = false;
        } this.setState({ genderError }) }


    }
    validationEntity(first_name, last_name, dob, gender,index) {
        let firstNameError = this.state.firstNameError;
        let genderError = this.state.genderError;
        let blurError = this.state.blurError;
        let dobError = this.state.blurError;
        let lastNameError = this.state.lastNameError;
        //spouseDetails.first_name !== undefined && spouseDetails.first_name !== null
        //Mani changes
        if (first_name===undefined || first_name === null || first_name.trim().length <= 0) {
            firstNameError[index] = true;
        }
            this.setState({ firstNameError })
        if (last_name===undefined || last_name === null || last_name.trim().length <= 0) { //Mani changes
            lastNameError[index] = true;
        } this.setState({ lastNameError})
        let currentDate = new Date();
        let givenDate = new Date(dob);
        let yearDiff = currentDate.getFullYear() - givenDate.getFullYear();
        let calendarError = this.state.calendarError;
        if (Number(currentDate) < Number(givenDate) || yearDiff >= 100) {
                blurError[index] = true;
                calendarError[index] = true;
            this.setState({
                blurError,
                calendarError,
            })
        }
        if (!dob || calendarError[index] === true) {
            dobError[index] = true;
            this.setState({ dobError})
        }
        if ( !gender || gender === "Select" || gender === undefined){
            genderError[index] = true;
        } this.setState({ genderError})

    }
    addOrEditSave = (i,e) => {
        e.preventDefault();
        const { addOrEditStatus, whoAreWeCovering } = this.state
        const { first_name, last_name, dob, gender} = whoAreWeCovering[i]; //pankaj changes
        // const { first_name, last_name, dob, gender} =  JSON.parse(JSON.stringify(whoAreWeCovering[i]));
       
        // console.log(whoAreWeCovering, "covering", i,gender )
        // console.log("whoAreWeCovering", JSON.stringify(whoAreWeCovering)) //pankaj changes
        this.setState({ InvalidDateErrorOnNext: true })
        this.validationEntity(first_name, last_name, dob, gender,i);
        let calendarError = this.state.calendarError;
        // if(relationShip === "Spouse"){}
        //Mani changes to allow first name as null
        if (first_name!==undefined && first_name !== null && first_name.trim().length > 0 && last_name!==undefined && last_name !== null && last_name.trim().length > 0 && dob && calendarError[i] === false && gender && gender !== "Select") {
            const closeForm = this.state.closeForm;
            closeForm[i] = true;
            // console.log(closeForm,"form")
            this.setState({
                closeForm
                // openForm: false,
            })
            
            if (addOrEditStatus === "edit" || addOrEditStatus === "add") {
                // let tempAllCoveredDetails = allCoveredDetails;
                // tempAllCoveredDetails.splice(i, 1, whoAreWeCovering[index]);
                // console.log(this.state.whoAreWeCovering,"aa")
                this.setState({
                    allCoveredDetails:  whoAreWeCovering, //pankaj changes
                    // addOrEditStatus: "",
                    editIndex: ''
                })

            }
            const firstNameError = this.state.firstNameError;
            const blurError = this.state.blurError;
            const dobError = this.state.dobError;
            const genderError = this.state.genderError;
            const calendarError = this.state.calendarError
            const lastNameError = this.state.lastNameError;
            firstNameError[i] = false;
            blurError[i] = false;
            dobError[i]= false;
            genderError[i] = false;
            calendarError[i] = false;
            lastNameError[i] = false;
            this.setState({
                firstNameError,
                lastNameError,
                dobError,
                genderError,
                blurError,
                calendarError
            })
        }

    }

    // addSpouseOrChild = (selectedPerson) => {

    //     if (this.state.openForm === false) {
    //         if (selectedPerson === "Add spouse") {
    //             let whoAreWeCovering = [...this.state.whoAreWeCovering]
    //             whoAreWeCovering["relationShip"] = "Spouse"
    //             let tempAddLink = [...this.state.toAddLink]
    //             tempAddLink = tempAddLink.filter(eachData => eachData !== selectedPerson)

    //             this.setState({
    //                 whoAreWeCovering,
    //                 toAddLink: tempAddLink
    //             })
    //         }
    //         if (selectedPerson === "Add child") {
    //             let whoAreWeCovering = [...this.state.whoAreWeCovering]
    //             whoAreWeCovering["relationShip"] = "Child"
    //             this.setState({
    //                 whoAreWeCovering
    //             })
    //         }
    //         this.setState({
    //             addOrEditHeader: selectedPerson,
    //             addOrEditStatus: "add"
    //         })
    //     }
    //     this.setState({
    //         openForm: true,
    //         bannerFlag: false,
    //         addLinkError: []
    //     })

    // }
    handleCancel = () => {

        if (this.state.addOrEditStatus === "edit") {
            //this.setState({whoAreWeCovering : whoAreWeCovering})
        }
        this.setState({
            openForm: false,
            firstNameError: false,
            lastNameError: false,
            dobError: false,
            genderError: false,
            blurError: false,
            calendarError: false,
            showPopup: false,

        })
        if (this.state.addOrEditStatus === "add" && this.state.displaySpouse === "true" && this.state.dropdownData === "Me and my spouse") {
            this.setState({
                toAddLink: ["Add spouse"]
            })
        }
        if (this.state.addOrEditStatus === "add" && this.state.displaySpouse === "true" && this.state.dropdownData === "Me, my spouse, and my child(ren)") {
            this.setState({
                toAddLink: ["Add spouse", ...this.state.toAddLink]
            })
        }

        if (this.state.addOrEditStatus === "add" && this.state.displaySpouse === "true" && this.state.dropdownData === "My spouse") {
            this.setState({
                toAddLink: ["Add spouse"]
            })
        }
        if (this.state.addOrEditStatus === "add" && this.state.displaySpouse === "true" && this.state.dropdownData === "My spouse and child") {
            this.setState({
                toAddLink: ["Add spouse", ...this.state.toAddLink]
            })
        }
        this.setState({
            addOrEditStatus: ""
        })
        document.body.style.overflow = 'auto';
    }

    handleEdit = (index) => {
        // e.preventDefault();
        const { allCoveredDetails } = this.state
        let editDetail = allCoveredDetails;
        let addOrEditHeader = this.state.addOrEditHeader;
        if (editDetail[index].relationShip === "Spouse") {
            addOrEditHeader[index] = "Edit";
        }
        if (editDetail[index].relationShip === "Child") {
            addOrEditHeader[index] = "Edit";
        }
        let addOrEditStatus = this.state.addOrEditStatus;
        addOrEditStatus[index] = "edit";
        const closeForm = this.state.closeForm;
        closeForm[index] = false;
        // console.log("editing Object", editDetail,closeForm);
        this.setState({
            addOrEditStatus,
            editIndex: index,
            closeForm,
            addOrEditHeader,
            whoAreWeCovering: editDetail
        })
    }

    handleRemove = (e) => {
        e.preventDefault()
        const { addOrEditStatus, allCoveredDetails } = this.state

        if (addOrEditStatus === "edit") {
            let tempAllCoveredDetails = allCoveredDetails;
            tempAllCoveredDetails.splice(this.state.editIndex, 1);
            this.setState({
                allCoveredDetails: tempAllCoveredDetails,
                addOrEditStatus: "",
                editIndex: '',
                showPopup: false,
                openForm: false,
            })

        }
        this.setState({
            whoAreWeCovering: {
                first_name: '',
                middle_initial: '',
                last_name: '',
                dob: null,
                gender: undefined
            },
            firstNameError: false,
            lastNameError: false,
            dobError: false,
            genderError: false,
            blurError: false,
            calendarError: false
        })

        if (this.state.addOrEditStatus === "edit" && this.state.displaySpouse === "true" && this.state.dropdownData === "Me and my spouse") {
            this.setState({
                toAddLink: ["Add spouse"]
            })
        }
        if (this.state.addOrEditStatus === "edit" && this.state.displaySpouse === "true" && this.state.dropdownData === "Me, my spouse, and my child(ren)") {
            this.setState({
                toAddLink: ["Add spouse", ...this.state.toAddLink]
            })
        }

        if (this.state.addOrEditStatus === "edit" && this.state.displaySpouse === "true" && this.state.dropdownData === "My spouse") {
            this.setState({
                toAddLink: ["Add spouse"]
            })
        }
        if (this.state.addOrEditStatus === "edit" && this.state.displaySpouse === "true" && this.state.dropdownData === "My spouse and child") {
            this.setState({
                toAddLink: ["Add spouse", ...this.state.toAddLink]
            })
        }
        this.setState({
            addOrEditStatus: ""
        })
        document.body.style.overflow = 'auto';
    }

    onPrevious = () => {
        this.props.history.push(`/eoi/contact`)
    }

    handleDropDown = (e) => {
        this.setState({
            dropdownData: e.target.value,
            addLinkError: [],
            openForm: false,
            editIndex: '',
            whoAreWeCovering: {
                first_name: '',
                middle_initial: '',
                last_name: '',
                dob: null,
                gender: undefined
            },
            firstNameError: false,
            lastNameError: false,
            dobError: false,
            genderError: false,
            blurError: false,
            calendarError: false
        })

        if (e.target.value !== "") {
            this.setState({ dropdownError: "" })
        }

    }
    handleNext = () => {
        // console.log(this.state.displayMember)
        let covered = "";
        let childArray = []

        if (this.state.displayMember === true) {
            covered = "Me"
        } if (this.state.displaySpouse === true) {
            covered = "My spouse"
        } if (this.state.displayChild === true) {
            covered = "My child"
        } if (this.state.displayMember === true && this.state.displaySpouse === true && this.state.displayChild === false) {
            covered = "Me,my spouse"
        } if (this.state.displayMember === true && this.state.displaySpouse === true && this.state.displayChild === true) {
            covered = "Me, my spouse, my child(ren)"
        } if (this.state.displayMember === true && this.state.displayChild === true && this.state.displaySpouse === false) {
            covered = "Me, my child(ren)"
        } if (this.state.displaySpouse === true && this.state.displayChild === true && this.state.displayMember === false) {
            covered = "My spouse, my child(ren)"
        }
        //console.log(covered, "handleNext")
        //console.log(this.state.allCoveredDetails,this.state.dropdownData)
        if (covered === "Me, my spouse, my child(ren)" || covered === "My spouse, my child(ren)") {
            this.state.allCoveredDetails.map(data => {
                if (data.relationShip === "Spouse") {
                    // console.log(_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse"))
                    // console.log(compareNestedObject(_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",{}),data))
                    _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", data)
                    _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.dob", moment(data.dob).format('YYYY-MM-DD'))
                }
                if (data.relationShip === "Child") {
                    childArray.push(data)
                }
                return data
            })
        }
        else if (covered === "Me,my spouse" || covered === "My spouse") {
            // ||
            //  ((this.state.displayMember === true && this.state.displaySpouse === true) || this.state.displaySpouse === true)){
            this.state.allCoveredDetails.map(data => {
                //if(data.relationShip === "Spouse"){
                // console.log(_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse"))
                // console.log(compareNestedObject(_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse",{}),data))
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", data)
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.dob", moment(data.dob).format('YYYY-MM-DD'))
                childArray = []
                return data
            })

        }
        else if (covered === "Me, my child(ren)" || covered === "My child") {
            //  || ((this.state.displayMember === true && this.sate.displayChild === true) || this.state.displayChild === true)){            
            this.state.allCoveredDetails.map(data => {
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", {})
                childArray.push(data)
                return data
            })

        }
        else if (covered === "Me") {
            //console.log("4444444444444")
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", {})
            childArray = []
        }


        childArray.length > 0 && childArray.map((childData, index) => {
            childData.sequence = index + 1
            childData.dob = moment(childData.dob).format('YYYY-MM-DD');
            return childData;
        })
        //console.log("child array:",childArray);
        if (childArray) {
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", childArray);
        }
        else {
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
        }
        this.props.submitMib(_.get(this.props, "prefillData", {}), '/eoi/homepage');
    }

    handleClose = () => {
        this.setState({
            bannerFlag: false
        });
    }
    onBenefitPopup = () => {
        this.setState({ showBenefitPopup: !this.state.showBenefitPopup });
    }

    onClosepopup = () => {
        //console.log("this.state", this.state);
        this.setState({ showBenefitPopup: !this.state.showBenefitPopup });
    }
    onBlurRequired = (e, errorField) => {
        let value = e.target.value.trim();
        if (value && value.length > 0) {
            this.setState({ [errorField]: "" });
        }
    }

    render() {
        let entityData = {};
        let covered = "";
        if (this.state.displayMember === true) {
            covered = "Me"
        }
        if (this.state.displaySpouse === true) {
            covered = "My spouse"
        } if (this.state.displayChild === true) {
            covered = "My child"
        } if (this.state.displayMember === true && this.state.displaySpouse === true && this.state.displayChild === false) {
            covered = "Me,my spouse"
        } if (this.state.displayMember === true && this.state.displaySpouse === true && this.state.displayChild === true) {
            covered = "Me, my spouse, my child(ren)"
        } if (this.state.displayMember === true && this.state.displayChild === true && this.state.displaySpouse === false) {
            covered = "Me, my child(ren)"
        } if (this.state.displaySpouse === true && this.state.displayChild === true && this.state.displayMember === false) {
            covered = "My spouse, my child(ren)"
        }
        let headingLabel = "Spouse";
        let contentlabel = "This includes marital spouse, domestic partner and/or civil union.";
        return (
            <>
                {this.state.showBenefitPopup ?
                    <ReviewBenefitsPopup headingLabel={headingLabel} contentlabel={contentlabel} closepopup={this.onClosepopup} />
                    : <>
                        {this.state.bannerFlag === true ?
                            <AlertBanner
                                handleClose={() => this.handleClose()}
                                errorMessage="Please fill out all required fields." />
                            : null}
                        {this.state.showPopup === true ? <RemovePopup
                            handleClosePopup={this.handleCancel}
                            handleRemove={this.handleRemove}
                            removeFamilyName={this.state.whoAreWeCovering.first_name}
                        ></RemovePopup> : null}
                        <main>
                            <div className="container-fluid">
                                <div className="prev-link">
                                    <button className="btn btn-link" onClick={(e) => this.onPrevious(e)}><i className="fa fa-arrow-left"></i>Back</button>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-5 col-md-7">
                                        <div className="intro">
                                            <h3>Who are we covering?</h3>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="eoi-coverage">I want coverage for</label>
                                            <select className="form-control" id="eoi-coverage" disabled={covered}
                                                onBlur={(e) => this.onBlurRequired(e, { covered })}
                                                value={covered}>
                                                <option value="Select" >{covered}</option>
                                            </select>
                                            {this.state.dropdownError.length > 1 ?
                                                <span className="error"><i className="fas fa-exclamation-circle"></i>{this.state.dropdownError}</span>
                                                :
                                                null
                                            }
                                        </div>

                                        {covered && covered !== "Me" &&
                                            <p className="pb-1">Tell us about your family.
                                                {covered && (covered === "Me and my spouse"
                                                    || covered === "Me, my spouse, and my child(ren)" ||
                                                    covered === "My spouse and child" ||
                                                    covered === "My spouse") &&
                                                    <div className="tips-info">
                                                        <div className="itext" onClick={() => this.onBenefitPopup()}>
                                                            <i className="fas fa-info-circle"></i>
                                                        </div>
                                                    </div>}
                                            </p>}
                                        <div className="row">
                                            {this.state.allCoveredDetails.length > 0 && this.state.allCoveredDetails.map((eachDetail, index) => {
                                             const prevData = this.state.prevData; // twinkle changes dob
                                              if(!this.state.whoAreWeCovering[index]){
                                                  const formValues = this.state.whoAreWeCovering;
                                                  formValues[index] = eachDetail;
                                                //   this.setState({whoAreWeCovering:formValues})
                                                let addOrEditHeader = this.state.addOrEditHeader;
                                                addOrEditHeader[index] = addOrEditHeader[index] === "Edit" ? "Edit":"Add";
                                                let addOrEditStatus = this.state.addOrEditStatus;
                                                addOrEditStatus[index] = "add";
                                                  this.setState({whoAreWeCovering:formValues,
                                                    addOrEditHeader,addOrEditStatus })
                                              }

                                              entityData = { ...this.state.whoAreWeCovering[index]};
                                                // entityData = {...this.state.whoAreWeCovering };
                                                // if(!this.state.whoAreWeCovering[index]){
                                                //     entityData["first_name"] = "";
                                                // }
                                                return (
                                                    ((this.state.addOrEditStatus[index] === "edit" || this.state.addOrEditStatus[index] === "add") && !this.state.closeForm[index] ?
                                                        <WhoAreWeCoveringForm
                                                            handleNameChange={this.handleNameChange.bind(null,index)}
                                                            handleShow={this.handleShow}
                                                            handleFieldBlur={this.handleFieldBlur.bind(null,index)}
                                                            handleDateChange={this.handleDateChange.bind(null,index)}
                                                            handleDateBlur={this.handleDateBlur.bind(null,index)}
                                                            handleOnOpen={this.handleOnOpen}
                                                            handleOnClose={this.handleOnClose}
                                                            showPopup={this.state.showPopup}
                                                            whoAreWeCovering={entityData}
                                                            addOrEditSave={this.addOrEditSave.bind(null,index)}
                                                            addOrEditHeader={this.state.addOrEditHeader}
                                                            handleCancel={this.handleCancel}
                                                            addOrEditStatus={this.state.addOrEditStatus}
                                                            firstNameError={this.state.firstNameError}
                                                            lastNameError={this.state.lastNameError}
                                                            dobError={this.state.dobError}
                                                            genderError={this.state.genderError}
                                                            blurError={this.state.blurError}
                                                            data={this.props}
                                                            index={index}
                                                            prevData ={prevData[index]}
                                                            isNotEmpty = {this.isNotEmpty}
                                                        ></WhoAreWeCoveringForm> : <WhoAreWeCoveringCompressView key={index}
                                                            whoAreWeCovering={entityData}
                                                            handleEdit={this.handleEdit}
                                                            index={index}
                                                        ></WhoAreWeCoveringCompressView>)
                                                )
                                            })}
                                         
                                        </div>

                                        {this.state.toAddLink !== "Me" && this.state.toAddLink && this.state.toAddLink.length > 0 && this.state.toAddLink.map((link, index) => (
                                            <> <button className="btn btn-link blue-link" onClick={() => this.addSpouseOrChild(link)}>+ {link}</button><br />
                                                {this.state.addLinkError.length > 0 ?
                                                    <><span className="addlinkerror">{this.state.addLinkError[index] && <i className="fas fa-exclamation-circle"></i>}{this.state.addLinkError[index]}</span> <br /></> : null
                                                }

                                            </>

                                        ))}
                                        <hr />
                                        <div className="field-group float-right">
                                        {this.state.allData === false ?
                                        <button className="btn btn-primary" onClick={() => this.handleNext()}>
                                        Next</button>:
                                        <button className="btn btn-primary" disabled={( this.state.closeForm.length !== this.state.allCoveredDetails.length) || (this.state.closeForm.indexOf(false) > -1) } onClick={() => this.handleNext()}>
                                        Next</button>}         
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {covered && (covered === "Me and my spouse"
                                || covered === "Me, my spouse, and my child(ren)" ||
                                covered === "My spouse and child" ||
                                covered === "My spouse") &&
                                <div className="tips-container" style={{ top: "120px" }}>
                                    <div className="tip-box">
                                        <div className="heading">Spouse</div>
                                        This includes marital spouse, domestic partner and/or civil union.
                                    </div>
                                </div>}
                        </main>
                    </>}
            </>
        );
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(ReviewWhoAreWeCovering);


import React, { Component } from 'react';
import AlertBanner from '../../../components/AlertBanner/AlertBanner';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { scrollUp, moveToPreviousQuestionFromCondition, imagePath } from '../../../utils/sharedFunction';
import { actionCreators } from '../../../store/HomePage/HomePageAction';
import _ from 'lodash';
import Warning from '../../../components/Warning/Warning';
import SaveForLater from '../../../components/SaveForLater/SaveForLater';

class MedicationforHQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerFlag: false,
      allmedications: [{}],
      medication: '',
      subHeader: '',
      textForSubHeader: '',
      labelFortextBox: '',
      addButtonText: '',
      conditionData: {},
      pageInd: '',
      errMsg:'',
      isSaved:false,
      isOpened:false
    }
  }

  componentDidMount() {
    let conditiondata = _.get(this.props, "location.state.condition")
    let disc_type = conditiondata.life_entities_dq.disclosure_types;
    let medicationList = [];
      
    disc_type && disc_type.map(data => {
      medicationList.push(data.answer_disclosure_info_value)
      return data;
    })
    if (conditiondata.condition_name === "Medication other than as prescribed") {
      this.setState({
        conditionData: conditiondata,
        subHeader: 'Medication other than as prescribed',
        textForSubHeader: 'Tell us about the medications you use other than as prescribed.',
        labelFortextBox: 'Medication(s)',
        addButtonText: '+ Add another medication',
        pageInd: 'medication',
        allmedications: medicationList,
        errMsg:"Please enter at least one medication."
      })
    }
    if (conditiondata.condition_name === "Consultation in hospital or health care facility") {
      this.setState({
        conditionData: conditiondata,
        subHeader: 'Consultation in hospital or health care facility',
        textForSubHeader: 'Tell us about the condition(s) you sought medical attention for.',
        labelFortextBox: 'Condition(s)',
        addButtonText: '+ Add another condition',
        pageInd: 'consultation',
        allmedications: medicationList,
        errMsg:"Please enter at least one condition."
      })
    }
    if (conditiondata.condition_name === "Prescribed medications") {
      this.setState({
        conditionData: conditiondata,
        subHeader: 'Prescribed medications',
        textForSubHeader: 'Tell us about your prescribed medications.',
        labelFortextBox: 'Medication(s)',
        addButtonText: '+ Add another medication',
        pageInd: 'presbMedication',
        allmedications: medicationList,
        errMsg:"Please enter at least one medication."
      })
    }
    window.addEventListener("popstate", () => {
      this.props.history.goForward();
      this.props.bClick(true);
    }) 
  }

  onMedicationChange = (e, index) => {
    let tempAllMedications = this.state.allmedications;
    tempAllMedications.splice(index, 1, e.target.value);
    this.setState({
      tempAllMedications: tempAllMedications,
      bannerFlag: false
    })

  }

  addMedication = (e) => {
    let medicationsList = this.state.allmedications;
    medicationsList.push(e.target.value);
    this.setState({
      medication: e.target.value
    })
  }

  removeMedication = (e, index) => {
    //console.log(index);
    let medicationList = this.state.allmedications;
    medicationList.splice(index, 1);
    this.setState({ allmedications: medicationList })
  }
  

  ismedicationEmpty = (spouseDetails) => {
    for (var i in spouseDetails.trim()) return false;
    return true;
  }

  handleBrowser= () =>{
    this.props.bClick(false);
   }
  handleNext = () => {
    let medications = this.state.allmedications;
    //console.log(this.ismedicationEmpty(medications[0]))
    if (this.ismedicationEmpty(medications[0]) === true) {
      this.setState({
        bannerFlag: true
      });
      scrollUp()
    }
    else {
      // let disclosure_types = [];
      //let medication_data = {};
      // medications.map((data, index) => {
      //   medication_data.disclosure_id = (index + 1).toString();
      //   medication_data.answer_disclosure_info_value = data;
      //   disclosure_types.push(medication_data);
      //   medication_data = {};
      //   return data
      // })
      //console.log("disclosure_types: ", disclosure_types);
      const activeQuestion = _.get(this.props, "activeQuestion", "")
      const baseQuestionDet = _.get(this.props, "prefillData.eoi_data.health_question_info.base_question", [])
      const baseQuestion = JSON.parse(JSON.stringify(baseQuestionDet))
      baseQuestion.map(question => {
        if (question.question_id.toString() === activeQuestion.toString()) {
          question.health_condition.map(condition => {
            if (condition.condition_id === this.state.conditionData.condition_id) {
              condition.condition_page_status = "ACTIVE"
              condition.life_entities_dq.map(life => {
                if (life.life_id === this.state.conditionData.life_entities_dq.life_id) {
                  medications && medications.map((data, index) => {
                    // medication_data.disclosure_id = (index + 1).toString();
                    // medication_data.answer_disclosure_info_value = data;
                    // disclosure_types.push(medication_data);
                    // medication_data = {};
                    life.disclosure_types && life.disclosure_types.map(disclosure => {
                      disclosure.disclosure_info_required=true
                      // disclosure.answer_disclosure_info=true
                      disclosure.answer_disclosure_info_value = data
                      return disclosure
                    })
                    return data
                  })
                  //console.log(life.disclosure_types,life.disclosure_types.answer_disclosure_info_value)
                  // life.disclosure_types = disclosure_types
                }
                return life
              })
            }
            else{
                condition.condition_page_status = "INACTIVE"
            }
            return condition
          })
        }
        else{
            question.health_condition && question.health_condition.map(condition => {
                condition.condition_page_status = "INACTIVE"
                  return condition
            })
        }
        return question
      })
      //console.log("base question", baseQuestion);
      _.set(this.props, "prefillData.eoi_data.health_question_info.base_question", baseQuestion)
      this.props.rCount(1)
      //moveToReflexiveQuestion(this.props)
      this.props.saveConditionQuestion(_.get(this.props,"prefillData",{}),this.props)
    }



  }

  handleClose = () => {
    this.setState({
      bannerFlag: false
    });

  }

  onPrevious = () => {
    moveToPreviousQuestionFromCondition(this.props)
  }
  saveForLater = () => {
    this.setState({isSaved:true});
  }
  continueEOI = () => {
    this.setState({isSaved:false});
  }
  leave = ()  => {
    this.props.history.push({pathname: `/eoi/success` })
  }
  
  displayFullName = () => {

    if (this.state.conditionData && this.state.conditionData.life_entities_dq) {

      let name = this.state.conditionData.life_entities_dq.life_name.split(', ')
      let first_name = ""
      let last_name = ""
      let middle_initial = ""
      if (_.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.source", "") === "GWDY") {
          first_name = name.slice(-1);
          last_name = name.length === 3 ? name.slice(0, 2) : name.slice(0,1)
      } else {
          first_name = name[1]
          last_name = name[0]
          middle_initial = name[2] ? name[2] : null
      }
      return (first_name + " " + (middle_initial ? (middle_initial + " ") : "") + last_name)

    }
  }

  getTextForHeader = () => {
    if (this.state.pageInd === "medication") {
      return (<p className="col-lg-6">
        You indicated use of the following
        <strong> in the past 5 years.</strong>
      </p>)
    }
    else if (this.state.pageInd === "consultation") {
      return (<p className="col-lg-6">
        You indicated that <strong> in the past 12 months</strong> you were received a consultation for a condition that was not mentioned in the previous questions.
      </p>)
    }
    else if (this.state.pageInd === "presbMedication") {
      return (<p className="col-lg-6">
        You indicated that <strong> in the past 12 months</strong> you were prescribed medication not mentioned in the previous questions.
      </p>)
    }
  }

  render() {

    //console.log(this.state.allmedications);
    // console.log("click",this.props.backClick)
    return (
      <>
        {this.state.isSaved === true ? <SaveForLater continueEOI={()=>this.continueEOI()} leave = {()=> this.leave()}/> : null}
        {this.props.backClick === true ? <Warning handleBrowser = {() => this.handleBrowser()}/> : null}
        {this.state.bannerFlag === true ?
          <AlertBanner
            handleClose={() => this.handleClose()}
            errorMessage={this.state.errMsg} />
          : null}
        <main className="knockout">
          <div className="row question-bg q-mobile-bg mb-3">
            <div className="container-fluid">
              <div className="prev-link">
                <button className="btn btn-link" onClick={this.onPrevious}>
                  <i className="fa fa-arrow-left"></i>Health question {_.get(this.props, "activeQuestion", "")}
                </button>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <img src={imagePath("./images/Employer-Gender-Neutral.svg")} alt="illness icon" className="employer-neutral-icon float-left" />
                  <h3 className='header-icon-padding'>{this.displayFullName()}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {this.getTextForHeader()}
            </div>
            <div className="row">
              <div className="col-lg-5 mt-4">
                <h6>{this.state.subHeader}</h6>
                <p className="pt-3 pb-3">{this.state.textForSubHeader}</p>
                {this.state.allmedications.map((item, index) => {
                  return (

                    <div className="form-group">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="last-name">{this.state.labelFortextBox}</label>
                        {/* {index !== 0 ? <button className="btn btn-link blue-link" onClick={(e) => this.removeMedication(e, index)}>Remove</button> : null} */}
                      </div>
                      <input className="form-control" id="last-name" maxLength={100} onChange={(e) => this.onMedicationChange(e, index)} value={item.length > 0 ? item : ""} type="text" />
                    </div>
                  )
                })} 
                {/* {this.state.allmedications.map((item, index) => {
                  return (

                    <div className="form-group">
                      <div className="d-flex justify-content-between">
                        <label htmlFor="last-name">{this.state.labelFortextBox}</label>
                        {index !== 0 ? <button className="btn btn-link blue-link" onClick={(e) => this.removeMedication(e, index)}>Remove</button> : null}
                      </div>
                      <input className="form-control" id="last-name" maxLength={100} onChange={(e) => this.onMedicationChange(e, index)} value={item.length > 0 ? item : ""} type="text" />
                    </div>
                  )
                })} */}
                {/* <button className="btn btn-link blue-link mt-4" onClick={this.addMedication}>{this.state.addButtonText}</button> */}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <hr />
                <div className="field-group table-header-group">
                  {_.get(this.props,"trustedFlag","") === true?
                    <button className="btn btn-link" onClick={()=>this.saveForLater()}>
                        Save for later
                    </button>
                    :
                    null
                  }
                  <button className="btn btn-primary float-right" onClick={this.handleNext}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>

    );
  }

}
//export default MedicationforHQ;
export default connect(
  (state) => state.eoiStore,
  (dispatch) => bindActionCreators(actionCreators, dispatch))(MedicationforHQ);


















// import React, { Component } from 'react';
// import AlertBanner from '../../../components/AlertBanner/AlertBanner';
// import { scrollUp, moveToPreviousQuestionFromCondition, moveToReflexiveQuestion } from '../../../utils/sharedFunction';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { actionCreators } from '../../../store/HomePage/HomePageAction';
// import _ from 'lodash';

// class MedicationforHQ extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       bannerFlag: false,
//       allmedications: [{}],
//       medication: '',
//       subHeader: '',
//       textForSubHeader: '',
//       labelFortextBox: '',
//       addButtonText: '',
//       conditionData: {},
//       pageInd: '',
//       errMsg:''

//     }
//   }

//   componentDidMount() {
//     //console.log("in did mount");
//     let conditiondata = _.get(this.props, "location.state.condition")
//     //console.log(conditiondata);
//     let disc_type = conditiondata.life_entities_dq.disclosure_types;
//     let medicationList = [];
//     disc_type && disc_type.map(data => {
//       medicationList.push(data.answer_disclosure_info_value)
//       return data;
//     })
//     if (conditiondata.condition_name === "Medication other than as prescribed") {
//       this.setState({
//         conditionData: conditiondata,
//         subHeader: 'Medication other than as prescribed',
//         textForSubHeader: 'Tell us about the medications you use other than as prescribed.',
//         labelFortextBox: 'Medication',
//         addButtonText: '+ Add another medication',
//         pageInd: 'medication',
//         allmedications: medicationList,
//         errMsg:"Please enter at least one medication."
//       })
//     }
//     if (conditiondata.condition_name === "Consultation in hospital or health care facility") {
//       this.setState({
//         conditionData: conditiondata,
//         subHeader: 'Consultation in hospital or health care facility',
//         textForSubHeader: 'Tell us about the condition(s) you sought medical attention for.',
//         labelFortextBox: 'Condition',
//         addButtonText: '+ Add another condition',
//         pageInd: 'consultation',
//         allmedications: medicationList,
//         errMsg:"Please enter at least one condition."
//       })
//     }
//     if (conditiondata.condition_name === "Prescribed medications") {
//       this.setState({
//         conditionData: conditiondata,
//         subHeader: 'Prescribed medications',
//         textForSubHeader: 'Tell us about your prescribed medications.',
//         labelFortextBox: 'Medication',
//         addButtonText: '+ Add another medication',
//         pageInd: 'presbMedication',
//         allmedications: medicationList,
//         errMsg:"Please enter at least one medication."
//       })
//     }

//   }

//   onMedicationChange = (e, index) => {
//     //console.log(index);
//     let tempAllMedications = this.state.allmedications;
//     tempAllMedications.splice(index, 1, e.target.value);
//     this.setState({
//       tempAllMedications: tempAllMedications,
//       bannerFlag: false
//     })

//   }

//   addMedication = (e) => {
//     let medicationsList = this.state.allmedications;
//     medicationsList.push(e.target.value);
//     this.setState({
//       medication: e.target.value
//     })
//   }

//   removeMedication = (e, index) => {
//     //console.log(index);
//     let medicationList = this.state.allmedications;
//     medicationList.splice(index, 1);
//     this.setState({ allmedications: medicationList })
//   }
  

//   ismedicationEmpty = (spouseDetails) => {
//     for (var i in spouseDetails) return false;
//     return true;
//   }

//   handleNext = () => {
//     let medications = this.state.allmedications;
//     //console.log(this.ismedicationEmpty(medications[0]))
//     if (this.ismedicationEmpty(medications[0]) === true) {
//       this.setState({
//         bannerFlag: true
//       });
//       scrollUp()
//     }
//     else {
//       let disclosure_types = [];
//       let medication_data = {};
//       medications.map((data, index) => {
//         medication_data.disclosure_id = (index + 1).toString();
//         medication_data.answer_disclosure_info_value = data;
//         disclosure_types.push(medication_data);
//         medication_data = {};
//         return data
//       })
//       //console.log("disclosure_types: ", disclosure_types);
//       const activeQuestion = _.get(this.props, "activeQuestion", "")
//       const baseQuestionDet = _.get(this.props, "prefillData.eoi_data.health_question_info.base_question", [])
//       const baseQuestion = JSON.parse(JSON.stringify(baseQuestionDet))
//       baseQuestion.map(question => {
//         if (question.question_id.toString() === activeQuestion.toString()) {
//           question.health_condition.map(condition => {
//             if (condition.condition_id === this.state.conditionData.condition_id) {
//               condition.life_entities_dq.map(life => {
//                 if (life.life_id === this.state.conditionData.life_entities_dq.life_id) {
//                   life.disclosure_types = disclosure_types
//                 }
//                 return life
//               })
//             }
//             return condition
//           })
//         }
//         return question
//       })
//       //console.log("base question", baseQuestion);
//       _.set(this.props, "prefillData.eoi_data.health_question_info.base_question", baseQuestion)

//       moveToReflexiveQuestion(this.props)
//       //this.props.saveConditionQuestion(_.get(this.props,"prefillData",{}),this.props)
//     }



//   }

//   handleClose = () => {
//     this.setState({
//       bannerFlag: false
//     });

//   }

//   onPrevious = () => {
//     moveToPreviousQuestionFromCondition(this.props)

//   }

//   displayFullName = () => {

//     if (this.state.conditionData && this.state.conditionData.life_entities_dq) {

//       let name = this.state.conditionData.life_entities_dq.life_name.split(', ')
//       //console.log(name)
//       let first_name = name[1]
//       let last_name = name[0]
//       let middle_initial = name[2] ? name[2] : null
//       return (first_name + " " + (middle_initial ? (middle_initial + " ") : "") + last_name)

//     }
//   }

//   getTextForHeader = () => {
//     if (this.state.pageInd === "medication") {
//       return (<p className="col-lg-6">
//         You indicated use of the following
//         <strong>in the past 5 years.</strong>
//       </p>)
//     }
//     else if (this.state.pageInd === "consultation") {
//       return (<p className="col-lg-6">
//         You indicated that <strong>in the past 12 months</strong> you received a consultation for a condition that was not mentioned in the previous questions.
//       </p>)
//     }
//     else if (this.state.pageInd === "presbMedication") {
//       return (<p className="col-lg-6">
//         You indicated that <strong>in the past 12 months</strong> you prescribed medication not mentioned in the previous questions.
//       </p>)
//     }
//   }

//   render() {

//     //console.log(this.state.allmedications);
//     return (
//       <>
//         {this.state.bannerFlag === true ?
//           <AlertBanner
//             handleClose={() => this.handleClose()}
//             errorMessage={this.state.errMsg} />
//           : null}
//         <main className="knockout">
//           <div className="row question-bg q-mobile-bg mb-3">
//             <div className="container-fluid">
//               <div className="prev-link">
//                 <button className="btn btn-link" onClick={this.onPrevious}>
//                   <i className="fa fa-arrow-left"></i>Health question {_.get(this.props, "activeQuestion", "")}
//                 </button>
//               </div>
//             </div>

//             <div className="container">
//               <div className="row">
//                 <div className="col-12 col-lg-6">
//                   <img src={imagePath("./images/Employer-Gender-Neutral.svg")} alt="illness icon" className="employer-neutral-icon float-left" />
//                   <h3 className='header-icon-padding'>{this.displayFullName()}</h3>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="container">
//             <div className="row">
//               {this.getTextForHeader()}
//             </div>
//             <div className="row">
//               <div className="col-lg-5 mt-4">
//                 <h6>{this.state.subHeader}</h6>
//                 <p className="pt-3 pb-3">{this.state.textForSubHeader}</p>
//                 {this.state.allmedications.map((item, index) => {
//                   return (

//                     <div className="form-group">
//                       <div className="d-flex justify-content-between">
//                         <label htmlFor="last-name">{this.state.labelFortextBox}</label>
//                         {index !== 0 ? <button className="btn btn-link blue-link" onClick={(e) => this.removeMedication(e, index)}>Remove</button> : null}
//                       </div>
//                       <input className="form-control" id="last-name" maxLength={100} onChange={(e) => this.onMedicationChange(e, index)} value={item.length > 0 ? item : ""} type="text" />
//                     </div>
//                   )
//                 })}
//                 <button className="btn btn-link blue-link mt-4" onClick={this.addMedication}>{this.state.addButtonText}</button>
//               </div>
//             </div>

//             <div className="row">
//               <div className="col-lg-6">
//                 <hr />
//                 <div className="field-group">
//                   <button className="btn btn-link" onClick="">
//                     Save for later
//                   </button>
//                   <button className="btn btn-primary float-right" onClick={this.handleNext}>
//                     Next
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </main>
//       </>

//     );
//   }

// }
// //export default MedicationforHQ;
// export default connect(
//   (state) => state.eoiStore,
//   (dispatch) => bindActionCreators(actionCreators, dispatch))(MedicationforHQ);

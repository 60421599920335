import React, { Component } from 'react';
import FAQ from '../../container/LandingPage/FAQ';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
import _ from "lodash";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClose: false,
            isFlag: false,
            
        }
    }
    handleOpen = (e) => {
        document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
        this.setState({ isFlag: true })
    }
    handleClose = () => {
        document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
        this.setState({ isFlag: false })
    }
    
    render() {
        // console.log(this.props,"props")
        return (
            <>
                {this.state.isFlag ?
                    <FAQ handleClose={() => this.handleClose()} /> : null}
                <footer>
                    <div className="row">
                        <div className="col-md-7 disclaimer-group">
                            <div className="disclaimer-item link-word-break footer-btn">
                                <button className="btn btn-link" onClick={(e) => this.handleOpen(e)}>
                                    FAQs
                                </button>
                            </div>
                            {((_.get(this.props,"prefillData.eoi_data.workday_group_ind","") === "Y" || _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.source","") === "GWDY") || (_.get(this.props,"groupInfoResponse.eoi_data.workday_group_ind","") === "Y") || (_.get(this.props,"workday","") === true) ) ? "":
                            <div className="disclaimer-item">
                                <strong>Questions?</strong>
                                <div className="mt-2">
                                    <i className="fas fa-phone-alt pr-1" alt="call icon"></i>
                                    <a href="tel:18006274200">1-800-627-4200</a>
                                </div>
                            </div>
                            }
                        </div>
                        {this.props.showMediaIcons === "APPROVED" || this.props.showMediaIcons === "DECLINED" || this.props.showMediaIcons === "REFERRED"?
                        <div className="col-md-5 social-container mb-4">
                            <a href="https://www.facebook.com/guardianlife/" title="Follow us on Facebook" 
                            target="_blank" rel="noopener noreferrer" className="socialmedia-wrapper" aria-label="Follow us on Facebook">
                                <i className="fab fa-facebook-f"></i>
                                <span className="screen-reader extra-screen-reader-text">Follow on Facebook</span>
                            </a>
                            <a href="https://twitter.com/intent/follow?source=followbutton&amp;variant=1.0&amp;screen_name=guardianlife"
                            title="Follow us on Twitter" target="_blank" rel="noopener noreferrer" className="socialmedia-wrapper" aria-label="Follow us on Twitter">
                                <i className="fab fa-twitter"></i>
                                <span className="screen-reader extra-screen-reader-text">Follow on Twitter</span>
                            </a>
                            <a href="https://www.linkedin.com/company/the-guardian-life-insurance-company-of-america_164085?trk=company_logo" 
                            title="Follow us on LinkedIn" target="_blank" rel="noopener noreferrer" className="socialmedia-wrapper" aria-label="Follow us on LinkedIn">
                                <i className="fab fa-linkedin-in"></i>
                                <span className="screen-reader extra-screen-reader-text">Follow on LinkedIn</span>
                            </a>
                            <a href="https://www.youtube.com/c/guardianlife" title="Follow us on YouTube" target="_blank"
                            rel="noopener noreferrer" className="socialmedia-wrapper" aria-label="Follow us on YouTube">
                                <i className="fab fa-youtube"></i>
                                <span className="screen-reader extra-screen-reader-text">Follow on YouTube</span>
                            </a>
                            <a href="https://instagram.com/guardianlife" title="Follow us on Instagram" target="_blank" 
                            rel="noopener noreferrer" className="socialmedia-wrapper" aria-label="Follow us on Instagram">
                                <i className="fab fa-instagram"></i>
                                <span className="screen-reader extra-screen-reader-text">Follow on Instagram</span>
                            </a>
                        </div>
                         :
                        null} 
                    </div>      
                    <div className="word-break">
                        <div className="legal">
                            © 2021 The Guardian Life Insurance Company of America, New York, NY. All rights reserved.
                        </div>
                    </div>
                </footer>

            </>
        )
    }
}
//export default Footer
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(Footer);


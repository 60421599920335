import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
import { imagePath } from '../../utils/sharedFunction';
// import _ from 'lodash';
class PreviouslyDeclined extends Component {
    downloadPDF = (e) => {
        e.preventDefault();
        this.props.downLoadPDFData(this.props.prefillData, true);
    }
    downloadApproveDecline = (e) => {
        e.preventDefault();
        
        this.props.downLoadApproveDecline(this.props.prefillData);
    }

componentDidMount(){
    window.onpopstate=function(event){
        window.history.go(1);
    };
}

    render() {
        return (
            <>

                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-8">
                                <div className="intro" >
                                    <h3 className="col-lg-12 col-md-10" style={{ marginBottom: "3.5rem", marginLeft: "-21px" }}>We have your prior application on file</h3>
                                    <h5 className="intro mt-2 mb-0">
                                        What’s next?
                                    </h5>
                                    <p className="lead mt-3">
                                        You indicated that your previous application for coverage was declined.
                                    </p>
                                    <p className="lead mt-3">
                                        Our decision to decline your application was based on your medical history and was not influenced by the amount of coverage you requested.
                                    </p>
                                    <p className="lead mt-3">
                                        At this time, we cannot accept a new application for the coverages you were previously declined for. You are still entitled to your Right to Additional Information as outlined in our decline letter.
                                    </p>
                                </div>

                                <div className="data-box">
                                    <h6>Stay connected anywhere you go</h6>
                                    <p className="lead">Use <a href="https://www.guardiananytime.com" target="_blank" rel="noopener noreferrer">Guardian Anytime</a> to file and check claims, view your benefits, and access your ID card.</p>
                                </div>
                                <h6 className="pt-3">Thank you for choosing Guardian!</h6>

                                {/* <button class="btn btn-secondary mt-4" onclick="">Return to Plansource</button> */}
                            </div>
                        </div>
                    </div>
                    <img src={imagePath("./images/landingpage-illustration.svg")} className="illustration-bg" alt="illustration-bg" />
                </main>
            </>
        )
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(PreviouslyDeclined);
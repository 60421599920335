import React from 'react';
import moment from "moment";

function WhoAreWeCoveringCompressView({index,handleEdit,whoAreWeCovering:{first_name,middle_initial,last_name,dob,gender,relationShip}}) {
    return (
        <div className="card">
            <div className="card-header">
                <h6>{first_name + " "+ (middle_initial ? middle_initial :"")} </h6>
                <button className="btn btn-outline small" onClick={() => handleEdit(index) }>Edit</button>
            </div>
            <div className="card-body">
                <p>{first_name + " "+(middle_initial? (middle_initial+" "):"") + last_name}</p>
                <div className="row">
                    {dob !== null && dob !== undefined ? 
                     <div className="col-sm-6">
                     <p>
                         <strong>Date of birth</strong>
                         <br />{moment(dob).format('YYYY-MM-DD')}
                     </p>
                 </div>                 
                      : null}
                    <div className="col-sm-6">
                        <p>
                            <strong>Relationship</strong>
                            <br />{relationShip}
                        </p>
                    </div>
                    <div className="col-sm-6">
                        {gender === "F"?
                        <p>
                            <strong>Gender</strong>
                            <br />Female
                        </p>
                        :
                        gender === "M"?
                        <p>
                            <strong>Gender</strong>
                            <br />Male
                        </p>
                        :
                        null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhoAreWeCoveringCompressView;
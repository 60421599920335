import React, { Component } from "react";

import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AlertBanner from "../../../components/AlertBanner/AlertBanner";
import { actionCreators } from "../../../store/HomePage/HomePageAction";
import {
  getActiveQuestionData,
  getQuestionDescriptionWithBold,
  moveToPreviousQuestion,
  scrollUp,
} from "../../../utils/sharedFunction";
import Warning from "../../../components/Warning/Warning";
import SaveForLater from "../../../components/SaveForLater/SaveForLater";

class NoConditionFamilyQ extends Component {
  state={
        questionData:{},
        displayBanner:false,
        isSaved:false,
        isOpened:false
    }
    

    componentDidMount(){
        
        let questionDataTemp = getActiveQuestionData(this.props)
        let questionData = JSON.parse(JSON.stringify(questionDataTemp))
        //console.log(questionData)

        //questionData.question_page_status = "ACTIVE"
        questionData.health_condition.map(condition => {
          condition.life_entities_dq.map(life => {
          if(life.answer_condition_info === true || life.answer_condition_info === false ){
          life.answer_condition_info = String(life.answer_condition_info)

          }
          return life

          })
          return condition
        })

        this.setState({
            questionData:questionData
        })
        scrollUp()
        window.addEventListener("popstate", () => {
          this.props.history.goForward();
          this.props.bClick(true);
        })
    }
    componentDidUpdate(prevProps){
        if(prevProps.match.params !== this.props.match.params){
            this.setState({
                questionData:{},
                checkedCondition:'',
                displayBanner:false
            })
            let questionDataTemp = getActiveQuestionData(this.props)
            let questionData = JSON.parse(JSON.stringify(questionDataTemp))
            //console.log(questionData)

            //questionData.question_page_status = "ACTIVE"
              //use this.state.questionData in render
            questionData.health_condition.map(condition => {
              condition.life_entities_dq.map(life => {
              if(life.answer_condition_info === true || life.answer_condition_info === false ){
              life.answer_condition_info = String(life.answer_condition_info)

              }
              return life

              })
              return condition
            })
                  //console.log(questionData)
            this.setState({
                questionData:questionData
            })
            scrollUp()
            
        }
        
    }
    handleBrowser= () =>{
      this.props.bClick(false);
     }
    handleNext = () => {
      let statusArray = [];
        this.state.questionData.health_condition.map(condition => {
          condition.life_entities_dq.map(life => {
            statusArray.push(life.answer_condition_info)
            return life
          })
          return condition
        })
        //console.log(statusArray)
        
        if(statusArray.includes(undefined) === true){
          this.setState({
              displayBanner:true
            })
        }
        else{
          let finalQData = {...this.state.questionData}
          finalQData.health_condition.map(condition => {
            condition.life_entities_dq.map(life => {
              life.answer_condition_info = this.changeToBoolean(life.answer_condition_info)
              return life
            })
            return condition
          })
          let singleDisclosure ;
          let baseQuestionDet = _.get(this.props,"prefillData.eoi_data.health_question_info.base_question",[]) 
          let question = _.get(this.props,"location.pathname","").split('/')[3]
          baseQuestionDet.map(eachQuestion => {
                if(eachQuestion.question_id === question){
                    Object.assign(eachQuestion,this.state.questionData)
                    Object.assign(eachQuestion.question_page_status = "ACTIVE")
                    eachQuestion.health_condition && eachQuestion.health_condition.map(condition=>{
                      condition.life_entities_dq && condition.life_entities_dq.map(life => {
                        if(life.disclosure_types && life.disclosure_types.length ===1){
                            singleDisclosure = true
                          //let activeCondition = _.get(this.props,"activeCondition")
                          //_.set(this.props,"activeCondition",1)
                          condition.condition_page_status = "ACTIVE"
                          life.disclosure_types.map(disclosure => {
                            disclosure.answer_disclosure_info = true
                            disclosure.disclosure_info_required = true
                            return disclosure
                          })
                        }
                        else{
                          singleDisclosure = false
                        }
                        return life
                      })
                      return condition
                    })
                }
                else{
                    Object.assign(eachQuestion.question_page_status = "INACTIVE")
                }
                return eachQuestion
          })
          //console.log(statusArray,statusArray.includes("true")===true)
          _.set(this.props,"prefillData.eoi_data.health_question_info.base_question",baseQuestionDet) 

          // if(statusArray.every(data => data === "false") === true){

          // if(statusArray.includes("true")===true){
          //   chooseNextCondition(this.props)
          // }
          // else{
          //   //this.props.history.push(`/eoi/letsMoveOn`)
          //   chooseNextQuestion(this.props)
            

          // }
          if(singleDisclosure){
          this.props.saveAnswerBaseQuestionYesNo(_.get(this.props,"prefillData",{}),statusArray.includes("true"),this.props)
          }
          else{
            this.props.saveAnswerBaseQuestion(_.get(this.props,"prefillData",{}),statusArray.includes("true"),this.props)
          }
          //this.props.saveAnswerBaseQuestionYesNo(_.get(this.props,"prefillData",{}),statusArray.includes("true")===true,this.props)

          

          // if(_.get(this.props,"trustedFlag","")===true){
          //     if(_.get(this.props, "prefillData.eoi_data.health_question_info.base_question_status","")==="ACTIVE"){
          //         _.set(this.props, "prefillData.eoi_data.health_question_info.base_question_status","COMPLETED")
          //         _.set(this.props, "prefillData.eoi_data.health_question_info.active_question_status","")
          //         _.set(this.props, "prefillData.eoi_data.health_question_info_status","COMPLETED")
          //         _.set(this.props, "prefillData.eoi_data.confirm_submit_status","START")
          //         //this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
          //     }
              
          //     this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""),'/eoi/homePage')
          // }
          // else{
          //     _.set(this.props, "nonTrustedData.health_question_info_status","COMPLETED")
          //     _.set(this.props, "nonTrustedData.confirm_submit_status", "START");
          //     this.props.history.push(`/eoi/homePage`)
          // }
        }
        
    }
    changeToBoolean = (stringValue) => {
      if(stringValue === "true"){
        return true
      }
      else{
        return false
      }
    }
    handlePrevious = () => {
        moveToPreviousQuestion(this.props)
    }
    getQuestionDescription = (questionDesc,questionId) => {

        let splitArrayResult = getQuestionDescriptionWithBold(questionDesc)
        if(splitArrayResult && splitArrayResult.length ===2 && splitArrayResult[0].length>0 && splitArrayResult[1].length>0){
            return (<p className="pt-3"><strong>{splitArrayResult[0]}</strong>{splitArrayResult[1]}</p> )
        }
        else{
            return   <p className="pt-3">{questionDesc}</p>
        }
                      
    }

    handleClose() {
      this.setState({ displayBanner: false })
    }
    saveForLater = () => {
      this.setState({isSaved:true});
    }
    continueEOI = () => {
      this.setState({isSaved:false});
    }
    leave=()=> {
      this.props.history.push({pathname: `/eoi/success` })
    }
    
    handleRadioSelection = (e) => {
      let statusArray = []
      let questionData = {...this.state.questionData}

      questionData.health_condition.map(condition => {
        condition.life_entities_dq.map(life => {
            if(life.life_id === e.target.name){
              life.answer_condition_info = e.target.value
            }
            return life
          })
          return condition
      })
      this.setState({
        questionData
      })
      this.state.questionData.health_condition.map(condition => {
        condition.life_entities_dq.map(life => {
          statusArray.push(life.answer_condition_info)
          return life
        })
        return condition
      })
      if(statusArray.includes(undefined) === false){
        this.setState({
          displayBanner:false
        })
      }
    }
    
    render() {
      const {questionData} = this.state
      const check =  _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.source", "") === "GWDY"
      //console.log(this.props,"pppppppppppppppppppppppp")
        return (
          <>
          {this.state.isSaved === true ? <SaveForLater continueEOI={()=>this.continueEOI()} leave = {()=> this.leave()}/> : null}
          {this.props.backClick === true ? <Warning handleBrowser = {() => this.handleBrowser()}/> : null}
          {this.state.displayBanner === true ?
              <AlertBanner handleClose={()=>this.handleClose()} errorMessage="Please select an answer for each member of your family."/>
              :
              null
          }
          <main class="knockout">
            <div class="row question-bg gray-bg">
              <div class="container-fluid">
                <div class="prev-link">
                  <button className="btn btn-link" onClick={()=>this.handlePrevious()}>
                      <i className="fa fa-arrow-left"></i>
                      {_.get(this.props,"activeQuestion","") === 1 ? "Back":"Previous question"}
                  </button>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-lg-7">
                    <h3 class='q-heading'>Question {questionData.question_id}/{_.get(this.props,"totalQuestion","")}</h3>
                    {questionData && questionData.question_desc &&
                        this.getQuestionDescription(questionData.question_desc,questionData.question_id)
                    }
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                {questionData && questionData.health_condition && questionData.health_condition.map(condition => (
                 condition.life_entities_dq && condition.life_entities_dq.map((life,index) => (
                  <div class="question-box-border" key={index}>
                    <fieldset class="form-group">
                    
                      <legend class="data-label">{life.life_desc.toLowerCase() === "employee"?"Me":check === true ? life.life_name.split(", ").slice(-1) :life.life_name.split(", ")[1]}</legend>
                      <div class="radio">
                        <input type="radio" id={"yes"+life.life_id} 
                        value={true} name={life.life_id} 
                        onChange={(e)=>this.handleRadioSelection(e)}
                        checked={life.answer_condition_info === "true"}
                         />
                        <label for={"yes"+life.life_id}>
                          <span>Yes</span>
                        </label>
                      </div>
                      <div class="radio">
                        <input type="radio" id={"no"+life.life_id} value={false} name={life.life_id}
                        onChange={(e)=>this.handleRadioSelection(e)}
                        checked={life.answer_condition_info === "false"}
                        />
                        <label for={"no"+life.life_id}>
                          <span>No</span>
                        </label>
                      </div>
                    </fieldset>
                  </div>
                 ))
                ))}
                  
                  <hr />
                  <div class="field-group">
                    {_.get(this.props,"trustedFlag","") === true?
                        <button class="btn btn-link" onClick={()=>this.saveForLater()}>
                            Save for later
                        </button>
                        :
                        null
                    }
                    <button class="btn btn-primary float-right" onClick={()=>this.handleNext()}>Next</button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
        );
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(NoConditionFamilyQ);

import React, { Component } from 'react';
//import {statesUS} from '../../utils/constant';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
import _ from 'lodash';
import {statesUS} from '../../utils/constant';
import { scrollUp } from '../../utils/sharedFunction';


class PrintedEOINonTrusted extends Component {
    state = {
        selectedState : ""
    }

    componentDidMount(){
         let state;
        let previousURL = _.get(this.props,"location.state.previousPage")
        // if(this.props.trustedFlag === false){
        //     if(previousURL === 'landingPage' ){
        //         state = _.get(this.props,"groupInfoResponse.eoi_data.group_info.group_situs_state","")
        //         //state = this.props.groupInfoResponse && this.props.groupInfoResponse.eoi_data &&this.props.groupInfoResponse.eoi_data.group_info.group_situs_state
        //     }
        //     else if(previousURL === 'mib'){
        //         state = _.get(this.props,"prefillData.eoi_data.group_info.group_situs_state")
        //     }
        // }
        // else if(this.props.trustedFlag === true){
        //     state = _.get(this.props,"prefillData.eoi_data.group_info.group_situs_state")
        // }
        if(this.props.trustedFlag === true){
             state = _.get(this.props,"prefillData.eoi_data.group_info.group_situs_state")
             }
        else{
            if(previousURL === 'landingPage' ){
                state = _.get(this.props,"groupInfoResponse.eoi_data.group_info.group_situs_state","")
                //state = this.props.groupInfoResponse && this.props.groupInfoResponse.eoi_data &&this.props.groupInfoResponse.eoi_data.group_info.group_situs_state
            }
            else if(previousURL === 'mib'){
                state = _.get(this.props,"prefillData.eoi_data.group_info.group_situs_state")
            }
            else{
                state = _.get(this.props,"nonTrustedData.eoi_data.group_info.group_situs_state","") 
            }
        }
        
        this.setState({
            selectedState:statesUS[state]
        })
        scrollUp();
    }

    // handleStateSelection = (e) => {
        //console.log(e.target.value)
    //     this.setState({
    //         stateSelected : e.target.value
    //     })
    // }

    onPrevious = () => {
        let previousURL = _.get(this.props,"location.state.previousPage")
        // console.log("previousURL",previousURL)
        // console.log(this.props.location)
        if (previousURL === 'mib')
        {
            this.props.history.push(`/eoi/mib`)
        }
        else if (previousURL === 'landingPage')
        {
        this.props.history.push(`/eoi/landingPage`)
        }
        else if(previousURL === 'findYourGroup'){
            this.props.history.push('/eoi/findyourgroup')
        }
       else if (previousURL === "consent"){
            //  console.log('comming');
            //  window.location.reload(true); 
             this.props.history.push(`/eoi/consent`) 
       }
        
    }

    downLoadPdf =(e)=>{
        //console.log("download pdf", this.props.prefillData.eoi_data.health_authorization.form_optout_link)
        //let fileURL="https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf";
        let fileURL = _.get(this.props,"groupInfoResponse.eoi_data.health_authorization.form_optout_link",null)
        // console.log(fileURL,"url")
        fetch(fileURL, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/pdf',
        },
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
            new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `EoiPrintedForm.pdf`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
   // link.parentNode.removeChild(link);
  });
}
    
    render() {
        // console.log("NonTrustedLandingPage" , this.props);
        return (
            <>
                
                <main>
                <div className="container-fluid">
                    <div className="prev-link" >
                        <button className="btn btn-link" onClick={() => this.onPrevious()}><i class="fa fa-arrow-left"></i>Back</button>
                    </div> 
                </div>
                    <div className="container printEOI-page">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="intro">
                                    <h3>Download your Evidence of Insurability form</h3>
                                    {/* <div>
                                        <p className="lead">To get started, tell us what state you work in.</p>
                                        <div className="form-group">
                                            <label htmlFor="eoi-state">What state do you work in?</label>
                                            <select className="form-control" id="eoi-state" onChange = {(e) =>  this.handleStateSelection(e)}>
                                                <option selected disabled>Select state</option>
                                                {statesUS.map((state,index) => (
                                                    <option value={state} key={index}>{state}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div> */}
                                    {/* {this.state.stateSelected && */}
                                        
                                            <p className="lead pt-4">Here’s the form you need to complete for the state of &nbsp;{this.state.selectedState}.</p>
                                            <button className="btn btn-primary" onClick={(e)=>this.downLoadPdf(e)}><i className="fa fa-download pr-2"></i> Download EOI form </button>
                                        
                                    {/* } */}
                                </div>
                                {/* {this.state.stateSelected && */}
                                    
                                        <div className="data-box" style={{fontWeight:'bold'}}> 
                                            <h5>Next steps</h5>
                                            <p className="lead">Complete the EOI form.  Print a paper copy of your completed form and provide handwritten signatures and dates.</p>
                                            <p className="lead">
                                            Send a digitally scanned or photographed copy of your completed form to our secure email address* or a paper copy via US mail.  Your submission must include all pages and be clear and complete.  We will notify you with the final underwriting decision.                                                  </p>
                                            <div className="mt-4" >
                                                <a href="mailto:appletonbilling@glic.com" >appletonbilling@glic.com</a>
                                            </div>
                                            <h6 className="text-black mt-4 mb-4">PO Box 14319 <br></br>
                                            Lexington, KY  40512</h6>
                                           
                                            <p className="lead">
                                                *Guardian accepts encrypted email (TLS) which protects information while in transit to us. Your email will be sent to us encrypted from email providers that support "opportunistic TLS email encryption" including the following providers: Gmail, Hotmail, iCloud, Mail.com, Outlook and Yahoo! Mail. If you are unsure whether your email provider uses this form of encryption, we would encourage you to mail your application through the US mail to the address above.  </p>
                                                
                                        </div>
                                        <h5 className="pt-5">Thank you for choosing Guardian!</h5>
                                    
                                {/* } */}
                            </div>
                        </div>
                    </div>

                </main>
            </>
        );
    }
}

//export default PrintedEOINonTrusted;
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(PrintedEOINonTrusted);

import React ,  { Component }from 'react';

class RemovePopup extends Component {

    render() {
        return (
    <>
        <div class="popup-bg">
            <div class="popup-box small">
                <div className="popup-header">
                    <button className="close hidden-text" onClick={this.props.handleClosePopup}>
                        <i className="fas fa-times"><span>close button</span></i>
                    </button>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>Are you sure you want to remove {this.props.removeFamilyName}?</h3>
                        </div>
                    </div>
                    <div className="field-group mt-5">
                        <div className="item-1"><button className="btn btn-outline" onClick={this.props.handleClosePopup}>Cancel</button></div>
                        <div className="item-2"><button className="btn btn-primary" onClick={(e) => this.props.handleRemove(e)}>Yes, remove</button></div>
                    </div>
                </div>
            </div>
        </div>
    </>
);
}
}
export default RemovePopup;

// import React, { Component } from 'react';
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actionCreators } from "../store/HomePage/HomePageAction";

let activeLifeCondition = 0

export function moveToHomePage(props) {
    // let previousPage = _.get(props, "location.state.previousPage")
    if (_.get(props, "trustedFlag", "") === true) {
        _.set(props, "prefillData.eoi_data.health_question_info_status", "COMPLETED")
        _.set(props, "prefillData.eoi_data.confirm_submit_status", "START")

        props.updateprefillData(_.get(props, "prefillData", ""), _.get(props, "prefillId", ""), '/eoi/homePage')
    }
    else {
        _.set(props, "nonTrustedPageStatus.health_question_info_status", "COMPLETED")
        _.set(props, "nonTrustedPageStatus.confirm_submit_status", "START");
        props.history.push(`/eoi/homePage`)
    }
}

export function authHeader() {
    let accessToken = JSON.parse(sessionStorage.getItem('eoi_token'));

    if (accessToken) {
        return accessToken;
    } else {
        return '';
    }
};



export function getJunction() {
    ////console.log(JSON.parse(sessionStorage.getItem('state')))
    let data = sessionStorage.getItem('state') && JSON.parse(sessionStorage.getItem('state'))

    // if(junction.eoiStore.trustedFlag === true || null){
    //     //console.log("aaa")
    //     return "grpeoia"
    // }
    // else{
    //     //console.log("bbbb")
    //     return "grpeoiua"
    // }
    // let junction = data.eoiStore.trustedFlag
    // //console.log(junction)
    if (data) {
        let junction = data.eoiStore.trustedFlag
        // console.log(junction,"mmmmmmmmm")
        if (junction === false) {
            //console.log("aaa")
            return "grpeoiua"
        }
        else {
            //console.log("bbbb")
            return "grpeoia"
        }
    }
    else {
        return "grpeoia"
    }

}
// scroll up function
export function scrollUp() {
    if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual';
    }
    // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support.
    window.scrollTo(0, 0);
}

export function getQuestionDescriptionWithBold(questionDesc) {
    let allDesc = [
        ["In the past 10 years", " have you been treated for or diagnosed as having any of the following?"],
        ["In the past 10 years", " have you been treated for or diagnosed by a licensed medical professional as having any of the following?"],
        ["In the past 7 years", " have you been treated for or diagnosed by a licensed medical professional as having any of the following?"],
        ["In the past 5 years", " do any of the following apply?"],
        ["In the past 5 years", " have you been treated for any of the following disorders or conditions?"],
        ["In the past 5 years", " have you been treated by a licensed medical professional for any of the following disorders or conditions?"],
        ["In the past 12 months", " have you consulted or been examined, treated by a physician, practitioner or specialist for any illness, injury, disease or disorder not listed in the previous questions? (Including routine physicals only when there is an existing or newly diagnosed condition)."],
        ["In the past 12 months", " have you been treated for or diagnosed by a medical professional for any illness, injury, disease or disorder not listed in the previous questions? (Including routine physicals only when there is an existing or newly diagnosed condition)."],
        ["Have you ever tested positive for HIV", " (Human Immunodeficiency Virus) antibodies? "],
        ["During the past 7 years", ", has any insured tested positive for the HIV (Human Immunodeficiency Virus)?"],
        ["Have you ever tested positive", " for exposure to the HIV infection or been diagnosed as having AIDS related complex (ARC) or AIDS caused by the HIV infection or other sickness or condition derived from such infection?"],
        ["Have you ever been treated for or diagnosed", " by a medical professional as having AIDS Related Complex (ARC)?"]
    ]
    for (let i = 0; i < 2; i++) {
        if (questionDesc === allDesc[i][0] + allDesc[i][1]) {
            return allDesc[i]
        }
    }
   
}
export function moveToPreviousQuestionFromCondition(props) {
    _.set(props, "activeCondition", 0)
    props.conditionStatus(0)
    activeLifeCondition = 0
    const activeQuestion = _.get(props, "activeQuestion", "")
    //console.log("prevvvvv",_.get(props,"activeCondition"))
    chooseQuestionTemplate(activeQuestion, props)
}
export function moveToPreviousQuestion(props) {
    //console.log("cccc")
    let activeQuestion = _.get(props, "activeQuestion", "")
     let reviewInd =  _.get(props,"prefillData.eoi_data.health_question_info.review_hq_enabled","")
    if (activeQuestion === 1) {
        if(reviewInd === "N"){
            props.history.push(`/eoi/healthHistory`)
            props.questionStatus(0)
        } else {
            props.history.push(`/eoi/review`)
            props.questionStatus(0)
        }
    }
    if (activeQuestion > 1) {
        const activeQuestion = _.get(props, "activeQuestion", "")
        let prevQ = activeQuestion - 1
    
        chooseQuestionTemplate(prevQ, props)
    }
}

function getCurrentLife(props, rv) {
    let allSelectedCondition = []
    let onlyselectedConditionInOrder = []
    let baseQuestionDet = _.get(props, "prefillData.eoi_data.health_question_info.base_question", [])
    const activeQuestion = _.get(props, "activeQuestion", "")
    let previousPage = _.get(props, "location.state.previousPage")
    // console.log(previousPage, "PREVIOUS PAGEE")
    // let lifeId = _.get(props, "location.state.lifeId");
    // console.log(lifeId, "LIFEEEEEEEEEEEEEEEE")
    //console.log(_.get(props,"location.pathname",""),_.get(props,"location.pathname","").split('/'))
    //const activeQuestion = _.get(props,"location.pathname","").split('/')[3]
    //console.log(baseQuestionDet,activeQuestion)
    baseQuestionDet.map(question => {
        //console.log(question.question_id.toString() === activeQuestion.toString(),question.question_id.toString() , activeQuestion.toString())
        if (question.question_id.toString() === activeQuestion.toString()) {
            //let lifeEntityLength;
            //question.
           // const lifeEntityLength = question.health_condition[0]["life_entities_dq"].length
            //const lifeEntityLength = question.condition_info_required === true ? 
            // question.health_condition[0]["life_entities_dq"].length
            // :
            // question.life_entities_bq.length

            //for (let i = 0; i < lifeEntityLength; i++) {
                let temp = []
                // console.log(props,"review")
                let reviewInd =  _.get(props,"prefillData.eoi_data.health_question_info.review_hq_enabled","")
                let reviewLifeID  = _.get(props, "prefillData.eoi_data.health_question_info.review_hq_life_id","")
                // console.log(reviewInd,reviewLifeID,"check")
                //console.log("enter")
                //console.log(question.answer_type ==="PICKLIST",question.answer_type )
                if (question.answer_type === "PICKLIST") {
                    question.health_condition.map((condition, index) => {
                        condition.life_entities_dq.map((life, i)=> {
                        if (question.condition_info_required === true && (condition.life_entities_dq[i].answer_condition_info === true || condition.life_entities_dq[i].answer_condition_info === "true")
                            && condition.condition_name !== "None of the above") {


                            let tempCondition = JSON.parse(JSON.stringify(condition))
                            let requiredDetails = {}
                            // console.log(tempCondition.life_entities_dq[i].life_id,"value", parseInt(reviewLifeID))
                                if (reviewInd === "Y" && reviewLifeID === tempCondition.life_entities_dq[i].life_id) {
                                    requiredDetails.condition_id = tempCondition.condition_id
                                    requiredDetails.condition_name = tempCondition.condition_name
                                    requiredDetails.condition_page_status = tempCondition.condition_page_status
                                    requiredDetails.life_entities_dq = tempCondition.life_entities_dq[i]
                                    requiredDetails.answer_type = tempCondition.answer_type
                                    requiredDetails.condition_info_required = true
                                    temp.push(requiredDetails)
                                    // console.log(temp, "temp")
                                }
                                if (reviewInd !== "Y") {
                                    requiredDetails.condition_id = tempCondition.condition_id
                                    requiredDetails.condition_name = tempCondition.condition_name
                                    requiredDetails.condition_page_status = tempCondition.condition_page_status
                                    requiredDetails.life_entities_dq = tempCondition.life_entities_dq[i]
                                    requiredDetails.answer_type = tempCondition.answer_type
                                    requiredDetails.condition_info_required = true
                                    temp.push(requiredDetails)
                                }
                            }
                            return life
                    })
                        return condition
                    })
                }
                if (question.answer_type === "YES_NO") {                 
                    question.health_condition.map((condition, index) => {
                        condition.life_entities_dq.map((life, i)=> {
                            if(previousPage === "review"){

                                if(life.life_id === reviewLifeID){
                                    if (question.condition_info_required === true && (condition.life_entities_dq[i].answer_condition_info === true || condition.life_entities_dq[i].answer_condition_info === "true")) {
                            let tempCondition = JSON.parse(JSON.stringify(condition))
                            let requiredDetails = {}
                            requiredDetails.condition_id = tempCondition.condition_id
                            requiredDetails.condition_name = tempCondition.condition_name
                            requiredDetails.condition_page_status = tempCondition.condition_page_status
                            requiredDetails.life_entities_dq = tempCondition.life_entities_dq[i]
                            requiredDetails.answer_type = tempCondition.answer_type
                            requiredDetails.condition_info_required = true
                            requiredDetails.questionType = "YES_NO"
                            temp.push(requiredDetails)
                        }
                                }
                            } else {
                                if (question.condition_info_required === true && (condition.life_entities_dq[i].answer_condition_info === true || condition.life_entities_dq[i].answer_condition_info === "true")) {
                                    let tempCondition = JSON.parse(JSON.stringify(condition))
                                    let requiredDetails = {}
                                    requiredDetails.condition_id = tempCondition.condition_id
                                    requiredDetails.condition_name = tempCondition.condition_name
                                    requiredDetails.condition_page_status = tempCondition.condition_page_status
                                    requiredDetails.life_entities_dq = tempCondition.life_entities_dq[i]
                                    requiredDetails.answer_type = tempCondition.answer_type
                                    requiredDetails.condition_info_required = true
                                    requiredDetails.questionType = "YES_NO"
                                    temp.push(requiredDetails)
                                }
                            }
                        

                        return life 
                    })
                        return condition
                    })

                }
                // else if(question.condition_info_required === false && question.life_entities_bq[i].answer_base_question === true){ 

                //             let tempCondition = JSON.parse(JSON.stringify(condition))
                //             let requiredDetails = {}
                //             requiredDetails.condition_id = tempCondition.condition_id
                //             requiredDetails.condition_name = tempCondition.condition_name
                //             requiredDetails.condition_page_status = tempCondition.condition_page_status
                //             requiredDetails.life_entities_dq = tempCondition.life_entities_bq[i]
                //             requiredDetails.answer_type = tempCondition.answer_type
                //             requiredDetails.condition_info_required = false
                //             temp.push(requiredDetails)
                // } 
                if (temp.length > 0) {
                    allSelectedCondition.push(temp)
                }

            // }
        }
        return question
    })

    allSelectedCondition.map(allConditionOfMember => {
        let temp = []
        allConditionOfMember.forEach((condition, index) => {
            //console.log(index)
            //console.log(condition)
            let conditionTemp = JSON.parse(JSON.stringify(condition))
            conditionTemp.condition_inOrder = index + 1
            //console.log(conditionTemp)
            temp.push(conditionTemp)
        })
        onlyselectedConditionInOrder.push(temp)
        return allConditionOfMember
    })
    return onlyselectedConditionInOrder
}
export function chooseNextCondition(props) {
    // console.log(props, "PRPOPDSSSSSSSSSSSSS")
    let previousPage = _.get(props, "location.state.previousPage")
   
    // console.log(previousPage, "PREVIOUS PAGE")
    let onlyselectedConditionInOrder = getCurrentLife(props)
    // console.log(onlyselectedConditionInOrder, "ORDEER")
    let currentLife = []
    const activeQuestion = _.get(props, "activeQuestion", "")

    let activeCondition = _.get(props, "activeCondition", 0)
    // console.log(activeCondition,"active")
    let nextC = parseInt(activeCondition) + 1

    currentLife = onlyselectedConditionInOrder[activeLifeCondition]
    // console.log(currentLife,"current")

    if (currentLife) {
        //console.log(activeCondition,currentLife.length,onlyselectedConditionInOrder,onlyselectedConditionInOrder[activeLifeCondition],activeLifeCondition)
        if (activeCondition.toString() === currentLife.length.toString()) {
            // console.log("curent if")
            props.conditionStatus(0)
            if (activeLifeCondition !== onlyselectedConditionInOrder.length - 1) {
                activeLifeCondition = activeLifeCondition + 1
                currentLife = onlyselectedConditionInOrder[activeLifeCondition]

                currentLife.map(condition => {
                    if (previousPage === "review") {
                        let lifeId = parseInt(props.location.state.lifeId)

                        props.history.push({
                            pathname: `/eoi/question/${activeQuestion}/letsTalkAbout/${lifeId}`,
                            state: {
                                "name": condition.life_entities_dq.life_name, "previousPage": "review", "lifeId": lifeId, "imgUrl": condition.life_entities_dq.life_desc.toLowerCase() === "spouse"
                                    ? './images/oswald-condition-illustration.svg' : './images/mandy-condition-illustration.svg'
                            }
                        })
                    } else {
                        props.history.push({
                            pathname: `/eoi/question/${activeQuestion}/letsTalkAbout`,
                            state: {
                                "name": condition.life_entities_dq.life_name, "imgUrl": condition.life_entities_dq.life_desc.toLowerCase() === "spouse"
                                    ? './images/oswald-condition-illustration.svg' : './images/mandy-condition-illustration.svg'
                            }
                        })
                    }
                    // })
                    return condition
                })
            }
            else if (activeQuestion === props.totalQuestion) {
                let lifeId = parseInt(props.location.state.lifeId)
                if (_.get(props, "trustedFlag", "") === true) {
                    //if(_.get(props, "prefillData.eoi_data.health_question_info.base_question_status","")==="ACTIVE"){
                    _.set(props, "prefillData.eoi_data.health_question_info.base_question_status", "COMPLETED")
                    _.set(props, "prefillData.eoi_data.health_question_info.active_question_status", "")
                    _.set(props, "prefillData.eoi_data.health_question_info_status", "COMPLETED")
                    _.set(props, "prefillData.eoi_data.confirm_submit_status", "START")
                    //this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
                    //}
                    // console.log(previousPage, "condition")
                    if (previousPage !== "review") {
                        props.updateprefillData(_.get(props, "prefillData", ""), _.get(props, "prefillId", ""), '/eoi/homePage')
                    } else {
                        props.updateprefillData(_.get(props, "prefillData", ""), _.get(props, "prefillId", ""), `/eoi/review/${lifeId}`)
                    }
                }
                else {
                    _.set(props, "nonTrustedPageStatus.health_question_info_status", "COMPLETED")
                    _.set(props, "nonTrustedPageStatus.confirm_submit_status", "START");
                    // console.log(previousPage,"conditionnon")
                    if (previousPage !== "review") {
                        props.history.push(`/eoi/homePage`)
                    } 
                    // else {
                    //     props.updateprefillData(_.get(props, "prefillData", ""), _.get(props, "prefillId", ""), `/eoi/review/${lifeId}`)
                    // }
                }
                //console.log(props)



            }

            else if (activeLifeCondition === onlyselectedConditionInOrder.length - 1 && previousPage !== "review") {
                activeLifeCondition = 0
                props.history.push(`/eoi/letsMoveOn`)
            }
            else if (previousPage === "review" && activeCondition === onlyselectedConditionInOrder[activeLifeCondition].length && onlyselectedConditionInOrder[activeLifeCondition].length > 1) {
                props.history.push(`/eoi/review`)
            }


            //console.log(_.get(props,"activeCondition",0))
            activeCondition = 0
        }
        else {
            // console.log(currentLife,"cccccccccccccccc")
            currentLife.map(condition => {
                //console.log("ccccccccccccc",condition,nextC)
                if ((condition.condition_inOrder).toString() === nextC.toString()) {
                    props.conditionStatus(nextC)
                    if (condition.answer_type === "PICKLIST" && condition.questionType !== "YES_NO") {
                        //console.log("1111111")
                        if (previousPage === "review") {
                            let lifeId = parseInt(props.location.state.lifeId)
                            props.history.push({
                                pathname: `/eoi/question/${activeQuestion}/condition/${nextC}/${lifeId}`,
                                state: { "condition": condition, "previousPage": "review", "lifeId": lifeId }

                            })
                        } else {
                            props.history.push({
                                pathname: `/eoi/question/${activeQuestion}/condition/${nextC}`,
                                state: { "condition": condition }

                            })
                        }

                    }
                    if (condition.answer_type === "ENTER_DETAILS" && condition.questionType !== "YES_NO") {
                        //console.log("222")
                        if (previousPage === "review") {
                            let lifeId = parseInt(props.location.state.lifeId)
                            props.history.push({
                                pathname: `/eoi/question/${activeQuestion}/medicationCondition/${nextC}/${lifeId}`,
                                state: { "condition": condition, "previousPage": "review", "lifeId": lifeId }

                            })
                        } else {
                            props.history.push({
                                pathname: `/eoi/question/${activeQuestion}/medicationCondition/${nextC}`,
                                state: { "condition": condition }

                            })
                        }
                    }
                    if (condition.questionType === "YES_NO") {
                        //console.log("3333")
                        // condition.life_entities_dq.map(life => {
                        //     if(life.disclosure_types.length ===1){
                        //         //console.log(props)
                        //        moveToReflexiveQuestion(props)
                        //     }
                        // })
                        //condition.life_entities_dq.map(life => {
                        if (condition.life_entities_dq.disclosure_types.length === 1) {
                            // _.set(props,"activeCondition",nextC)
                            // console.log(nextC)
                            // console.log(props.activeCondition)
                            //     let a ={};
                            // //    //a.activeCondition = nextC
                            //     let b = Object.assign(a,props)
                            //     b.activeCondition = 1
                            //     let c=Object.assign(b.activeCondition,1)
                            //      //console.log(a,c)
                            //     // Object.assign(a.)
                            //     // Object.assign(props.activeCondition,nextC)
                            //     //             //console.log("mspssss",props)
                            //     // props.activeCondition = nextC
                            //     //console.log(props)

                            let a = Object.assign({}, props)
                            a.activeCondition = 1
                            //console.log(a)
                            moveToReflexiveQuestion(a)

                        }
                        else {
                            if (previousPage === "review") {
                                let lifeId = parseInt(props.location.state.lifeId)
                                props.history.push({
                                    pathname: `/eoi/question/${activeQuestion}/condition/${nextC}/${lifeId}`,
                                    state: { "condition": condition, "previousPage": "review", "lifeId": lifeId }
                                })
                            } else {
                                props.history.push({
                                    pathname: `/eoi/question/${activeQuestion}/condition/${nextC}`,
                                    state: { "condition": condition }
                                })
                            }

                        }
                        //})
                    }

                    //console.log("ssssssssssss",activeCondition)  

                }
                return condition
            })

        }
    }

}
export function moveToReflexiveQuestion(props, rv) {
    // console.log(props, "PRPOPDSSSSSSSSSSSSS")

    let previousPage = _.get(props, "location.state.previousPage")
    // console.log(previousPage, "PREVIOUS PAGE")
    let refluxData = getRefluxData(props)
    // let onlyselectedConditionInOrder = getCurrentLife(props)
    // console.log(onlyselectedConditionInOrder.length, "length")
    const activeReflux = _.get(props, "activeReflux", 0)
    // console.log(activeReflux,"kkkk");
    const activeQuestion = _.get(props, "activeQuestion", 0)
    // console.log(activeQuestion, "ACTIVE QUESTION")
    let activeCondition = _.get(props, "activeCondition", 0)
    // console.log(activeCondition, "ActiveCOndition")
    let nextR = parseInt(activeReflux) + 1
    // console.log("nextR", nextR);
    if (refluxData) {
        if (activeReflux === refluxData.length) {
            props.refluxStatus(0)
            chooseNextCondition(props)
        }
        else {
            refluxData.map(reflux => {
                if (reflux.reflux_inOrder.toString() === nextR.toString()) {
                    props.refluxStatus(nextR)
                    if (rv === "reviewEdit") {
                        let lifeId = parseInt(props.location.state.lifeId)
                        props.conditionStatus(activeCondition)
                        props.questionStatus(activeQuestion)
                        props.history.push({
                            pathname: `/eoi/question/${activeQuestion}/condition/${activeCondition}/reflux/${nextR}/${lifeId}`,
                            state: { "reflux": reflux, "previousPage": "reviewEdit", "lifeId": lifeId }
                        })
                        // this.props.nextRfid();
                    }
                    else if (previousPage === "review") {
                        let lifeId = parseInt(props.location.state.lifeId)
                        props.conditionStatus(activeCondition)
                        props.questionStatus(activeQuestion)
                        props.history.push({
                            pathname: `/eoi/question/${activeQuestion}/condition/${activeCondition}/reflux/${nextR}/${lifeId}`,
                            state: { "reflux": reflux, "previousPage": "review", "lifeId": lifeId }
                        })
                        // this.props.nextRfid();
                    }
                    else {
                        props.history.push({
                            pathname: `/eoi/question/${activeQuestion}/condition/${activeCondition}/reflux/${nextR}`,
                            state: { "reflux": reflux }
                        })
                    }

                }
                return reflux
            })
        }
    }
}
// export function moveToReflexiveQuestionFromYesNo (props){
//     const activeQuestion = _.get(props,"activeQuestion",0)
//     let baseQuestionDet = _.get(props,"prefillData.eoi_data.health_question_info.base_question",[]) 

//     //console.log(activeQuestion)
//     let refluxData = []
//     baseQuestionDet.map(eachQuestion => {
//         if(eachQuestion.question_id.toString() === "4"){
//             eachQuestion.health_condition.map(condition=>{
//             condition.life_entities_dq.map(life => {
//                 if(life.disclosure_types.length ===1){
//                 condition.condition_page_status = "ACTIVE"
//                 life.disclosure_types.map(disclosure => {
//                     let tempReflux = JSON.parse(JSON.stringify(disclosure))
//                     let requiredDetails = {}
//                     requiredDetails.condition_id = condition.condition_id
//                     requiredDetails.condition_name = condition.condition_name
//                     requiredDetails.life_entities_dq = condition.life_entities_dq
//                     requiredDetails.disclosureData = disclosure
//                     requiredDetails.answer_type = "YES_NO"
//                     refluxData.push(requiredDetails)
//                 })
//                 }
//             })
//             })
//         }
//         return eachQuestion
//     })

//     //console.log(refluxData)
//     refluxData && refluxData.map(reflux => {
//         ////console.log(reflux.reflux_inOrder , nextR)
//         // if(reflux.reflux_inOrder.toString() === nextR.toString()){
//             props.conditionStatus(1)
//              props.refluxStatus(1)

//             //console.log(activeQuestion,reflux)
//             props.history.push({
//                 pathname:`/eoi/question/${activeQuestion}/condition/1/reflux/1`,
//                 state:{"reflux":reflux}
//             }) 


//         return reflux
//     })

// }

function getactiveLife(props, conditionOrder) {
    let currentActiveLife = [];
    let activeLife = _.get(props, "activeLife", 0)
    if (activeLife !== 0) {
        conditionOrder.map((cLife, index) => {
            cLife.map((lifeValue, ind) => {
                currentActiveLife.push(parseInt(lifeValue.life_entities_dq.life_id))
                return lifeValue
            })
            return cLife
        })
        activeLifeCondition = currentActiveLife.indexOf(activeLife)//parseInt(activeLife) - 1
    }
}
function getRefluxData(props) {
    // const activeReflux = _.get(props,"activeReflux","")
    let refluxData = []

    let onlyselectedConditionInOrder = getCurrentLife(props)

    let currentLife = []
    // const activeQuestion = _.get(props,"activeQuestion","")
    let activeCondition = _.get(props, "activeCondition", 0)
    getactiveLife(props, onlyselectedConditionInOrder)
    currentLife = onlyselectedConditionInOrder[activeLifeCondition]
    if (currentLife) {
        currentLife.map(condition => {
            if (condition.condition_inOrder.toString() === activeCondition.toString()) {
                if (condition.answer_type === "PICKLIST") {
                    if (condition.life_entities_dq.disclosure_types) {
                        condition.life_entities_dq.disclosure_types.map(disclosure => {
                            if (disclosure.answer_disclosure_info === true) {
                                let tempReflux = JSON.parse(JSON.stringify(disclosure))
                                let requiredDetails = {}
                                requiredDetails.condition_id = condition.condition_id
                                requiredDetails.condition_name = condition.condition_name
                                requiredDetails.life_entities_dq = condition.life_entities_dq
                                requiredDetails.disclosureData = tempReflux
                                requiredDetails.answer_type = "PICKLIST"
                                refluxData.push(requiredDetails)
                            }
                            return disclosure
                        })
                    }
                
            }

            if (condition.answer_type === "ENTER_DETAILS") {
                if (condition.life_entities_dq.disclosure_types) {
                    condition.life_entities_dq.disclosure_types.map(disclosure => {
                        if (disclosure.answer_disclosure_info_value && disclosure.answer_disclosure_info_value.length > 0) {
                            let tempReflux = JSON.parse(JSON.stringify(disclosure))
                            let requiredDetails = {}
                            requiredDetails.condition_id = condition.condition_id
                            requiredDetails.condition_name = condition.condition_name
                            requiredDetails.life_entities_dq = condition.life_entities_dq
                            requiredDetails.disclosureData = tempReflux
                            requiredDetails.answer_type = "ENTER_DETAILS"
                            refluxData.push(requiredDetails)
                        }
                        return disclosure
                    })
                }
            }
            }
            return condition
        
        })

    }
    refluxData.forEach((reflux, index) => {
        reflux.reflux_inOrder = index + 1
    })
    return refluxData
}
export function moveToPreviousRefluxQuestion(props) {

    let refluxData = getRefluxData(props)
    const activeReflux = _.get(props, "activeReflux", 0)
    const activeQuestion = _.get(props, "activeQuestion", "")
    // console.log(activeQuestion)
    let activeCondition = _.get(props, "activeCondition", 0)
    let activeLife = _.get(props, "history.location.state.lifeId", null)
    let nextR = parseInt(activeReflux) - 1
    //console.log(nextR,activeReflux - 1,parseInt(activeReflux) - 1)
    if (refluxData) {
        //console.log(activeReflux.toString())
        if (activeReflux.toString() === "1") {
            //console.log("uuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu")
            props.refluxStatus(0)
            moveToConditionFromReflux(props)
        }
        else {
            refluxData.map(reflux => {
                // console.log(reflux.reflux_inOrder , nextR)
                if (reflux.reflux_inOrder.toString() === nextR.toString()) {
                    props.refluxStatus(nextR)
                    props.history.push({
                        pathname: `/eoi/question/${activeQuestion}/condition/${activeCondition}/reflux/${nextR}`,
                        state: { "reflux": reflux, "lifeId": activeLife }
                    })

                }
                // else{
                //     nextR = activeReflux
                // }
                return reflux
            })
        }
    }
}


function moveToConditionFromReflux(props) {
    let onlyselectedConditionInOrder = getCurrentLife(props)

    let currentLife = []
    const activeQuestion = _.get(props, "activeQuestion", "")
    let activeCondition = _.get(props, "activeCondition", 0)
    let nextC = activeCondition
    getactiveLife(props, onlyselectedConditionInOrder)
    currentLife = onlyselectedConditionInOrder[activeLifeCondition]
    if (currentLife) {
        currentLife.map(condition => {
            if (condition.condition_inOrder.toString() === nextC.toString()) {
                props.conditionStatus(nextC)
                props.questionStatus(activeQuestion)
                if (condition.answer_type === "PICKLIST" && condition.questionType !== "YES_NO") {
                    props.history.push({
                        pathname: `/eoi/question/${activeQuestion}/condition/${nextC}`,
                        state: { "condition": condition }
                    })
                }
                if (condition.answer_type === "ENTER_DETAILS" && condition.questionType !== "YES_NO") {
                    props.history.push({
                        pathname: `/eoi/question/${activeQuestion}/medicationCondition/${nextC}`,
                        state: { "condition": condition }

                    })
                }
                if (condition.questionType === "YES_NO") {
                    // console.log(condition,"kkkkkkk",condition.questionType)
                    if (condition.answer_type === "PICKLIST" && condition.life_entities_dq.disclosure_types.length !== 1 && condition.questionType === "YES_NO") {
                        props.history.push({
                            pathname: `/eoi/question/${activeQuestion}/condition/${nextC}`,
                            state: { "condition": condition }
                        })
                    }
                    else if (condition.life_entities_dq.disclosure_types.length === 1) {
                        //console.log("vvvvvvvvvvvvvvv")
                        moveToPreviousQuestionFromCondition(props)
                    }
                    else {
                        props.history.push({
                            pathname: `/eoi/question/${activeQuestion}/medicationCondition/${nextC}`,
                            state: { "condition": condition }

                        })
                    }
                }
                //console.log("ssssssssssss",activeCondition)  

            }
            return condition
        })

        //}
    }
}

export function chooseNextQuestion(props, path) {
    // console.log(props, "PROPS")
    const activeQuestion = _.get(props, "activeQuestion")
    let previousPage = _.get(props, "location.state.previousPage")
    let nextQ = parseInt(activeQuestion) + 1
    // console.log(activeQuestion,props.totalQuestion)
    if (props.totalQuestion > 0 && activeQuestion === props.totalQuestion) {
        if (_.get(props, "trustedFlag", "") === true) {
            //if(_.get(props, "prefillData.eoi_data.health_question_info.base_question_status","")==="ACTIVE"){
            _.set(props, "prefillData.eoi_data.health_question_info.base_question_status", "COMPLETED")
            _.set(props, "prefillData.eoi_data.health_question_info.active_question_status", "")
            _.set(props, "prefillData.eoi_data.health_question_info_status", "COMPLETED")
            _.set(props, "prefillData.eoi_data.confirm_submit_status", "START")
            //this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
            //}
            // console.log(previousPage, "question")
            if (previousPage !== "review") {
                props.updateprefillData(_.get(props, "prefillData", ""), _.get(props, "prefillId", ""), '/eoi/homePage')
            }
            else {
                props.updateprefillData(_.get(props, "prefillData", ""), _.get(props, "prefillId", ""), `/eoi/review/${props.lifeId}`)
            }
        }
        else {
            _.set(props, "nonTrustedPageStatus.health_question_info_status", "COMPLETED")
            _.set(props, "nonTrustedPageStatus.confirm_submit_status", "START");
            // console.log(previousPage, "question")
            if (previousPage !== "review") {
                props.history.push(`/eoi/homePage`)
            }
        //     else {
        //         props.updateprefillData(_.get(props, "prefillData", ""), _.get(props, "prefillId", ""), `/eoi/review/${props.lifeId}`)
        //     }
        }



    }
    else {
        chooseQuestionTemplate(nextQ, props, path)
    }


}
function chooseQuestionTemplate(nextQ, props, path) {

    let answer_type;
    
    let previousPage = _.get(props, "location.pathname")
    // console.log(previousPage, "PREVIPSA")
    const baseQuestion = _.get(props, "prefillData.eoi_data.health_question_info.base_question")
    
    baseQuestion && baseQuestion.map(question => {

        if (question.question_id === nextQ.toString()) {
            answer_type = question.answer_type       
        }
        return question
    })

    if (answer_type === "PICKLIST") {
        let lifeEntities;
        let lifeArray;
        let reviewInd =  _.get(props,"prefillData.eoi_data.health_question_info.review_hq_enabled","")
        let reviewLifeID  = _.get(props, "prefillData.eoi_data.health_question_info.review_hq_life_id","")
        
        baseQuestion && baseQuestion.map(question => {
            if (question.question_id === nextQ.toString()) {
                // console.log(nextQ.toString(),"nextquestion",question.question_id)
                // console.log(reviewInd,"ind")
                if(reviewInd === "N" || reviewInd ===""){
                    lifeEntities = question && question.health_condition && question.health_condition[0].life_entities_dq
                    } else {
                    lifeArray = question && question.health_condition && question.health_condition[0].life_entities_dq  
                    lifeEntities = lifeArray.filter((life) => life.life_id === reviewLifeID) 
                    }
            }
            return question
        })

        if ((previousPage.includes("/eoi/review") && lifeEntities.length >= 1) || reviewInd === "Y") {
            props.questionStatus(nextQ, props)
            props.history.push({
                pathname: `/eoi/question/${nextQ}/${reviewLifeID}`,
                state: { "page": "memberOnly", "previousPage": "review", "lifeId":reviewLifeID}
            })
        } else {
            if (lifeEntities.length === 1) {
                props.questionStatus(nextQ, props)
                props.history.push({
                    pathname: `/eoi/question/${nextQ}`,
                    state: { "page": "memberOnly", "previousPage": path && path }
                })
            }
            if (lifeEntities.length > 1) {
                props.questionStatus(nextQ, props)
                props.history.push({
                    pathname: `/eoi/question/${nextQ}`,
                    state: { "page": "family", "previousPage": path && path }
                })
            }
        }
    }
    if (answer_type === "YES_NO") {
        let lifeEntities;
        let reviewInd =  _.get(props,"prefillData.eoi_data.health_question_info.review_hq_enabled","")
        let reviewLifeID  = _.get(props, "prefillData.eoi_data.health_question_info.review_hq_life_id","")
    //    console.log(reviewInd,reviewLifeID,"kkk")
        baseQuestion && baseQuestion.map(question => {
            if (question.question_id === nextQ.toString()) {
                question.health_condition.map(condition => {
                    lifeEntities = condition.life_entities_dq
                    return condition
                })
            }
            return question
        })
        if ((previousPage.includes("/eoi/review") && lifeEntities.length >= 1) || (reviewInd === "Y") ) {
            props.questionStatus(nextQ, props)
            props.history.push({
                pathname: `/eoi/question/${nextQ}/${reviewLifeID}`,
                state: { "page": "noConditionMemberOnly", "previousPage": "review", "lifeId": reviewLifeID }
            })
        } else {
            if (lifeEntities.length === 1) {
                props.questionStatus(nextQ, props)
                props.history.push({
                    pathname: `/eoi/question/${nextQ}`,
                    state: { "page": "noConditionMemberOnly", "previousPage": path && path }
                })
            }
            if (lifeEntities.length > 1) {
                props.questionStatus(nextQ, props)
                props.history.push({
                    pathname: `/eoi/question/${nextQ}`,
                    state: { "page": "noConditionFamily", "previousPage": path && path }
                })
            }
        }
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(chooseNextQuestion);

export function getActiveQuestionData(props) {
    const baseQuestionDet = _.get(props, "prefillData.eoi_data.health_question_info.base_question", [])
    const baseQuestion = JSON.parse(JSON.stringify(baseQuestionDet))
    let question = _.get(props, "location.pathname", "").split('/')[3]

    //let questionNumber = question.replace(/\D/g,"")
    let questionData = {}
    baseQuestion.map(eachQuestion => {
        if (eachQuestion.question_id === question) {
            questionData = eachQuestion
        }

        return eachQuestion
    })
    //console.log(questionData)
    return questionData

}

export function checkConditionPerIndividualIsChecked(questionData) {
    const lifeEntityLength = questionData.health_condition[0]["life_entities_dq"].length
    let finalLifeStatus = []
    for (let i = 0; i < lifeEntityLength; i++) {
        let eachLifeStatus = []
        questionData.health_condition.map((condition, index) => {
            eachLifeStatus.push(condition.life_entities_dq[i].answer_condition_info)
            return condition
        })
        if (eachLifeStatus.includes(true)) {
            finalLifeStatus.push(true)
        }
        else {
            finalLifeStatus.push(false)
        }
    }
    return finalLifeStatus;
}
export function imagePath(path) {

    if (window.location.host === "localhost:4000") {
        return path
    } else {
        return "./" + window.location.pathname.split("/")[1] + path.slice(1)
    }

}
export function compareNestedObject(obj1, obj2){

    //Loop through properties in object 1

for (let p in obj1) {

       //Check property exists on both objects

       if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;



       switch (typeof (obj1[p])) {

              //Deep compare objects

              case 'object':

                    if (!compareNestedObject(obj1[p], obj2[p])) return false;

                    break;

              //Compare function code

              case 'function':

                    if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;

                    break;

              //Compare values

              default:

                    if (obj1[p] !== obj2[p]) return false;

       }

}



//Check object 2 for any extra properties

for (let p in obj2) {

       if (typeof (obj1[p]) === 'undefined') return false;

}

return true;



}
// export function compareNestedObject(obj1, obj2){
//            //Loop through properties in object 1
//        for (let p in obj1) {
//               //Check property exists on both objects
//               if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

//               switch (typeof (obj1[p])) {
//                      //Deep compare objects
//                      case 'object':
//                            if (!Object.compare(obj1[p], obj2[p])) return false;
//                            break;
//                      //Compare function code
//                      case 'function':
//                            if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;
//                            break;
//                      //Compare values
//                      default:
//                            if (obj1[p] !== obj2[p]) return false;
//               }
//        }

//        //Check object 2 for any extra properties
//        for (let p in obj2) {
//               if (typeof (obj1[p]) === 'undefined') return false;
//        }
//        return true;

// }

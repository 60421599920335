import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/HomePage/HomePageAction';
import _ from "lodash";
import JustAMoment from '../../components/Processing/JustAMoment';
import { scrollUp } from '../../utils/sharedFunction';
import SaveForLater from '../../components/SaveForLater/SaveForLater';


class eSignChild extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkBoxValue: false,
            showLoader: false,
            displayChild: false,
            childEligible: [false],
            date: new Date().getFullYear(),
            childName:[],
            visible:0,
            child: [{
                rx_auth : false,
                childName : "",
                checkBoxValue: false,
            }],
            isSaved:false,
            isOpened:false

        }
    }
    componentDidMount() {

        let child_eligible = this.state.childEligible;
        let child_names = this.state.childName;
        let child_info = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", [])
        let childState = _.get(this.props, "prefillData.eoi_data.group_info.group_situs_state")
        if (_.get(this.props, "prefillData.eoi_data.esign_child_status", "") !== "COMPLETED") {
            _.set(this.props, "prefillData.eoi_data.esign_child_status", "ACTIVE")
            this.props.updateprefillData(_.get(this.props, "prefillData", ""), _.get(this.props, "prefillId", ""))
        }
        // let child_Eligibles = []
        let childElectionsList = [];
        let eligibleChild =[];
        // if(childState !== null){
            child_info && child_info.map((child) => {
                let currentAge = this.state.date - parseInt(child.dob);
                    if (currentAge >= 18) {
                        child_names.push(child.first_name + " "+ child.middle_initial + " "+ child.last_name);
                        eligibleChild.push(child);
                        child_eligible= true;
                        
                    } else if (currentAge >= 15 && childState === "NC") {
                        // console.log(childState.includes("NC"))
                        child_names.push(child.first_name + " "+ child.middle_initial + " "+ child.last_name);
                        eligibleChild.push(child);
                        child_eligible= true;
                    } else {
                        eligibleChild.push(child);
                        child_eligible= false;
                    }
    
                // console.log(child_eligible);
                // console.log(child_names)
                return child_eligible;
    
            })
        // }
        scrollUp();
        if (eligibleChild.length > 0) {
            for (let k = 0; k < eligibleChild.length; k++) {
                let childElection = {};
                    childElection.rx_auth =  (eligibleChild[k].rx_auth, false);
                    childElection.child_name = eligibleChild[k].first_name;
                    childElection.checkBoxValue = (eligibleChild[k].checkBoxValue,false);
                    childElectionsList.push(childElection);
            }
            
        }
        this.setState({childName :child_names, child: childElectionsList})
    }
    downloafPDF = (e) => {
        e.preventDefault();
        this.props.downLoadPDFData();
    }

    onSubmit = () => {
        let child = _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
        if(this.state.visible !== this.state.childName.length-1){
            let inc=this.state.visible
            // console.log(this.state.child.length,"submit length")

            for (let k = 0; k < this.state.child.length; k++) {
                let objIndex = child.findIndex((obj => obj.first_name===this.state.child[k].child_name));
                // console.log(objIndex,"obj");
                // console.log(this.state.child[k].child_name);
                _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + objIndex + `].rx_auth`, this.state.child[objIndex].rx_auth);
             
            }
            if (_.get(this.props, "prefillData.eoi_data.esign_child_status", "") === "ACTIVE") {
                _.set(this.props, "prefillData.eoi_data.esign_child_status", "COMPLETED")
            }
            
            inc++;
            // console.log(inc)
            const newChild = [...this.state.child];
            newChild[inc].checkBoxValue = false;

            // this.state.child[inc].checkBoxValue = false;
            this.setState({visible:inc,child:newChild})
            scrollUp();
            // console.log(this.state.visible <= this.state.childName.length);
            // console.log(this.state.child)
            
        } else {
            // console.log(this.state.child)
            for (let k = 0; k < this.state.child.length; k++) {
                let objIndex = child.findIndex((obj => obj.first_name===this.state.child[k].child_name));
                // console.log(objIndex,"obj");
                // console.log(this.state.child[k].child_name);
                _.set(this.props, `prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[` + objIndex + `].rx_auth`, this.state.child[objIndex].rx_auth);
             
            }
            this.setState({ showLoader: true });
            this.props.submitAndSign(this.props.prefillData);
        }
      
        
    }

    onChangeBox = (e,index) => {
        // console.log("document.value", document.getElementById("checks_"+index));
      
        let box = document.getElementById("checks_"+index) ;
        // console.log( box.checked);
        // console.log( document.getElementById("checks_1"));

        if (box.checked) {
            // this.setState({this.state.child[index].rx_auth : true });
            const newChild = [...this.state.child];
            newChild[index].rx_auth = true;
            newChild[index].checkBoxValue = true;
            this.setState({ "checkBoxValue": true, child : newChild});
        }
         else {
            // console.log("in false");
            const newChild = [...this.state.child];
            newChild[index].checkBoxValue = false;
            this.setState({ "checkBoxValue": false, child : newChild});
        }
    }
    saveForLater = () => {
        this.setState({isSaved:true});
    }
    continueEOI = () => {
        this.setState({isSaved:false});
    }
    leave = () => {
        this.props.history.push({ pathname: `/eoi/success` })
    }
    render() {
        // console.log("this.props", this.props);
    //    console.log(this.state.visible)
    //    console.log(this.state.visible++)

           return (
               <>
                {this.state.isSaved === true ? <SaveForLater continueEOI={()=>this.continueEOI()} leave = {()=> this.leave()}/> : null}
                   {this.state.showLoader ? <JustAMoment /> : 
                       <main>
                           {this.state.visible <= this.state.childName.length-1 ?
                           <div class="container">
                               <div class="row">
                                   <div class="col-lg-5">
                                       <div class="intro">
                                           <h3>Dependent signature</h3>
                                           <p>We need your approval and signature before we can decide on your coverage.
                                           </p>
                                           <p><strong>Important:</strong> This section must only be completed by the individual whose name appears below.
                                           </p>
                                       </div>
                                       <div class="data-box checkbox">
                                           <input type="checkbox" id={"checks_"+this.state.visible} checked = {this.state.child[this.state.visible].checkBoxValue} onChange={(e) => this.onChangeBox(e,this.state.visible)} />
                                         
                                           <label htmlFor={"checks_"+this.state.visible} class="mb-0">
                                               By checking this box, I, {this.state.childName[this.state.visible]}, agree:
                                           </label>
                                       </div>
                                       <ul className="e-sign">
                                        <li className="pt-0">
                                            That the Evidence of Insurability form, enrollment form, and all other required forms bearing my signature will be signed using an electronic signature which will be of the same legal effect, validity or enforceability as if signed using a pen and ink
                                        </li>
                                        <li>
                                            I have read the <a href="/#" onClick={(e) => this.downloafPDF(e)}>Evidence of Insurability form</a> and all other included forms, including my responses, and all disclosures
                                        </li>
                                        <li>
                                            That the Evidence of Insurability form is considered a part of the policy
                                            {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "NY" ? " and certificate " : " "}
                                            of group insurance through which I am applying for coverage
                                        </li>
                                        <li>
                                            That to the best of my knowledge and belief the answers and information I provided on the Evidence of Insurability form
                                            {_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") !== "NY" ? " and any other required " : " "}
                                            is true, accurate and complete
                                        </li>
                                        <li>
                                            To receive all required documents, including the Evidence of Insurability form, and any documents signed by me, at the email address that I have provided.
                                        </li>
                                    </ul>
                                       <hr />
                                       <div class="field-group">
                                           {_.get(this.props, "trustedFlag", "") === true ?
                                               <button className="btn btn-link" onClick={()=>this.saveForLater()}>
                                                   Save for later
                                               </button>
                                               :
                                               null
                                           }
                                         
                                           <button class="btn btn-secondary float-right"
                                               disabled={!this.state.child[this.state.visible].checkBoxValue}
                                               onClick={() => this.onSubmit()}>
                                               { this.state.childName.length -1 !== this.state.visible ?
                                                   "Sign & continue" : "Sign & submit"}
                                           </button>
                                       </div>
                                   </div>
                               </div>
   
   
                           </div>
                          :"" }
                       </main>
    }
               </>
           );
       }
    }


export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(eSignChild);
import React, { Component } from 'react';

class AlertRedBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideBanner : false
        }
    }

    handleClose = () => { 
        this.setState ({
            hideBanner : true
        })
    }

    render() {
        return (
            <> { this.state.hideBanner ? null  : 
                <div className="alert-banner error">
                    <button className="close hidden-text" onClick={this.handleClose}>
                        <i className="fas fa-times"> <span>Close</span> </i>
                    </button>
                    <span className="alert-msg"><i className="fas fa-ban"></i>An error occurred. Please try again</span>
                </div> }
            </>
        )
    }
}
export default AlertRedBanner



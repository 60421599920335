import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../../store/HomePage/HomePageAction';
import {chooseNextCondition, imagePath} from '../../../utils/sharedFunction';

class LetsTalkAbout extends Component {
    handleContinue = () => {
        chooseNextCondition(this.props)
        // if(this.props.eoiData && this.props.eoiData.healthQuestionInfo){
        // this.props.eoiData.healthQuestionInfo.letsTalkAbout = "COMPLETED"
        // //this.props.eoiData.healthQuestionStatus = "COMPLETED"
        // this.props.eoiData.healthQuestionInfo.moveOnStatus = "ACTIVE"
        // // this.props.eoiData.reviewStatus = "START"
        // this.props.history.push({
        //     pathname:'/eoi/move',
        //     state:{nextQ:2}
        // })
        // }
    }
    displayFullName = (fullName) => {
        //let name,first_name,last_name,middle_initial
        //console.log(this.state.conditionData.life_entities_dq.life_name.split('/ '))
        // if(this.state.conditionData && this.state.conditionData.life_entities_dq){

         let name =fullName.split(', ')
        //console.log(name)
        if(name.length ===3 || name.length ===2){
            let first_name = name[1] && name[1]
            // let last_name = name[0] && name[0]
            // let middle_initial = name[2] ? name[2]:null
            //return (first_name + " "+(middle_initial? (middle_initial+" "):"") + last_name)
            return first_name
        }
        else{
            return fullName
        }
        

        //}
    }
    render() {
        //console.log(this.props)
        return (
            <>
                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 v-align-center">
                                <div className="intro">
                                    <h3>Now, let’s learn about {this.props.location && this.props.location.state && this.props.location.state.name && this.displayFullName(this.props.location.state.name)}’s conditions</h3>
                                    <div className="text-center-mobile">
                                        <button className="btn btn-primary mt-4" onClick={()=>this.handleContinue()}>Continue</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 text-center">
                                <img src={this.props.location && this.props.location.state && imagePath(this.props.location.state.imgUrl)} className="illustration" alt="illustration" />
                            </div>
                        </div>

                    </div>

                </main>
            </>
        );
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(LetsTalkAbout);
//export default LetsTalkAbout;

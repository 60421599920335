import React, { Component } from "react";
import { Button } from "glic-ui-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/HomePage/HomePageAction";
import _ from "lodash";
import { scrollUp } from '../../utils/sharedFunction';


class ReviewHeightWeight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUpFirst: false,
            first_name: "",
            h_feet: "",
            h_inches: "",
            w_pounds: "",
            htwt_lifeId: "",
        }
    }

    componentDidMount() {
        scrollUp();
        //this.updateChevron();
        let feet = ""; let inches = ""; let weight = ""; let childCount = [];
        let lifeId = _.get(this.props, "lifeId")
        const check =  _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.source", "") === "GWDY"
        if (lifeId === _.get(this.props, "lifeId")) {
            let name = this.props.prefillData.eoi_data.health_question_info.life_entities[lifeId - 1].life_name.split(', ');
            let firstName = (check === true ? name.slice(-1)[0]:name[1])
            if (this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.first_name === firstName) {
                feet = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_feet", []);
                inches = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.height_inches", []);
                weight = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.weight", []);
            } else if (this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.first_name === firstName) {
                feet = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_feet", []);
                inches = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.height_inches", []);
                weight = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse.weight", []);
            }
            else {
                childCount = (this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child).length;
                for (let i = 0; i < childCount; i++) {
                    if (this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[i].first_name === firstName) {
                        feet = this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[i].height_feet;
                        inches = this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[i].height_inches;
                        weight = this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child[i].weight;
                    }
                }
            }
        }

        this.setState({
            h_feet: feet,
            h_inches: inches,
            w_pounds: weight,
            htwt_lifeId: lifeId
        })
    };

    updateChevron = () => {
        this.setState({ showUpSecond: this.props.isChevron });
    }
 
    collapse = (e, txt) => {
        let showUpFlag = false;
        if (txt === "two") {
            showUpFlag = this.state.showUpSecond
            if (showUpFlag === true) {
                this.setState({ showUpSecond: false });
               // this.props.chevron(false);
            }
            else {
                this.setState({ showUpSecond: true });
                //this.props.chevron(true);
            }
        }
    }

    editInfo = () => {
        // console.log("ChangeMyResponseClicked!", this.state.htwt_lifeId);
        let id = this.state.htwt_lifeId;
        this.props.history.push({
            pathname: `/eoi/heightAndweight/${ id }`,
            state: { "previousPage": "review" }
        })
    }


    render() {
        return (
            <>
                <div class="container">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="row">
                                <ul class="col-md-10 accordion" id="accordion">
                                    <li class="accordion-card light-gray-background pr-3 pl-2 remove-border remove-bmargin">
                                        <div class="accordion-card-header remove-border" id="headingOne">
                                            <p class="pr-5 pl-2 m-0">Your height and weight </p>
                                            <button aria-label="Your height and weight" class="close hidden-text align-chevron" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded={this.state.showUpSecond} aria-controls="collapseOne"
                                                onClick={(e) => this.collapse(e, "two")}>
                                                <i className={this.state.showUpSecond ? "fas fa-chevron-up" : "fas fa-chevron-down"}> <span className="fas fa-chevron-up">Collapse button</span></i>
                                            </button>
                                        </div>
                                    </li>
                                    <div class="ml-2">
                                        <div id="collapseOne"
                                            className={this.state.showUpSecond ? "collapse show" : "collapse"}
                                            aria-labelledby="headingOne"
                                            data-parent="#accordion">
                                            <div class="accordion-card-body mt-3">
                                                <div>
                                                    <p class="pr-5 pl-2 m-0"> Height </p>
                                                    <div class="flex-accordion-header ml-2 mb-3">
                                                        <p class="plum-shade font-weight-bold"> {this.state.h_feet} feet {this.state.h_inches} inches</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="pr-5 pl-2 m-0"> Weight </p>
                                                    <div class="flex-accordion-header ml-2 mb-3">
                                                        <p class="plum-shade font-weight-bold">{this.state.w_pounds} pounds</p>
                                                    </div>
                                                </div>
                                                <Button type="outlined" color="navy" className="mt-4 mb-3" onClick={this.editInfo}>
                                                    Change my response
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(ReviewHeightWeight);



import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../../store/HomePage/HomePageAction';
import {imagePath} from '../../../utils/sharedFunction';
import _ from 'lodash';

class DeclinedPage extends Component {
    downloadPDF = (e) => {
        e.preventDefault();
        this.props.downLoadPDFData(this.props.prefillData,true);
    }
    downloadApproveDecline = (e) => {
        e.preventDefault();
        this.props.downLoadApproveDecline(this.props.prefillData);
    }
    render() {
        return (
            <>

                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-8">
                                <div className="intro">
                                    <h3>Your application was declined.</h3>

                                    <h6 className="pt-2">
                                        What’s next?
                                    </h6>
                                    <p className="lead mt-3">We recommend you download your declination letter and your electronic Evidence of Insurability (eEOI) form.</p>

                                    <div className="field-group mt-5">
                                        <button className="btn btn-primary" onClick={(e) => this.downloadApproveDecline(e)}><i className="fa fa-download pr-2"></i>Declination letter</button>
                                        <button className="btn btn-primary" onClick={(e) => this.downloadPDF(e)}><i className="fa fa-download pr-2"></i> Your eEOI form </button>
                                    </div>
                                </div>
                                {((_.get(this.props,"prefillData.eoi_data.workday_group_ind","") === "Y") || (_.get(this.props,"groupInfoResponse.eoi_data.workday_group_ind","") === "Y") ) ? "":
                                <>                              
                                <div className="data-box">
                                    <h6>Stay connected anywhere you go</h6>
                                    <p className="lead">Use <a href="https://www.guardiananytime.com" target="_blank" rel="noopener noreferrer">Guardian Anytime</a> to file and check claims, view your benefits, and access your ID card.</p>
                                </div>
                                <h6 className="pt-3">Thank you for choosing Guardian!</h6>
                                </>}
                                {/* <button class="btn btn-secondary mt-4" onclick="">Return to Plansource</button> */}
                            </div>
                        </div>
                    </div>
                    <img src={imagePath("./images/landingpage-illustration.svg")} className="illustration-bg" alt="illustration-bg" />
                </main>
            </>
        )
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(DeclinedPage);

import React, { Component } from "react";
import { Button } from "glic-ui-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/HomePage/HomePageAction";
import _ from "lodash";
import { scrollUp} from '../../utils/sharedFunction';
// import { TextLink } from "glic-ui-components";

class ReviewKnockOutQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUpFirst: false,
            OtherKnockOutQ: [],
            CriticalKnockOutQ: [],
            CancerKnockOutQ: [],
            first_name: "",
            other_life_entities: [],
            critical_life_entities: [],
            cancer_life_entities: [],
            heading:"",
            coveragesElected:[]
        }
    }

    componentDidMount() {
      scrollUp();
      // let knockOutQuestions = _.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question", []);
      let criticalKnockOutQ =  _.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.critical_illness_knockout_question", []);
      let cancerKnockOutQ =  _.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.cancer_knockout_question", []);
      let otherKnockOutQ =  _.get(this.props, "prefillData.eoi_data.health_question_info.knockout_question.other_knockout_question", []);
      let hasKnockOutQuestions = _.get(this.props, "prefillData.eoi_data.health_question_info.has_knockout_question", []);
      let otherLife = [];
      let criticalLife = [];
      let cancerLife = [];
      let firstName = "";
      let otherlifeEntities = [];
      let criticallifeEntities = [];
      let cancerlifeEntities = [];
      let lifeId = _.get(this.props, "lifeId")


        if(hasKnockOutQuestions === true){
          otherKnockOutQ && otherKnockOutQ.map(other=>{
            other.life_entities_kq.map(ol => {
              if(ol.knock_out_answer === true || ol.knock_out_answer === false ){
                otherLife.knock_out_answer = String(ol.knock_out_answer)
              }
             
              return otherLife.push(ol)
            })
            return other
          })

          criticalKnockOutQ && criticalKnockOutQ.map(critical=>{
            critical.life_entities_kq.map(cl => {
              if(cl.knock_out_answer === true || cl.knock_out_answer === false ){
                cl.knock_out_answer = String(cl.knock_out_answer)
              }
              return criticalLife.push(cl)
            })
            return critical
          })   
          
          cancerKnockOutQ && cancerKnockOutQ.map(cancer=>{
            cancer.life_entities_kq.map(ca => {
              if(ca.knock_out_answer === true || ca.knock_out_answer === false ){
                ca.knock_out_answer = String(ca.knock_out_answer)
              }
              return cancerLife.push(ca)
            })
            return cancer
          })  
        }

      if (lifeId === _.get(this.props, "lifeId")) {
        let name = this.props.prefillData.eoi_data.health_question_info.life_entities[lifeId - 1].life_name.split(', ');
        const check =  _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.source", "") === "GWDY"
        firstName = check === true ? name.slice(-1) : name[1];
        criticallifeEntities = criticalLife.filter(function (el) {
          return el.life_id === lifeId
        });
        // console.log(criticallifeEntities, "CRITICAL")
        cancerlifeEntities = cancerLife.filter(function (el) {
          return el.life_id === lifeId
        });
        // console.log(cancerlifeEntities)
        otherlifeEntities = otherLife.filter(function (el) {
          return el.life_id === lifeId
        });
        // console.log(otherlifeEntities, "OTHER")
      }     
      const coverages = JSON.parse(JSON.stringify(_.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections",{})))
      const disabilityCoverages = ["short_term_disability", "long_term_disability", "voluntary_long_term_disability", "voluntary_short_term_disability" ]
      let coverageElected = []
      let allEmployeeStatus = []
      let allSpouseStatus = []
      let allChildrenStatus = []
      for (const coverage in coverages){
        let disabilityCoverageElectedStatus = false
        let employeeCoveredStatus = false
        let spouseCoveredStatus = false
        let childCoveredStatus = false
        if(disabilityCoverages.includes(coverage)){           
            disabilityCoverageElectedStatus = coverages[coverage] && coverages[coverage].elected && coverages[coverage].elected
        }
        else{
             employeeCoveredStatus = coverages[coverage] && coverages[coverage].employee_covered && coverages[coverage].employee_covered
             spouseCoveredStatus = coverages[coverage] && coverages[coverage].spouse_covered && coverages[coverage].spouse_covered
             childCoveredStatus = coverages[coverage] && coverages[coverage].child_covered && coverages[coverage].child_covered 
        }
        if(employeeCoveredStatus === true || spouseCoveredStatus === true || childCoveredStatus ===true || disabilityCoverageElectedStatus === true){
            coverageElected.push(coverage)
        }
       coverageElected.map((elect,index) =>{
       if(elect === "voluntary_critical_illness" ||  coverage === "basic_critical_illness" ){ 
            if(elect  !== "voluntary_critical_illness"){
             this.setState ({heading :'Specified Disease '})
            }
          }
          return elect;
        })
        allEmployeeStatus.push(employeeCoveredStatus)
        allEmployeeStatus.push(disabilityCoverageElectedStatus)
        allSpouseStatus.push(spouseCoveredStatus)
        allChildrenStatus.push(childCoveredStatus)
    }
    this.setState({
      coveragesElected : coverageElected
    })

    this.setState({
      OtherKnockOutQ: otherKnockOutQ, CriticalKnockOutQ: criticalKnockOutQ, CancerKnockOutQ: cancerKnockOutQ, first_name: firstName,
      other_life_entities: otherlifeEntities, critical_life_entities: criticallifeEntities, 
      cancer_life_entities: cancerlifeEntities
    })
  };

  editInfo =(e,txt)=>{
    let lifeId = _.get(this.props, "lifeId")
    if(txt === "critical")
    {
      this.props.history.push({
      pathname: `/eoi/criticalKnockOutQ/${lifeId}`,
      state: {"previousPage":"review", "lifeId": lifeId}})
    }
    else if(txt === "cancer")
    {
      this.props.history.push({
      pathname: `/eoi/cancerKnockOutQ/${lifeId}`,
      state: {"previousPage":"review",  "lifeId": lifeId}})
    }
    else if( txt === "other")
    {
      this.props.history.push({
      pathname: `/eoi/otherKnockOutQ/${lifeId}`,
      state: {"previousPage":"review",  "lifeId": lifeId}})
    }
  }
    

    collapse = (e, txt) => {
        let showUpFlag = false;
        if (txt === "one") {
            showUpFlag = this.state.showUpFirst
            if (showUpFlag === true) {
                this.setState({ showUpFirst: false });
            }
            else {
                this.setState({ showUpFirst: true });
            }
        }
        if (txt === "two") {
          showUpFlag = this.state.showUpSecond
          if (showUpFlag === true) {
              this.setState({ showUpSecond: false });
          }
          else {
              this.setState({ showUpSecond: true });
          }
      }
      if (txt === "three") {
        showUpFlag = this.state.showUpThird
        if (showUpFlag === true) {
            this.setState({ showUpThird: false });
        }
        else {
            this.setState({ showUpThird: true });
        }
      }
    }

  render() {
    return (
      <>

        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <h3 class="pt-5 pb-4">{this.state.first_name}’s health history</h3>
            </div>
          </div>
        </div>
        {this.state.critical_life_entities.length > 0 ?
        <div class="container remove-tmargin" >
          <div class="row">
            <div class="col-md-10">
              <div class="row">
                <ul class="col-md-10 accordion" id="accordion">
                  <li class="accordion-card light-gray-background pr-3 pl-2 remove-border remove-bmargin">
                    <div
                      class="accordion-card-header remove-border"
                      id="headingOne"
                    >
                      <p class="pr-5 pl-2 m-0">{_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") !== "NY" ? <strong>Critical Illness </strong> : this.state.heading === '' ? <strong>Voluntary Specified Disease </strong> : <strong>{this.state.heading}</strong> }health questions</p>
                            <button
                              className="close hidden-text align-chevron"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                              onClick={(e) => this.collapse(e, "one")}
                            >
                         <i className={this.state.showUpFirst ? "fas fa-chevron-up" : "fas fa-chevron-down"}> <span className="fas fa-chevron-up">Collapse button</span> </i>
                      </button>
                    </div>
                    </li>
                    <div class="ml-2">
                    <div id="collapseOne" className={this.state.showUpFirst ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordion">
                    
                            <div class="accordion-card-body mt-3">
                              <div>
                                {this.state.CriticalKnockOutQ && this.state.CriticalKnockOutQ.map((criticalq, index) => (
                                  <>
                                    <p class="pr-5 pl-2 m-0">
                                      {criticalq.question_desc}
                                    </p>

                                    <div class="flex-accordion-header ml-2 mb-3">
                                      <i class="fas fa-check align-fa-check plum-shade"></i>
                                      <p class="plum-shade font-weight-bold">{this.state.critical_life_entities[index].knock_out_answer && this.state.critical_life_entities[index].knock_out_answer.includes("true") ? "Yes" : "No"}</p>                                    </div>

                                  </>
                                ))}
                              </div>
                        <Button
                          type="outlined"
                          color="navy"
                          className="mt-4 mb-3"
              onClick={(e) => this.editInfo(e,"critical")}
                        >
                          Change my response
                        </Button>
                      </div>
                      </div>
                    </div>
                   
              
                </ul>
              </div>
            </div>
          </div>
        </div> : null }

            {this.state.cancer_life_entities.length > 0 ?
              <div class="container">
                <div class="row">
                  <div class="col-md-10">
                    <div class="row">
                      <ul class="col-md-10 accordion" id="accordion">
                        <li class="accordion-card light-gray-background pr-3 pl-2 remove-border remove-bmargin">
                          <div
                            class="accordion-card-header remove-border"
                            id="headingOne"
                          >
                            {this.state.cancer_life_entities.length > 1 ?
                              <p class="pr-5 pl-2 m-0"><strong>Cancer</strong> health questions</p> :
                              <div>
                                {this.state.CancerKnockOutQ && this.state.CancerKnockOutQ.map((cancerq, index) => (
                                  <p class="pr-5 pl-2 m-0">{cancerq.question_desc}</p>))}
                              </div>
                            }
                            <button
                              class="close hidden-text align-chevron"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="true"
                              aria-controls="collapseTwo"
                              onClick={(e) => this.collapse(e, "two")}
                            >
                              <i className={this.state.showUpSecond ? "fas fa-chevron-up" : "fas fa-chevron-down"}> <span className="fas fa-chevron-up">Collapse button</span> </i>
                            </button>
                          </div>
                        </li>
                        <div class="ml-2">
                        <div
                          id="collapseOne"
                          className={this.state.showUpSecond ? "collapse show" : "collapse"}
                          aria-labelledby="headingTwo"
                          data-parent="#accordion"
                        >

                          <div class="accordion-card-body mt-3">
                            {this.state.CancerKnockOutQ && this.state.CancerKnockOutQ.map((cancerq, index) => (
                              <div>
                                {this.state.cancer_life_entities.length > 1 ?

                                  <p class="pr-5 pl-2 m-0">
                                    {cancerq.question_desc}
                                  </p> : null}
                                <div class="flex-accordion-header ml-2 mb-3">
                                  <i class="fas fa-check align-fa-check plum-shade"></i>
                                  <p class="plum-shade font-weight-bold">{this.state.cancer_life_entities[index].knock_out_answer && this.state.cancer_life_entities[index].knock_out_answer === "true" ? "Yes" : "No"}</p>
                                </div>
                              </div>))}

                            <Button
                              type="outlined"
                              color="navy"
                              className="mt-4 mb-3"
                            onClick={(e) => this.editInfo(e,"cancer")}
                            >
                              Change my response
                            </Button>
                          </div>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> : null}
  
        {this.state.other_life_entities.length > 0 ?
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              <div class="row">
                <ul class="col-md-10 accordion" id="accordion">
                  <li class="accordion-card light-gray-background pr-3 pl-2 remove-border remove-bmargin">
                    <div
                      class="accordion-card-header remove-border"
                      id="headingOne"
                    >
                            {this.state.other_life_entities.length > 1 ?
                              <p class="pr-5 pl-2 m-0"><strong>Voluntary Life</strong> health questions</p> :
                              <div>
                                {this.state.OtherKnockOutQ && this.state.OtherKnockOutQ.map((otherq, index) => (
                                  <p class="pr-5 pl-2 m-0">{otherq.question_desc}
                                  </p>))}
                              </div>
                            }
                      <button
                        class="close hidden-text align-chevron"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                        onClick={(e) => this.collapse(e, "three")}
                      >
                                <i className={this.state.showUpThird ? "fas fa-chevron-up" : "fas fa-chevron-down"}> <span className="fas fa-chevron-up">Collapse button</span> </i>
                      </button>
                    </div>
                    </li>
                    <div class="ml-2">
                    <div id="collapseThree" className={this.state.showUpThird ? "collapse show" : "collapse"} aria-labelledby="headingThree" data-parent="#accordion">
                      <div class="accordion-card-body mt-3">
                      {this.state.OtherKnockOutQ && this.state.OtherKnockOutQ.map((otherq, index) => (
                              <div>
                                {this.state.other_life_entities.length > 1 ?

                                  <p class="pr-5 pl-2 m-0">
                                    {otherq.question_desc}
                                  </p> : null}
                                <div class="flex-accordion-header ml-2">
                                  <i class="fas fa-check align-fa-check plum-shade"></i>
                                  <p class="plum-shade font-weight-bold">{this.state.other_life_entities[index].knock_out_answer && this.state.other_life_entities[index].knock_out_answer === true ? "Yes" : "No"}</p>
                                </div>
                              </div>))}
  
                        <Button
                          type="outlined"
                          color="navy"
                          className="mt-4 mb-3"
onClick={(e) => this.editInfo(e,"other")}
                        >
                          Change my response
                        </Button>
                        </div>
                      </div>
                    </div>
               
                </ul>
              </div>
            </div>
          </div>
        </div> : null }
  
        {/* <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-4">
              <div class="field-group">
  
                <button class="btn btn-primary" onclick="">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div> */}
  

    </>
    );
  }
}
export default connect(
  (state) => state.eoiStore,
  (dispatch) => bindActionCreators(actionCreators, dispatch))(ReviewKnockOutQ);



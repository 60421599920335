import React, { Component } from "react";

import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actionCreators } from "../../../store/HomePage/HomePageAction";
import { scrollUp } from "../../../utils/sharedFunction";
import HealthFamilyQ from "./HealthFamilyQ";
import HealthMemberQ from "./HealthMemberQ";
import NoConditionFamilyQ from "./NoConditionFamilyQ";
import NoConditionMemberQ from "./NoConditionMemberQ";

class AllQuestions extends Component {
    componentDidMount () {
        document.title = "Health History - Questions | Group EOI - Guardian";

        scrollUp()
        // console.log(this.props.activeQuestion)
        if(_.get(this.props, "prefillData.eoi_data.health_question_info.base_question_status","")!=="COMPLETED"){
            _.set(this.props, "prefillData.eoi_data.health_question_info.base_question_status","ACTIVE")
            _.set(this.props, "prefillData.eoi_data.health_question_info.active_question_status",this.props.activeQuestion)
            if(this.props.trustedFlag === true){
                this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
            }
        }
    }
    componentDidUpdate (prevProps) {
        scrollUp()
        if(prevProps.match.params !== this.props.match.params){
            // console.log(this.props.activeQuestion)
            if(_.get(this.props, "prefillData.eoi_data.health_question_info.base_question_status","")!=="COMPLETED"){
                _.set(this.props, "prefillData.eoi_data.health_question_info.base_question_status","ACTIVE")
                _.set(this.props, "prefillData.eoi_data.health_question_info.active_question_status",this.props.activeQuestion)
                if(this.props.trustedFlag === true){
                    this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
                }
            }
        }
    }
    
    render() {
        return (
            <>
            {this.props.location.state.page ==="memberOnly"?
               <HealthMemberQ {...this.props}/>
               : 
               this.props.location.state.page === "family"?
               <HealthFamilyQ {...this.props}/>
               :
               this.props.location.state.page === "noConditionMemberOnly"?
               <NoConditionMemberQ {...this.props}/>
               :
               this.props.location.state.page === "noConditionFamily"?
               <NoConditionFamilyQ {...this.props} />
               :
                null
            }
            </>
        );
    }
}

// export default AllQuestions;
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(AllQuestions);
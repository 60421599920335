import "date-fns";

import React, { Component } from "react";

import _ from "lodash";
import moment from "moment";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DateFnsUtils from "@date-io/date-fns";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import AlertBanner from "../../components/AlertBanner/AlertBanner";
import { actionCreators } from "../../store/HomePage/HomePageAction";
import { scrollUp } from "../../utils/sharedFunction";
import CheckPopup from "../../components/CheckPopUp/CheckPopUp";

let showCityDetails = "NO";

class TellUsMore extends Component {

    constructor(props) {
        super(props);
        this.state = {
            changeFlag: false,
            dob: null,
            calendarError: false,
            blurError: false,
            wasOpened: false,
            alphaNum: /^[A-Za-z0-9 ]+$/i,
            alpha: /^[A-Za-z ]+$/i,
            numeric: /^[0-9]+$/i,
            //stateCode : "",
            //gender : "",
            first_name: "",
            last_name: "",
            actualSsn: "",
            maskedSsn: "",
            zip_code: "",
            middle_initial: "",
            address_line1: "",
            address_line2: "",
            isOpen: false,
            isSelect: false
        }

        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event) {
        const prevValue = _.get(this.state, "selfInfo.maskedSsn", []);
        const newValue = event.target.value;
        let actual = _.get(this.state, "selfInfo.actualSsn", "");

        if (newValue.length > 11) {
            return;
        }

        // typing forward
        if (newValue.length > prevValue.length) {
            let newChar = newValue.split("").pop();

            actual = `${actual}${newChar}`;
        }
        // backspacing / deleting
        else {
            const charsRemovedCount = prevValue.length - newValue.length;

            actual = actual.substr(0, actual.length - charsRemovedCount);
        }

        if (actual === '' || this.state.numeric.test(actual)) {

            //this.setState({selfInfo});
            var selfInfo = { ...this.state.selfInfo };
            selfInfo["actualSsn"] = actual;
            selfInfo["maskedSsn"] = this.starredMask("mask", actual);
            selfInfo["numberedSsn"] = this.starredMask("number", actual);
            this.setState({ selfInfo });
        }

        //this.props.change(`owner_${this.props.match.params.idx}_ssn`, actual);
    }


    starredMask(formatType, ssn, showLast4 = true) {
        // replace numeric digits with * asterisk
        let valueHidden = "";
        if (formatType === "mask") {
            valueHidden = ssn.replace(/[\d]/g, "*");
        } else if (formatType === "number") {
            valueHidden = ssn;
        }

        if (valueHidden.length <= 3) {
            return valueHidden;
        }

        if (valueHidden.length <= 5) {
            return valueHidden.slice(0, 3) + "-" + valueHidden.slice(3, 5);
        }

        if (showLast4) {
            return (
                valueHidden.slice(0, 3) +
                "-" +
                valueHidden.slice(3, 5) +
                "-" +
                valueHidden.slice(5, 7) +
                "" +
                ssn.substr(7)
            );
        } else {
            return (
                valueHidden.slice(0, 3) +
                "-" +
                valueHidden.slice(3, 5) +
                "-" +
                valueHidden.slice(5, 9)
            );
        }
    }

    componentDidMount = () => {
        document.title = "Tell us about yourself | Group EOI - Guardian"
        document.getElementsByClassName('showHide')[0].style.display = 'none';
        document.getElementsByClassName('secondaryradioHide')[0].style.display = 'none';
        document.getElementsByClassName('showbanner')[0].style.display = 'none';
        scrollUp();
        //console.log("mount");
        this.props.saveBooleanFun(); //Dummy prop change

        var selfInfo = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", {});

        
        // console.log("selfInfo", selfInfo);
        let ssn1 = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ssn1", "");
        let ssn2 = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ssn2", "");
        let ssn3 = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ssn3", "");

        if (ssn1 !== "") {
            selfInfo["actualSsn"] = ssn1.concat(ssn2).concat(ssn3);
            selfInfo["maskedSsn"] = "***-**-**".concat(ssn3.substr(2, 2));
            selfInfo["numberedSsn"] = ssn1 + "-" + ssn2 + "-" + ssn3;
        }

        selfInfo["secondaryPhoneType"] = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.secondary_phone_type", "");
        selfInfo["primaryPhoneType"] = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.primary_phone_type", "");

        const employee = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", "");

        if (employee.primary_phone_type === "H") {
            selfInfo["primaryPhone"] = employee.home_phone1 + employee.home_phone2 + employee.home_phone3;
        }
        if (employee.primary_phone_type === "C") {
            selfInfo["primaryPhone"] = employee.cell_phone1 + employee.cell_phone2 + employee.cell_phone3;
        }
        if (employee.secondary_phone_type === "H") {
            selfInfo["secondaryPhone"] = employee.home_phone1 + employee.home_phone2 + employee.home_phone3;
        }
        if (employee.secondary_phone_type === "C") {
            selfInfo["secondaryPhone"] = employee.cell_phone1 + employee.cell_phone2 + employee.cell_phone3;
        }
        let dob = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.dob", null);

        //moment(childData.dob).format('MM/DD/YYYY')
        if (dob !== null) {
            selfInfo["dob"] = moment(dob).format('MM/DD/YYYY');
        }
        this.setState({ selfInfo });

    }

    onBack = () => {
        _.set(this.props, "statecityCodes.state_province_code", "");
        _.set(this.props, "statecityCodes.city.city_name", []);
        _.set(this.props, "statecityCodes.cityFlag", null);
        _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", {});
        let previousURL = _.get(this.props, "location.state.previousPage")
        // console.log("previousURL",previousURL)

        if (previousURL === "review") {
            this.props.history.push(`/eoi/review`)
        } else {
            this.props.history.push(`/eoi/homePage`)
        }

    }
    handlePopUp = () => {
        this.setState({ isSelect: true, isOpen: false });
    }

    handleDateChange = (event) => {
        //console.log("this.state.calendarError", this.state.calendarError, event.target.value);
        //console.log("event.target.value ["+event.target.value+"]tt["+JSON.stringify(event.target.value)+"]");
        var selfInfo = { ...this.state.selfInfo };
        //console.log("event.target.value", event.target.value);

        if (event.target.value.length === 0) {
            selfInfo["dob"] = event.target.value
            this.setState({
                selfInfo
            })
        }
        if (this.state.calendarError === false) {
            selfInfo["dob"] = event.target.value
            this.setState({
                selfInfo
            })
        }
        if (this.state.calendarError === true && event.target.value.length !== 0) {
            selfInfo["dob"] = null;
            this.setState({
                selfInfo
            })
            this.setState({
                blurError: true

            })
        }
        else {
            this.setState({
                blurError: false
            })
        }
        let currentDate = new Date();
        let givenDate = new Date(event.target.value);
        let yearDiff = currentDate.getFullYear() - givenDate.getFullYear();
        //console.log("currentDate", currentDate, new Date(event.target.value));
        //console.log(Number(currentDate),  Number(givenDate));
        if (Number(currentDate) < Number(givenDate) || yearDiff >= 100) {
            //console.log("in if");
            this.setState({
                blurError: true

            })
        }

        if (event.target.value) this.setState({ dobReq: false })

    }

    changeEvent = (date) => {
        var selfInfo = { ...this.state.selfInfo };
        if (this.state.wasOpened === true) {
            selfInfo["dob"] = moment(date).format('MM/DD/YYYY');
            this.setState({
                selfInfo
            })
            if (this.state.blurError === true) {
                this.setState({
                    blurError: false
                })
            }
        }
        // if(this.state.calendarError === false){
        //     if(e.target.value && this.state.dobError === true){
        //         this.setState({dobReq:""})
        //     }
        // }

        //console.log("JSON.stringify(date)", JSON.stringify(date));
        if (JSON.stringify(date) === null || JSON.stringify(date) === "null") {
            this.setState({
                calendarError: true
            })
        }
        else {
            this.setState({
                calendarError: false,
                dobReq: ""
            })
        }
    }
    // handleOnOpen = () => {
    //     this.setState({
    //         wasOpened:true
    //     })
    // }
    handleOnClose = () => {
        this.setState({
            wasOpened: false
        })
    }

    changeTextObjectFields = (e, objectName, regEx) => {
        var selfInfo = { ...this.state.selfInfo };
        //console.log("pageElepageEle", selfInfo);
        if (e.target.value === '' || regEx.test(e.target.value)) {

            selfInfo[objectName] = e.target.value;
            //console.log("element",pageEle);
            //console.log("pageElepageEle", selfInfo);
            this.setState({ selfInfo });
        }
    }

    handlePhone = (values) => {
        var selfInfo = { ...this.state.selfInfo };
        selfInfo["primaryPhone"] = values.value;
        this.setState({
            selfInfo
        })
    }

    handleSecPhone = (values) => {
        //console.log(values.value.trim().length);
        if (values.value.trim().length > 0) {
            document.getElementsByClassName('showHide')[0].style.display = 'block';
            document.getElementsByClassName('secondaryradioHide')[0].style.display = 'block';

        } else {
            document.getElementsByClassName('showHide')[0].style.display = 'none';
            document.getElementsByClassName('secondaryradioHide')[0].style.display = 'none';
        }

        var selfInfo = { ...this.state.selfInfo };
        selfInfo["secondaryPhone"] = values.value;
        this.setState({
            selfInfo
        })
    }

    handleEmail = (e) => {
        var selfInfo = { ...this.state.selfInfo };
        selfInfo["email_address"] = e.target.value;
        this.setState({ selfInfo });
    }

    onSubmit = (e) => {

        this.setState({
            ssnReq: '',
            ssnLength: '',
            firstNameReq: '',
            lastNameReq: '',
            dobReq: "",
            genderReq: '',
            zipReq: '',
            zipFormat: '',
            addressReq: '',
            primaryphoneLength: '',
            primaryphoneReq: '',
            primarycheckBox: '',
            secondaryPhoneLength: '',
            secondarycheckBox: '',
            emailRequired: '',
            emailInvalid: '',
            preferredContact: ''
        });

        let ssnLength = _.get(this.state, "selfInfo.maskedSsn", "");
        let actualssn = _.get(this.state, "selfInfo.actualSsn", "");
        let zipReq = _.get(this.state, "selfInfo.zip_code", "");

        let priPhoneLength = _.get(this.state, "selfInfo.primaryPhone", "").trim().length;
        let secPhoneLength = _.get(this.state, "selfInfo.secondaryPhone", "").trim().length;
        let emailLength = _.get(this.state, "selfInfo.email_address", "").trim().length;
        let showError = false;
        if (this.state.blurError) {
            showError = true;
        }
        //zip validation
        if (_.get(this.props, "statecityCodes.cityFlag", "") === "N") {
            showError = true;
        }
        if (zipReq.trim().length <= 0) {
            this.setState({ "zipReq": "Zip is required" });
            showError = true;
        } else if (this.state.zipCodeCountry === "US") {
            var reg = /^\d{5}([-])?(\d{4})?$/gm;
            if (zipReq.trim().length < 5 || !reg.test(zipReq.trim())) {
                this.setState({ "zipFormat": "Zip format is not correct. Try again" });
                showError = true;
            }

        } else if (this.state.zipCodeCountry === "CAN") {
            reg = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            if (zipReq.trim().length < 6 || !reg.test(zipReq.trim())) {
                this.setState({ "zipFormat": "Zip format is not correct. Try again" });
                showError = true;
            }
        }
        //ssn validation

        if (ssnLength.trim().length <= 0) {
            this.setState({ "ssnReq": "SSN is required" });
            showError = true;
        } else if (actualssn.trim().length < 9) {
            this.setState({ "ssnLength": "SSN must be 9 digits" });
            showError = true;
        }

        //email validation
        if (_.get(this.props, "isEmailValid", "") === false) {
            showError = true;
        }
        if (emailLength > 0) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(_.get(this.state, "selfInfo.email_address", "").trim())) {
                //console.log("in  valid");
                this.setState({ "emailInvalid": "Email format is example@domain.com" });
                showError = true;
            }
        } else {
            this.setState({ "emailRequired": "Email address is required" });
            showError = true;
        }

        //preferred Contact
        let preferredContact = document.getElementsByName('contact-phone'), prefcheckedFlag = false;
        for (let i = 0; i < preferredContact.length; i++) {
            //console.log("preferredContact[i].checked", preferredContact[i].checked);
            if (preferredContact[i].checked) {
                prefcheckedFlag = true;
            }
        }
        if (!prefcheckedFlag) {
            this.setState({ "preferredContact": "Select preferred contact method" });
            showError = true;
        }
        //Secondary Mobile
        //let seccheckedFlag = false;
        if (secPhoneLength > 0) {
            let checkboxes = document.getElementsByName('secondary-phone');
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    // seccheckedFlag = true;
                }
            }
            // console.log(seccheckedFlag);
            if (secPhoneLength < 10) {
                this.setState({ "secondaryPhoneLength": "Secondary phone must be 10 digits" });
                showError = true;
            }
            // else if(secPhoneLength === 10) {
            //     if(!seccheckedFlag) {
            //         this.setState({"secondarycheckBox" : "Specify type of phone numer"});
            //         showError = true;
            //     }
            // }

        }

        //Gender
        let genderLength = _.get(this.state, "selfInfo.gender", "").trim().length;
        if (genderLength <= 0 || genderLength === undefined) {
            this.setState({ "genderReq": "Gender is required" });
            showError = true;
        }

        //first_name validation
        let first_name = _.get(this.state, "selfInfo.first_name", "");
        if (first_name.trim().length <= 0) {
            this.setState({ "firstNameReq": "First name is required" });
            showError = true;
        }

        let last_name = _.get(this.state, "selfInfo.last_name", "");
        if (last_name.trim().length <= 0) {
            this.setState({ "lastNameReq": "Last name is required" });
            showError = true;
        }
        //dob
        let dob = _.get(this.state, "selfInfo.dob", "");
        if (dob === null || dob.length <= 0) {
            this.setState({ "dobReq": "Date of birth is required" });
            showError = true;
        }

        //address validation
        let address_line1 = _.get(this.state, "selfInfo.address_line1", "");
        //console.log("addressaddress", address, address.trim().length);
        if (address_line1.trim().length <= 0) {
            this.setState({ "addressReq": "Address is required" });
            showError = true;
        }

        //primary Mobile
        if (priPhoneLength > 0) {
            //home or mobile is mandatory
            let checkboxes = document.getElementsByName('primary-phone'), checkedFlag = false;
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].checked) {
                    checkedFlag = true;
                }
            }

            if (priPhoneLength < 10) {
                this.setState({ "primaryphoneLength": "Primary phone must be 10 digits" });
                showError = true;
            } else if (priPhoneLength === 10) {
                if (!checkedFlag) {
                    this.setState({ "primarycheckBox": "Specify type of phone number" });
                    showError = true;
                }
            }
        } else {
            this.setState({ "primaryphoneReq": "Primary phone is required" });
            showError = true;
        }

        //console.log("showError", showError);
        if (showError === true) {
            document.getElementsByClassName('showbanner')[0].style.display = 'block';
            window.scrollTo(0, 0);
        } else {
            //_.set(this.props,"nonTrustedData.add_benefit_coverage_info.tell_us_more_status","COMPLETED")
            //_.set(this.props,"nonTrustedData.add_benefit_coverage_info.who_are_we_covering_status","ACTIVE")
            // _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.maskedSsn", this.state.selfInfo.maskedSsn);
            // _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.numberedSsn", this.state.selfInfo.numberedSsn);
            // _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.actualSsn", this.state.selfInfo.actualSsn);

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ssn1", this.state.selfInfo.actualSsn.substring(0, 3));
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ssn2", this.state.selfInfo.actualSsn.substring(3, 5));
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ssn3", this.state.selfInfo.actualSsn.substring(5, 9));

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.first_name", this.state.selfInfo.first_name);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.middle_initial", this.state.selfInfo.middle_initial);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.last_name", this.state.selfInfo.last_name);


            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.dob", moment(this.state.selfInfo.dob).format('YYYY-MM-DD'));
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.state", _.get(this.props, "statecityCodes.state_province_code", ""));
            // _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.state","MD");

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.gender", this.state.selfInfo.gender);

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.city", document.getElementById('eoi-city').value);
            //_.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.city", "BALTIMORE");

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.address_line1", this.state.selfInfo.address_line1);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.address_line2", this.state.selfInfo.address_line2);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip_code", this.state.selfInfo.zip_code);
            //_.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip_code", "21201");

            //_.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.primaryPhone",this.state.selfInfo.primaryPhone );
            //_.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.secondaryPhone",this.state.selfInfo.secondaryPhone );
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.email_address", this.state.selfInfo.email_address);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.contact_method", this.state.selfInfo.contact_method);


            if (this.state.selfInfo.primaryPhoneType && this.state.selfInfo.primaryPhoneType === "H") {
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone1", this.state.selfInfo.primaryPhone && this.state.selfInfo.primaryPhone.substring(0, 3))
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone2", this.state.selfInfo.primaryPhone && this.state.selfInfo.primaryPhone.substring(3, 6))
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone3", this.state.selfInfo.primaryPhone && this.state.selfInfo.primaryPhone.substring(6, 10))
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.primary_phone_type", this.state.selfInfo.primaryPhoneType)

            }
            if (this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhoneType === "H") {
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone1", this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhone.substring(0, 3))
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone2", this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhone.substring(3, 6))
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone3", this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhone.substring(6, 10))
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.secondary_phone_type", this.state.selfInfo.secondaryPhoneType)

            }
            if (this.state.selfInfo.primaryPhoneType && this.state.selfInfo.primaryPhoneType === "C") {

                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone1", this.state.selfInfo.primaryPhone && this.state.selfInfo.primaryPhone.substring(0, 3))
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone2", this.state.selfInfo.primaryPhone && this.state.selfInfo.primaryPhone.substring(3, 6))
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone3", this.state.selfInfo.primaryPhone && this.state.selfInfo.primaryPhone.substring(6, 10))
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.primary_phone_type", this.state.selfInfo.primaryPhoneType)

            }
            if (this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhoneType === "C") {
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone1", this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhone.substring(0, 3))
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone2", this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhone.substring(3, 6))
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone3", this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhone.substring(6, 10))
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.secondary_phone_type", this.state.selfInfo.secondaryPhoneType)

            }



            //_.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.", );


            let previousURL = _.get(this.props, "location.state.previousPage")
            // console.log("previousURL",previousURL)

            if (previousURL === "review") {
                this.props.history.push('/eoi/whoAreWeCovering')
            }
            else {
                this.setState({ isOpen: true })
                setTimeout(() => {
                    const el = document.getElementById("submit-button")
                    if (el) {
                        el.focus();
                    }
                }, 500);
                if (this.state.isSelect === true) {
                    this.props.history.push('/eoi/whoAreWeCovering')
                }
            }
        }


    }

    genderChange = (event) => {
        var selfInfo = { ...this.state.selfInfo };
        selfInfo["gender"] = event.target.value;
        this.setState({ selfInfo })
    }

    onChangeName = (event, label) => {
        var selfInfo = { ...this.state.selfInfo };
        selfInfo[label] = event.target.value;
        this.setState({ selfInfo })

    }

    handleChangeinssn = (event, b = true) => {
        let ssn = event.target.value;
        let valueHidden = ssn.replace(/[\d]/g, "*");

        let a = "";
        if (valueHidden.length <= 3) {
            a = valueHidden;
        }

        if (valueHidden.length <= 5) {
            //console.log("hh", this.state.zipCodeCountry);
            a = valueHidden.slice(0, 3) + "-" + valueHidden.slice(3, 5);
        }


        if (b) {
            a = valueHidden.slice(0, 3) +
                "-" +
                valueHidden.slice(3, 5) +
                "-" +
                valueHidden.slice(5, 7) +
                "" +
                ssn.substr(7)
        } else {
            a = valueHidden.slice(0, 3) +
                "-" +
                valueHidden.slice(3, 5) +
                "-" +
                valueHidden.slice(5, 9)
        }
        this.setState({ "ssn": a });
    }

    blurZip = (event) => {
        // console.log(this.state.zipCodeCountry,event.target.value.trim().length)
        if (event.target.value && event.target.value.trim().length > 0) {
            this.setState({ "zipReq": "", "zipFormat": "" });
        }
        // if(event.target.value && event.target.value.trim().length === 5 && this.state.zipCodeCountry !== "US" && this.state.zipCodeCountry !== "CAN"){
        //     this.props.getStateandCity(event.target.value);
        // }
        if (this.state.zipCodeCountry === "US") {
            var reg = /^\d{5}([-])?(\d{4})?$/gm;
            if (event.target.value.trim().length < 5 || !reg.test(event.target.value.trim())) {
                _.set(this.props, "statecityCodes.state_province_code", "");
                _.set(this.props, "statecityCodes.city.city_name", []);
                _.set(this.props, "statecityCodes.cityFlag", "N");
                this.setState({ "zipFormat": "Zip format is not correct. Try again" });
                return false;
            }
            else if (event.target.value.trim().length >= 5 && event.target.value.trim().length <= 10) {
                this.props.getStateandCity(event.target.value);
            }

        } else if (this.state.zipCodeCountry === "CAN") {
            reg = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            // console.log("aaa",reg.test(event.target.value.trim()))

            if (event.target.value.trim().length < 6 || !reg.test(event.target.value.trim())) {
                // console.log("bbb")

                _.set(this.props, "statecityCodes.state_province_code", "");
                _.set(this.props, "statecityCodes.city.city_name", []);
                _.set(this.props, "statecityCodes.cityFlag", "N");
                this.setState({ "zipFormat": "Zip format is not correct. Try again" });
                return false;
            }
            else if (event.target.value.trim().length === 6 || event.target.value.trim().length === 7) {
                // console.log("ccc")

                this.props.getStateandCity(event.target.value);
            }
        }
        showCityDetails = "YES";

        // this.props.getStateandCity(event.target.value);
    }

    changeZip = (event) => {
        //let zipCodeCountry = "";
        var selfInfo = { ...this.state.selfInfo };

        if (event.target.value.trim().length === 1) {
            //console.log("ddd", isNaN(event.target.value));
            if (isNaN(event.target.value)) { // String
                // console.log("11")
                this.setState({ "zipMaxLength": 7, "zipMinLength": 6, "zipCodeCountry": "CAN" });

                selfInfo["zip_code"] = event.target.value;
                this.setState({ selfInfo });
                //zipCodeCountry = "CAN";
            } else {
                // console.log("22")
                this.setState({ "zipMaxLength": 10, "zipMinLength": 5, "zipCodeCountry": "US" });
                selfInfo["zip_code"] = event.target.value;
                this.setState({ selfInfo });
                //zipCodeCountry = "US";
            }
            //this.setState({"zip" : event.target.value});
        } else {
            //if(this.state.zipCodeCountry === "US") {
            let regex = /^[0-9 -]+$/i
            if (event.target.value === "" || (event.target.value.trim().length !== 0 || regex.test(event.target.value))) {
                // console.log("333")
                selfInfo["zip_code"] = event.target.value.trim();
                this.setState({ selfInfo, "zipCodeCountry": "US" });
            }
            //}
            // console.log(this.state.zipCodeCountry,"zippppp")
            if (this.state.zipCodeCountry === "CAN") {
                let regex = /^[A-Za-z0-9 -]+$/i;
                if (event.target.value === "" || (regex.test(event.target.value))) {
                    // console.log("44")
                    selfInfo["zip_code"] = event.target.value;
                    this.setState({ selfInfo, "zipCodeCountry": "CAN" });
                }
            }
        }
    }

    handleClose = () => {
        document.getElementsByClassName('showbanner')[0].style.display = 'none';
    }
    cancelPopup = () => {
        this.setState({ isOpen: false });
    }

    primaryRadioButton = (e) => {
        var selfInfo = { ...this.state.selfInfo };
        selfInfo["primaryPhoneType"] = e.target.value;
        this.setState({ selfInfo })
        if (e.target.value === "H") {
            selfInfo["secondaryPhoneType"] = "C";
            this.setState({ selfInfo })
        }
        if (e.target.value === "C") {
            selfInfo["secondaryPhoneType"] = "H";
            this.setState({ selfInfo })
        }
        if (e.target.value !== "") {
            this.setState({ primarycheckBox: "" })
        }
    }

    secondaryRadioButton = (e) => {
        var selfInfo = { ...this.state.selfInfo };
        selfInfo["secondaryPhoneType"] = e.target.value;
        this.setState({ selfInfo });
    }

    primaryValidation = (e) => {
        let primaryPhone = _.get(this.state, "selfInfo.primaryPhone", []);
        if (primaryPhone.length > 0 && primaryPhone.length < 10) {
            this.setState({
                primaryphoneLength: "primary phone must be 10 digits",
                primaryphoneReq: ""
            })
        }
        if (primaryPhone.length === 0) {
            this.setState({
                primaryphoneLength: ""

            })
        }

        if (primaryPhone.length === 10) {
            this.setState({
                primaryphoneLength: "",
                primaryphoneReq: ""
            })
        }
    }

    secondaryValidation = () => {
        let secondaryPhone = _.get(this.state, "selfInfo.secondaryPhone", []);
        if (secondaryPhone.length > 0 && secondaryPhone.length < 10) {

            this.setState({
                secondaryPhoneLength: "Secondary phone must be 10 digits"
            })
        }

        if (secondaryPhone.length === 0) {
            this.setState({
                secondaryPhoneLength: ""
            })
        }
        if (secondaryPhone.length === 10) {
            this.setState({
                secondaryPhoneLength: ""
            })
        }
    }
    

    emailValidation = () => {

        let emailLength = _.get(this.state, "selfInfo.email_address", "").trim().length;
        this.props.getEmailAddress(_.get(this.state, "selfInfo.email_address", "").trim())
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //console.log("emailLength", emailLength);
        if (emailLength > 0) {
            this.setState({ "emailRequired": "" });
            if (!re.test(_.get(this.state, "selfInfo.email_address", "").trim())) {
                //console.log("in  valid");
                this.setState({ "emailInvalid": "Email format is example@domain.com" });
            }
        } else {
            this.setState({ "emailRequired": "", "emailInvalid": "" });
        }



        if ((re.test(String(_.get(this.state, "selfInfo.email_address", "").trim()).toLowerCase()))) {
            this.setState({
                emailInvalid: ""
            })
        }

    }

    onChangeContactType = (e) => {
        //console.log("jj", e.target.value);
        if (e.target.value !== "") {
            this.setState({ preferredContact: "" })
        }

        var selfInfo = { ...this.state.selfInfo };

        //if(e.target.name ==="contact-phone"){
        if (e.target.value === "P") {
            selfInfo["contact_method"] = "P";
            this.setState({ selfInfo });
        }
        if (e.target.value === "S") {
            selfInfo["contact_method"] = "S";
            this.setState({ selfInfo });
        }
        //}
        if (e.target.value === "E") {
            selfInfo["contact_method"] = "E";
            this.setState({ selfInfo });
        }
    }

    onSSNEye = (e) => {
        this.setState({ changeFlag: !this.state.changeFlag });
    }
    blurSSN = (e) => {
        let actualssn = _.get(this.state, "selfInfo.actualSsn", "");
        if (actualssn.trim().length > 0 && actualssn.trim().length < 9) {
            this.setState({ "ssnLength": "SSN must be 9 digits", "ssnReq": "" });
        }


        if (actualssn.trim().length === 0) {
            this.setState({
                ssnLength: ""

            })
        }

        if (actualssn.trim().length === 9) {
            this.setState({
                ssnLength: "",
                ssnReq: ""
            })
        }
    }

    onBlurRequired = (e, errorField) => {
        let value = e.target.value.trim();
        if (value && value.length > 0) {
            this.setState({ [errorField]: "" });
        }
    }

    render() {
        //console.log("dov", _.get(this.state, "selfInfo.dob", null));
        //console.log(this.props.nonTrustedData,this.props.prefillData)
        // if(
        // _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip", "").trim().length <= 0) {
        //     _.set(this.props, "statecityCodes.state_province_code", "");
        //     _.set(this.props, "statecityCodes.city.city_name", []);
        //     _.set(this.props, "statecityCodes.cityFlag", "");
        //     //console.log("city", _.get(this.props, "statecityCodes.cityFlag"));
        // }

        let stateZipCode = {};
        if (showCityDetails === "YES") {
            stateZipCode = _.get(this.props, "statecityCodes", "");
        } else if (_.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip_code", "").trim().length <= 0) {
            _.set(this.props, "statecityCodes.state_province_code", "");
            _.set(this.props, "statecityCodes.city.city_name", []);
            _.set(this.props, "statecityCodes.cityFlag", "");
        } else if (_.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip_code", "").trim().length > 0) {
            stateZipCode = _.get(this.props, "statecityCodes", "");
        }

        //console.log("stateZipCode", stateZipCode);

        let stateProvCode = _.get(stateZipCode, "state_province_code", "");
        let cityName = _.get(stateZipCode, "city.city_name", []);
        // cityName= ["dd"];
        // _.set(this.props, "statecityCodes.cityFlag", "Y");
        let zipErrMsg = "";
        // console.log(_.get(this.props, "statecityCodes.cityFlag", ""),"invaiddd")
        if (_.get(this.props, "statecityCodes.cityFlag", "") === "N") {
            zipErrMsg = "Invalid zip";
            cityName = "";
            stateProvCode = "";
            if (document.getElementById("eoi-city")) {
                document.getElementById("eoi-city").disabled = true;
            }
        }
        //let citydisabledFlag = true;
        if (cityName.length === 1 && _.get(this.props, "statecityCodes.cityFlag", "") === "Y") {
            if (document.getElementById("eoi-city")) {
                document.getElementById("eoi-city").disabled = true;
            }
        }
        if (cityName.length > 1 && _.get(this.props, "statecityCodes.cityFlag", "") === "Y") {
            //console.log("in iff");
            document.getElementById("eoi-city") &&
                document.getElementById("eoi-city").removeAttribute('disabled');
            //cityenableFlag = false;
            // } else if( this.props && this.props.cityFlag === "Y"){
            //     document.getElementById("eoi-city").disabled = true;
            // }

        }
        // function enableFocus() {
        //     document.getElementById("demo").focus();
        // }

        // handleOnOpen = () => {
        //     document.getElementById("demo").focus();
        // }

        let emailValid = _.get(this.props, "isEmailValid", true)
        // let emailLength = _.get(this.state, "selfInfo.email_address", "").trim().length;
        return (
            <>
                {this.state.isOpen ?
                    <CheckPopup handlePopUp={() => this.handlePopUp()} /> : null}
                <AlertBanner handleClose={() => this.handleClose()} errorMessage="Please fill out all required fields." />
                <main>
                    <div className="container-fluid">
                        <div className="prev-link">
                            <button className="btn btn-link" onClick={() => this.onBack()}><i className="fa fa-arrow-left"></i>Back</button>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-5">
                                <div className="intro">
                                    <h1 style={{ fontSize: '32px' }}>Tell us about yourself</h1>
                                    <p>Let us know who you are and what coverage you would like to apply for. </p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="ssn">SSN</label>
                                    <div className="input-group">
                                        <input aria-label="SSN"
                                            //className="form-control" 
                                            className={`form-control ${this.state.ssnReq || this.state.ssnLength ? 'invalid' : ''}`}
                                            // aria-invalid={true}
                                            aria-describedby="ssn-error"
                                            ref={(input) => {
                                                this.ssnInput = input;
                                            }}
                                            id="ssn"
                                            type="tel"
                                            onBlur={(e) => this.blurSSN(e)}
                                            value={!this.state.changeFlag ? _.get(this.state, "selfInfo.maskedSsn", "") : _.get(this.state, "selfInfo.numberedSsn", "")}
                                            onChange={this.handleChange}
                                        //style={this.state.ssnReq || this.state.ssnLength ? {border: " 2px solid #BC3D19"}  : null}
                                        />
                                        <div className="input-group-append">
                                            <button aria-label="View social security number" className="btn btn-right hidden-text" onClick={(e) => this.onSSNEye(e)}
                                                type="button"><i className={!this.state.changeFlag ? "fas fa-eye-slash" : "fas fa-eye"}><span>pass-btn</span></i></button>
                                        </div>
                                    </div>
                                    <p id="ssn-error">
                                        {this.state.ssnReq ?
                                            <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.ssnReq}</span> : this.state.ssnLength ?
                                                <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.ssnLength}</span> : null}
                                    </p>
                                </div>

                                <div className="row form-group">
                                    <div className="col-md-10 col-sm-9">
                                        <label htmlFor="first-name">First name</label>
                                        <input
                                            //className="form-control invalid" 
                                            className={`form-control ${this.state.firstNameReq ? 'invalid' : ''}`}
                                            aria-describedby="firstNameReq-error"
                                            id="first-name" maxLength="30"
                                            onBlur={(e) => this.onBlurRequired(e, "firstNameReq")}
                                            type="text" value={_.get(this.state, "selfInfo.first_name")}
                                            onChange={(e) => this.onChangeName(e, "first_name")} />
                                        {this.state.firstNameReq ?
                                            <span id="firstNameReq-error" className="error"><i aria-label="error" role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.firstNameReq}</span> : null}
                                    </div>
                                    <div className="col-md-2 col-sm-3">
                                        <label htmlFor="mi">MI</label>
                                        <input className="form-control" id="mi"
                                            onChange={(e) => this.changeTextObjectFields(e, "middle_initial", this.state.alpha)}
                                            maxLength="1"
                                            value={_.get(this.state, "selfInfo.middle_initial", "")}
                                            type="text" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="last-name">Last name</label>
                                    <input aria-label="Last name"
                                        className={`form-control ${this.state.lastNameReq ? 'invalid' : ''}`}
                                        aria-describedby="lastNameReq-error"
                                        id="last-name" type="text" maxLength="30"
                                        value={_.get(this.state, "selfInfo.last_name")} onBlur={(e) => this.onBlurRequired(e, "lastNameReq")}
                                        onChange={(e) => this.onChangeName(e, "last_name")}
                                    />
                                    {this.state.lastNameReq ?
                                        <span id="lastNameReq-error" className="error"><i aria-label="error" role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.lastNameReq}</span> : null}
                                </div>
                                <div className="row form-group ">
                                    <div className="col-8">
                                        <label htmlFor="date-picker-dialog">Date of birth</label>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                KeyboardButtonProps={
                                                    {
                                                        'aria-label': 'Date Picker',

                                                    }
                                                }
                                                InputProps={
                                                    {
                                                        'aria-describedby': 'dob-error'
                                                    }
                                                }
                                                id="date-picker-dialog"
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                value={_.get(this.state, "selfInfo.dob", null)}
                                                onChange={this.changeEvent}
                                                onBlur={this.handleDateChange}
                                                placeholder="MM/DD/YYYY"
                                                autoOk={true}
                                                onOpen={this.handleOnOpen}
                                                onClose={this.handleOnClose}
                                                maxDate={new Date()}
                                                //disablePast={true}
                                                error={(this.state.blurError || this.state.dobReq) ? 'invalid' : ''}
                                                helperText={null}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <p id="dob-error">
                                            {
                                                this.state.blurError === true ?
                                                    <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>Date of birth is invalid</span> :
                                                    this.state.dobReq ?
                                                        <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.dobReq}</span>

                                                        : null}
                                        </p>
                                    </div>
                                </div>
                                {/* // dropdown arrow / */}
                                <div className="form-group">
                                    <label htmlFor="eoi-gender">Gender</label>
                                    <select id="eoi-gender"
                                        className={`form-control ${this.state.genderReq ? 'invalid' : ''} ${!_.get(this.state, "selfInfo.gender") ? 'placeholder' : ''}`}
                                        aria-describedby="genderReq-error"
                                        value={_.get(this.state, "selfInfo.gender")}
                                        onBlur={(e) => this.onBlurRequired(e, "genderReq")}
                                        onChange={(e) => this.genderChange(e)} >
                                        <option value="" selected >Select </option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>
                                    {this.state.genderReq ?
                                        <span id="genderReq-error" className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.genderReq}</span> : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="address">Address</label>
                                    <input className={`form-control ${this.state.addressReq ? 'invalid' : ''}`}
                                        aria-describedby="addressReq-error"
                                        value={_.get(this.state, "selfInfo.address_line1")}
                                        onBlur={(e) => this.onBlurRequired(e, "addressReq")}
                                        onChange={(e) => this.changeTextObjectFields(e, "address_line1", this.state.alphaNum)}
                                        id="address" type="text" maxLength="30" />

                                    {this.state.addressReq ?
                                        <span id="addressReq-error" className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.addressReq}</span> : null}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="suite">Suite, Apt <span>(optional)</span> </label>
                                    <input className="form-control" id="suite"
                                        value={_.get(this.state, "selfInfo.address_line2", "")}
                                        onChange={(e) => this.changeTextObjectFields(e, "address_line2", this.state.alphaNum)}
                                        type="text" maxLength="30" />
                                </div>
                                <div className="row form-group">
                                    <div className="col-lg-6 col-md-5 col-sm-7">
                                        <label htmlFor="zip">Zip</label>
                                        <input className={`form-control ${(zipErrMsg || this.state.zipFormat || this.state.zipReq) ? 'invalid' : ''}`}
                                            // aria-invalid={true}
                                            aria-describedby="zip-error"
                                            value={_.get(this.state, "selfInfo.zip_code")}
                                            id="zip" type="text" maxLength={this.state.zipMaxLength}
                                            onChange={(e) => this.changeZip(e)}
                                            onBlur={(e) => this.blurZip(e)}
                                        />

                                    </div>
                                    <div className="col-lg-12">
                                        <p id="zip-error">
                                            {this.state.zipReq ?
                                                <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.zipReq}</span> :
                                                this.state.zipFormat ? <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>
                                                    {this.state.zipFormat}</span> : zipErrMsg ? <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>
                                                        {zipErrMsg}</span> :
                                                    null}
                                        </p>
                                    </div>
                                </div>
                                <div className="row form-group">

                                    <div className="col-lg-10 col-md-10 col-sm-11">
                                        <label htmlFor="eoi-city">City</label>
                                        <select className={`form-control ${cityName.length <= 0 ? 'placeholder' : ''}`}
                                            id="eoi-city" disabled>


                                            {cityName.length === 0 ? <option disabled selected >Select</option> :
                                                cityName.length === 1 ? cityName.map((city, index) => (
                                                    <option value={city} key={index}>{city}</option>
                                                )) :
                                                    cityName.map((city, index) => (
                                                        <option value={city} key={index}>{city}</option>
                                                    ))}
                                        </select>
                                    </div>
                                </div>
                                {/* // dropdown arrow / */}
                                <div className="row form-group">
                                    <div className="col-lg-6 col-md-5 col-sm-7">
                                        <label htmlFor="state">State / Province </label>
                                        <input className="form-control" value={stateProvCode}
                                            id="state" type="text" disabled />
                                    </div>
                                </div>

                                <h2 style={{ fontSize: '20px' }} className="mt-5 pb-4">Contact information</h2>
                                <div className="form-group">
                                    <label htmlFor="primary-phone">Primary phone</label>
                                    <NumberFormat aria-label="Primary phone number" format="###-###-####"
                                        id="groupContactPhone"
                                        className={`form-control ${(this.state.primaryphoneReq || this.state.primaryphoneLength) ? 'invalid' : ''}`}
                                        // aria-invalid={true}
                                        aria-describedby="contact-error"
                                        //onChange={(e)=>this.props.handlePhone(e)}
                                        onValueChange={this.handlePhone}
                                        value={_.get(this.state, "selfInfo.primaryPhone")}
                                        onBlur={(e) => this.primaryValidation(e)} />
                                    <p id="contact-error">
                                        {this.state.primaryphoneLength ?
                                            <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.primaryphoneLength}</span>
                                            : this.state.primaryphoneReq ? <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.primaryphoneReq}</span> : null
                                        }
                                    </p>
                                </div>
                                <fieldset className="form-group">
                                    <div className="radio">
                                        <input type="radio" id="phone-home1"
                                            aria-label="Home Radio Button"
                                            aria-describedby="primarycheckbox-error"
                                            value="H" name="primary-phone"
                                            checked={(_.get(this.state, "selfInfo.primaryPhoneType") === "H")}
                                            onChange={(e) => this.primaryRadioButton(e)} />
                                        <label htmlFor="phone-home1">
                                            <span>Home</span>
                                        </label>
                                    </div>
                                    <div className="radio">
                                        <input type="radio" id="phone-mobile1"
                                            aria-describedby="primarycheckbox-error"
                                            aria-label="Mobile Radio Button"
                                            value="C" name="primary-phone"
                                            checked={(_.get(this.state, "selfInfo.primaryPhoneType") === "C")}
                                            onChange={(e) => this.primaryRadioButton(e)} />
                                        <label htmlFor="phone-mobile1">
                                            <span>Mobile</span>
                                        </label>
                                    </div>
                                    <p id="primarycheckbox-error">
                                        {this.state.primarycheckBox ?
                                            <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.primarycheckBox}</span> : null}
                                    </p>
                                </fieldset>

                                <div className="form-group">
                                    <label htmlFor="secondary-phone">Secondary phone <span>(optional)</span> </label>
                                    <NumberFormat aria-label="Secondary phone number" format="###-###-####"
                                        id="secondary-phone"
                                        aria-describedby="digit-error"
                                        className={`form-control ${this.state.secondaryPhoneLength ? 'invalid' : ''}`}
                                        onBlur={() => this.secondaryValidation()}
                                        onValueChange={this.handleSecPhone}
                                        value={_.get(this.state, "selfInfo.secondaryPhone", "")}
                                    />
                                    <p id="digit-error">
                                        {this.state.secondaryPhoneLength ?
                                            <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.secondaryPhoneLength}</span> : null}
                                    </p>
                                </div>
                                <fieldset className="form-group showHide">
                                    <div className="radio">
                                        <input type="radio" id="phone-home2"
                                            value="H" name="secondary-phone"
                                            checked={_.get(this.state, "selfInfo.secondaryPhoneType") === "H"}
                                            disabled={_.get(this.state, "selfInfo.primaryPhoneType") === "H"}
                                            onChange={(e) => this.secondaryRadioButton(e)} />
                                        <label htmlFor="phone-home2">
                                            <span>Home</span>
                                        </label>
                                    </div>
                                    <div className="radio">
                                        <input type="radio" id="phone-mobile2"
                                            aria-describedby="secondarycheckBox-error"
                                            value="C" name="secondary-phone"
                                            checked={_.get(this.state, "selfInfo.secondaryPhoneType") === "C"}
                                            disabled={_.get(this.state, "selfInfo.primaryPhoneType") === "C"}
                                            onChange={(e) => this.secondaryRadioButton(e)} />
                                        <label htmlFor="phone-mobile2">
                                            <span>Mobile</span>
                                        </label>
                                    </div>
                                    {this.state.secondarycheckBox ?
                                        <span id="secondarycheckBox-error" className="error"><i aria-label="error" role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.secondarycheckBox}</span> : null}
                                </fieldset>

                                <div className="form-group">
                                    <label htmlFor="email-address">Email address</label>
                                    <input className={`form-control ${(this.state.emailRequired || this.state.emailInvalid) ? 'invalid' : ''}`}
                                        onChange={(e) => this.handleEmail(e)}
                                        value={_.get(this.state, "selfInfo.email_address")}
                                        // aria-invalid={true}
                                        aria-describedby="email-error"
                                        onBlur={this.emailValidation}
                                        id="email-address" maxLength="50" type="text" />
                                    <p id="email-error">
                                        {this.state.emailRequired ?
                                            <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.emailRequired}</span>
                                            : this.state.emailInvalid ? <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.emailInvalid}</span> : null
                                        }
                                        {!emailValid &&
                                            <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{'Email is Invalid'}</span>
                                        }
                                    </p>
                                </div>
                                <fieldset className="form-group">
                                    <legend className="data-label">What’s your preferred contact method?</legend>
                                    <div className="radio mt-3">
                                        <input type="radio" id="contact-primary-phone" value="P"
                                            aria-describedby="preferredcontact-error"
                                            checked={_.get(this.state, "selfInfo.contact_method") === "P"}
                                            name="contact-phone" onChange={(e) => this.onChangeContactType(e)} />
                                        <label htmlFor="contact-primary-phone">
                                            <span>Primary phone</span>
                                        </label>
                                    </div>
                                    <div className="radio secondaryradioHide">
                                        <input type="radio" id="secondary-mobile" value="S"
                                            aria-describedby="preferredcontact-error"
                                            checked={_.get(this.state, "selfInfo.contact_method") === "S"}
                                            name="contact-phone" onChange={(e) => this.onChangeContactType(e)} />
                                        <label htmlFor="secondary-mobile">
                                            <span>Secondary phone</span>
                                        </label>
                                    </div>
                                    <div className="radio">
                                        <input type="radio" id="email" value="E" name="contact-phone"
                                            aria-describedby="preferredcontact-error"
                                            checked={_.get(this.state, "selfInfo.contact_method") === "E"}
                                            onChange={(e) => this.onChangeContactType(e)} />
                                        <label htmlFor="email">
                                            <span>Email</span>
                                        </label>
                                    </div>
                                    <p id="preferredcontact-error">
                                        {this.state.preferredContact ?
                                            <span className="error"><i role="img" aria-hidden="true" className="fas fa-exclamation-circle"></i>{this.state.preferredContact}</span> : null}
                                    </p>

                                </fieldset>
                                <hr />
                                <div className="float-right align-center">
                                    {_.get(this.props, "location.state.previousPage") === "review" ?
                                        <button className="btn btn-primary" onClick={(e) => this.onSubmit(e)}> Next </button> :
                                        <button id="submit-button" className="btn btn-primary btn-double-line" onClick={(e) => this.onSubmit(e)}>Submit Personal Information</button>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </>
        );
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(TellUsMore);

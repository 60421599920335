import React, { Component } from "react";

class RemovePopup extends Component {

    
    render() {
        return (
    <>
        <div class="popup-bg">
            <div class="popup-box small">
                <div class="popup-header">
                    <button class="close hidden-text">
                        <i class="fas fa-times"><span>close button</span></i>
                    </button>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h3>Are you sure you want to remove {this.props.lifeName}?</h3>
                        </div>
                    </div>
                    <div class="field-group mt-5">
                        <div class="item-1"><button class="btn btn-outline" onClick={this.props.cancelPopup}>Cancel</button></div>
                        <div class="item-2"><button class="btn btn-primary" onClick={this.props.removePopup}>Yes, remove</button></div>
                    </div>
                </div>
            </div>
        </div>
    </>
        );
    }
}
export default RemovePopup;
import "date-fns";

import React, { Component } from "react";

import _ from "lodash";
import moment from "moment";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
 import { parseISO } from 'date-fns'
import AlertBanner from "../../components/AlertBanner/AlertBanner";
import { actionCreators } from "../../store/HomePage/HomePageAction";
import { scrollUp } from "../../utils/sharedFunction";
import CheckPopup from "../../components/CheckPopUp/CheckPopUp";
import SaveForLater from "../../components/SaveForLater/SaveForLater";
import DuplicatePopup from "../../components/DuplicateSSN/DuplicatePopup";

let showCityDetails = "NO";

class ContactInfo extends Component { 
    
    constructor(props) {
        super(props);
        this.state = {
            displayBanner:false,
            changeFlag : false,
            dob: null,
            calendarError:false,
            blurError:false,
            wasOpened:false,
            alphaNum : /^[A-Za-z0-9 ]+$/i,
            alpha : /^[A-Za-z ]+$/i,
            numeric : /^[0-9]+$/i,
            //stateCode : "",
            //gender : "",
            first_name : "",
            last_name : "",
            actualSsn: "",
            maskedSsn: "",
            zip_code : "", 
            middle_initial : "",
            address_line1 : "",
            address_line2 : "",
            isOpen:false,
            isSelect:false,
            isDuplicate: false,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let prevValue = _.get(this.state, "selfInfo.maskedSsn", []);
        if(prevValue === null){
            prevValue = ""
        }
        const newValue = event.target.value;
        let actual = _.get(this.state, "selfInfo.actualSsn", "");
        if(actual === null){
            actual = ""
        }
        if (newValue.length > 11) {
          return;
        }
    
        // typing forward
        if (newValue.length > prevValue.length) {
          let newChar = newValue.split("").pop();
    
          actual = `${actual}${newChar}`;
        }
        // backspacing / deleting
        else {
          const charsRemovedCount = prevValue.length - newValue.length;
    
          actual = actual.substr(0, actual.length - charsRemovedCount);
        }
    
        if (actual === '' || this.state.numeric.test(actual)) {
        
        //this.setState({selfInfo});
        var selfInfo = {...this.state.selfInfo};
        selfInfo["actualSsn"] = actual;
        selfInfo["maskedSsn"] = this.starredMask("mask", actual);
        selfInfo["numberedSsn"] = this.starredMask("number", actual);
        this.setState({selfInfo});
    }
    
        //this.props.change(`owner_${this.props.match.params.idx}_ssn`, actual);
      }
    
    
      starredMask(formatType, ssn, showLast4 = true) {
        // replace numeric digits with * asterisk
        let valueHidden = "";
        if(formatType ==="mask") {
            valueHidden = ssn.replace(/[\d]/g, "*");
        } else if(formatType ==="number") {
            valueHidden = ssn;
        }
    
        if (valueHidden.length <= 3) {
          return valueHidden;
        }
    
        if (valueHidden.length <= 5) {
          return valueHidden.slice(0, 3) + "-" + valueHidden.slice(3, 5);
        }
    
        if (showLast4) {
          return (
            valueHidden.slice(0, 3) +
            "-" +
            valueHidden.slice(3, 5) +
            "-" +
            valueHidden.slice(5, 7) +
            "" +
            ssn.substr(7)
          );
        } else {
          return (
            valueHidden.slice(0, 3) +
            "-" +
            valueHidden.slice(3, 5) +
            "-" +
            valueHidden.slice(5, 9)
          );
        }
      }

    componentDidMount = () => {
        document.title = "Tell us about yourself | Group EOI - Guardian";
        // console.log(this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information)
        document.getElementsByClassName('showHide')[0].style.display = 'none';
        document.getElementsByClassName('secondaryradioHide')[0].style.display = 'none';
        //document.getElementsByClassName('showbanner')[0].style.display = 'none';

        scrollUp();
        //console.log("mount");
        this.props.saveBooleanFun(); //Dummy prop change

        var selfInfo = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", {});

        //console.log("selfInfo", selfInfo);
        let ssn1 = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ssn1", "");
        let ssn2 = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ssn2", "");
        let ssn3 = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ssn3", "");

        if(ssn1 !== ""){
        selfInfo["actualSsn"] = ssn1 && ssn2 && ssn1.concat(ssn2).concat(ssn3);
        selfInfo["maskedSsn"] = ssn3 && "***-**-**".concat(ssn3.substr(2,2));
        selfInfo["numberedSsn"] = ssn1 && ssn2 && ssn3 && ssn1+"-"+ssn2+"-"+ssn3;
        }

        selfInfo["secondaryPhoneType"] = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.secondary_phone_type", "");
        selfInfo["primaryPhoneType"] = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.primary_phone_type", "");

        const employee = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", "");
            
        if(employee.primary_phone_type === "H" ){
            selfInfo["primaryPhone"] = employee.home_phone1 + employee.home_phone2 + employee.home_phone3;
        }
        if(employee.primary_phone_type === "C" ){
            selfInfo["primaryPhone"] = employee.cell_phone1 + employee.cell_phone2 + employee.cell_phone3;
        }
        if(employee.secondary_phone_type === "H" ){
            selfInfo["secondaryPhone"] = employee.home_phone1 + employee.home_phone2 + employee.home_phone3;
        }
        if(employee.secondary_phone_type === "C" ){
            selfInfo["secondaryPhone"] = employee.cell_phone1 + employee.cell_phone2 + employee.cell_phone3;
        }
        // let tempDob = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.dob", null)
        // let dob = tempDob
        let dob = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.dob", null)
        //moment(childData.dob).format('MM/DD/YYYY')
        if(dob !== null) {
            // selfInfo["dob"] = moment(dob).format('MM-DD-YYYY');
            // console.log(selfInfo["dob"],"kkkk")
            // console.log(this.props)
            // console.log(dob)
            //selfInfo["dob"] = moment(dob).format('MM/DD/YYYY');
        }
        // console.log(selfInfo)
        if(selfInfo.maskedSsn){
            selfInfo["maskedSsn_disb"] = true
        }
        if(selfInfo.first_name){
            selfInfo["first_name_disb"] = true
        }
        if(selfInfo.first_name && selfInfo.last_name){
            selfInfo["middle_initial_disb"] = true
        }
        if(selfInfo.last_name){
            selfInfo["last_name_disb"] = true
        }
        if(selfInfo.dob){
            selfInfo["dob_disb"] = true
        }
        if(selfInfo.gender){
            selfInfo["gender_disb"] = true
        }
        if(selfInfo.address_line1){
            selfInfo["address_line1_disb"] = true
        }
        if(selfInfo.address_line2){
            selfInfo["address_line2_disb"] = true
        }
         let stateZipCode = _.get(this.props,"statecityCodes", "");
        let cityName = _.get(stateZipCode, "city.city_name", []);
        let lowerCaseCityName = cityName.map(a=>{return a.toLowerCase()})
        // console.log(cityName,lowerCaseCityName)
        let stateProvinceCode = _.get(stateZipCode,"state_province_code", "");
        if(_.get(selfInfo,"zip_code","") && cityName && lowerCaseCityName.includes(selfInfo.city.toLowerCase()) && stateProvinceCode && stateProvinceCode === selfInfo.state){
            selfInfo["zip_code_disb"] = true
            document.getElementById("eoi-city").disabled = true;
            selfInfo["city_disb"] = true
        }
        this.setState({selfInfo});
        
    }

    onBack = () => {
        _.set(this.props, "statecityCodes.state_province_code", "");
        _.set(this.props, "statecityCodes.city.city_name", []);
        //_.set(this.props, "statecityCodes.cityFlag", null);
        //_.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", {});

        let previousURL = _.get(this.props,"location.state.previousPage")
        // console.log("previousURL",previousURL)

        if(previousURL === "review") {
            this.props.history.push(`/eoi/review`)
        } else{
            this.props.history.push(`/eoi/benefits`)
        }
       
    }
    handlePopUp=()=>{
        this.setState({isSelect:true, isOpen:false, isDuplicate:false});
    }

    handleDuplicate=()=>{
        this.setState({ isOpen:false, isDuplicate:false});
    }

    handleDateChange = (event) => {
        //console.log("this.state.calendarError", this.state.calendarError, event.target.value);
        //console.log("event.target.value ["+event.target.value+"]tt["+JSON.stringify(event.target.value)+"]");
        var selfInfo = {...this.state.selfInfo};
        //console.log("event.target.value", event.target.value);
       
        if(event.target.value.length===0 ){
            selfInfo["dob"] = event.target.value
            this.setState({
                selfInfo
            })
        }
        if(this.state.calendarError===false){
            selfInfo["dob"] = event.target.value
            this.setState({
                selfInfo
            })
        }
        if(this.state.calendarError===true && event.target.value.length!==0){
            selfInfo["dob"] = null;
            this.setState({
                selfInfo
            })
            this.setState({
                blurError:true
               
            })
        }
        else{
            this.setState({
                blurError:false
            })
        }
        let currentDate = new Date();
        let givenDate = new Date(event.target.value)
        let yearDiff = currentDate.getFullYear() - givenDate.getFullYear();
        //console.log("currentDate", currentDate, new Date(event.target.value));
        //console.log(Number(currentDate),  Number(givenDate));
        if(Number(currentDate) < Number(givenDate) || yearDiff >= 100) {
            //console.log("in if");
            this.setState({
                blurError:true
               
            })
        }

        if(event.target.value)this.setState({dobReq:false})


    }

    changeEvent = (date) => {
        var selfInfo = {...this.state.selfInfo};
        if (this.state.wasOpened===true){
            selfInfo["dob"] = moment(date).format('MM/DD/YYYY');
            this.setState({
                selfInfo
            })
            if(this.state.blurError===true){
                this.setState({
                    blurError:false
                })
            }
        }
        // if(this.state.calendarError === false){
        //     if(e.target.value && this.state.dobError === true){
        //         this.setState({dobReq:""})
        //     }
        // }
       
        //console.log("JSON.stringify(date)", JSON.stringify(date));
        if(JSON.stringify(date)===null || JSON.stringify(date)==="null" ){
            this.setState({
                calendarError:true
            })
        }
        else{
            this.setState({
                calendarError:false,
                dobReq : ""
            })
        }
    }
    handleOnOpen = () => {
        this.setState({
            wasOpened:true
        })
    }
    handleOnClose = () => {
        this.setState({
            wasOpened:false
        })
    }

    changeTextObjectFields = (e, objectName, regEx) => {
        var selfInfo = {...this.state.selfInfo};
        //console.log("pageElepageEle", selfInfo);
        if (e.target.value === '' || regEx.test(e.target.value)) {
          
            selfInfo[objectName] = e.target.value;
          //console.log("element",pageEle);
          //console.log("pageElepageEle", selfInfo);
          this.setState({ selfInfo }); 
        }
      }
    saveForLater = () => {
        this.setState({isSaved:true});
    }
    continueEOI = () => {
        this.setState({isSaved:false});
    }

      handlePhone = (values) => {
        var selfInfo = {...this.state.selfInfo};
        selfInfo["primaryPhone"] = values.value;
        this.setState({
            selfInfo
        })  
     }

     handleSecPhone = (values) => {
        //console.log(values.value.trim().length);
        if(values.value.trim().length > 0) {
            document.getElementsByClassName('showHide')[0].style.display = 'block';
            document.getElementsByClassName('secondaryradioHide')[0].style.display = 'block';

        } else {
            document.getElementsByClassName('showHide')[0].style.display = 'none';
            document.getElementsByClassName('secondaryradioHide')[0].style.display = 'none';
        }

        var selfInfo = {...this.state.selfInfo};
        selfInfo["secondaryPhone"] = values.value;
        this.setState({
            selfInfo
        }) 
     }

    handleEmail = (e) => {
        var selfInfo = {...this.state.selfInfo};
        selfInfo["email_address"] = e.target.value;
        this.setState({selfInfo});
    }

    leave = () => {
        this.props.history.push({ pathname: `/eoi/success` })
    }
    onSubmit = (e)  => {
        
        this.setState({
            ssnReq : '',
            ssnLength : '',
            firstNameReq : '',
            lastNameReq : '',
            dobReq : "",
            genderReq : '',
            zipReq : '',
            zipFormat : '',
            addressReq : '',
            primaryphoneLength : '',
            primaryphoneReq : '',
            primarycheckBox : '',
            secondaryPhoneLength : '',
            secondarycheckBox : '',
            emailRequired : '',
            emailInvalid : '',
            preferredContact : ''
        });

        let ssnLength = _.get(this.state, "selfInfo.maskedSsn" ,  "");
        let actualssn = _.get(this.state, "selfInfo.actualSsn" ,  "");
        let zipReq = _.get(this.state, "selfInfo.zip_code", "");
        let priPhoneLength = _.get(this.state, "selfInfo.primaryPhone","") && _.get(this.state, "selfInfo.primaryPhone","").trim().length;
        let secPhoneLength =  _.get(this.state, "selfInfo.secondaryPhone","") && _.get(this.state, "selfInfo.secondaryPhone", "").trim().length;
        let emailLength =  _.get(this.state, "selfInfo.email_address", "") && _.get(this.state, "selfInfo.email_address", "").trim().length;
        let showError = false;
        if(this.state.blurError) {
            showError = true;
        }
        //zip validation
        if( _.get(this.props, "statecityCodes.cityFlag", "") === "N") {
            showError = true;
        }
        if(zipReq===null || (zipReq && zipReq.trim().length <= 0) || zipReq==="") {
            this.setState({"zipReq" : "Zip is required"});
            showError = true;
        } else if(this.state.zipCodeCountry === "US") {
            var reg =/^\d{5}([-])?(\d{4})?$/gm;
            if(zipReq!==null){
            if( zipReq.trim().length < 5 || !reg.test(zipReq.trim())) {
                this.setState({"zipFormat" : "Zip format is not correct. Try again"});
                showError = true;
            }
            }

        } else if(this.state.zipCodeCountry === "CAN") {
            reg = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            if(zipReq!==null){
            if(zipReq.trim().length < 6 || !reg.test(zipReq.trim())) {
                this.setState({"zipFormat" : "Zip format is not correct. Try again"});
                showError = true;
            }
            }
        }
        //ssn validation
       
        if(ssnLength === null ||(ssnLength &&ssnLength.trim().length <= 0) || ssnLength==="") {
            this.setState({"ssnReq" : "SSN is required"});
            showError = true;
        } else if(actualssn === null || (actualssn && actualssn.trim().length < 9)|| actualssn==="") {
            this.setState({"ssnLength" : "SSN must be 9 digits"});
            showError = true;
        }

        //email validation
        if (_.get(this.props, "isEmailValid", "") === false) {
            showError = true;
        }
        if(emailLength > 0) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!re.test(_.get(this.state, "selfInfo.email_address", "").trim())) {
                //console.log("in  valid");
                this.setState({"emailInvalid" : "Email format is example@domain.com"});
                showError = true;
            }
        }else {
            this.setState({"emailRequired" : "Email address is required"});
            showError = true;
        }

        //preferred Contact
        let preferredContact = document.getElementsByName('contact-phone'),prefcheckedFlag = false;
        for(let i=0;i<preferredContact.length;i++) {
            //console.log("preferredContact[i].checked", preferredContact[i].checked);
            if(preferredContact[i].checked) {
                prefcheckedFlag = true;
            }
        }
        if(!prefcheckedFlag) {
            this.setState({"preferredContact" : "Select preferred contact method"});
            showError = true;
        }
        //Secondary Mobile
       //let seccheckedFlag = false;
        if( secPhoneLength > 0) {
            let checkboxes = document.getElementsByName('secondary-phone');
            for(let i=0;i<checkboxes.length;i++) {
                if(checkboxes[i].checked) {
                   // seccheckedFlag = true;
                }
            }
            // console.log(seccheckedFlag);
            if(secPhoneLength < 10) {
                this.setState({"secondaryPhoneLength" : "Secondary phone must be 10 digits"});
                showError = true;
            } 
            // else if(secPhoneLength === 10) {
            //     if(!seccheckedFlag) {
            //         this.setState({"secondarycheckBox" : "Specify type of phone numer"});
            //         showError = true;
            //     }
            // }
            
        }

        //Gender
        let genderLength = _.get(this.state, "selfInfo.gender", "") && _.get(this.state, "selfInfo.gender", "").trim().length;
        if(genderLength <= 0 || genderLength === undefined || genderLength===null || genderLength==="") {
            this.setState({"genderReq" : "Gender is required"});
            showError = true;
        }

        //first_name validation
        let first_name = _.get(this.state, "selfInfo.first_name", "");
        if( first_name === null || (first_name && first_name.trim().length <=0) || first_name==="") {
            this.setState({"firstNameReq" : "First name is required"});
            showError = true;
        }

        let last_name = _.get(this.state, "selfInfo.last_name", "");
        if(last_name === null || (last_name &&last_name.trim().length <=0) || last_name==="") {
            this.setState({"lastNameReq" : "Last name is required"});
            showError = true;
        }
        //dob
        let dob = _.get(this.state, "selfInfo.dob", "");
        if(dob === null  || dob.length <=0) {
            this.setState({"dobReq" : "Date of birth is required"});
            showError = true;
        }

        //address validation
        let address_line1 = _.get(this.state, "selfInfo.address_line1", "");
        //console.log("addressaddress", address, address.trim().length);
        if(address_line1===null || (address_line1 && address_line1.trim().length <=0) || address_line1==="") {
            this.setState({"addressReq" : "Address is required"});
            showError = true;
        }

        //primary Mobile
        if( priPhoneLength > 0) {
            //home or mobile is mandatory
            let checkboxes = document.getElementsByName('primary-phone'),checkedFlag= false;
            for(let i=0;i<checkboxes.length;i++) {
                if(checkboxes[i].checked) {
                    checkedFlag = true;
                }
            }
           
            if(priPhoneLength < 10) {
                this.setState({"primaryphoneLength" : "Primary phone must be 10 digits"});
                showError = true;
            } else if(priPhoneLength === 10) {
                if(!checkedFlag) {
                    this.setState({"primarycheckBox" : "Specify type of phone number"});
                    showError = true;
                }
            }
        } else {
            this.setState({"primaryphoneReq" : "Primary phone is required"});
            showError = true;
        }
       
        
        //console.log("showError", showError);
        if(showError === true) {
            this.setState({
                displayBanner:true
            })
            //document.getElementsByClassName('showbanner')[0].style.display = 'block';
            window.scrollTo(0,0);
        } else {
            //_.set(this.props,"prefillData.add_benefit_coverage_info.tell_us_more_status","COMPLETED")
            //_.set(this.props,"prefillData.add_benefit_coverage_info.who_are_we_covering_status","ACTIVE")
            // _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.maskedSsn", this.state.selfInfo.maskedSsn);
            // _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.numberedSsn", this.state.selfInfo.numberedSsn);
            // _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.actualSsn", this.state.selfInfo.actualSsn);

            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ssn1", this.state.selfInfo.actualSsn.substring(0, 3));
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ssn2", this.state.selfInfo.actualSsn.substring(3, 5));
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.ssn3", this.state.selfInfo.actualSsn.substring(5, 9));

            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.first_name", this.state.selfInfo.first_name);
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.middle_initial", this.state.selfInfo.middle_initial );
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.last_name", this.state.selfInfo.last_name);
            
    
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.dob", moment(this.state.selfInfo.dob).format('YYYY-MM-DD'));
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.state", _.get(this.props, "statecityCodes.state_province_code", ""));
                       // _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.state","MD");

            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.gender", this.state.selfInfo.gender);
            
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.city", document.getElementById('eoi-city').value);
                        //_.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.city", "BALTIMORE");

            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.address_line1", this.state.selfInfo.address_line1);
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.address_line2", this.state.selfInfo.address_line2);
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip_code", this.state.selfInfo.zip_code);
                        //_.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip_code", "21201");

            //_.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.primaryPhone",this.state.selfInfo.primaryPhone );
            //_.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.secondaryPhone",this.state.selfInfo.secondaryPhone );
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.email_address",this.state.selfInfo.email_address );
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.contact_method",this.state.selfInfo.contact_method );
            

            if (this.state.selfInfo.primaryPhoneType && this.state.selfInfo.primaryPhoneType === "H") {
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone1", this.state.selfInfo.primaryPhone && this.state.selfInfo.primaryPhone.substring(0, 3))
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone2", this.state.selfInfo.primaryPhone && this.state.selfInfo.primaryPhone.substring(3, 6))
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone3", this.state.selfInfo.primaryPhone && this.state.selfInfo.primaryPhone.substring(6, 10))  
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.primary_phone_type", this.state.selfInfo.primaryPhoneType)
  
            } 
            if(this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhoneType === "H"){
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone1", this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhone.substring(0, 3))
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone2", this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhone.substring(3, 6))
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone3", this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhone.substring(6, 10))
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.secondary_phone_type", this.state.selfInfo.secondaryPhoneType)

            }
            if (this.state.selfInfo.primaryPhoneType && this.state.selfInfo.primaryPhoneType === "C") {
    
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone1", this.state.selfInfo.primaryPhone && this.state.selfInfo.primaryPhone.substring(0, 3))
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone2", this.state.selfInfo.primaryPhone && this.state.selfInfo.primaryPhone.substring(3, 6))
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone3", this.state.selfInfo.primaryPhone && this.state.selfInfo.primaryPhone.substring(6, 10))
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.primary_phone_type", this.state.selfInfo.primaryPhoneType)

            }
            if(this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhoneType === "C"){
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone1", this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhone.substring(0, 3))
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone2", this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhone.substring(3, 6))
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone3", this.state.selfInfo.secondaryPhone && this.state.selfInfo.secondaryPhone.substring(6, 10))
                _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.secondary_phone_type", this.state.selfInfo.secondaryPhoneType)

            }
        
            

            //_.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.", );

            //console.log("thhis.props",this.props.prefillData.eoi_data.benefit_coverage_info.eoi_info);

            //this.props.history.push('/eoi/whoAreWeCovering')
            _.set(this.props,"prefillData.eoi_data.benefit_coverage_info.confirm_contact_status","COMPLETED")
            _.set(this.props, "prefillData.eoi_data.benefit_coverage_info_status","COMPLETED")
            _.set(this.props, "prefillData.eoi_data.health_question_info_status","START")
          // this.props.submitMib(_.get(this.props, "prefillData", {}),'/eoi/homePage');
           let previousURL = _.get(this.props,"location.state.previousPage")
            // console.log("previousURL",previousURL)

            if(previousURL === "review") {
                // this.props.submitMib(_.get(this.props, "prefillData", ""),'/eoi/review');
                this.props.updateprefillData(_.get(this.props, "prefillData", ""),_.get(this.props, "prefillId",""),'/eoi/review');
            } 
            if( _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.duplicateSSN","") === true ){
                this.setState({isDuplicate:true})
            }
            else{

                if(this.state.isSelect === false){
                    this.setState({isOpen:true})
                }
                if(this.state.isSelect === true && this.state.isOpen === false && this.state.isDuplicate === false){
               this.props.updateprefillData(_.get(this.props, "prefillData", ""), _.get(this.props, "prefillId",""),'/eoi/reviewWhoAreWeCovering');
            //    this.props.submitMib(_.get(this.props, "prefillData", {}),'/eoi/reviewWhoAreCovering');
            }
            }
            //this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""),'/eoi/homePage')
           // this.props.submitMib(_.get(this.props, "prefillData", {}),'/eoi/homePage');
        
        }
     
    }

    genderChange= (event) => {
        var selfInfo = {...this.state.selfInfo};
        selfInfo["gender"] = event.target.value;
        this.setState({ selfInfo })
    }

    onChangeName = (event, label) => {
        var selfInfo = {...this.state.selfInfo};
        selfInfo[label] = event.target.value;
        this.setState({ selfInfo })

    }

    handleChangeinssn  = (event, b= true) => {
        let ssn = event.target.value;
        let valueHidden = ssn.replace(/[\d]/g, "*");

        let a = "";
        if (valueHidden.length <= 3) {
           a = valueHidden;
        }
    
        if (valueHidden.length <= 5) {
            //console.log("hh", this.state.zipCodeCountry);
           a = valueHidden.slice(0, 3) + "-" + valueHidden.slice(3, 5);
        }
     
       
         if(b) {
           a = valueHidden.slice(0, 3) +
            "-" +
            valueHidden.slice(3, 5) +
            "-" +
            valueHidden.slice(5, 7) +
            "" +
            ssn.substr(7)
         } else {
            a = valueHidden.slice(0, 3) +
            "-" +
            valueHidden.slice(3, 5) +
            "-" +
            valueHidden.slice(5, 9)
         }
        this.setState({"ssn" :a });
    }

    blurZip = (event) => {
        if(event.target.value && event.target.value.trim().length > 0) {
            this.setState({"zipReq" : "", "zipFormat" : ""});
        }
        if(this.state.zipCodeCountry === "US") {
            var reg =/^\d{5}([-])?(\d{4})?$/gm;
            if(event.target.value.trim().length < 5 || !reg.test(event.target.value.trim())) {
                _.set(this.props, "statecityCodes.state_province_code", "");
                _.set(this.props, "statecityCodes.city.city_name", []);
                _.set(this.props, "statecityCodes.cityFlag", "N");
                this.setState({"zipFormat" : "Zip format is not correct. Try again"});
                return false;
            }
            else if(event.target.value.trim().length >= 5 && event.target.value.trim().length <= 10  ){
                this.props.getStateandCity(event.target.value);
            }

        } else if(this.state.zipCodeCountry === "CAN") {
            reg = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
            if(event.target.value.trim().length < 6 || !reg.test(event.target.value.trim())) {
                _.set(this.props, "statecityCodes.state_province_code", "");
                _.set(this.props, "statecityCodes.city.city_name", []);
                _.set(this.props, "statecityCodes.cityFlag", "N");
                this.setState({"zipFormat" : "Zip format is not correct. Try again"});
                return false;
            }
            else if(event.target.value.trim().length === 6 || event.target.value.trim().length === 7  ){
                // console.log("ccc")
                this.props.getStateandCity(event.target.value);
            }
        }
        showCityDetails = "YES";
       
        // this.props.getStateandCity(event.target.value);
    }
    
    changeZip = (event) => {
        //let zipCodeCountry = "";
        var selfInfo = {...this.state.selfInfo};
       
        if(event.target.value.trim().length === 1) {
            //console.log("ddd", isNaN(event.target.value));
            if(isNaN(event.target.value)) { // String
                this.setState({"zipMaxLength" : 7, "zipMinLength" : 6, "zipCodeCountry" : "CAN"});
                
                selfInfo["zip_code"] = event.target.value;
                this.setState({selfInfo});
                //zipCodeCountry = "CAN";
            } else {
                this.setState({"zipMaxLength" : 10, "zipMinLength" : 5,  "zipCodeCountry" : "US"});
                selfInfo["zip_code"] = event.target.value;
                this.setState({selfInfo});
                //zipCodeCountry = "US";
            }
            //this.setState({"zip" : event.target.value});
        } else {
            //if(this.state.zipCodeCountry === "US") {
                let regex = /^[0-9 -]+$/i
                if(event.target.value === "" || (event.target.value.trim().length !== 0 ||regex.test(event.target.value))) {
                    selfInfo["zip_code"] = event.target.value.trim();
                    this.setState({selfInfo, "zipCodeCountry" : "US"});
                }
            //}
            if(this.state.zipCodeCountry === "CAN") {
                let regex = /^[A-Za-z0-9 -]+$/i;
                if(event.target.value === "" || regex.test(event.target.value)) {
                    selfInfo["zip_code"] = event.target.value;
                    this.setState({selfInfo, "zipCodeCountry" : "CAN"});
                }
            }
        }
    }
    handleDataChange = (e) =>{
        var selfInfo = {...this.state.selfInfo};
        selfInfo["city"] = e.target.value
        this.setState({selfInfo});
    }

    handleClose = () => {
        this.setState({
            displayBanner:false
        })
        //document.getElementsByClassName('showbanner')[0].style.display = 'none';
    }

    primaryRadioButton =(e)=>{
        var selfInfo = {...this.state.selfInfo};
        selfInfo["primaryPhoneType"] = e.target.value;
        this.setState({selfInfo})
        if(e.target.value ==="H"){
            selfInfo["secondaryPhoneType"] = "C";
            this.setState({selfInfo})
        }
        if(e.target.value ==="C" ){
            selfInfo["secondaryPhoneType"] = "H";
            this.setState({selfInfo})
        }
        if(e.target.value!==""){
            this.setState({primarycheckBox:""})
        }
    }

    secondaryRadioButton =(e)=>{
        var selfInfo = {...this.state.selfInfo};
        selfInfo["secondaryPhoneType"] = e.target.value;
        this.setState({selfInfo});
    }

    primaryValidation = (e) => {
        let primaryPhone = _.get(this.state, "selfInfo.primaryPhone", []);
        if (primaryPhone.length > 0 && primaryPhone.length < 10) {
            this.setState({
                primaryphoneLength: "primary phone must be 10 digits",
                primaryphoneReq : ""
            })
        }
        if (primaryPhone.length === 0) {
            this.setState({
                primaryphoneLength: ""
                
            })
        }
        
        if (primaryPhone.length === 10) {
            this.setState({
                primaryphoneLength: "",
                primaryphoneReq : ""
            })
        }
    }

    secondaryValidation = () => {
        let secondaryPhone = _.get(this.state, "selfInfo.secondaryPhone", []);
        if (secondaryPhone.length > 0 &&  secondaryPhone.length < 10) {
               
            this.setState({
                secondaryPhoneLength: "Secondary phone must be 10 digits"
            })
        } 
       
        if (secondaryPhone.length === 0) {
            this.setState({
                secondaryPhoneLength: ""
            })
        }
        if (secondaryPhone.length === 10) {
            this.setState({
                secondaryPhoneLength: ""
            })
        }
    }

    emailValidation =() =>{
        
        let emailLength = _.get(this.state, "selfInfo.email_address" ,"").trim().length;
        this.props.getEmailAddress(_.get(this.state, "selfInfo.email_address", "").trim())
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //console.log("emailLength", emailLength);
        if(emailLength > 0) {
            this.setState({"emailRequired" : ""});
            if(!re.test( _.get(this.state, "selfInfo.email_address" ,"").trim())) {
                //console.log("in  valid");
                this.setState({"emailInvalid" : "Email format is example@domain.com"});
               
            }
        } else {
            this.setState({"emailRequired" : "", "emailInvalid" : ""});
        }
        
         if((re.test(String( _.get(this.state, "selfInfo.email_address" ,"").trim()).toLowerCase()))){
            this.setState({
                emailInvalid: ""
            })
         }
     
    }

    onChangeContactType =(e)=>{
        //console.log("jj", e.target.value);
        if(e.target.value !==""){
            this.setState({preferredContact : ""})
        }

        var selfInfo = {...this.state.selfInfo};
       
       //if(e.target.name ==="contact-phone"){
           if(e.target.value === "P"){
            selfInfo["contact_method"] = "P";
            this.setState({selfInfo});
           }
           if(e.target.value === "S"){
            selfInfo["contact_method"] = "S";
            this.setState({selfInfo});
            }
       //}
       if(e.target.value === "E"){
            selfInfo["contact_method"] = "E";
            this.setState({selfInfo});
        }
   }

    onSSNEye =(e)=>{ 
        this.setState({changeFlag : !this.state.changeFlag});
    }
    blurSSN = (e)=> {
        let actualssn = _.get(this.state, "selfInfo.actualSsn" ,  "");
        if(actualssn === null){
            actualssn = ""
        }
        if(actualssn.trim().length >0  && actualssn.trim().length < 9) {
            this.setState({"ssnLength" : "SSN must be 9 digits", "ssnReq" : ""});
        } 

       
        if (actualssn.trim().length === 0) {
            this.setState({
                ssnLength: ""
                
            })
        }
        
        if (actualssn.trim().length === 9) {
            this.setState({
                ssnLength: "",
                ssnReq : ""
            })
        }
    }
    
    onBlurRequired = (e, errorField) => {
        let value = e.target.value.trim();
        if(value && value.length > 0) {
            this.setState({[errorField] : ""});
        }
    }

    render() {
        //console.log("dov", _.get(this.state, "selfInfo.dob", null));
        console.log(_.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info",{}),"kkkk")
        //console.log(this.props.prefillData,this.props.prefillData)
        // if(
        // _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip", "").trim().length <= 0) {
        //     _.set(this.props, "statecityCodes.state_province_code", "");
        //     _.set(this.props, "statecityCodes.city.city_name", []);
        //     _.set(this.props, "statecityCodes.cityFlag", "");
        //     //console.log("city", _.get(this.props, "statecityCodes.cityFlag"));
        // }
        //let bornState = statesUS.concat(statesCan);
        let stateZipCode = {};
        if(showCityDetails === "YES") {
             stateZipCode = _.get(this.props,"statecityCodes", "");
        }
        else if(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip_code", "") && 
            _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip_code", "").trim().length <= 0)
        {
            _.set(this.props, "statecityCodes.state_province_code", "");
            _.set(this.props, "statecityCodes.city.city_name", []);
            _.set(this.props, "statecityCodes.cityFlag", "");
        } 
        else if(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip_code", "") && 
            _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip_code", "").trim().length > 0)
        {
            stateZipCode = _.get(this.props,"statecityCodes", "");
        }
        
        //console.log("stateZipCode", stateZipCode);

        let stateProvCode = _.get(stateZipCode,"state_province_code", "");
        let cityName = _.get(stateZipCode, "city.city_name", []);
            // cityName= ["dd"];
            // _.set(this.props, "statecityCodes.cityFlag", "Y");
        let zipErrMsg = "";

        if( _.get(this.props, "statecityCodes.cityFlag", "") === "N") {
            zipErrMsg = "Invalid zip";
            cityName = "";
            stateProvCode = "";
            if(document.getElementById("eoi-city")) {
                document.getElementById("eoi-city").disabled = true;
            }
        }
        //let citydisabledFlag = true;
        if(cityName.length === 1 &&  _.get(this.props, "statecityCodes.cityFlag", "") === "Y") {
            if(document.getElementById("eoi-city")) {
                document.getElementById("eoi-city").disabled = true;
            }
        }
        if(cityName.length >1 &&  _.get(this.props, "statecityCodes.cityFlag", "") === "Y") {
            //document.getElementById("eoi-city") &&
            //document.getElementById("eoi-city").removeAttribute('disabled');
            

        }
        let a = _.get(this.state,"zip_code_disb",null)
        // console.log(a)
        let b = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.zip_code", "")
        let tempCity =  _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.city", "")
        let tempState = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.state", "")
        let lowerCaseCityName = cityName && cityName.map(a=>{return a.toLowerCase()})
        // console.log(cityName,lowerCaseCityName)
        //let stateProvinceCode = _.get(stateZipCode,"state_province_code", "");
        if(b && cityName && lowerCaseCityName.includes(tempCity.toLowerCase()) && stateProvCode && stateProvCode === tempState){
            // console.log("111111111")
            //selfInfo["zip_code_disb"] = true
            document.getElementById("eoi-city") && (document.getElementById("eoi-city").disabled = true);
            //selfInfo["city_disb"] = true
        }
        // if(a!==null && a === true){
        //     console.log("111111111")
        //     document.getElementById("eoi-city").disabled = true;
        // }
        else if(cityName.length >1 &&  _.get(this.props, "statecityCodes.cityFlag", "") === "Y" && a!==true) {
            // console.log("2222222222222")
            document.getElementById("eoi-city") &&
            document.getElementById("eoi-city").removeAttribute('disabled');
            

        }
        let emailValid = _.get(this.props, "isEmailValid", true)
        // let emailLength = _.get(this.state, "selfInfo.email_address", "").trim().length;
        return (
            <>
            {this.state.isSaved === true ? <SaveForLater continueEOI={()=>this.continueEOI()} leave = {()=> this.leave()}/> : null}
            {this.state.isOpen === true ? <CheckPopup handlePopUp={()=>this.handlePopUp()}/> : null}
            {this.state.isDuplicate === true ?  <DuplicatePopup handleDuplicate={()=> this.handleDuplicate()} isTrusted={this.props.trustedFlag}/>: null}
            {this.state.displayBanner && 
            (this.state.ssnReq || this.state.ssnLength|| this.state.firstNameReq|| this.state.lastNameReq|| this.state.dobReq||
             this.state.blurError|| this.state.genderReq|| this.state.addressReq ||this.state.zipFormat|| this.state.zipReq||
              this.state.zipErrMsg|| this.state.primaryphoneReq|| this.state.primaryphoneLength||
               this.state.primarycheckBox ||this.state.secondaryPhoneLength|| this.state.secondarycheckBox||this.state.emailRequired ||this.state.emailInvalid||
                this.state.preferredContact)?
            <AlertBanner handleClose ={() =>this.handleClose()} errorMessage="Please fill out all required fields."/>
            :
            null}
            <main>
                <div class="container-fluid">
                    <div class="prev-link">
                        <button class="btn btn-link" onClick={() => this.onBack()}><i class="fa fa-arrow-left"></i>Back</button>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                            <div class="intro">
                            <h1 style={{ fontSize: '32px' }}>Tell us about yourself</h1>
                            </div>
                            <div class="form-group">
                                <label for="ssn">SSN</label>
                                <div class="input-group">
                                    <input 
                                    //class="form-control" 
                                    className={`form-control ${this.state.ssnReq || this.state.ssnLength ? 'invalid' : ''}`}
                                                ref={(input) => {
                                                this.ssnInput = input;
                                                }}
                                                id="ssn"
                                                type="tel"
                                                onBlur={(e) => this.blurSSN(e)}
                                                value={!this.state.changeFlag?_.get(this.state, "selfInfo.maskedSsn", ""): _.get(this.state, "selfInfo.numberedSsn", "")}
                                                onChange={this.handleChange}
                                                disabled={_.get(this.state, "selfInfo.maskedSsn_disb")}
                                                //style={this.state.ssnReq || this.state.ssnLength ? {border: " 2px solid #BC3D19"}  : null}
                                            />
                                    <div class="input-group-append">
                                        <button aria-label="View social security number" class="btn btn-right hidden-text" onClick={(e)=>this.onSSNEye(e)} 
                                        type="button"><i class={!this.state.changeFlag?"fas fa-eye-slash" : "fas fa-eye"}><span>pass-btn</span></i></button>
                                    </div>
                                   
                                </div>
                                {this.state.ssnReq ? 
                                    <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.ssnReq}</span> : this.state.ssnLength ?
                                    <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.ssnLength}</span>  : null  }
                            </div>

                            <div class="row form-group">
                                <div class="col-md-10 col-sm-9">
                                    <label for="first-name">First name</label>
                                    <input 
                                    //class="form-control invalid" 
                                    className={`form-control ${this.state.firstNameReq ? 'invalid' : ''}`}
                                    id="first-name" maxLength="30" 
                                    onBlur={(e) => this.onBlurRequired(e, "firstNameReq")}
                                    type="text" value={_.get(this.state, "selfInfo.first_name")} 
                                    onChange={(e) => this.onChangeName(e,"first_name")}
                                    disabled={_.get(this.state, "selfInfo.first_name_disb")}
                                    />
                                {this.state.firstNameReq ?
                                <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.firstNameReq}</span> : null }
                                </div>
                                <div class="col-md-2 col-sm-3">
                                    <label for="mi">MI</label>
                                    <input class="form-control" id="mi" 
                                    onChange={(e) => this.changeTextObjectFields(e,"middle_initial", this.state.alpha )}
                                    maxLength="1"
                                    value={_.get(this.state, "selfInfo.middle_initial","")}
                                    disabled={_.get(this.state, "selfInfo.middle_initial_disb")}
                                    type="text" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="last-name">Last name</label>
                                <input
                                 className={`form-control ${this.state.lastNameReq ? 'invalid' : ''}`} 
                                 id="last-name" type="text" maxLength="30"
                                 value={_.get(this.state, "selfInfo.last_name")} onBlur={(e) => this.onBlurRequired(e, "lastNameReq")}
                                 onChange={(e) => this.onChangeName(e, "last_name")} 
                                 disabled={_.get(this.state, "selfInfo.last_name_disb")}
                                 />
                                 {this.state.lastNameReq ?
                                 <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.lastNameReq}</span> : null }
                            </div>
                            <div class="row form-group ">
                                <div class="col-8">
                                <label for="date-picker-dialog">Date of birth</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                        KeyboardButtonProps={
                                            { 'aria-label': 'Date Picker' }
                                        }
                                            id="date-picker-dialog"
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            value={parseISO(_.get(this.state, "selfInfo.dob", null))}
                                            onChange={this.changeEvent}
                                            onBlur={this.handleDateChange}
                                            placeholder="MM/DD/YYYY"
                                            autoOk={true}
                                            onOpen={this.handleOnOpen}
                                            onClose={this.handleOnClose}
                                            maxDate={new Date()}
                                            //disablePast={true}
                                            error={(this.state.blurError || this.state.dobReq) ? 'invalid' : ''}
                                            helperText={null}
                                            disabled={_.get(this.state, "selfInfo.dob_disb")} 
                                        />
                                    </MuiPickersUtilsProvider>
                                    {
                                    this.state.blurError===true? 
                                    <span className="error"><i class="fas fa-exclamation-circle"></i>Date of birth is invalid</span> :
                                    this.state.dobReq ? 
                                    <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.dobReq}</span> 
                                    
                                    : null }
                                </div>
                            </div>
                            {/* // dropdown arrow / */}
                            <div class="form-group">
                                <label for="eoi-gender">Gender</label>
                                <select id="eoi-gender"
                                className={`form-control ${this.state.genderReq ? 'invalid' : ''} ${!_.get(this.state, "selfInfo.gender") ? 'placeholder' : ''}`}
                                value={_.get(this.state, "selfInfo.gender")} 
                                onBlur={(e) => this.onBlurRequired(e, "genderReq")}
                                disabled={_.get(this.state, "selfInfo.gender_disb")}
                                onChange={(e) => this.genderChange(e)} >
                                    <option value="" selected >Select </option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                </select>
                                {this.state.genderReq ? 
                                <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.genderReq}</span>: null }
                            </div>
                            <div class="form-group">
                                <label for="address">Address</label>
                                <input className={`form-control ${this.state.addressReq ? 'invalid' : ''}`}
                                value={_.get(this.state, "selfInfo.address_line1")}
                                onBlur={(e) => this.onBlurRequired(e, "addressReq")}
                                onChange={(e) => this.changeTextObjectFields(e,"address_line1", this.state.alphaNum )}
                                disabled={_.get(this.state, "selfInfo.address_line1_disb")}
                                id="address" type="text" maxLength="30"/>
                                
                                {this.state.addressReq ?
                                <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.addressReq}</span> : null}
                            </div>
                            <div class="form-group">
                                <label for="suite">Suite, Apt <span>(optional)</span> </label>
                                <input class="form-control" id="suite" 
                                value={_.get(this.state, "selfInfo.address_line2", "")}
                                onChange={(e) => this.changeTextObjectFields(e,"address_line2", this.state.alphaNum )}
                                disabled={_.get(this.state, "selfInfo.address_line2_disb")}
                                type="text" maxLength="30"/>
                            </div>
                            <div class="row form-group">
                                <div class="col-lg-6 col-md-5 col-sm-7">
                                <label for="zip">Zip</label>
                                <input className={`form-control ${(zipErrMsg || this.state.zipFormat || this.state.zipReq) ? 'invalid' : ''}`}
                                 value={_.get(this.state, "selfInfo.zip_code")}
                                 id="zip" type="text" maxLength={this.state.zipMaxLength}
                                 aria-describedby="zip-error"
                                 onChange={(e) => this.changeZip(e)}
                                 onBlur={(e) => this.blurZip(e)}
                                 disabled={_.get(this.state, "selfInfo.zip_code_disb")}
                                 />
                                 
                                </div>
                                <div class="col-lg-12">
                                <p id="zip-error">   
                                {this.state.zipReq ?
                                <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.zipReq}</span> : 
                                 this.state.zipFormat ? <span className="error"><i class="fas fa-exclamation-circle"></i>
                                 {this.state.zipFormat}</span> : zipErrMsg ? <span className="error"><i class="fas fa-exclamation-circle"></i>
                                 {zipErrMsg}</span>:
                                 null} 
                                 </p>
                                 </div>         
                            </div>
                            <div class="row form-group">
                            <div class="col-lg-10 col-md-10 col-sm-11">
                                <label for="eoi-city">City</label>
                                {/* {cityName && cityName.map(city => (
                                    city === selfInfo.city?
                                     <select className={`form-control ${cityName.length <= 0 ? 'placeholder' : ''}`}
                                    id="eoi-city" disabled value={selfInfo.city}>
                                    <option value={city} key={index}>{city}</option>
                                    </select>
                                ))} */}
                                <select className={`form-control ${cityName.length <= 0 ? 'placeholder' : ''}`}
                                //disabled={_.get(this.state, "selfInfo.zip_code_disb")} 
                                 id="eoi-city" value = {_.get(this.state, "selfInfo.city")} onChange={(e)=>this.handleDataChange(e)}
                                 disabled
                                  >
                                
                               
                                {cityName.length === 0 ? <option disabled selected >Select</option> : 
                                cityName.length === 1? cityName.map((city,index) => (
                                    <option value={city} key={index}>{city}</option>
                                )) :
                                    cityName.map((city,index) => (
                                        <option value={city} key={index}>{city}</option>
                                    ))   }
                                </select>  
                            </div>
                            </div>
                            {/* // dropdown arrow / */}
                            <div class="row form-group">
                            <div class="col-lg-6 col-md-5 col-sm-7">
                                    <label for="state">State / Province </label>
                                    <input class="form-control" value={stateProvCode}
                                    id="state" type="text" disabled />
                                </div>
                            </div>

                            <h2 style={{ fontSize: '20px' }} class="mt-5 pb-4">Contact information</h2>
                            <div class="form-group">
                                <label for="primary-phone">Primary phone</label>
                                <NumberFormat format ="###-###-####" 
                                    id="primary-phone" 
                                    className={`form-control ${(this.state.primaryphoneReq || this.state.primaryphoneLength) ? 'invalid' : ''}`}
                                    //onChange={(e)=>this.props.handlePhone(e)}
                                    onValueChange = {this.handlePhone}
                                    // aria-invalid={true}
                                    aria-describedby="contact-error"
                                    value={_.get(this.state, "selfInfo.primaryPhone")} 
                                    onBlur={(e)=>this.primaryValidation(e)} />
                                <p id="contact-error">
                                {this.state.primaryphoneLength ? 
                                <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.primaryphoneLength}</span>
                                : this.state.primaryphoneReq ?<span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.primaryphoneReq}</span> : null
                                }
                                </p>
                            </div>
                            <fieldset class="form-group">
                                <div class="radio">
                                    <input type="radio" id="phone-home1" 
                                    aria-label="Home Radio Button"
                                    value="H" name="primary-phone" 
                                    checked={(_.get(this.state, "selfInfo.primaryPhoneType") === "H" )}
                                    onChange={(e) => this.primaryRadioButton(e)} />
                                    <label for="phone-home1">
                                        <span>Home</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <input type="radio" id="phone-mobile1" 
                                    aria-label="Mobile Radio Button"
                                    value="C" name="primary-phone" 
                                    checked={(_.get(this.state, "selfInfo.primaryPhoneType") === "C")}
                                    onChange={(e) => this.primaryRadioButton(e)} />
                                    <label for="phone-mobile1">
                                        <span>Mobile</span>
                                    </label>
                                </div>
                                {this.state.primarycheckBox ?
                                <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.primarycheckBox}</span> : null }
                            </fieldset>
                            
                            <div class="form-group">
                                <label for="secondary-phone">Secondary phone <span>(optional)</span> </label>
                                <NumberFormat format ="###-###-####" 
                                    id="secondary-phone" 
                                    aria-describedby="digit-error"
                                    className={`form-control ${this.state.secondaryPhoneLength ? 'invalid' : ''}`}
                                    onBlur={()=>this.secondaryValidation()}
                                    onValueChange = {this.handleSecPhone}
                                    value={_.get(this.state, "selfInfo.secondaryPhone", "")} 
                                    />
                                    <p id="digit-error">
                                        {this.state.secondaryPhoneLength ?
                                        <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.secondaryPhoneLength}</span> : null }
                                    </p>
                            </div>
                            <fieldset class="form-group showHide">
                                <div class="radio">
                                    <input type="radio" id="phone-home2" 
                                    value="H" name="secondary-phone" 
                                    checked={_.get(this.state, "selfInfo.secondaryPhoneType") === "H" } 
                                    disabled={_.get(this.state, "selfInfo.primaryPhoneType")==="H"}
                                    onChange={(e) => this.secondaryRadioButton(e)} />
                                    <label for="phone-home2">
                                        <span>Home</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <input type="radio" id="phone-mobile2" 
                                    value="C" name="secondary-phone" 
                                    checked={_.get(this.state, "selfInfo.secondaryPhoneType") === "C"}
                                    disabled={_.get(this.state, "selfInfo.primaryPhoneType") ==="C"}
                                    onChange={(e) => this.secondaryRadioButton(e)} />
                                    <label for="phone-mobile2">
                                        <span>Mobile</span>
                                    </label>
                                </div>
                                {this.state.secondarycheckBox ?
                                <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.secondarycheckBox}</span> : null}
                            </fieldset>

                            <div class="form-group">
                                <label for="email-address">Email address</label>
                                <input className={`form-control ${(this.state.emailRequired || this.state.emailInvalid) ? 'invalid' : ''}`}
                                onChange={(e)=>this.handleEmail(e)}
                                // aria-invalid={true}
                                aria-describedby="email-error"
                                value={_.get(this.state, "selfInfo.email_address")}
                                onBlur={this.emailValidation}
                                id="email-address" maxLength="50" type="text"
                                />
                                <p id="email-error">
                               {this.state.emailRequired ?  
                                <span id="emailRequired-error" className="error"><i class="fas fa-exclamation-circle"></i>{this.state.emailRequired}</span>
                                :  this.state.emailInvalid ?  <span id="emailInvalid-error" className="error"><i class="fas fa-exclamation-circle"></i>{this.state.emailInvalid}</span> : null
                                }
                               {!emailValid &&
                                    <span  id="emailValid-error" aria-label="Email is Invalid" className="error"><i className="fas fa-exclamation-circle"></i>{'Email is Invalid'}</span>
                                } 
                                </p>
                            </div>
                            <fieldset class="form-group">
                                <legend class="data-label">What’s your preferred contact method?</legend>
                                <div class="radio mt-3">
                                    <input type="radio" id="contact-primary-phone" value="P" 
                                    aria-describedby="preferredcontact-error" 
                                    checked={_.get(this.state, "selfInfo.contact_method") === "P"}
                                    name="contact-phone" onChange={(e) => this.onChangeContactType(e)} />
                                    <label for="contact-primary-phone">
                                        <span>Primary phone</span>
                                    </label>
                                </div>
                                <div class="radio secondaryradioHide">
                                    <input type="radio" id="secondary-mobile" value="S"
                                    aria-describedby="preferredcontact-error"  
                                     checked={_.get(this.state, "selfInfo.contact_method") === "S"}
                                    name="contact-phone" onChange={(e) => this.onChangeContactType(e)} />
                                    <label for="secondary-mobile">
                                        <span>Secondary phone</span>
                                    </label>
                                </div>
                                <div class="radio">
                                    <input type="radio" id="email" value="E"  name="contact-phone"  
                                    checked={_.get(this.state, "selfInfo.contact_method") === "E"}
                                    aria-describedby="preferredcontact-error"
                                    onChange={(e) => this.onChangeContactType(e)} />
                                    <label for="email">
                                        <span>Email</span>
                                    </label>
                                </div>
                                <p id="preferredcontact-error">
                                {this.state.preferredContact ?
                                <span className="error"><i class="fas fa-exclamation-circle"></i>{this.state.preferredContact}</span> : null }
                                </p>
                            </fieldset>
                            <hr/>
                            {/* <div class="field-group float-right">
                                <button class="btn btn-primary" onClick={(e)=>this.onSubmit(e)}>Next</button>
                            </div> */}
                            <div className="field-group align-center">
                                    <button className="btn btn-link pt-4" onClick={()=>this.saveForLater()}>Save for later</button>
                                    {_.get(this.props,"location.state.previousPage") === "review" ?
                             <button className="btn btn-primary float-right"  onClick={(e)=>this.onSubmit(e)}> Return to review page </button>:
                                <button className="btn btn-primary float-right btn-double-line" onClick={(e)=>this.onSubmit(e)}>
                               Submit Personal Information
                                </button>                   
    }        
                               
                            </div>
                        </div>
                    </div>


                </div>
            </main>
        </>
        );
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(ContactInfo);






// import React, { Component } from "react";

// import _ from "lodash";
// import NumberFormat from "react-number-format";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";

// import AlertBanner from "../../components/AlertBanner/AlertBanner";
// import { actionCreators } from "../../store/HomePage/HomePageAction";
// import { scrollUp } from "../../utils/sharedFunction";

// // let flag=true
// class ContactInfo extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             contact: {},
//            primaryPhone : "",
//            secondaryPhone : "",
//             email: "",
//             primaryPhoneType: "",
//             secondaryPhoneType: "",
//             emailError: "",
//             contactMethod: "",
//             phoneError: "",
//             phoneError1:"",
//             typeError1:"",
//             typeError3: "",
//             flag :true,
//             phoneError2:"",
//             //phoneError3:""
//         }
//     }
//     handleClose = () => {
//         this.setState({flag : true
//         });
//       }
//     primary = (values) => {
//                 this.setState({
//                     primaryPhone: values.value })
//     }
    
//     secondary = (values) => {
//             this.setState({
//                 secondaryPhone: values.value})
//     }
//     handleEmail = (e) => {
//             this.setState({
//                 email: e.target.value
//             })
//     }
// emailValidation =() =>{
//     const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     let mail =this.state.email;
//     // if (mail === "" || mail === null) {
//     //     this.setState({
//     //         emailError: " Email address is required"
//     //     })
//     // }
//     if ( (mail.length >1) && !(re.test(String(mail).toLowerCase())) ) {
//         this.setState({
//             emailError: " Email format is example@domain.com"
//         })
//      }
//      if((re.test(String(mail).toLowerCase()))){
//         this.setState({
//             emailError: ""
//         })
//      }
 
// }

// primaryValidation = (e) => {
//     if (this.state.primaryPhone.length > 0 && this.state.primaryPhone.length < 10) {
//         this.setState({
//             phoneError: " primary phone must be 10 digits"
//         })
//     } 
//     // if(this.state.primaryPhone.length<10){
//     //     this.setState({
//     //         typeError1: ""
//     //     })
//     // }
//     // else if (this.state.primaryPhone.length === 0) {
//     //     this.setState({
//     //         phoneError: " primary phone is required"
//     //     })
//     // }
//     if (this.state.primaryPhone.length === 10) {
//         this.setState({
//             phoneError: ""
//         })
//     }
// }
//     secondaryValidation = () => {
//         if (this.state.secondaryPhone.length > 0 && this.state.secondaryPhone.length < 10) {
//             this.setState({
//                 phoneError1: " secondary phone must be 10 digits"
//             })
//         } 
//         if (this.state.secondaryPhone.length === 10) {
//             this.setState({
//                 phoneError1: ""
//             })
//         }
//     }
//  onChangeContactType =(e)=>{
//      if(e.target.value !==""){
//          this.setState({typeError3 : ""})
//      }
    
//     if(e.target.name ==="contact-method"){
//         if(e.target.value === "P"){
//         this.setState({ contactMethod: "P" })
//         }
//         if(e.target.value === "S"){
//             this.setState({ contactMethod: "S" })
//             }
//     }
//     if(e.target.value === "E"){
//         this.setState({ contactMethod: "E" })
//         }
// }

//     componentDidMount() {
//         const employee = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", "");
//             //console.log(employee)
//         //  let status =   _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.confirm_contact_status","")
//         //console.log("status--",status);
        
//          if(employee.primary_phone_type === "H" ){
//         this.setState({
//             primaryPhone: employee.home_phone1 + employee.home_phone2 + employee.home_phone3,
//             primaryPhoneType :employee.primary_phone_type
//         })
//     }
//     if(employee.primary_phone_type === "C" ){
//         this.setState({
//             primaryPhone: employee.cell_phone1 + employee.cell_phone2 + employee.cell_phone3,
//             primaryPhoneType :employee.primary_phone_type
//         })
//     }
//     if(employee.secondary_phone_type === "H" ){
//         this.setState({
//             secondaryPhone: employee.home_phone1 + employee.home_phone2 + employee.home_phone3,
//             secondaryPhoneType :employee.secondary_phone_type
//         })
//     }
//     if(employee.secondary_phone_type === "C" ){
//         this.setState({
//             secondaryPhone: employee.cell_phone1 + employee.cell_phone2 + employee.cell_phone3,
//             secondaryPhoneType :employee.secondary_phone_type
//         })
//     }
//     if(employee.email_address !== null || employee.email_address !== ""){
//         //console.log(status, "STATUS");
//         //if(status==="ACTIVE"){
//         //     this.setState({email :""}) ;
//             //console.log("this.state.email",this.state.email,employee.email_address)  
//         //  } else if(status==="COMPLETED"){
//             this.setState({email: employee.email_address})
//          //}
//     }

//     if(employee.contact_method === "P" || employee.contact_method === "S" ||employee.contact_method === "E"){
// this.setState({contactMethod:employee.contact_method})
//     }
//     scrollUp();
//     }
    

    

//  primaryRadioButton =(e)=>{
//         this.setState({ primaryPhoneType: e.target.value})
//         if(e.target.value ==="H"){
//             this.setState({secondaryPhoneType:"C"})
//         }
//         if(e.target.value ==="C"){
//             this.setState({secondaryPhoneType:"H"})
//         }
//         if(e.target.value!==""){
//             this.setState({typeError1:""})
//         }
//         }
        
//         secondaryRadioButton =(e)=>{
//             this.setState({ secondaryPhoneType: e.target.value}) 
//         }

//     onPrevious = (e) => {
//         this.props.history.push('/eoi/benefits')
//     }
//     handleNext = () => {
//         if(!this.state.primaryPhone || !this.state.primaryPhoneType 
//         || !this.state.email  || !this.state.contactMethod){
//            this.setState({
//             flag : false
//            }) 
//         }
            
//         if (this.state.primaryPhone && this.state.primaryPhoneType === "H") {
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone1", this.state.primaryPhone.substring(0, 3))
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone2", this.state.primaryPhone.substring(3, 6))
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone3", this.state.primaryPhone.substring(6, 10))
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.primary_phone_type", this.state.primaryPhoneType)
            
//         } 
//         if(this.state.secondaryPhone && this.state.secondaryPhoneType === "H"){
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone1", this.state.secondaryPhone.substring(0, 3))
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone2", this.state.secondaryPhone.substring(3, 6))
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.home_phone3", this.state.secondaryPhone.substring(6, 10))
            
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.secondary_phone_type", this.state.secondaryPhoneType)
//         }
//         if (this.state.primaryPhone && this.state.primaryPhoneType === "C") {

//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone1", this.state.primaryPhone.substring(0, 3))
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone2", this.state.primaryPhone.substring(3, 6))
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone3", this.state.primaryPhone.substring(6, 10))
           
//                 _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.primary_phone_type", this.state.primaryPhoneType)
//         }
//         if(this.state.secondaryPhone && this.state.secondaryPhoneType === "C"){
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone1", this.state.secondaryPhone.substring(0, 3))
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone2", this.state.secondaryPhone.substring(3, 6))
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.cell_phone3", this.state.secondaryPhone.substring(6, 10))
           
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.secondary_phone_type", this.state.secondaryPhoneType)
//         }
//         if (this.state.email !== "") {
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.email_address", this.state.email)
//         }
//         if (this.state.contactMethod !== "") {
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.contact_method", this.state.contactMethod)
//         }
//         if(this.state.primaryPhone.length ===0){
//             this.setState({phoneError:" primary phone is required"})
//            } 
//            if (this.state.email === "" || this.state.email === null) {
//             this.setState({
//                 emailError: " Email address is required"
//             })
//         }
//          if((this.state.contactMethod !=="P" && this.state.contactMethod !=="S" && this.state.contactMethod !=="E")){
//             this.setState({typeError3:" Select preferred contact method"})  
//             }
//         //  else  if(this.state.primaryPhone.length>0 && this.state.primaryPhone.length<10){
//         //     this.setState({ phoneError: " primary phone must be 10 digits"})
//         //    } 
//         if(this.state.primaryPhone.length===10 && (this.state.primaryPhoneType!=="C" && this.state.primaryPhoneType!=="H")){
//             //console.log("specify type--",this.state.primaryPhone.length)
//             this.setState({typeError1:" Specify type of phone number"})
//            }
//            if(this.state.primaryPhone.length<10){
//             this.setState({
//                 typeError1: ""
//             })
//         }  
            
//            if(this.state.primaryPhone && this.state.primaryPhoneType && this.state.email && this.state.contactMethod){
//             _.set(this.props,"prefillData.eoi_data.benefit_coverage_info.confirm_contact_status","COMPLETED")
//             _.set(this.props, "prefillData.eoi_data.benefit_coverage_info_status","COMPLETED")
//             _.set(this.props, "prefillData.eoi_data.health_question_info_status","START")
//             this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""),'/eoi/homePage')
//             //this.props.history.push(`/eoi/homePage`)
//            }
//     }

//     render() {
//         //console.log("props", this.props);
//         return (
//             <>
// {this.state.flag === false &&
//  (!this.state.primaryPhone || !this.state.primaryPhoneType || !this.state.email || !this.state.contactMethod) 
//  ? 
//  <AlertBanner
//     handleClose ={() =>this.handleClose()}
//     errorMessage="Please fill out all required fields."/> 
//  :null}
//                 <main>
//                     <div className="container-fluid">
//                         <div className="prev-link">
//                             <button className="btn btn-link" onClick={(e) => this.onPrevious(e)}><i className="fa fa-arrow-left"></i>Back</button>
//                         </div>
//                     </div>
//                     <div className="container">
//                             <div className="row">
//                             <div className="col-lg-5">
//                                 <div className="intro">
//                                     <h3>Confirm your contact information</h3>
//                                 </div>
//                                 <div className="form-group">
//                                     <label htmlFor="primary-phone">Primary phone</label>
//                                         <NumberFormat format="(###) ###-####" id="primary-phone"
//                                             className={`form-control ${this.state.phoneError.length >1 ? 'invalid' : ''}`}
//                                             value={this.state.primaryPhoneType === "H"? this.state.primaryPhone:this.state.primaryPhone}
//                                             onBlur={(e)=>this.primaryValidation(e)}
//                                             onValueChange={(values) => this.primary(values)}  />
//                                      {this.state.phoneError.length >1 ?
//                                      <span className="error"><i className="fas fa-exclamation-circle"></i>{this.state.phoneError}</span>
                                     
//                                 : null}
                                
//                                      </div>
//                                 <fieldset className="form-group">
//                                     <div className="radio">
//                                         <input type="radio" id="phone-home1"  name="primary-phone"
//                                          value="H"   checked={(this.state.primaryPhoneType === "H" )}
//                                         // disabled={this.state.secondaryPhoneType==="H"}
//                                             onChange={(e) => this.primaryRadioButton(e)} />
//                                         <label htmlFor="phone-home1">
//                                             <span>Home</span>
//                                         </label>
//                                     </div>
//                                     <div className="radio">
//                                         <input type="radio" id="phone-mobile1"  name="primary-phone"
//                                             checked={(this.state.primaryPhoneType === "C")}
//                                             //disabled={this.state.secondaryPhoneType==="C"}
//                                           value="C"  onChange={(e) => this.primaryRadioButton(e)} />
//                                         <label htmlFor="phone-mobile1">
//                                             <span>Mobile</span>
//                                         </label>
//                                     </div>
//                                     {this.state.typeError1.length >1 ?
//                                      <span className="error"><i className="fas fa-exclamation-circle"></i>{this.state.typeError1}</span>
//                                 : null}
//                                 </fieldset>
//                                 {/* // fieldset part of the form group / */}
//                                 <div className="form-group">
//                                     <label htmlFor="secondary-phone">Secondary phone <span>(optional)</span> </label>

//                                         <NumberFormat format="(###) ###-####"  id="secondary-phone"
//                                         className={`form-control ${this.state.phoneError1.length>1 ? 'invalid' : ''}`}
//                                             value={this.state.secondaryPhoneType === "H"?this.state.secondaryPhone:this.state.secondaryPhone}
//                                         onBlur={()=>this.secondaryValidation()}
//                                             onValueChange={(values) => this.secondary(values)} />
                                    
//                                     {this.state.phoneError1.length>1 ?
//                                         <span className="error"><i className="fas fa-exclamation-circle"></i>{this.state.phoneError1}</span>
//                                       : null }
//                                         </div>
//                                         {this.state.secondaryPhone &&
//                                 <fieldset className="form-group">
                                    
//                                     <div className="radio">
//                                         <input type="radio" id="phone-home2" value="H" name="secondary-phone"
//                                             checked={this.state.secondaryPhoneType === "H" } 
//                                             disabled={this.state.primaryPhoneType==="H"}
//                                             onChange={(e) => this.secondaryRadioButton(e)} />
//                                         <label htmlFor="phone-home2">
//                                             <span>Home</span>
//                                         </label>
//                                     </div>
//                                     <div className="radio">
//                                         <input type="radio" id="phone-mobile2" value="C" name="secondary-phone"
//                                             checked={this.state.secondaryPhoneType === "C"}
//                                             disabled={this.state.primaryPhoneType==="C"}
//                                             onChange={(e) => this.secondaryRadioButton(e)} />
//                                         <label htmlFor="phone-mobile2">
//                                             <span>Mobile</span>
//                                         </label>
                                       
//                                     </div>
//                                 </fieldset>
//                                 }

//                                 <div className="form-group">
//                                     <label htmlFor="email-address">Email address</label>
//                                     <input className={`form-control ${this.state.emailError.length>1 ? 'invalid' : ''}`}
//                                      id="email-address" type="text" 
//                                     onChange={(e) => this.handleEmail(e)} onBlur={this.emailValidation}
//                                         value={this.state.email} minLength="1" maxLength="50"/>
//                                       {this.state.emailError.length >1 ?   
//                                     <span className="error"><i className="fas fa-exclamation-circle"></i>{this.state.emailError}</span>
//                             : null }
//                                     </div>


//                                 <fieldset className="form-group pb-3">
//                                     <legend className="data-label">What’s your preferred contact method?</legend>
//                                     <div className="radio mt-4">
//                                         <input type="radio" id="contact-primary-phone" value="P"
//                                             name="contact-method" checked={this.state.contactMethod === "P"}
//                                             onChange={(e) => this.onChangeContactType(e)} />
//                                         <label htmlFor="contact-primary-phone">
//                                             <span>Primary phone</span>
//                                         </label>
//                                     </div>
//                                     {(this.state.secondaryPhone) ?
//                                         <div className="radio">
//                                             <input type="radio" id="contact-secondary-phone" value="S"
//                                                 name="contact-method" checked={this.state.contactMethod === "S"}
//                                                 onChange={(e) => this.onChangeContactType(e)} />
//                                             <label htmlFor="contact-secondary-phone">
//                                                 <span>Secondary phone</span>
//                                             </label>
//                                         </div>
//                                         : null
//                                     }
//                                     <div className="radio">
//                                         <input type="radio" id="email"  name="contact-method"
//                                             checked={this.state.contactMethod === "E"}value="E"
//                                             onChange={(e) => this.onChangeContactType(e)} />

//                                         <label htmlFor="email">
//                                             <span>Email</span>
//                                         </label>
//                                     </div>
//                                     {this.state.typeError3.length >1 ?
//                                      <span className="error"><i className="fas fa-exclamation-circle"></i>{this.state.typeError3}</span>
//                                 : null}
//                                 </fieldset>
//                                 {/* // fieldset part of the form group / */}
//                                 <hr className="mt-5" />
//                                 <div className="field-group">
//                             <button className="btn btn-link" onClick="">Save for later</button>
//                             <button className="btn btn-primary float-right" onClick={() => this.handleNext()}>Next</button>
//                         </div>
//                             </div>
//                         </div>
                        

//                     </div>
//                 </main>

//             </>
//         )
//     }
// }
// export default connect(
//     (state) => state.eoiStore,
//     (dispatch) => bindActionCreators(actionCreators, dispatch))(ContactInfo);

import React from 'react';
import {imagePath} from '../../utils/sharedFunction'

const PageHeader= props => (
    <>
    <header>
        <div className="col-md-12">
            {/* <a className="menu-icon" href="/#">
                <img src={imagePath("./images/Icon_Menu.svg")} alt="Menu icon" />
            </a> */}
            <a href="/#">
                <img className="logo" src={imagePath("./images/guardian_logo.svg")} alt="Guardian Logo" />
            </a>
        </div>
    </header>
    </>
);

export default PageHeader

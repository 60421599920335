import React, { Component } from "react";

import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";


import HomePageHeader from "../../components/HomePageHeader/HomePageHeader";
import HomePageSteps from "../../components/HomePageSteps/HomePageSteps";
import { actionCreators } from "../../store/HomePage/HomePageAction";
import {
  chooseNextQuestion,
  scrollUp,
  imagePath,
  //moveToPreviousQuestion
} from "../../utils/sharedFunction";

let activeQ = "" 
class HomePage extends Component {
    handleTrustedFlowClick = (step) => {
        this.props.changeWelcomeBackStatus()
        switch(step) {
            case "step1" : 

                let step1Status = {
                    "review_benefits_status":"benefits",
                    "confirm_contact_status":"contact"
                }
                //console.log(this.props)
                if(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info_status") === "START"){
                    //console.log("if", this.props)
                        _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.review_benefits_status","ACTIVE")
                        _.set(this.props, "prefillData.eoi_data.benefit_coverage_info_status","ACTIVE")
                        this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""),'/eoi/benefits')
                        // this.props.history.push(`/eoi/benefits`)
                }
                else if(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info_status") === "ACTIVE" && _.get(this.props, "welcomeBackStatus") === false){
                    this.props.history.push(`/eoi/benefits`)
                }  
                else if(_.get(this.props, "welcomeBackStatus","") === true && _.get(this.props, "prefillData.eoi_data.benefit_coverage_info_status") !== "START"){
                    //console.log("elsee",this.props)
                    for(let i = 0;i<7;i++){
                        //console.log(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info."+Object.keys(step1Status)[i]))
                        if(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info."+Object.keys(step1Status)[i])==="ACTIVE"){
                            //console.log(this.props)
                            this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""),`/eoi/`+Object.values(step1Status)[i])
                            //this.props.history.push(`/eoi/`+Object.values(step1Status)[i]);
                            break;
                        }
                    }
                }
                
                
                
                break;
            case "step2" : 

            // if(_.get(this.props, "prefillData.eoi_data.health_question_info.base_question_status")==="ACTIVE"){ 
            //                     //console.log("ddddddddddddddddddddddddddddddd")
            //                     // let activeQ = _.get(this.props, "prefillData.eoi_data.health_question_info.active_question_status")
            //                     //console.log(activeQ)
            //                     // this.props.questionStatus(parseInt(activeQ))
            //                     // _.set(this.props,"activeQuestion",parseInt(activeQ))
            //                     let a = this.props
            //                     Object.assign(a.activeQuestion,activeQ-1)
            //                     //console.log(_.get(this.props,"activeQuestion"),a)
            //                     //console.log(this.props.activeQuestion)
            //                     //this.props.questionStatus(parseInt(activeQ)-1).then(chooseNextQuestion(this.props))
            //                     chooseNextQuestion(this.props)
            //                 }
            //33333333333333333333333333333333333333333333333333333333333333333333333
                let step2Status = {
                        "knockout_page_status": "knockOutQ",
                        "updateHtandWt_status":"heightAndweight",
                        "health_history_status":"healthHistory",
                        //"base_question_status":
                        
                }
                //this.props.getKnockOutData(_.get(this.props,"prefillData",{}))
                if(_.get(this.props, "prefillData.eoi_data.health_question_info_status") === "START"){
                    //console.log("if", this.props)
                    _.set(this.props, "prefillData.eoi_data.health_question_info_status","ACTIVE")
                    this.props.getKnockOutData(_.get(this.props,"prefillData",{}))
                    
                }
                else if(_.get(this.props, "prefillData.eoi_data.health_question_info_status") === "ACTIVE" && _.get(this.props, "welcomeBackStatus") === false){
                    this.props.getKnockOutData(_.get(this.props,"prefillData",{}))
                }
                else if(_.get(this.props, "welcomeBackStatus","") === true && _.get(this.props, "prefillData.eoi_data.health_question_info_status") !== "START"){
                    //console.log("elsee",this.props)
                    if(_.get(this.props, "prefillData.eoi_data.health_question_info.base_question_status")==="ACTIVE"){ 
                                chooseNextQuestion(this.props)
                    }
                    else{
                        for(let i = 0;i<7;i++){
                        //console.log(_.get(this.props, "prefillData.eoi_data.health_question_info.base_question_status"))
                        
                            if(_.get(this.props, "prefillData.eoi_data.health_question_info."+Object.keys(step2Status)[i])==="ACTIVE"){ 
                                //console.log(this.props)
                                this.props.history.push(`/eoi/`+Object.values(step2Status)[i]);
                                break;
                            }
                        
                        
                    }
                    }
                    
                }


                // let step2Status = {
                //     "healthQ1Status": "healthQ1",
                //     "healthQ2Status":"healthQ2",
                //     "healthQ3Status":"healthQ3",
                //     "moveOnStatus":"move",
                //     "healthHistoryStatus":"healthHistory",
                //     "letsTalkAbout":"letsTalk"
                // }
        //     let data=    (_.get(this.props,"prefillData"))
        //     //console.log("data0-----",data)
               //this.props.getKnockOutData(_.get(this.props,"prefillData",{}))
                
        //         let res= _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.source","");
        //         //console.log("knock q--",res)
        //  let flag=       _.get(this.props, "prefillData.eoi_data.health_question_info.has_knockout_question",false)
        //      //console.log("falg",flag)  
        //  if(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.source") !== null){
        //         if(_.get(this.props, "prefillData.eoi_data.health_question_info.has_knockout_question") === true){
                 
                  //this.props.history.push(`/eoi/knockOutQ`)
        //         }
        //     }
                // if(_.get(this.props, "prefillData.eoi_data.health_question_info_status") === "START"){
                //     this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))        
                //     this.props.history.push(`/eoi/healthHistory`)
                // }
                // else if(_.get(this.props, "prefillData.eoi_data.health_question_info_status") === "ACTIVE" && _.get(this.props, "welcomeBackStatus") === false){
                //     this.props.history.push(`/eoi/healthHistory`)
                // }  
                // else if(_.get(this.props, "welcomeBackStatus","") === true && 
                // _.get(this.props, "prefillData.eoi_data.benefit_coverage_info_status") !== "START"){
                //     //console.log("elsee",this.props)
                //     for(let i = 0;i<7;i++){
                //         //console.log(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info."+Object.keys(step1Status)[i]))
                //         if(_.get(this.props, "prefillData.eoi_data.health_question_info_status."+Object.keys(step2Status)[i])==="ACTIVE"){
                //             //console.log(this.props)
                //             this.props.history.push(`/eoi/`+Object.values(step1Status)[i]);
                //             break;
                //         }
                //     }
                // }
               
                
                break;
            case "step3" : 
                // if(_.get(this.props, "prefillData.eoi_data.confirm_submit_status") === "START"){
                //     this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))        
                //     this.props.history.push(`/eoi/review`)
                // }
                let step3Status = {
                    "review_status" :"review",
                    "esign_employee_status" :"employeeSignature",
                    "esign_spouse_status" :"dependentSignature"
                }
                //console.log(this.props)
                // this.props.initiateUW(_.get(this.props,"prefillData",{}))
                if(_.get(this.props, "prefillData.eoi_data.confirm_submit_status") === "START"){
                    //console.log("if", this.props)
                       // _.set(this.props, "prefillData.eoi_data.benefit_coverage_info.review_benefits_status","ACTIVE")
                        _.set(this.props, "prefillData.eoi_data.confirm_submit_status","ACTIVE")
                        this.props.history.push(`/eoi/review`)
                        //this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""),'/eoi/benefits')
                        // this.props.history.push(`/eoi/benefits`)
                }
                else if(_.get(this.props, "prefillData.eoi_data.confirm_submit_status") === "ACTIVE" && _.get(this.props, "welcomeBackStatus") === false){
                    this.props.history.push(`/eoi/review`)
                }  
                else if(_.get(this.props, "welcomeBackStatus","") === true && _.get(this.props, "prefillData.eoi_data.confirm_submit_status") !== "START"){
                    //console.log("elsee",this.props)
                    for(let i = 0;i<7;i++){
                        //console.log(_.get(this.props, "prefillData.eoi_data."+Object.keys(step3Status)[i]))
                        if(_.get(this.props, "prefillData.eoi_data."+Object.keys(step3Status)[i])==="ACTIVE"){
                            //console.log(this.props)
                            this.props.history.push(`/eoi/`+Object.values(step3Status)[i]);
                            break;
                        }
                    }
                }
                
                break;
            default :

        }
    }
    handleNonTrustedFlowClick = (step) => {
        //console.log("clicked", this.props)
        switch(step) {
            case "step1" : 
                
                //console.log(this.props)
                if(_.get(this.props, "nonTrustedPageStatus.add_benefit_coverage_info_status") === "START"){
                    //console.log("if", this.props)
                        // _.set(this.props, "nonTrustedData.tell_us_more_status","ACTIVE")
                        _.set(this.props, "nonTrustedPageStatus.add_benefit_coverage_info_status","ACTIVE")
                        this.props.history.push(`/eoi/tellUsMore`)
                }
                if(_.get(this.props, "nonTrustedPageStatus.add_benefit_coverage_info_status") === "ACTIVE"){
                        this.props.history.push(`/eoi/tellUsMore`)
                }
                break;
            case "step2" :
                if(_.get(this.props, "nonTrustedPageStatus.health_question_info_status") === "START"){
                    //console.log("if", this.props)
                        _.set(this.props, "nonTrustedPageStatus.health_question_info_status","ACTIVE")
                        this.props.history.push(`/eoi/heightAndweight`)
                }
                if(_.get(this.props, "nonTrustedPageStatus.health_question_info_status") === "ACTIVE"){
                        this.props.history.push(`/eoi/heightAndweight`)
                } 
                    
                break;
            case "step3" : 
                // this.props.initiateUW(_.get(this.props,"prefillData",{}))
                if(_.get(this.props, "nonTrustedPageStatus.confirm_submit_status") === "START"){
                    //console.log("if", this.props)
                        _.set(this.props, "nonTrustedPageStatus.confirm_submit_status","ACTIVE")
                        this.props.history.push(`/eoi/review`)
                }
                if(_.get(this.props, "nonTrustedPageStatus.confirm_submit_status") === "ACTIVE"){
                        this.props.history.push(`/eoi/review`)
                }
                break;
            default :

        }
    }

    componentDidMount() {
        document.title = "Let's keep going | Group EOI - Guardian";
        // console.log(this.props, "HOME PAGEEEEE")
        // let browserUrl = window.location.href
        // if(browserUrl.includes(trustedJunction)){
        //     this.props.assignPath(true)
        // }
        // if(browserUrl.includes(nonTrustedJunction))
        //     this.props.assignPath(false)
        const baseQuestion = _.get(this.props,"prefillData.eoi_data.health_question_info.base_question",{})
        if(baseQuestion && baseQuestion.length>0){
            this.props.assignTotalQuestion(baseQuestion.length)

        }
        activeQ = _.get(this.props, "prefillData.eoi_data.health_question_info.active_question_status")
        this.props.questionStatus(parseInt(activeQ)-1)
        //_.set(this.props,"activeQuestion",parseInt(activeQ))
        //console.log(this.props,"sssssssssssssssssssss")
        scrollUp();
        // window.addEventListener('popstate', (event) => {
        //     console.log("TRUEE")
        //     moveToPreviousQuestion(this.props)
        //   });
        //this.props.saveEoiData(this.props.prefillData); 
    }

    getTrustedFlowStatusInfo = (property) => {
        let status = _.get(this.props, "prefillData.eoi_data."+property);
        //console.log(status)
        let val = "row";
        if(status === "COMPLETED") {
            val = "row completed"    
        } else if(status === "START" || status === "ACTIVE") {
            val = "row active"
        }
        // if(_.get(this.props, "trustedFlag") === false) {
        //    if(property === "benefit_coverage_info_status")
        //       val = "row active"
        // }
        return val;
    }

    getNonTrustedFlowStatusInfo = (property) => {
        let status = _.get(this.props, "nonTrustedPageStatus."+property);
        //console.log(status)
        let val = "row";
        if(status === "COMPLETED") {
            val = "row completed"    
        } else if(status === "START" || status === "ACTIVE") {
            val = "row active"
        }
        // if(_.get(this.props, "trustedFlag") === false) {
        //     if(property === "benefit_coverage_info_status")
        //        val = "row active"
        //  }
        return val;
    }

    getButtonText = () => {
        if(_.get(this.props, "welcomeBackStatus","") === true && _.get(this.props, "prefillData.eoi_data.benefit_coverage_info_status") !== "START"){
            return "Resume"
        }
        else{
            return "Continue"
        }
    }

    displayTrustedFlowHeader = () => {
        //console.log(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info_status"),_.get(this.props, "welcomeBackStatus"))
        if(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info_status") === "START"){
            //console.log("1")
            return "Let’s get started"
        }
        if(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info_status") === "ACTIVE" && _.get(this.props, "welcomeBackStatus") === false){
            //console.log("1")
            return "Let’s get started"
        }
        if(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info_status") !== "START" &&  _.get(this.props, "welcomeBackStatus") === true ){
            //console.log("2")
            return "Welcome back!"
        }
        if(_.get(this.props, "prefillData.eoi_data.benefit_coverage_info_status") === "COMPLETED" &&  _.get(this.props, "welcomeBackStatus") === false ){
            //console.log("3")
            return "Let’s keep going"
        }
    }
    displayNonTrustedFlowHeader = () => {

        if(_.get(this.props, "nonTrustedPageStatus.add_benefit_coverage_info_status") === "START" || _.get(this.props, "nonTrustedPageStatus.add_benefit_coverage_info_status") === "ACTIVE"){
            return "Let’s get started"
        }
        if(_.get(this.props, "nonTrustedPageStatus.add_benefit_coverage_info_status") === "COMPLETED"){
            return "Let’s keep going"
        }
    }


    render() {
        //console.log(this.props)
        const {trustedFlag} = this.props

        return (
            <>
                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="intro">
                                    {trustedFlag === true ?
                                        <HomePageHeader 
                                            // eoiData = {_.get(this.props, "prefillData.eoi_data")} 
                                            // welcomeBackStatus={_.get(this.props, "welcomeBackStatus","")} 
                                            header = {this.displayTrustedFlowHeader()}
                                            // subHeader = "Complete your EOI in 3 steps."
                                            subHeader = "Complete your EOI in 3 steps. You can always save your answers and come back later."
                                        />
                                        :
                                        <HomePageHeader 
                                            // eoiData = {_.get(this.props, "prefillData.eoi_data")} 
                                            // welcomeBackStatus={_.get(this.props, "welcomeBackStatus","")} 
                                            header = {this.displayNonTrustedFlowHeader()}
                                            subHeader = "Complete your EOI in 3 steps."
                                        />
                                    }
                                </div>
                                
                                {trustedFlag === true ?
                                    <ul className="step-tracker">
                                        <HomePageSteps 
                                            stepStatus = {this.getTrustedFlowStatusInfo("benefit_coverage_info_status")}
                                            stepNumber = "1"
                                            heading = "Review benefits &amp; coverage"
                                            btnText = {this.getButtonText()}
                                            handleClick = {()=>this.handleTrustedFlowClick("step1")}>
                                        </HomePageSteps>
                                        <HomePageSteps 
                                            stepStatus = {this.getTrustedFlowStatusInfo("health_question_info_status")}
                                            stepNumber = "2"
                                            heading = "Answer health questions"
                                            btnText = {this.getButtonText()}
                                            handleClick = {()=>this.handleTrustedFlowClick("step2")}>
                                        </HomePageSteps>
                                        
                                        <HomePageSteps 
                                            stepStatus = {this.getTrustedFlowStatusInfo("confirm_submit_status")}
                                            stepNumber = "3"
                                            heading = "Confirm &amp; submit"
                                            btnText = {this.getButtonText()}
                                            handleClick = {()=>this.handleTrustedFlowClick("step3")}>
                                        </HomePageSteps>
                                    </ul>
                                    :
                                    <ul className="step-tracker">
                                        <HomePageSteps 
                                            stepStatus = {this.getNonTrustedFlowStatusInfo("add_benefit_coverage_info_status")}
                                            stepNumber = "1"
                                            heading = "Add benefits &amp; coverage"
                                            btnText = "Continue"
                                            handleClick = {()=>this.handleNonTrustedFlowClick("step1")}>
                                        </HomePageSteps>

                                        <HomePageSteps 
                                            stepStatus = {this.getNonTrustedFlowStatusInfo("health_question_info_status")}
                                            stepNumber = "2"
                                            heading = "Answer health questions"
                                            btnText = "Continue"
                                            handleClick = {()=>this.handleNonTrustedFlowClick("step2")}>
                                        </HomePageSteps>
                                        
                                        <HomePageSteps 
                                            stepStatus = {this.getNonTrustedFlowStatusInfo("confirm_submit_status")}
                                            stepNumber = "3"
                                            heading = "Confirm &amp; submit"
                                            btnText = "Continue"
                                            handleClick = {()=>this.handleNonTrustedFlowClick("step3")}>
                                        </HomePageSteps>
                                    </ul>
                                }
                            </div>
                            <div className="col-lg-6">
                                <img src={imagePath("./images/home-illustration.svg")} className="illustration" alt="" />
                            </div>
                        </div>
                    </div>

                </main>
            </>
        );
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(HomePage);

import React from 'react';

const index = (props) => {
    return (
        <li className={props.stepStatus}>
            <div className="col-sm-2">
                <div className="step-count">
                    <span className="step-number">{props.stepNumber}</span>
                    <span className="fas fa-check"></span>
                    <span className="sr-only fas fa-check">Completed</span>
                </div>                            
            </div>
            <div className="col-sm-10">
                <div className="step-name">
                    <span class= "h5">{props.heading}</span>
                    <div className="step-details">
                        <button className="btn btn-secondary" onClick={props.handleClick}>{props.btnText}</button>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default index;
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
// import { Button } from "glic-ui-components";
import { scrollUp } from '../../utils/sharedFunction';
import ReviewDetails from './ReviewDetails';
import ReviewBasics from './ReviewBasics';
import ReviewHealthHistory from './ReviewHealthHistory';
import _ from 'lodash';
import CheckPopUp from '../../components/CheckPopUp/CheckPopUp';
import SaveForLater from '../../components/SaveForLater/SaveForLater';



class ReviewPage extends Component{
    constructor(props) {
        super(props);
        this.state = {

            employeeCovered:false,
            spouseCovered:false,
            childCovered:false,
            childEligible: false,
            date: new Date().getFullYear(),
            isOpen:false,
            isSelect:false,
            displayChild:false,
            isSaved:false,
            isOpened:false
        }
    }
    componentDidMount(){
        document.title = "Let's Review | Group EOI - Guardian";
        let child_info = _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
        let childState = _.get(this.props, "prefillData.eoi_data.group_info.group_situs_state");        // let child_eligible = this.state.childEligible;
        scrollUp()
        let coverages = null;
        if(_.get(this.props,"trustedFlag",""))
        {
            if(_.get(this.props, "prefillData.eoi_data.review_status","")!=="COMPLETED"){
                _.set(this.props, "prefillData.eoi_data.review_status","ACTIVE")
                this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
            }
            coverages =_.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections",{})
        }
        else
        {
            coverages =_.get(this.props,"nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections",{})
        }
        const disabilityCoverages = ["short_term_disability", "long_term_disability", "voluntary_long_term_disability", "voluntary_short_term_disability" ]
        let coverageElected = []
        let allEmployeeStatus = []
        let allSpouseStatus = []
        let allChildrenStatus = []
        

        for (const coverage in coverages){
            let disabilityCoverageElectedStatus = false
            let employeeCoveredStatus = false
            let spouseCoveredStatus = false
            let childCoveredStatus = false
            if(disabilityCoverages.includes(coverage)){
                
                disabilityCoverageElectedStatus = coverages[coverage] && coverages[coverage].elected && coverages[coverage].elected
            }
            else{
                employeeCoveredStatus = coverages[coverage] && coverages[coverage].employee_covered && coverages[coverage].employee_covered
                spouseCoveredStatus = coverages[coverage] && coverages[coverage].spouse_covered && coverages[coverage].spouse_covered
                childCoveredStatus = coverages[coverage] && coverages[coverage].child_covered && coverages[coverage].child_covered
            }
            
            //console.log("reviewPage-StatusCovered",employeeCoveredStatus,spouseCoveredStatus,childCoveredStatus,disabilityCoverageElectedStatus)
            if(employeeCoveredStatus === true || spouseCoveredStatus === true || childCoveredStatus ===true || disabilityCoverageElectedStatus === true){
                coverageElected.push(coverage)
            }
            allEmployeeStatus.push(employeeCoveredStatus)
            allEmployeeStatus.push(disabilityCoverageElectedStatus)
            allSpouseStatus.push(spouseCoveredStatus)
            allChildrenStatus.push(childCoveredStatus)
        }
        let child_count = 0;
            child_info && child_info.map((child) => {
                 let currentAge = this.state.date - parseInt(child.dob);
                 let child_eligible = this.state.childEligible;
                 if(!child_eligible){
                 if (currentAge >= 18) {
                     // child_eligible = true;
                     this.setState({ childEligible:true})
                     child_count++;
                 } else if (currentAge >= 15 && childState ==="NC") {
                     this.setState({ childEligible:true})
                     // console.log(childState.includes("NC"))
                     // child_eligible = true;
                     child_count++;
                 } else {
                     child_eligible = false;
                 }
             }
             return child_eligible;
             })
             if(child_info.length!== child_count){
                 this.setState({displayChild:true})
             }
        this.setState({
            employeeCovered: allEmployeeStatus.includes(true),
            spouseCovered:allSpouseStatus.includes(true),
            childCovered:allChildrenStatus.includes(true),
            // childEligible:child_eligible
        })

    }
    handlePopUp =()=>{
        this.setState({
            isSelect:true,isOpen:false
        })
    }
    handleNext = (e) => {  
        this.setState({isOpen:true})
    if(this.state.isSelect === true){ 
        this.props.initiateUW(_.get(this.props,"prefillData",{}))
        if(_.get(this.props, "prefillData.eoi_data.review_status","")==="ACTIVE"){
            _.set(this.props, "prefillData.eoi_data.review_status","COMPLETED")
            this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
        }
        //console.log("whoCovered", this.state.displaySpouse);
        // this.setState({isOpen:true})
        // if(this.state.isSelect === true){ 
        if(this.state.employeeCovered === true || (this.state.employeeCovered === true && this.state.spouseCovered === true) || (this.state.employeeCovered === false && this.state.childCovered === true  && (this.state.childEligible === false || this.state.displayChild === true)))  {
            this.props.history.push(`/eoi/employeeSignature`)
        }
        else if((this.state.spouseCovered === true && this.state.employeeCovered === false) || this.props.whoCovered.includes("My spouse")) {
            this.props.history.push(`/eoi/dependentSignature`)
        } else if((this.state.childCovered === true && this.state.childEligible === true) ){
            this.props.history.push(`/eoi/dependentChild`)    
        }
    }
    } 
    saveForLater = () => {
        this.setState({isSaved:true});
    }
    continueEOI = () => {
        this.setState({isSaved:false});
    }
    leave = () => {
        this.props.history.push({ pathname: `/eoi/success` })
    }
    


render(){
    const { id } = this.props.match.params;
    return (
        <>
        {this.state.isSaved === true ? <SaveForLater continueEOI={()=>this.continueEOI()} leave = {()=> this.leave()}/> : null}
         {this.state.isOpen ?
                <CheckPopUp handlePopUp={()=>this.handlePopUp()}/> : null}
        <main class="knockout">
            <div class="container">
                <div class="row">
                    <div class="col-md-10">
                        <h1 style = {{fontSize:"32px"}} class="pt-5">Let’s review</h1>
                        <p class="pt-3 pb-3">Do we have everything right?</p>
                    </div>
                </div>
            </div>
                <ReviewBasics {...this.props}></ReviewBasics>
                <ReviewDetails {...this.props}></ReviewDetails>
                <ReviewHealthHistory id = {id} {...this.props}></ReviewHealthHistory>
            <div class="container">
                <div class="row">
                    <div class="col-md-8">
                        <div class="field-group">
                        {this.props.trustedFlag === true ?   <button className="btn btn-link" onClick={()=>this.saveForLater()}>Save for later</button> : null}
                            <button className="btn btn-primary float-right mr-3" onClick={(e)=>this.handleNext(e)}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    )
    }


}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(ReviewPage);
import React, { Component } from 'react';
import { Button } from "glic-ui-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/HomePage/HomePageAction";
import _ from "lodash";
import { moveToReflexiveQuestion, scrollUp } from '../../utils/sharedFunction';
import RemovePopup from '../../components/RemovePopup/RemovePopup';

export class ReviewHealthQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpFirst: false,
      HealthQ1: [],
      lifeId: 0,
      collapseArray: [],
      discloureCollapse: [],
      removePopup: false
    }
  }

  componentDidMount() {
    scrollUp();
    // window.location.reload();
    let healthQ1 = _.get(this.props, "prefillData.eoi_data.health_question_info.base_question", []);
    let life_id = _.get(this.props, "lifeId") - 1;
    _.set(this.props, "prefillData.eoi_data.health_question_info.review_hq_life_id", this.props.lifeId)
    _.set(this.props, "prefillData.eoi_data.health_question_info.review_hq_enabled", "Y")
    this.setState({ HealthQ1: healthQ1, lifeId: life_id });

  }
  removePopup = (e) => {
    this.setState({ removePopup: true });
    e.preventDefault();
  }
  cancelPopup = () => {
    // console.log("removePopup")
    this.setState({ removePopup: false });
  }
  // edit condition
  editCondition = (e, qId, cId, dId, lId) => {
    // console.log("coming")
    let healthQuestion = _.get(this.props, "prefillData.eoi_data.health_question_info.base_question", []);
    let conditionArray = []
    let disclosureArray = []
    // console.log("healthQuestion,", healthQuestion)
    healthQuestion.map(ques => {
      if (ques.question_id === qId) {
        ques.health_condition.map(condn => {
          condn.life_entities_dq.map(life_entity => {
            if (life_entity.life_id === lId && life_entity.answer_condition_info === true) {
              conditionArray.push(condn.condition_id)
              if (condn.condition_id === cId) {
                life_entity.disclosure_types.map(disclosureType => {
                  if (disclosureType.answer_disclosure_info === true && condn.answer_type === "PICKLIST") {
                    disclosureArray.push(disclosureType.disclosure_id)
                  }
                  else if (condn.answer_type === "ENTER_DETAILS" && disclosureType.answer_disclosure_info_value !== "") {
                    disclosureArray.push(disclosureType.disclosure_id)
                  }
                  return disclosureType
                })
              }
            }
            return life_entity
          })
          return condn
        })
      }
      return ques
    })
    let a = Object.assign({}, this.props)
    a.activeReflux = disclosureArray.indexOf(dId)
    a.activeCondition = conditionArray.indexOf(cId) + 1
    a.activeQuestion = qId
    a.activeLife = parseInt(lId)
    //  a.activeReflux =  disclosureArray.indexOf(dId) 
    this.props.eInfo('true');
    //  console.log(this.props.eInfo, this.props, "EINFO111")
    a.history.push({
      state: { "previousPage": "reviewEdit", "lifeId": lId }
    })

    this.props.rLid(lId);
    _.set(this.props, "location.state.lifeId", lId)
    // console.log("refLid-", this.props.isRlid);
    moveToReflexiveQuestion(a, "reviewEdit")
  }
  removeReflux = (e, quesId, condId, disclId, lifeId) => {
    let deselectFlag = false;
    let healthQues = _.get(this.props, "prefillData.eoi_data.health_question_info.base_question", []);
    //console.log("removeReflux",e,quesId,condId,disclId,lifeId,healthQues)
    healthQues.map(ques => {
      if (ques.question_id === quesId) {
        ques.health_condition.map(condn => {
          if (condn.condition_id === condId) {
            condn.life_entities_dq.map(life_entity => {
              if (life_entity.life_id === lifeId) {
                life_entity.disclosure_types.map(disclosureType => {
                  if (disclosureType.disclosure_id === disclId && disclosureType.disclosure_desc === "Medication") {
                    _.set(disclosureType, "answer_disclosure_info_value", "")
                  }
                  else if (disclosureType.disclosure_id === disclId) {
                    _.set(disclosureType, "answer_disclosure_info", false)
                    if (_.get(disclosureType, "answer_disclosure_info_value", false)) {
                      _.set(disclosureType, "answer_disclosure_info_value", "")
                    }
                  }

                  if (disclosureType.answer_disclosure_info === true) {
                    deselectFlag = true;
                  }
                  return disclosureType
                })
                if (!deselectFlag) {
                  _.set(life_entity, "answer_condition_info", false)
                }
              }
              return life_entity
            })
          }
          return condn
        })
      }
      return ques
    })

    this.setState({ removePopup: false });
    let healthQ = _.get(this.props, "prefillData.eoi_data.health_question_info.base_question", []);
    this.setState({ HealthQ1: healthQ });
    this.props.updateprefillData(_.get(this.props, "prefillData", ""), _.get(this.props, "prefillId", ""), '/eoi/review')

  }

  collapse = (e, questionId) => {
    if (this.state.collapseArray.includes(questionId)) {
      // console.log(this.props,"propss");
      let removeId = this.state.collapseArray.filter(id => id !== questionId)
      this.setState({
        collapseArray: removeId
      })
    }
    else {
      this.setState({
        collapseArray: [...this.state.collapseArray, questionId]
      })
    }
  }
  moveToQuestion = (questionId) => {
    // console.log(questionId)
    this.props.eInfo('false')
    let a = Object.assign({}, this.props)
    a.activeCondition = 0
    a.activeReflux = 0
    // console.log(this.props.eInfo, "EINFO")
    this.props.questionStatus(parseInt(questionId) - 1, a, "review")
    _.set(this.props, "prefillData.eoi_data.health_question_info.review_hq_life_id", this.props.lifeId)
    _.set(this.props, "prefillData.eoi_data.health_question_info.review_hq_enabled", "Y")
    //chooseNextQuestion(this.props,parseInt(questionId)-1)
  }
  conditionCollapse = (e, disclosureId) => {
    if (this.state.discloureCollapse.includes(disclosureId)) {
      let removeId = this.state.discloureCollapse.filter(id => id !== disclosureId)
      this.setState({
        discloureCollapse: removeId
      })
    }

    else {
      this.setState({
        discloureCollapse: [...this.state.discloureCollapse, disclosureId]
      })
    }

  }
  render() {
    return (
      <>
        <div class="container">
          <div class="row">
            <div class="col-md-10">
              {this.state.HealthQ1.map((question) => (
                question.health_condition.slice(0, 1).map((condition) => (
                  condition.life_entities_dq.map((answer) => (
                    this.props.lifeId === answer.life_id ?
                      <>
                        <div class="row mb-3">
                          <ul class="col-md-10 accordion" id="accordion">
                            <li class="accordion-card light-gray-background pr-3 pl-2 remove-border remove-bmargin">
                              <div class="accordion-card-header remove-border" id="headingOne">
                                <p class="pr-5 pl-2 m-0" key={question.question_id}>
                                  {question.question_desc}
                                </p>
                                <button
                                  class="close hidden-text align-chevron"
                                  type="button"
                                  aria-label= {question.question_desc} 
                                  data-toggle="collapse"
                                  data-target="#collapseThree"
                                  aria-expanded={this.state.collapseArray.includes(question.question_code)}
                                  aria-controls="collapseThree"
                                  onClick={(e) => this.collapse(e, question.question_code)}
                                >
                                  <i className={this.state.collapseArray.includes(question.question_code) ? "fas fa-chevron-up" : "fas fa-chevron-down"}> <span className="fas fa-chevron-up">Collapse button</span> </i>
                                </button>
                              </div>
                            </li>
                            <section id="collapseOne" className={this.state.collapseArray.includes(question.question_code) ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordion">
                              <div class="accordion-card-body ">
                                <div class="mt-3">
                                  {question.health_condition.map((condition) => (
                                    condition.life_entities_dq.map((answer) => (
                                      this.props.lifeId === answer.life_id ?
                                        <div class="flex-accordion-header mb-2 pl-3">
                                          {question.answer_type === "PICKLIST" ?
                                            (answer.answer_condition_info ?
                                              <div>
                                                <i>
                                                  <p class="plum-shade font-weight-bold fas fa-check align-fa-check plum-shade" key={condition.condition_id}>&nbsp;{condition.condition_name}</p>
                                                </i>
                                              </div>
                                              :
                                              <p key={condition.condition_id}> {condition.condition_name}</p>
                                            )
                                            :
                                            (answer.answer_condition_info ?
                                              <div>
                                                <i>
                                                  <p class="plum-shade font-weight-bold fas fa-check align-fa-check plum-shade" key={condition.condition_id}>&nbsp;Yes</p>
                                                </i>
                                              </div>
                                              :
                                              <div>
                                                <i>
                                                  <p class="plum-shade font-weight-bold fas fa-check align-fa-check plum-shade" key={condition.condition_id}>&nbsp;No</p>
                                                </i>
                                              </div>)
                                          }
                                        </div>
                                        :
                                        null
                                    ))
                                  ))}
                                </div>
                                <Button
                                  type="outlined"
                                  color="navy"
                                  className="mt-4 mb-3 ml-3"
                                  onClick={() => this.moveToQuestion(question.question_id)}
                                >
                                  Change my response
                                </Button>

                                {question.health_condition.map((condition) => (

                                  condition.life_entities_dq.map((answer) => (


                                    this.props.lifeId === answer.life_id ?

                                      <>
                                        <>
                                          {answer && answer.disclosure_types && answer.disclosure_types.map((disclosure) => (
                                            <div class="container">

                                              {disclosure.answer_disclosure_info_value || disclosure.answer_disclosure_info ?
                                                <div class="row">
                                                  {answer && answer.answer_condition_info ?
                                                    <ul
                                                      class="col-md-12 accordion plum-border mt-2"
                                                      id="accordion"
                                                    >
                                                      <li class="accordion-card pr-3 pl-2 remove-border remove-bmargin">
                                                        <div
                                                          class="accordion-card-header remove-border"
                                                          id="headingOne"
                                                        >
                                                          <h4 class="h6 pr-5 pl-1 m-0 plum-shade font-weight-bold">
                                                            {disclosure.disclosure_desc}
                                                          </h4>
                                                          <button
                                                            class="close hidden-text align-chevron plum-shade"
                                                            type="button"
                                                            aria-label={disclosure.disclosure_desc}
                                                            data-toggle="collapse"
                                                            data-target="#collapseTwo"
                                                            aria-expanded={this.state.discloureCollapse.includes(condition.condition_id + disclosure.disclosure_id)}
                                                            aria-controls="collapseTwo"
                                                            onClick={(e) => this.conditionCollapse(e, condition.condition_id + disclosure.disclosure_id)}
                                                          >

                                                            <i className={this.state.discloureCollapse.includes(condition.condition_id + disclosure.disclosure_id) ? "fas fa-chevron-up" : "fas fa-chevron-down"}> <span className="fas fa-chevron-up">Collapse button</span> </i>

                                                          </button>
                                                        </div>

                                                        <div id="collapseOne" className={this.state.discloureCollapse.includes(condition.condition_id + disclosure.disclosure_id) ? "collapse show" : "collapse"} aria-labelledby="headingTwo" data-parent="#accordion">

                                                          <div class="accordion-card-body">
                                                            <div class="row">
                                                              {disclosure && disclosure.disclosure_question_info && disclosure.disclosure_question_info.map((disAns) => (
                                                                <>
                                                                  <div class="col-md-6 mb-3">
                                                                    <p class="font-weight-bold" >
                                                                      {disAns.disclosure_qstn_desc}
                                                                    </p>
                                                                    <p key={disAns.disclosure_qstn_id}>{disAns.disclosure_qstn_desc === "Medication" || disAns.disclosure_qstn_desc === "Consultation" ? disclosure.answer_disclosure_info_value : disAns.disclosure_qstn_ans}</p>
                                                                  </div>
                                                                </>
                                                              ))}
                                                            </div>
                                                            {disclosure && disclosure.phycisian_info && (disclosure.phycisian_info.physician_attended_flag === true) ?
                                                              <div class="col-md-6 pl-0 mb-3">
                                                                <p class="font-weight-bold">
                                                                  Did you attend a physician in relation to
                                                                  your {disclosure.disclosure_desc} ?
                                                                </p>
                                                                <p>{disclosure && disclosure.phycisian_info && (disclosure.phycisian_info.physician_attended_flag === true) ? "Yes" : "No"}</p>
                                                              </div> : null}
                                                            {disclosure && disclosure.phycisian_info && disclosure.phycisian_info.physician_attended_flag === true ?
                                                              <>
                                                                <div class="row mb-3">
                                                                  {disclosure && disclosure.phycisian_info && disclosure.phycisian_info.physicians_choosen && disclosure.phycisian_info.physicians_choosen.map((physician, index) => (
                                                                    // disclosure.phycisian_info.physician_attended_flag === true ? 
                                                                    physician.physician_selected === true ?
                                                                      <>

                                                                        <div class="col-md-6">
                                                                          {index === 0 ?
                                                                            <p class="font-weight-bold">Physicians</p>
                                                                            : <br />}
                                                                          <p>
                                                                            <u>{physician.name}</u>
                                                                          </p>
                                                                          <p class="mb-3">{physician.speciality}</p>
                                                                          <p>Address</p>
                                                                          <p>{physician.addressLine1}{physician.addressLine2} </p>
                                                                          <p class="mb-3">{physician.city}, {physician.ui_state_cd} {physician.zip}</p>
                                                                          <p class="mb-3">Phone: {physician.phone}</p>
                                                                          <p class="font-weight-bold">
                                                                            When did you last visit {physician.name + " "}
                                                                            for this condition?
                                                                          </p>
                                                                          <p>{physician.lastVisitMonth + " " + physician.lastVisitYear}</p>
                                                                        </div>
                                                                      </> : " "
                                                                  ))}
                                                                </div>
                                                              </>
                                                              : null}
                                                            <div class="flex-heath-histreview mt-4">
                                                              <button

                                                                className="btn btn-outline small mb-3"
                                                                onClick={(e) => this.editCondition(e, question.question_id, condition.condition_id, disclosure.disclosure_id, answer.life_id)}
                                                              >
                                                                Edit
                                                              </button>
                                                              {/* <button className="btn btn-link blue-link" onClick={(e) => this.removePopup(e)}>
                                                      Remove
                                                    </button> */}
                                                              {
                                                                this.state.removePopup && <RemovePopup lifeName={disclosure.disclosure_desc} cancelPopup={this.cancelPopup} removePopup={(e) => this.removeReflux(e, question.question_id, condition.condition_id, disclosure.disclosure_id, answer.life_id)}></RemovePopup>
                                                              }
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </ul> : ""}
                                                </div>
                                                : ""}</div>
                                          ))}
                                        </>
                                      </> : ""
                                  ))
                                ))}
                              </div>
                            </section>
                          </ul>
                        </div>
                      </> : ""
                  ))
                ))
              ))}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(
  (state) => state.eoiStore,
  (dispatch) => bindActionCreators(actionCreators, dispatch))(ReviewHealthQ);

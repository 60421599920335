import React from "react";

import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

function WhoAreWeCoveringFormNonTrusted({addOrEditStatus,handleShow,
    addOrEditHeader,handleNameChange,handleDateChange,handleDateBlur,handleOnOpen,handleOnClose,handleCancel,addOrEditSave,handleFieldBlur,
    whoAreWeCovering:{first_name,last_name,middle_initial,dob,gender},
    firstNameError,lastNameError,dobError,genderError,blurError}){
        return (
            <div className="card add-dependent" >
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <h6 className="mb-4 mt-2">{addOrEditHeader}</h6>
                    {addOrEditStatus === "edit" ? <button class="btn btn-link blue-link" onClick={(e)=>handleShow(e)}>Remove</button> : null}
                </div>
                <div className="row form-group">
                    <div className="col-lg-9 col-md-10 col-sm-9">
                        <label htmlFor="first-name">First name</label>
                        <input id="first-name" type="text"
                            className={`form-control ${firstNameError ? 'invalid' : ''}`}
                            maxLength={30}
                            value = {first_name}
                            onChange = {(e)=>handleNameChange(e,"first_name")} 
                            onBlur = {(e)=>handleFieldBlur(e,"first_name")}
                            
                        />
                        {firstNameError === true ?
                        <span className="error"><i class="fas fa-exclamation-circle"></i>First name is required</span> 
                        :
                        null}
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-3">
                        <label htmlFor="mi">MI</label>
                        <input className="form-control" id="mi" type="text"  
                            maxLength={1}
                            value = {middle_initial}
                            onChange = {(e)=>handleNameChange(e,"middle_initial")} 
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="last-name">Last name</label>
                    <input id="last-name" type="text"
                        className={`form-control ${lastNameError ? 'invalid' : ''}`}
                        maxLength={30}
                        value = {last_name}
                        onChange = {(e)=>handleNameChange(e,"last_name")}
                        onBlur = {(e)=>handleFieldBlur(e,"last_name")}
                    />
                    {lastNameError === true ?
                    <span className="error"><i class="fas fa-exclamation-circle"></i>Last name is required</span>
                    :
                    null}
                </div>
                
                <div className="row form-group">
                    <div className="col-lg-8 col-md-7 col-sm-9">
                    <label htmlFor="last-name">Date of birth</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                // label="Date of birth"
                                placeholder="MM/DD/YYYY"
                                id="date-picker-dialog"
                                variant="inline"
                                format="MM/dd/yyyy"
                                value={dob}
                                onChange={handleDateChange}
                                onBlur={handleDateBlur}
                                autoOk={true}
                                maxDate={new Date()}
                                onOpen={() => handleOnOpen()}
                                onClose={() => handleOnClose()}
                                error={blurError || dobError ? 'invalid' : ''}
                                helperText={null}
                                required = {dobError}
                                //disablePast={true}
                            />
                        </MuiPickersUtilsProvider>
                        {blurError ? 
                        <span className="error"><i className="fas fa-exclamation-circle"></i>Date of birth is invalid</span> 
                        :
                        dobError ? 
                        <span className="error"><i className="fas fa-exclamation-circle"></i>Date of birth is required</span> 
                        : null }
                    </div>
                    
                </div>
                <div className="form-group">
                    <label htmlFor="eoi-gender">Gender</label>
                    <select id="eoi-gender" 
                        className={`form-control ${genderError ? 'invalid' : ''}`}
                        value={gender} 
                        onChange = {(e)=>handleNameChange(e,"gender")}
                        onBlur = {(e)=>handleFieldBlur(e,"gender")}
                    >
                        <option defaultValue >Select</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                    </select>
                    {genderError === true?
                    <span className="error"><i class="fas fa-exclamation-circle"></i>Gender is required</span>
                    :
                    null}
                </div>
                <div className="field-group">
                    <button className="btn btn-outline" onClick={()=>handleCancel()}>Cancel</button>
                    <button className="btn btn-primary" onClick={(e)=>addOrEditSave(e)} onMouseDown={(e)=>e.preventDefault()}>Save</button>
                    {/* onMouseDown={(e)=>e.preventDefault()} */}
                </div>
            </div>
        </div>
    );
}

export default WhoAreWeCoveringFormNonTrusted;
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
import _ from 'lodash';
import { statesUS } from '../../utils/constant'
import { scrollUp } from '../../utils/sharedFunction';


class PrintedEOITrusted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTrusted: false,
            isAdmin: false,
            selectedState: ""
        }

    }
    componentDidMount() {
        let state = _.get(this.props, "prefillData.eoi_data.group_info.group_situs_state")
        //let state =  _.get(this.props, "prefillData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee.state", "")

        //state = "FL"
        //console.log(statesUS[state])
        this.setState({
            selectedState: statesUS[state]
        })
        scrollUp()
    }

    downLoadPdf = (e) => {
        //console.log("download pdf", this.props.prefillData.eoi_data.health_authorization.form_optout_link)
        //let fileURL="https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf";
        let fileURL = _.get(this.props, "prefillData.eoi_data.health_authorization.form_optout_link", null)
        fetch(fileURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `EoiPrintedForm.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                // link.parentNode.removeChild(link);
            });
    }

    onPrevious = () => {
        let previousURL = _.get(this.props, "location.state.previousPage")
        //console.log("previousURL",previousURL)
        if (previousURL === 'mib') {
            this.props.history.push(`/eoi/mib`)
        }
        else if (previousURL === 'landingPage') {
            this.props.history.push(`/eoi/landingPage`)
        }
       else if(previousURL === "consent"){
        //  console.log('comming');
        //  window.location.reload(true); 
         this.props.history.push(`/eoi/consent`) 
       // this.props.history.push(`/eoi/trustedPrintedEOI`);   
        }
    }

    render() {
        // console.log("TrustedLandingPage", this.props);
        // console.log(_.get(this.props, "prefillData.eoi_data.workday_group_ind"), "day")

        return (
            <>

                <main>
                    <div className="container-fluid">
                        <div className="prev-link">
                            <button className="btn btn-link" onClick={() => this.onPrevious()}><i className="fa fa-arrow-left"></i>Back</button>
                        </div>
                    </div>
                    <div className="container printEOI-page">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="intro">
                                    <h3>Download your Evidence of Insurability form</h3>
                                    <p className="lead">Here’s the form you need to complete for the state of &nbsp;{this.state.selectedState}.</p>
                                    <button className="btn btn-primary" onClick={(e) => this.downLoadPdf(e)}><i className="fa fa-download pr-2"></i> Download EOI form </button>
                                </div>
                                <div className="data-box">
                                    <h5>Next steps</h5>
                                    {((_.get(this.props, "prefillData.eoi_data.workday_group_ind", "") === "Y") || (_.get(this.props, "groupInfoResponse.eoi_data.workday_group_ind", "") === "Y")) ? 
                                    <p className="lead">Email us your completed form to <a href="mailto:guardianunderwriting@glic.com">guardianunderwriting@glic.com</a>. We’ll review your application and notify you with the final underwriting decision.</p> :
                                    <>
                                      <p className="lead">Complete the EOI form.  Print a paper copy of your completed form and provide handwritten signatures and dates.</p>
                                      <p className="lead">Send a digitally scanned or photographed copy of your completed form to our secure email address* or a paper copy via US mail.  Your submission must include all pages and be clear and complete.  We will notify you with the final underwriting decision.                                                  </p>
                                          
                                {/* <p className="lead">Send us your completed form via US mail or to our secure email address. After we review your application, we’ll notify you with the final underwriting decision.</p> */}
                                    <div className="mt-4">
                                        <a href="mailto:appletonbilling@glic.com">appletonbilling@glic.com</a>
                                    </div>
                                    <h6 className="text-black mt-4 mb-4">PO Box 14319 <br></br>
                                    Lexington, KY  40512</h6>
                                   
                                    <p className="lead"> *Guardian accepts encrypted email (TLS) which protects information while in transit to us. Your email will be sent to us encrypted from email providers that support "opportunistic TLS email encryption" including the following providers: Gmail, Hotmail, iCloud, Mail.com, Outlook and Yahoo! Mail. If you are unsure whether your email provider uses this form of encryption, we would encourage you to mail your application through the US mail to the address above. </p>
                                    </>}
                                </div>
                                <h5 className="pt-5">Thank you for choosing Guardian!</h5>
                                {this.state.isTrusted === true ?
                                        <button className="btn btn-secondary mt-4" onClick="">Return to Plansource</button>
                                        : null}
                                
                            </div>
                        </div>
                    </div>

                </main>
            </>
        )
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(PrintedEOITrusted);

import React from 'react';
// import AlertBanner from '../../components/AlertBanner/AlertBanner';
// import { FormControl, Datepicker, Dropdown } from 'glic-ui-components';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
import _ from "lodash";

 class TermsConditions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            agreementFlag : true
        }
    }
    handleClose = () => {
        document.getElementsByClassName('showbanner')[0].style.display = 'none';
        
    } 

    
    render() {
        let alertMessage = "You must agree to these Terms and Conditions to use our site."
        // console.log(_.get(this.props, "nonTrustedData.eoi_data.group_info.group_situs_state"),"state",_.get(this.props, "prefillData.eoi_data.group_info.group_situs_state"))
        return (
            <>

                <div className="banner-margin showbanner">
                    <div className="alert-banner info">
                        <button className="close hidden-text" onClick={() =>this.handleClose()}
                        >
                            <i className="fas fa-times"> <span>Close</span> </i>
                        </button>

                        <span className="alert-msg"><i className="fas fa-exclamation-triangle"></i>{alertMessage}</span>

                    </div>
                </div>

                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="intro">
                                    <h3>Guardian Online Terms and Conditions of Use</h3>
                                    <p>By clicking “I Accept,” I acknowledge that I have read and consent to Guardian’s Online Terms and Conditions of User and Guardian Privacy Statement. I acknowledge that Guardian’s  Online Terms & Conditions of Use contain a binding arbitration clause.
                                    </p>
                                    {_.get(this.props, "nonTrustedData.eoi_data.group_info.group_situs_state") === "OR" || _.get(this.props, "prefillData.eoi_data.group_info.group_situs_state") === "OR" ? <>
                                    {(process.env.ENVIRONMENT ==='uat') ?
                                    <button className="btn btn-link blue-shade" onClick={()=>window.open("https://qa.guardiananytime.com/gafd/wps/wcm/connect/8af48ff7-d31c-4a77-b68b-33fd367a779e/OR+Online+Terms+and+Conditions+of+Use+-+Health+and+Life+v2.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CACHEID=ROOTWORKSPACE-8af48ff7-d31c-4a77-b68b-33fd367a779e-nXF22Gz","_blank")}>Guardian’s Online Terms and Conditions of Use</button> :
                                    <button className="btn btn-link blue-shade" onClick={()=>window.open("https://www.guardiananytime.com/gafd/wps/wcm/connect/8af48ff7-d31c-4a77-b68b-33fd367a779e/OR+Online+Terms+and+Conditions+of+Use+-+Health+and+Life+v2.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CACHEID=ROOTWORKSPACE-8af48ff7-d31c-4a77-b68b-33fd367a779e-nXF22Gz","_blank")}>Guardian’s Online Terms and Conditions of Use</button> 
                                }</>:
                                    <button className="btn btn-link blue-shade" onClick={()=>window.open("https://www.guardianlife.com/terms-conditions","_blank")}>Guardian’s Online Terms and Conditions of Use</button> }
                                    <button className="btn btn-link blue-shade" onClick={()=>window.open("https://www.guardianlife.com/privacy-policy","_blank")}>Privacy Policy</button>
                                </div>
                                <div className="field-group mt-5">
                                    <button className="btn btn-link blue-shade" onClick={this.props.declineTerms}>Opt out</button>
                                    <button className="btn btn-primary"  onClick={()=>this.props.acceptTerms()}>I accept</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(TermsConditions);
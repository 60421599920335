import React, { Component } from 'react';
import { coverageDetails } from '../../utils/constant';
import NumberFormat from 'react-number-format';
import AlertBanner from '../../components/AlertBanner/AlertBanner';
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store/HomePage/HomePageAction';
import { scrollUp } from "../../utils/sharedFunction";
import ReviewBenefitsPopup from '../ReviewBenefitsNCoverages/ReviewBenefitsPopup';

class SelectCoverage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBenefitPopup: false,
            memberName: "",
            spouseName: "",
            bannerFlag: false,
            showUpMember: true,
            showDownSpouse: true,
            childShow: {
                showDownChild: true,
            },
            dropdownValue: "",
            childDownList: [],
            childNameList: [],
            child_election: [{
                coverage_name: "basic_life",
                child_covered: false,
                child_excess_amount: null,
                child_insured_amount: null,
                ceaErr: false,
                ceazErr: false,
                ciaErr: false,
            }, {
                coverage_name: "voluntary_life",
                child_covered: false,
                child_excess_amount: null,
                child_insured_amount: null,
                ceaErr: false,
                ceazErr: false,
                ciaErr: false,
            }
            ],
            child_electionsList: [],
            coverageElections: [
                {
                    coverage_name: "basic_life",
                    employee_covered: false,
                    employee_excess_amount: null,
                    employee_insured_amount: null,
                    spouse_covered: false,
                    spouse_excess_amount: null,
                    spouse_insured_amount: null,
                    eeaErr: false,
                    eeazErr: false,
                    eiaErr: false,
                    seaErr: false,
                    seazErr: false,
                    siaErr: false,
                    member_only: false

                },
                {
                    coverage_name: "voluntary_life",
                    employee_covered: false,
                    employee_excess_amount: null,
                    employee_insured_amount: null,
                    spouse_covered: false,
                    spouse_excess_amount: null,
                    spouse_insured_amount: null,
                    eeaErr: false,
                    eeazErr: false,
                    eiaErr: false,
                    seaErr: false,
                    seazErr: false,
                    siaErr: false,
                    member_only: false
                },
                {
                    coverage_name: "long_term_disability",
                    employee_covered: false,
                    employee_excess_amount: null,
                    employee_insured_amount: null,
                    eeaErr: false,
                    eeazErr: false,
                    eiaErr: false,
                    member_only: true
                },
                {
                    coverage_name: "short_term_disability",
                    employee_covered: false,
                    employee_excess_amount: null,
                    employee_insured_amount: null,
                    eeaErr: false,
                    eeazErr: false,
                    eiaErr: false,
                    member_only: true
                },
                {
                    coverage_name: "voluntary_long_term_disability",
                    employee_covered: false,
                    employee_excess_amount: null,
                    employee_insured_amount: null,
                    eeaErr: false,
                    eeazErr: false,
                    eiaErr: false,
                    member_only: true
                }]
        }

    }

    componentDidMount() {
        //console.log("in did mount");
        let childElectionsList = [];
        let childElection = this.state.child_election;
        let child_DownList = [];
        let childNames = [];
        let member_name = "";
        let spouse_name = "";
        let dropdown = _.get(this.props, "whoCovered");
        let coverage_elections = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections", {});
        let memberdata = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.employee", {});
        let spousedata = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.spouse", {});
        let childdata = _.get(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.personal_information.child", []);
        if (childdata.length > 0) {
            childdata.map(child => {
                childNames.push(child.first_name + " " + (child.middle_initial ? (child.middle_initial + " ") : "") + child.last_name);
                if (coverage_elections.basic_life !== undefined) {
                    childElection[0].child_covered = coverage_elections.basic_life.child_covered;
                    childElection[0].child_excess_amount = coverage_elections.basic_life.child_excess_amount;
                    childElection[0].child_insured_amount = coverage_elections.basic_life.child_insured_amount;

                    childElection[1].child_covered = coverage_elections.voluntary_life.child_covered;
                    childElection[1].child_excess_amount = coverage_elections.voluntary_life.child_excess_amount;
                    childElection[1].child_insured_amount = coverage_elections.voluntary_life.child_insured_amount;
                }

                childElectionsList.push(childElection);
                child_DownList.push(this.state.childShow);
                return child;
            })
        }
        //let middile_initial_forMember = memberdata.middle_initial !== undefined ? memberdata.middle_initial : " ";
        //(memberdata.middle_initial ? (memberdata.middle_initial + " ") : "")
        member_name = memberdata.first_name + " " + (memberdata.middle_initial ? (memberdata.middle_initial + " ") : "") + memberdata.last_name;
        spouse_name = spousedata.first_name + " " + (spousedata.middle_initial ? (spousedata.middle_initial + " ") : "") + spousedata.last_name;

        let coverageElectionsList = this.state.coverageElections;

        if (coverage_elections.basic_life !== undefined) {
            coverageElectionsList[0].employee_covered = coverage_elections.basic_life.employee_covered;
            coverageElectionsList[0].employee_excess_amount = coverage_elections.basic_life.employee_excess_amount;
            coverageElectionsList[0].employee_insured_amount = coverage_elections.basic_life.employee_insured_amount;
            coverageElectionsList[0].spouse_covered = coverage_elections.basic_life.spouse_covered;
            coverageElectionsList[0].spouse_excess_amount = coverage_elections.basic_life.spouse_excess_amount;
            coverageElectionsList[0].spouse_insured_amount = coverage_elections.basic_life.spouse_insured_amount;

            coverageElectionsList[1].employee_covered = coverage_elections.voluntary_life.employee_covered;
            coverageElectionsList[1].employee_excess_amount = coverage_elections.voluntary_life.employee_excess_amount;
            coverageElectionsList[1].employee_insured_amount = coverage_elections.voluntary_life.employee_insured_amount;
            coverageElectionsList[1].spouse_covered = coverage_elections.voluntary_life.spouse_covered;
            coverageElectionsList[1].spouse_excess_amount = coverage_elections.voluntary_life.spouse_excess_amount;
            coverageElectionsList[1].spouse_insured_amount = coverage_elections.voluntary_life.spouse_insured_amount;

            coverageElectionsList[2].employee_covered = coverage_elections.long_term_disability.elected;
            coverageElectionsList[2].employee_excess_amount = coverage_elections.long_term_disability.excess_amount;
            coverageElectionsList[2].employee_insured_amount = coverage_elections.long_term_disability.insured_amount;

            coverageElectionsList[3].employee_covered = coverage_elections.short_term_disability.elected;
            coverageElectionsList[3].employee_excess_amount = coverage_elections.short_term_disability.excess_amount;
            coverageElectionsList[3].employee_insured_amount = coverage_elections.short_term_disability.insured_amount;

            coverageElectionsList[4].employee_covered = coverage_elections.voluntary_long_term_disability.elected;
            coverageElectionsList[4].employee_excess_amount = coverage_elections.voluntary_long_term_disability.excess_amount;
            coverageElectionsList[4].employee_insured_amount = coverage_elections.voluntary_long_term_disability.insured_amount;
        }
        scrollUp();


        this.setState({
            coverageElections: coverageElectionsList,
            dropdownValue: dropdown,
            memberName: member_name,
            spouseName: spouse_name,
            child_electionsList: childElectionsList,
            childNameList: childNames,
            childDownList: child_DownList
        })
    }

    onPrevious = () => {
        this.props.history.push(`/eoi/whoAreWeCovering`);
    }

    onCheckBoxChange = (e, index, relInd, childOuterIndex) => {

        let banner_flag = this.state.bannerFlag;
        const childElections = [...this.state.child_electionsList];
        let coverageElectionsList = [...this.state.coverageElections];
        let filterObj = coverageElectionsList[index];
        if (relInd === "member") {
            if (filterObj.employee_covered) {
                filterObj.employee_covered = false;
                filterObj.employee_excess_amount = null;
                filterObj.employee_insured_amount = null;
            }
            else {
                filterObj.employee_covered = true;
                banner_flag = false;
                filterObj.eeaErr = false;
                filterObj.eeazErr = false;
                filterObj.eiaErr = false;
            }
        }
        if (relInd === "spouse") {
            if (filterObj.spouse_covered) {
                filterObj.spouse_covered = false;
                filterObj.spouse_excess_amount = null;
                filterObj.spouse_insured_amount = null;
            }
            else {
                filterObj.spouse_covered = true;
                banner_flag = false;
                filterObj.seaErr = false;
                filterObj.seazErr = false;
                filterObj.siaErr = false;
            }
        }
        if (relInd === "child") {
            const child_election = childElections[childOuterIndex];
            const childArray = child_election;
            let filteredChildObj = childArray[index];
            if (filteredChildObj.child_covered) {
                filteredChildObj.child_covered = false;
                filteredChildObj.child_excess_amount = null;
                filteredChildObj.child_insured_amount = null;
            }
            else {
                //console.log("in child cov")
                filteredChildObj.child_covered = true;
                banner_flag = false;
                filteredChildObj.ceaErr = false;
                filteredChildObj.ceazErr = false;
                filteredChildObj.ciaErr = false;
            }
            childArray[index] = filteredChildObj;
            childElections[childOuterIndex] = childArray;
        }
        coverageElectionsList[index] = filterObj;
        this.setState({ coverageElections: coverageElectionsList, child_electionsList: childElections, bannerFlag: banner_flag })

    }

    onTextBoxChange = (e, index, fieldInd, childOuterIndex) => {
        const childElections = [...this.state.child_electionsList];
        let coverageElectionsList = this.state.coverageElections;
        let filterObj = coverageElectionsList[index];
        if (fieldInd === "eea") {
            filterObj.employee_excess_amount = e.floatValue;
        }
        if (fieldInd === "eia") {
            filterObj.employee_insured_amount = e.floatValue;
        }
        if (fieldInd === "sea") {
            filterObj.spouse_excess_amount = e.floatValue;
        }
        if (fieldInd === "sia") {
            filterObj.spouse_insured_amount = e.floatValue;
        }
        if (fieldInd === "cea") {
            const child_election = childElections[childOuterIndex];
            const childArray = child_election;
            let filteredChildObj = childArray[index];

            filteredChildObj.child_excess_amount = e.floatValue;

            childArray[index] = filteredChildObj;
            childElections[childOuterIndex] = childArray;
        }
        if (fieldInd === "cia") {
            const child_election = childElections[childOuterIndex];
            const childArray = child_election;
            let filteredChildObj = childArray[index];

            filteredChildObj.child_insured_amount = e.floatValue;

            childArray[index] = filteredChildObj;
            childElections[childOuterIndex] = childArray;
        }


        coverageElectionsList[index] = filterObj;
        this.setState({ coverageElections: coverageElectionsList, child_electionsList: childElections })
    }

    handleFieldBlur = (e, index, fieldInd, childOuterIndex) => {
        const childElections = [...this.state.child_electionsList];
        let coverageElectionsList = this.state.coverageElections;
        let filterObj = coverageElectionsList[index];
        if (fieldInd === "eea" && (filterObj.employee_excess_amount !== null || filterObj.employee_excess_amount === 0)) {
            filterObj.eeaErr = false;
        }
        if (fieldInd === "eea" && (filterObj.employee_excess_amount !== 0 || filterObj.employee_excess_amount > 0)) {
            filterObj.eeazErr = false;
        }
        if (fieldInd === "eia" && filterObj.employee_insured_amount !== null) {
            filterObj.eiaErr = false;
        }
        if (fieldInd === "sea" && (filterObj.spouse_excess_amount !== null || filterObj.spouse_excess_amount === 0)) {
            filterObj.seaErr = false;
        }
        if (fieldInd === "sea" && (filterObj.spouse_excess_amount !== 0 || filterObj.spouse_excess_amount > 0)) {
            filterObj.seazErr = false;
        }
        if (fieldInd === "sia" && filterObj.spouse_insured_amount !== null) {
            filterObj.siaErr = false;
        }
        if (fieldInd === "cea") {
            const child_election = childElections[childOuterIndex];
            const childArray = child_election;
            let filteredChildObj = childArray[index];

            if (filteredChildObj.child_excess_amount !== null || filteredChildObj.child_excess_amount === 0) {
                filteredChildObj.ceaErr = false;
            }

            if (filteredChildObj.child_excess_amount !== 0 || filteredChildObj.child_excess_amount > 0) {
                filteredChildObj.ceazErr = false;
            }

            childArray[index] = filteredChildObj;
            childElections[childOuterIndex] = childArray;
        }
        if (fieldInd === "cia") {
            const child_election = childElections[childOuterIndex];
            const childArray = child_election;
            let filteredChildObj = childArray[index];
            if (filteredChildObj.child_insured_amount !== null) {
                filteredChildObj.ciaErr = false;
            }
            childArray[index] = filteredChildObj;
            childElections[childOuterIndex] = childArray;
        }


        coverageElectionsList[index] = filterObj;
        this.setState({ coverageElections: coverageElectionsList, child_electionsList: childElections })
    }


    handleNext = () => {
        let dropDown = this.state.dropdownValue;
        let showErr = false;
        let showBanner = this.state.bannerFlag;
        let coverageElectionsList = this.state.coverageElections;
        let childElections = this.state.child_electionsList;
        if ((dropDown === "Me, my spouse, and my child(ren)" || dropDown === "Me and my spouse" || dropDown === "Me and my child" || dropDown === "My spouse and child")) {
            coverageElectionsList.map(cov => {
                if (cov.employee_covered && (cov.employee_excess_amount === undefined || cov.employee_excess_amount === null)) {
                    cov.eeaErr = true;
                    showErr = true;
                }
                if (cov.employee_covered && (cov.employee_insured_amount === undefined || cov.employee_insured_amount === null)) {
                    cov.eiaErr = true;
                    showErr = true;
                }
                if (cov.employee_covered && (cov.employee_excess_amount === 0)) {
                    cov.eeazErr = true;
                    showErr = true;
                }
                if (!cov.member_only && cov.spouse_covered && (cov.spouse_excess_amount === undefined || cov.spouse_excess_amount === null)) {
                    cov.seaErr = true;
                    showErr = true;
                }
                if (!cov.member_only && cov.spouse_covered && (cov.spouse_insured_amount === undefined || cov.spouse_insured_amount === null)) {
                    cov.siaErr = true;
                    showErr = true;
                }
                if (!cov.member_only && cov.spouse_covered && (cov.spouse_excess_amount === 0)) {
                    cov.seazErr = true;
                    showErr = true;
                }
                return cov

            })
        }
        if (childElections.length > 0) {
            childElections.map(ce => {
                ce.map(childCovg => {
                    if (childCovg.child_covered && (childCovg.child_excess_amount === undefined || childCovg.child_excess_amount === null)) {
                        childCovg.ceaErr = true;
                        showErr = true;
                    }
                    if (childCovg.child_covered && (childCovg.child_insured_amount === undefined || childCovg.child_insured_amount === null)) {
                        childCovg.ciaErr = true;
                        showErr = true;
                    }
                    if (childCovg.child_covered && (childCovg.child_excess_amount === 0)) {
                        childCovg.ceazErr = true;
                        showErr = true;
                    }
                    return childCovg
                })
                return ce
            })
        }
        if ((dropDown === "Me, my spouse, and my child(ren)" || dropDown === "Me and my spouse")) {

            if (coverageElectionsList.filter(cov => cov.employee_covered).length === 0) {
                //console.log(" in emp covered");
                showErr = true;
                showBanner = true;
                window.scrollTo(0, 0);
            }

            if (coverageElectionsList.filter(cov => (!cov.member_only && cov.spouse_covered)).length === 0) {
                showErr = true;
                showBanner = true;
                window.scrollTo(0, 0);
            }
        }
        if (dropDown === "Me and my child") {

            if (coverageElectionsList.filter(cov => cov.employee_covered).length === 0) {
                //console.log(" in emp covered");
                showErr = true;
                showBanner = true;
                window.scrollTo(0, 0);
            }

            childElections.map(ce => {
                if (ce.filter(childCovg => childCovg.child_covered).length === 0) {
                    showErr = true;
                    showBanner = true;
                    window.scrollTo(0, 0);
                }
                return ce;
            })
        }

        if (dropDown === "My spouse and child") {
            if (coverageElectionsList.filter(cov => (!cov.member_only && cov.spouse_covered)).length === 0) {
                showErr = true;
                showBanner = true;
                window.scrollTo(0, 0);
            }
        }

        if ((dropDown === "Me, my spouse, and my child(ren)" || dropDown === "My spouse and child" || dropDown === "My child")) {
            childElections.map(ce => {
                if (ce.filter(childCovg => childCovg.child_covered).length === 0) {
                    showErr = true;
                    showBanner = true;
                    window.scrollTo(0, 0);
                }
                return ce;
            })

        }


        this.setState({ coverageElections: coverageElectionsList, child_electionsList: childElections, bannerFlag: showBanner })
        let basic_life = coverageElectionsList[0];
        let voluntary_life = coverageElectionsList[1];
        let long_term_disability = coverageElectionsList[2];
        let short_term_disability = coverageElectionsList[3];
        let voluntary_long_term_disability = coverageElectionsList[4];

        if (!showErr) {

            //console.log("api call for create case");
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life", basic_life);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life", voluntary_life);

            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.long_term_disability.elected", long_term_disability.employee_covered);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.long_term_disability.excess_amount", long_term_disability.employee_excess_amount);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.long_term_disability.insured_amount", long_term_disability.employee_insured_amount);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.short_term_disability.elected", short_term_disability.employee_covered);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.short_term_disability.excess_amount", short_term_disability.employee_excess_amount);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.short_term_disability.insured_amount", short_term_disability.employee_insured_amount);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_long_term_disability.elected", voluntary_long_term_disability.employee_covered);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_long_term_disability.excess_amount", voluntary_long_term_disability.employee_excess_amount);
            _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_long_term_disability.insured_amount", voluntary_long_term_disability.employee_insured_amount);
            const childElection = this.state.child_electionsList;
            if (childElection && childElection.length >= 1) {
                let first_child_election = childElection[0];
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.child_covered", first_child_election[0].child_covered);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.child_excess_amount", first_child_election[0].child_excess_amount);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.child_insured_amount", first_child_election[0].child_insured_amount);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.child_covered", first_child_election[1].child_covered);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.child_excess_amount", first_child_election[1].child_excess_amount);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.child_insured_amount", first_child_election[1].child_insured_amount);

            }
            if (dropDown === "Me and my spouse" || dropDown === "My spouse") {
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.child_covered", false);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.child_excess_amount", null);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.child_insured_amount", null);

                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.child_covered", false);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.child_excess_amount", null);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.child_insured_amount", null);
            }

            if (dropDown === "Me and my child" || dropDown === "My child") {
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.spouse_covered", false);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.spouse_excess_amount", null);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.spouse_insured_amount", null);

                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.spouse_covered", false);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.spouse_excess_amount", null);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.spouse_insured_amount", null);

            }

            if (dropDown === "My spouse and child" || dropDown === "My child") {
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.employee_covered", false);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.employee_excess_amount", null);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.basic_life.employee_insured_amount", null);

                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.employee_covered", false);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.employee_excess_amount", null);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_life.employee_insured_amount", null);

                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.long_term_disability.elected", false);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.long_term_disability.insured_amount", null);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.long_term_disability.excess_amount", null);

                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.short_term_disability.elected", false);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.short_term_disability.insured_amount", null);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.short_term_disability.excess_amount", null);

                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_long_term_disability.elected", false);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_long_term_disability.insured_amount", null);
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections.voluntary_long_term_disability.excess_amount", null);

            }

            if (!showErr) {
                if (_.get(this.props, "groupInfoResponse.eoi_data.group_info", "")) {
                    _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.group.number", _.get(this.props, "groupInfoResponse.eoi_data.group_info.group_id", ""));

                }
                _.set(this.props, "nonTrustedData.eoi_data.authenticated", false);
                if (_.get(this.props, "dataRestoration.eoi_data.case_id")) {
                    _.set(this.props, "nonTrustedData.eoi_data.case_id", _.get(this.props, "dataRestoration.eoi_data.case_id"));
                }
                _.set(this.props, "nonTrustedData.eoi_data.benefit_coverage_info.eoi_info.group.class_id", "0001");
                _.set(this.props, "nonTrustedPageStatus.add_benefit_coverage_info_status", "COMPLETED");
                _.set(this.props, "nonTrustedPageStatus.health_question_info_status", "START");
                _.set(this.props, "nonTrustedData.eoi_data.group_info", _.get(this.props, "groupInfoResponse.eoi_data.group_info"))

                this.props.createCase(_.get(this.props, "nonTrustedData", ""));

                this.props.history.push(`/eoi/mib`);

            }
        }


    }

    collapse = (ele, rel) => {
        let showUpFlag = false;
        if (rel === "member") {
            showUpFlag = this.state.showUpMember;
            if (showUpFlag === true) {
                this.setState({ showUpMember: false });
            }
            else {
                this.setState({ showUpMember: true });
            }
        }
        else if (rel === "spouse") {
            showUpFlag = this.state.showDownSpouse;
            if (showUpFlag === true) {
                this.setState({ showDownSpouse: false });
            }
            else {
                this.setState({ showDownSpouse: true });
            }
        }
        else {
            const childList = this.state.childDownList;
            const filteredChildList = childList[rel];
            let filteredobj = filteredChildList;
            //console.log(childList);
            if (filteredobj.showDownChild === true) {
                filteredobj.showDownChild = false;
                childList[rel] = filteredobj;
                this.setState({ childDownList: childList });
            }
            else {
                filteredobj.showDownChild = true;
                childList[rel] = filteredobj;
                this.setState({ childDownList: childList });
            }
        }



    }

    handleClose = () => {
        this.setState({
            bannerFlag: false
        });
    }

    onBenefitPopup = () => {
        this.setState({ showBenefitPopup: !this.state.showBenefitPopup });
    }

    onClosepopup = () => {
        //console.log("this.state", this.state);
        this.setState({ showBenefitPopup: !this.state.showBenefitPopup });
    }

    render() {
        // console.log(this.props)
        let check = _.get(this.props, "nonTrustedData.eoi_data.admin_group_indicator")
        console.log(check, "ll", this.props);
        let memCov = _.get(this.props, "nonTrustedData.eoi_data.admin_group_coverages")
        let headingLabel = "Children’s coverage";
        let contentlabel = "All children’s current insured and requested additional amounts should match. If you change the amount for one child, we will automatically update the amounts for your other child(ren).";
        return (
            <>
                {this.state.bannerFlag === true ? <AlertBanner
                    errorMessage="Please select at least one coverage for each member of your family and fill out all required fields."
                    handleClose={() => this.handleClose()} /> : null}
                {this.state.showBenefitPopup ?
                    <ReviewBenefitsPopup headingLabel={headingLabel} contentlabel={contentlabel} closepopup={this.onClosepopup} />
                    :
                    <main>
                        <div className="container-fluid">
                            <div className="prev-link">
                                <button className="btn btn-link" onClick={this.onPrevious}><i className="fa fa-arrow-left"></i>Back</button>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="intro">
                                        <h3>Select your coverage</h3>

                                        <p>Choose the benefits you would like for each member of your family.
                                            {this.state.child_electionsList.length >= 2 &&
                                                <div className="tips-info">
                                                    <div className="itext" onClick={() => this.onBenefitPopup()}><i className="fas fa-info-circle"></i></div>
                                                </div>}
                                        </p>
                                    </div>
                                    <div className="row">
                                        <ul className="col-lg-10 accordion" id="accordion">
                                            {this.state.dropdownValue === "Me, my spouse, and my child(ren)" || this.state.dropdownValue === "Me and my spouse" || this.state.dropdownValue === "Me and my child" ?
                                                <li className="accordion-card">
                                                    <div className="accordion-card-header" id="headingOne">
                                                        <h6>{this.state.memberName}</h6>
                                                        <button className="close hidden-text" type="button" data-toggle="collapse"
                                                            data-target="#collapseOne" aria-expanded="true"
                                                            aria-controls="collapseOne" onClick={(e) => this.collapse(e, "member")}>
                                                            <i className={this.state.showUpMember ? "fas fa-chevron-up" : "fas fa-chevron-down"}> <span className="fas fa-chevron-up">Collapse button</span> </i>
                                                        </button>
                                                    </div>
                                                    <div id="collapseOne" className={this.state.showUpMember ? "collapse show" : "collapse"} aria-labelledby="headingOne" data-parent="#accordion">
                                                        {this.state.coverageElections.map((coverage, index) => {

                                                            return (
                                                                <div className="accordion-card-body">
                                                                    <div className="checkbox">
                                                                        {check === "N" || check === undefined ?
                                                                            <> <input type="checkbox" checked={coverage.employee_covered} onChange={(e) => this.onCheckBoxChange(e, index, "member", " ")} id={coverage.coverage_name} name={coverage.coverage_name} />
                                                                                <label htmlFor={coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</label>
                                                                            </> : memCov.employee.map((cov) => (
                                                                                cov === coverageDetails[coverage.coverage_name].value ?
                                                                                    <> <input type="checkbox" checked={coverage.employee_covered} onChange={(e) => this.onCheckBoxChange(e, index, "member", " ")} id={coverage.coverage_name} name={coverage.coverage_name} />
                                                                                        <label htmlFor={coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</label></> : null
                                                                            ))
                                                                        }
                                                                    </div>
                                                                    {coverage.employee_covered ?
                                                                        <div className="data-box checkbox-data">
                                                                            <div className="form-group">
                                                                                <label htmlFor={coverage.coverage_name + "-current"}>Current insured amount ($)</label>
                                                                                <NumberFormat thousandSeparator={true} decimalSeparator={false} className="form-control" maxLength={14} onValueChange={(values) => this.onTextBoxChange(values, index, "eia", " ")} onBlur={(e) => this.handleFieldBlur(e, index, "eia", " ")} value={coverage.employee_insured_amount} id={coverage.coverage_name + "-current"} type="text" placeholder="E.g., 5,000" />
                                                                                {coverage.eiaErr ? <span className="error"><i className="fas fa-exclamation-circle"></i>Amount is required</span> : null}

                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label htmlFor={coverage.coverage_name + "-requested"}>Requested additional amount ($)</label>
                                                                                <NumberFormat thousandSeparator={true} decimalSeparator={false} className="form-control" maxLength={14} onValueChange={(values) => this.onTextBoxChange(values, index, "eea", " ")} onBlur={(e) => this.handleFieldBlur(e, index, "eea", " ")} value={coverage.employee_excess_amount} id={coverage.coverage_name + "-requested"} type="text" placeholder="E.g., 5,000" />
                                                                                {coverage.eeaErr ? <span className="error"><i className="fas fa-exclamation-circle"></i>Amount is required</span> : null}
                                                                                {coverage.eeazErr && coverage.eeaErr === false ? <span className="error"><i className="fas fa-exclamation-circle"></i>Zero is not a valid amount</span> : null}
                                                                            </div>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </li> : null}
                                            {this.state.dropdownValue === "Me, my spouse, and my child(ren)" || this.state.dropdownValue === "Me and my spouse" || this.state.dropdownValue === "My spouse and child" ?
                                                <li className="accordion-card">
                                                    <div className="accordion-card-header" id="headingTwo">
                                                        <h6>{this.state.spouseName}</h6>
                                                        <button className="close hidden-text" type="button" data-toggle="collapse"
                                                            data-target="#collapseTwo" aria-expanded="true"
                                                            aria-controls="collapseTwo" onClick={(e) => this.collapse(e, "spouse")}>
                                                            <i className={this.state.showDownSpouse ? "fas fa-chevron-down" : "fas fa-chevron-up"}><span>Collapse button</span></i>
                                                        </button>
                                                    </div>
                                                    <div id="collapseTwo" className={this.state.showDownSpouse ? "collapse " : "collapse show"} aria-labelledby="headingTwo" data-parent="#accordion">
                                                        {this.state.coverageElections.map((coverage, index) => {

                                                            return (
                                                                !coverage.member_only ?
                                                                    <div className="accordion-card-body">

                                                                        <div className="checkbox">
                                                                            {check === "N" || check === undefined ?
                                                                                <>  <input type="checkbox" checked={coverage.spouse_covered} onChange={(e) => this.onCheckBoxChange(e, index, "spouse", " ")} id={"spouse" + coverage.coverage_name} name={"spouse" + coverage.coverage_name} />
                                                                                    <label htmlFor={"spouse" + coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</label>
                                                                                </>
                                                                                : memCov.spouse.map((cov) => (
                                                                                    cov === coverageDetails[coverage.coverage_name].value ?
                                                                                        <>  <input type="checkbox" checked={coverage.spouse_covered} onChange={(e) => this.onCheckBoxChange(e, index, "spouse", " ")} id={"spouse" + coverage.coverage_name} name={"spouse" + coverage.coverage_name} />
                                                                                            <label htmlFor={"spouse" + coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</label> </> : null
                                                                                ))
                                                                            }
                                                                        </div>
                                                                        {coverage.spouse_covered ?
                                                                            <div className="data-box checkbox-data">
                                                                                <div className="form-group">
                                                                                    <label htmlFor={"spouse" + coverage.coverage_name + "-current"}>Current insured amount ($)</label>
                                                                                    <NumberFormat thousandSeparator={true} decimalSeparator={false} className="form-control" maxLength={14} onValueChange={(values) => this.onTextBoxChange(values, index, "sia", " ")} onBlur={(e) => this.handleFieldBlur(e, index, "sia", " ")} value={coverage.spouse_insured_amount} id={"spouse" + coverage.coverage_name + "-current"} type="text" placeholder="E.g., 5,000" />
                                                                                    {coverage.siaErr ? <span className="error"><i className="fas fa-exclamation-circle"></i>Amount is required</span> : null}
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor={"spouse" + coverage.coverage_name + "-requested"}>Requested additional amount ($)</label>
                                                                                    <NumberFormat thousandSeparator={true} decimalSeparator={false} className="form-control" maxLength={14} onValueChange={(values) => this.onTextBoxChange(values, index, "sea", " ")} onBlur={(e) => this.handleFieldBlur(e, index, "sea", " ")} value={coverage.spouse_excess_amount} id={"spouse" + coverage.coverage_name + "-requested"} type="text" placeholder="E.g., 5,000" />
                                                                                    {coverage.seaErr ? <span className="error"><i className="fas fa-exclamation-circle"></i>Amount is required</span> : null}
                                                                                    {coverage.seazErr && coverage.seaErr === false ? <span className="error"><i className="fas fa-exclamation-circle"></i>Zero is not a valid amount</span> : null}
                                                                                </div>
                                                                            </div>
                                                                            : null}
                                                                    </div> : null
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </li> : null}
                                            {this.state.child_electionsList.length > 0 && this.state.child_electionsList.map((childElection, outerIndex) => {
                                                return (
                                                    <li className="accordion-card">
                                                        <div className="accordion-card-header" id={"headingThree" + outerIndex}>
                                                            <h6>{this.state.childNameList[outerIndex]}</h6>
                                                            <button className="close hidden-text" type="button" data-toggle="collapse"
                                                                data-target={"#collapseThree" + outerIndex} aria-expanded="true"
                                                                aria-controls={"collapseThree" + outerIndex} onClick={(e) => this.collapse(e, outerIndex)}>
                                                                <i className={this.state.childDownList[outerIndex].showDownChild ? "fas fa-chevron-down" : "fas fa-chevron-up"}><span>Collapse button</span></i>
                                                            </button>
                                                        </div>
                                                        <div id={"collapseThree" + outerIndex} className={this.state.childDownList[outerIndex].showDownChild ? "collapse " : "collapse show"} aria-labelledby={"headingThree" + outerIndex} data-parent="#accordion">
                                                            {childElection.map((coverage, index) => {

                                                                return (

                                                                    <div className="accordion-card-body">
                                                                        <div className="checkbox">
                                                                            {check === "N" || check === undefined ?
                                                                                <><input type="checkbox" checked={coverage.child_covered} onChange={(e) => this.onCheckBoxChange(e, index, "child", outerIndex)} id={"child" + outerIndex + index + coverage.coverage_name} name={"child" + outerIndex + index + coverage.coverage_name} />
                                                                                    <label htmlFor={"child" + outerIndex + index + coverage.coverage_name} name={"child" + outerIndex + index + coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</label>
                                                                                </> :
                                                                                memCov.child.map((cov) => (
                                                                                    cov === coverageDetails[coverage.coverage_name].value ?
                                                                                        <><input type="checkbox" checked={coverage.child_covered} onChange={(e) => this.onCheckBoxChange(e, index, "child", outerIndex)} id={"child" + outerIndex + index + coverage.coverage_name} name={"child" + outerIndex + index + coverage.coverage_name} />
                                                                                            <label htmlFor={"child" + outerIndex + index + coverage.coverage_name} name={"child" + outerIndex + index + coverage.coverage_name}>{coverageDetails[coverage.coverage_name].value}</label></> : null
                                                                                ))
                                                                            }
                                                                        </div>
                                                                        {coverage.child_covered ?
                                                                            <div className="data-box checkbox-data">
                                                                                <div className="form-group">
                                                                                    <label htmlFor={"child" + outerIndex + index + coverage.coverage_name + "-current"}>Current insured amount ($)</label>
                                                                                    <NumberFormat thousandSeparator={true} decimalSeparator={false} className="form-control" maxLength={14} onValueChange={(values) => this.onTextBoxChange(values, index, "cia", outerIndex)} onBlur={(e) => this.handleFieldBlur(e, index, "cia", outerIndex)} value={coverage.child_insured_amount} id={"chil" + outerIndex + index + coverage.coverage_name + "-current"} type="text" placeholder="E.g., 5,000" />
                                                                                    {coverage.ciaErr ? <span className="error"><i className="fas fa-exclamation-circle"></i>Amount is required</span> : null}
                                                                                </div>
                                                                                <div className="form-group">
                                                                                    <label htmlFor={"child" + outerIndex + index + coverage.coverage_name + "-requested"}>Requested additional amount ($)</label>
                                                                                    <NumberFormat thousandSeparator={true} decimalSeparator={false} className="form-control" maxLength={14} onValueChange={(values) => this.onTextBoxChange(values, index, "cea", outerIndex)} onBlur={(e) => this.handleFieldBlur(e, index, "cea", outerIndex)} value={coverage.child_excess_amount} id={"child" + outerIndex + index + coverage.coverage_name + "-requested"} type="text" placeholder="E.g., 5,000" />
                                                                                    {coverage.ceaErr ? <span className="error"><i className="fas fa-exclamation-circle"></i>Amount is required</span> : null}
                                                                                    {coverage.ceazErr && coverage.ceaErr === false ? <span className="error"><i className="fas fa-exclamation-circle"></i>Zero is not a valid amount</span> : null}
                                                                                </div>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                    <hr />
                                    <div className="field-group float-right">
                                        <button className="btn btn-primary" onClick={this.handleNext}>Next</button>
                                    </div>
                                </div>
                            </div>


                        </div>
                        {this.state.child_electionsList.length >= 2 &&
                            <div className="tips-container">
                                <div className="tip-box">
                                    <div className="heading">{headingLabel}</div>
                                    {contentlabel}
                                </div>
                            </div>}

                    </main>

                }
            </>
        );
    }
}

export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(SelectCoverage);
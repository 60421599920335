import React, { Component } from 'react';

class LandingPopUp extends Component {

    render() {
        return (
            <>
                <div className="popup-bg">
                    <div className="popup-box big">
                        <div className="popup-header">
                            <button className="close hidden-text">
                                <i className="fas fa-times"><span>close button</span></i>
                            </button>
                        </div>
                        <div className="container landing-popup">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h4 role='dialog'>Important Notice For Employees and Participants Regarding Evidence of Insurability</h4>
                                    <hr />
                                    <p>In certain circumstances, your Guardian group plan may require that you  submit evidence of insurability (EOI) before your coverage is approved. EOI is part of the application process in which you provide information on the condition of your health  or your dependent's health in order to be considered for certain types of insurance coverage.</p>
                                    <p>This typically occurs when you:
                                        <ul>
                                            <li>Enroll for coverage outside of your initial coverage eligibility period, or</li>
                                            <li>Apply for a higher amount of coverage than what's considered guaranteed issue in your plan</li>
                                        </ul>
                                    </p>
                                    <p>Once you submit EOI , Guardian will review it and let you know the status of your request for coverage. You or your employer should not submit premium payments until you are notified that your request for coverage is approved.</p>
                                    <p>If you have any questions, please contact us at:</p>
                                    <div className="mt-4">
                                        <i className="fas fa-envelope pr-1" alt="email icon"></i>
                                        <a href="mailto:CRU@glic.com">CRU@glic.com</a>
                                    </div>
                                    <div className="mt-2">
                                        <i className="fas fa-phone-alt pr-1" alt="call icon"></i>
                                        <a href="tel:18006274200">1-800-627-4200</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="v-center-content mt-3 mb-3">
                            <div><button className="btn btn-primary m-2" onClick={this.props.handlePopUp} > Close </button></div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default LandingPopUp


import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../../store/HomePage/HomePageAction';
import _ from 'lodash';
// import Warning from "../../../components/Warning/Warning";
import {chooseNextQuestion,imagePath} from '../../../utils/sharedFunction'
class MoveOn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastOrNext : "next"
        }
    }
    onMove = () => {
        chooseNextQuestion(this.props)
        // const lifeEntities=_.get(this.props,"prefillData.eoi_data.health_question_info.life_entities",[])
         //console.log(lifeEntities.length)
        // let conditionInfo;
        // const baseQuestion = _.get(this.props,"prefillData.eoi_data.health_question_info.base_question")
        // baseQuestion && baseQuestion.map(question => {
        //     if(question.question_id === "1"){
        //         conditionInfo = question.condition_info_required
        //     }
        //     return question
        // })
        // const activeQuestion = _.get(this.props,"activeQuestion","")
        // if(lifeEntities.length === 1 && conditionInfo === true){
        //     this.props.questionStatus(parseInt(activeQuestion)+1)
        //     this.props.history.push({
        //             pathname: `/eoi/question/${parseInt(activeQuestion)+1}`,
        //             state: "memberOnly"
        //     })
        // }
        // if(lifeEntities.length > 1 && conditionInfo === true){
        //     this.props.questionStatus(parseInt(activeQuestion)+1)
        //     this.props.history.push({
        //         pathname: `/eoi/question/${parseInt(activeQuestion)+1}`,
        //         state: "family"
        //     })
        // }
        
        
    }
    componentDidMount() {
        //console.log(this.props.totalQuestion,this.props.activeQuestion)
        if(parseInt(_.get(this.props,"activeQuestion"))=== parseInt(_.get(this.props,"totalQuestion"))-1){
            this.setState({
                lastOrNext:"last"
            })
        }
        else{
            this.setState({
                lastOrNext:"next"
            })
        }
        window.addEventListener("popstate", () => {
            this.props.history.goForward();
            // this.props.bClick(true);
          })
    }
    // handleBrowser= () =>{
    //     this.props.bClick(false);
    //    }

    render() {
        //console.log("inside render--",this.props);
        return (
            <>
             {/* {this.props.backClick === true ? <Warning handleBrowser = {() => this.handleBrowser()}/> : null} */}

                <main>
                    <div className="container moveon-page">
                        <div className="col-lg-12 ">
                            <div className="col-lg-5 intro text-center">
                                
                                    <div><h3>Thanks! Let’s move on to the {this.state.lastOrNext} question.</h3>
                                        <button className="btn btn-primary mt-4" onClick={(e) => this.onMove()}>Continue</button>
                                    </div> 
                                   
                                 </div>
                            <img src={imagePath("./images/moveon-illustration.svg")} className="illustration mt-5" alt="illustration" />
                        </div>
                    </div>

                </main>
            </>
        )
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(MoveOn);
import React from 'react';
import {
  imagePath,
} from "../../utils/sharedFunction";
 const JustAMoment= props => (
    <>

        <main>
                <div className="container processing-page">
                    <div className="col-lg-12 text-center">
                        <div className="col-lg-5 intro">
                            <h3>Hold tight while we get your answer...</h3>
                        </div>
                        <img src={imagePath("./images/Double_Ring.svg")} className="spinner mt-5" alt="spinner" />
                    </div>
                </div>

        </main>
    </>
);

export default JustAMoment
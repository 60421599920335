import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from 'lodash';
import { actionCreators } from '../../store/HomePage/HomePageAction';
import { scrollUp } from '../../utils/sharedFunction';

class ReviewBasics extends Component {

  constructor(props) {
    super(props);
    this.state = {

        employeeCovered:false,
        spouseCovered:false,
        childCovered:false,
        coverageSvg:null
    }
  }
  componentDidMount(){
      scrollUp()
      const coverages = _.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.coverage_elections",{})

      // if(_.get(this.props, "prefillData.eoi_data.review_status","")!=="COMPLETED"){
      //     _.set(this.props, "prefillData.eoi_data.review_status","ACTIVE")
      //     this.props.updateprefillData(_.get(this.props, "prefillData",""), _.get(this.props, "prefillId",""))
      // }
      const disabilityCoverages = ["short_term_disability", "long_term_disability", "voluntary_long_term_disability", "voluntary_short_term_disability" ]
      let coverageElected = []
      let allEmployeeStatus = []
      let allSpouseStatus = []
      let allChildrenStatus = []
      
      for (const coverage in coverages){
          let disabilityCoverageElectedStatus = false
          let employeeCoveredStatus = false
          let spouseCoveredStatus = false
          let childCoveredStatus = false
          if(disabilityCoverages.includes(coverage)){
              
              disabilityCoverageElectedStatus = coverages[coverage] && coverages[coverage].elected && coverages[coverage].elected
          }
          else{
              employeeCoveredStatus = coverages[coverage] && coverages[coverage].employee_covered && coverages[coverage].employee_covered
              spouseCoveredStatus = coverages[coverage] && coverages[coverage].spouse_covered && coverages[coverage].spouse_covered
              childCoveredStatus = coverages[coverage] && coverages[coverage].child_covered && coverages[coverage].child_covered 
          }
          
          if(employeeCoveredStatus === true || spouseCoveredStatus === true || childCoveredStatus ===true || disabilityCoverageElectedStatus === true){
              coverageElected.push(coverage)
          }
          allEmployeeStatus.push(employeeCoveredStatus)
          allEmployeeStatus.push(disabilityCoverageElectedStatus)
          allSpouseStatus.push(spouseCoveredStatus)
          allChildrenStatus.push(childCoveredStatus)
      }
        // console.log("allEmployeeStatus",allEmployeeStatus)
      if(allEmployeeStatus.includes(true))
      {
        this.setState({coverageSvg : "./images/Employer-Gender-Neutral.svg"})
      }
      if(allSpouseStatus.includes(true) || allChildrenStatus.includes(true))
      {
        this.setState({coverageSvg : "./images/family-icon.svg"})
      }

      this.setState({
          employeeCovered: allEmployeeStatus.includes(true),
          spouseCovered:allSpouseStatus.includes(true),
          childCovered:allChildrenStatus.includes(true)
      })

  }

  gettingCovered = () => {
    const { employeeCovered, spouseCovered, childCovered } = this.state    
    
     if(employeeCovered ===true && spouseCovered===true && childCovered===true){
        return <p class="hudson-navy font-weight-bold">Me, my spouse, my child(ren)</p>
     }
     else if(employeeCovered ===true && spouseCovered===true && childCovered===false){
         return <p class="hudson-navy font-weight-bold">Me, my spouse</p> 
     }
     else if(employeeCovered ===true && spouseCovered===false && childCovered===false){
         return <p class="hudson-navy font-weight-bold">Me</p>
     }
     else if(employeeCovered ===false && spouseCovered===true && childCovered===false){
         return <p class="hudson-navy font-weight-bold">My spouse</p> 
     }
     else if(employeeCovered ===false && spouseCovered===false && childCovered===true){
         return <p class="hudson-navy font-weight-bold">My child(ren)</p>
    }
     else if(employeeCovered ===true && spouseCovered===false && childCovered===true){
         return <p class="hudson-navy font-weight-bold">Me, my child(ren)</p> 
    }
     else if(employeeCovered ===false && spouseCovered===true && childCovered===true){
         return <p class="hudson-navy font-weight-bold">My spouse, my child(ren)</p> 
    }
  }

  render() {

    const {trustedFlag} = this.props ;

    return (
      <>
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <p>
                <strong>Group ID / Plan number</strong>
              </p>
              <p class="pb-3">{_.get(this.props, "prefillData.eoi_data.group_info.group_id", "") }</p>
            </div>
            <div class="col-md-4">
              <p>
                <strong>Company / Planholder name</strong>
              </p>
              <p class="pb-2"> {_.get(this.props, "prefillData.eoi_data.group_info.group_name", "") }</p>
            </div>
          </div>
        </div>

        <div class="container">
              <div class="row">
                <div class="col-md-10">
                  <div class="pt-4">
                    <h2 style = {{fontSize:"24px"}} class="hugo-blue">Who's getting covered:</h2>
                  </div>
                </div>
              </div>
         
                <div className="row">
                  <div className="col-1 col-md-1 col-sm-2 mb-3">             
                    <img src={this.state.coverageSvg} alt="" className="data-icon"  
                    width="48px"
                    height="48px"
                    />
                </div>
               

                {trustedFlag === true ?
                     <div class="col-5 mt-2">
                       <div class="hudson-navy font-weight-bold">{this.gettingCovered()}</div>
                    </div>
                     : 
                   <div class="col-5 mt-2">
                      <div class="hudson-navy font-weight-bold">{_.get(this.props, "whoCovered", "")}</div>
                    </div>
                  }
              </div>
            </div>


      </>
    )
  }


}
export default connect(
  (state) => state.eoiStore,
  (dispatch) => bindActionCreators(actionCreators, dispatch))(ReviewBasics
  );
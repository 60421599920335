import React, { Component } from "react";

export default class ReviewBenefitsPopup extends Component {
    handleClose(){
        this.props.closepopup();
    }
    render() {
        return (
            <>
                <div className="popup">
                    <button  onClick={() => this.handleClose()} className="close">
                        <span className="fas fa-times"></span>
                    </button>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h4>{this.props.headingLabel}</h4>
                                <div className="tip-section">
                                    <div className="small-copy">{this.props.contentlabel}</div>
                                </div>
                            </div>
                        </div>
                        <div className="field-group mt-5">
                            <button className="btn btn-primary" onClick={() => this.handleClose()}>Close</button>
                        </div>
                    </div>
                </div>
            </>)
    }
}
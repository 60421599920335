import React, { Component } from "react";
import {
  imagePath,
} from "../../utils/sharedFunction";

class UnauthorizedError extends Component {
    render() {
        return (
            <>
        <main>
            {/* {console.log("aaaaaaaaaa")} */}
            <div>
                <header>
                        <div className="col-md-12">
                          <a className="navbar-brand" href="./images/guardian_logo.svg">
                            <img
                              className="logo"
                              src={imagePath("./images/guardian_logo.svg")}
                              alt="Guardian Logo"
                            />
                          </a>
                        </div>
                </header>
                <div className="field-group">
                  {/* {console.log(this.props)}
                  {this.props.location && this.props.location.state.isAxiosError===true?
                  <div className="error" ><h1 style={{textAlign:'center'}}>{this.props.location.state.message}</h1></div>
                  : */}
                    <div className="error" ><h1 style={{textAlign:'center'}}>You are not authorized to access this page!</h1></div>
                  {/* } */}
                </div>
                
            </div>
        </main>
    </>
        );
    }
}

export default UnauthorizedError;
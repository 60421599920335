
import {
    CHANGE_PAGE_STATUS, GET_STATE_CITY,
    GET_PREFILL_DATA, ASSIGN_TRUSTED_STATUS,
    STORE_PREFILL_ID, LINK,
    CHANGE_WELCOME_BACK_STATUS_TO_FALSE,
    GET_GROUP_INFO, CLEAR_GET_GROUP_INFO_DATA,
    ASSIGN_TEMPLATE,
    QUESTION_STATUS, TOTAL_QUESTION,
    SAVE_COVERAGE_DETAILS, SAVE_WHO_COVRED, GET_KNOCKOUT_DATA,
    CONDITION_STATUS, GET_RECAPTCHA_RESP_VALUE, REFLUX_STATUS, SET_REVIEW_PAGE_BYTEDATA, CLEAR_PHYSICIAN_STATE_CITY_DETAILS,
    DISPLAY_MEDIA_ICONS, LIFE_STATUS, COUNT_CONDITION, EDIT_INFO,COUNT_REFLEX,CLICK_BROWSER,CONTACT_BUTTON,RESTORE,WORKDAY_CHECK
    ,SAVE_CA_CONSTENT_DATA,
    GET_EMAIL_VALIDATE
} from "../../utils/actionTypes";


const initialState = {
    recaptchaError: false,
    // prefillData:{},
    trustedFlag: null,
    prefillId: "",
    welcomeBackStatus: true,
    nonTrustedPageStatus: {
        "add_benefit_coverage_info_status": "START"
    },
    nonTrustedData: {
        //"add_benefit_coverage_info_status" : "START",
        // "tell_us_more_status": "",
        // "who_are_we_covering_status":"",
        // "select_coverage_status":"",

        "eoi_data": {
            "authenticated": false,
            "case_id": "",
            "benefit_coverage_info": {

                "eoi_info": {
                    "group": {
                        "number": "",
                        "class_id": ""
                    },
                    "personal_information": {
                        "employee": {
                        },
                        "spouse": {},
                        "child": []
                    },
                    "coverage_elections": {}
                }

            },

        },
    },

    prefillData: null,
    //{
    // "user_id": "TestUser",
    // "eoi_data": {
    //     "authenticated": true,
    //     "encrypted_prefillId": "jNhPFUdTi6C1SLD7qzL4xA==",
    //     "benefit_coverage_info": {
    //         "eoi_info": {
    //             "source": "TEST",
    //             "version": "2.0",
    //             "eoi_verification_required_flag": false,
    //             "class_id": "0001",
    //             "group": {
    //                 "number": "00358029",
    //                 "class_id": "0001"
    //             },
    //             "event_details": {
    //                 "event_type": "E",
    //                 "event_date": "2019-05-05T00:00:00.000+00:00",
    //                 "sign_date": "2019-05-05T00:00:00.000+00:00"
    //             },
    //             "coverage_elections": {
    //                 "basic_life": {
    //                     "employee_covered": true,
    //                     "spouse_covered": false,
    //                     "child_covered": false,
    //                     "employee_insured_amount": 0.0,
    //                     "employee_excess_amount": 50000.0,
    //                     "spouse_insured_amount": null,
    //                     "spouse_excess_amount": null,
    //                     "child_insured_amount": null,
    //                     "child_excess_amount": null,
    //                     "employee_effective_date": "2019-06-06T00:00:00.000+00:00",
    //                     "spouse_effective_date": null,
    //                     "child_effective_date": null,
    //                     "employee_coverage_details": {
    //                         "guarantee_issue_amount": 100000.0,
    //                         "additional_amount": 0.0,
    //                         "conditional_amount": 0.0,
    //                         "existing_amount": 100000.0,
    //                         "elected_amount": 150000.0,
    //                         "max_amount": 300000.0,
    //                         "late_entrant": "N",
    //                         "ben_desc_cd": "LIFE",
    //                         "open_enrollment_ind": "N",
    //                         "grandfather_ind": "Y",
    //                         "grandfather_amount": 25000.0
    //                     },
    //                     "spouse_coverage_details": null,
    //                     "child_coverage_details": null
    //                 },
    //                 "voluntary_life": {
    //                     "employee_covered": false,
    //                     "spouse_covered": false,
    //                     "child_covered": false,
    //                     "employee_insured_amount": null,
    //                     "employee_excess_amount": null,
    //                     "spouse_insured_amount": null,
    //                     "spouse_excess_amount": null,
    //                     "child_insured_amount": null,
    //                     "child_excess_amount": null,
    //                     "employee_effective_date": null,
    //                     "spouse_effective_date": null,
    //                     "child_effective_date": null,
    //                     "employee_coverage_details": null,
    //                     "spouse_coverage_details": null,
    //                     "child_coverage_details": null
    //                 },
    //                 "basic_critical_illness": {
    //                     "employee_covered": false,
    //                     "spouse_covered": false,
    //                     "child_covered": false,
    //                     "employee_insured_amount": null,
    //                     "employee_excess_amount": null,
    //                     "spouse_insured_amount": null,
    //                     "spouse_excess_amount": null,
    //                     "child_insured_amount": null,
    //                     "child_excess_amount": null,
    //                     "employee_effective_date": null,
    //                     "spouse_effective_date": null,
    //                     "child_effective_date": null,
    //                     "employee_coverage_details": null,
    //                     "spouse_coverage_details": null,
    //                     "child_coverage_details": null
    //                 },
    //                 "voluntary_critical_illness": {
    //                     "employee_covered": false,
    //                     "spouse_covered": false,
    //                     "child_covered": false,
    //                     "employee_insured_amount": null,
    //                     "employee_excess_amount": null,
    //                     "spouse_insured_amount": null,
    //                     "spouse_excess_amount": null,
    //                     "child_insured_amount": null,
    //                     "child_excess_amount": null,
    //                     "employee_effective_date": null,
    //                     "spouse_effective_date": null,
    //                     "child_effective_date": null,
    //                     "employee_coverage_details": null,
    //                     "spouse_coverage_details": null,
    //                     "child_coverage_details": null
    //                 },
    //                 "long_term_disability": {
    //                     "elected": false,
    //                     "insured_amount": null,
    //                     "excess_amount": null,
    //                     "effective_date": null,
    //                     "employee_coverage_details": {
    //                         "guarantee_issue_amount": null,
    //                         "additional_amount": null,
    //                         "conditional_amount": null,
    //                         "existing_amount": null,
    //                         "elected_amount": null,
    //                         "max_amount": null,
    //                         "late_entrant": null,
    //                         "ben_desc_cd": null,
    //                         "open_enrollment_ind": null,
    //                         "grandfather_ind": null,
    //                         "grandfather_amount": null
    //                     }
    //                 },
    //                 "short_term_disability": {
    //                     "elected": false,
    //                     "insured_amount": null,
    //                     "excess_amount": null,
    //                     "effective_date": null,
    //                     "employee_coverage_details": {
    //                         "guarantee_issue_amount": null,
    //                         "additional_amount": null,
    //                         "conditional_amount": null,
    //                         "existing_amount": null,
    //                         "elected_amount": null,
    //                         "max_amount": null,
    //                         "late_entrant": null,
    //                         "ben_desc_cd": null,
    //                         "open_enrollment_ind": null,
    //                         "grandfather_ind": null,
    //                         "grandfather_amount": null
    //                     }
    //                 },
    //                 "cancer": {
    //                     "employee_covered": false,
    //                     "spouse_covered": false,
    //                     "child_covered": false,
    //                     "tpa_benefit_code_employee": null,
    //                     "tpa_benefit_code_spouse": null,
    //                     "tpa_benefit_code_child": null,
    //                     "employee_effective_date": null,
    //                     "spouse_effective_date": null,
    //                     "child_effective_date": null,
    //                     "employee_coverage_details": {
    //                         "guarantee_issue_amount": null,
    //                         "additional_amount": null,
    //                         "conditional_amount": null,
    //                         "existing_amount": null,
    //                         "elected_amount": null,
    //                         "max_amount": null,
    //                         "late_entrant": null,
    //                         "ben_desc_cd": null,
    //                         "open_enrollment_ind": null,
    //                         "grandfather_ind": null,
    //                         "grandfather_amount": null
    //                     },
    //                     "spouse_coverage_details": {
    //                         "guarantee_issue_amount": null,
    //                         "additional_amount": null,
    //                         "conditional_amount": null,
    //                         "existing_amount": null,
    //                         "elected_amount": null,
    //                         "max_amount": null,
    //                         "late_entrant": null,
    //                         "ben_desc_cd": null,
    //                         "open_enrollment_ind": null,
    //                         "grandfather_ind": null,
    //                         "grandfather_amount": null
    //                     },
    //                     "child_coverage_details": {
    //                         "guarantee_issue_amount": null,
    //                         "additional_amount": null,
    //                         "conditional_amount": null,
    //                         "existing_amount": null,
    //                         "elected_amount": null,
    //                         "max_amount": null,
    //                         "late_entrant": null,
    //                         "ben_desc_cd": null,
    //                         "open_enrollment_ind": null,
    //                         "grandfather_ind": null,
    //                         "grandfather_amount": null
    //                     }
    //                 },
    //                 "voluntary_long_term_disability": {
    //                     "elected": false,
    //                     "insured_amount": null,
    //                     "excess_amount": null,
    //                     "effective_date": null,
    //                     "employee_coverage_details": {
    //                         "guarantee_issue_amount": null,
    //                         "additional_amount": null,
    //                         "conditional_amount": null,
    //                         "existing_amount": null,
    //                         "elected_amount": null,
    //                         "max_amount": null,
    //                         "late_entrant": null,
    //                         "ben_desc_cd": null,
    //                         "open_enrollment_ind": null,
    //                         "grandfather_ind": null,
    //                         "grandfather_amount": null
    //                     }
    //                 },
    //                 "loss_of_license_lumpsum": {
    //                     "employee_covered": false,
    //                     "group_id": null,
    //                     "application_type": null,
    //                     "insured_amount": null,
    //                     "requested_amount": null,
    //                     "stepup_amount": null,
    //                     "effective_date": null,
    //                     "tpa_benefit_code_employee": null
    //                 },
    //                 "loss_of_license_monthly": {
    //                     "employee_covered": false,
    //                     "group_id": null,
    //                     "application_type": null,
    //                     "insured_amount": 0.0,
    //                     "requested_amount": 0.0,
    //                     "stepup_amount": 0.0,
    //                     "monthly_lol_plus_elected": false,
    //                     "effective_date": null,
    //                     "tpa_benefit_code_employee": null
    //                 },
    //                 "group_term_life": {
    //                     "group_id": null,
    //                     "employee_covered": false,
    //                     "spouse_covered": false,
    //                     "child_covered": false,
    //                     "employee_guarantee_issue_amount": 0.0,
    //                     "employee_additional_amount_requested": 0.0,
    //                     "spouse_conditional_issue_amount": 0.0,
    //                     "spouse_conditional_issue_answer": 0.0,
    //                     "spouse_additional_amount_requested": 0.0,
    //                     "children_issue_amount_requested": 0.0,
    //                     "children_additional_amount_requested": 0.0,
    //                     "tpa_benefit_code_employee": null,
    //                     "tpa_benefit_code_spouse": null,
    //                     "tpa_benefit_code_child": null
    //                 },
    //                 "ten_year_term_life": {
    //                     "employee_covered": false,
    //                     "spouse_covered": false,
    //                     "group_id": null,
    //                     "employee_requested_amount": 0.0,
    //                     "spouse_requested_amount": 0.0,
    //                     "tpa_benefit_code_employee": null,
    //                     "tpa_benefit_code_spouse": null
    //                 },
    //                 "twenty_year_term_life": {
    //                     "employee_covered": false,
    //                     "spouse_covered": false,
    //                     "group_id": null,
    //                     "employee_requested_amount": 0.0,
    //                     "spouse_requested_amount": 0.0,
    //                     "tpa_benefit_code_employee": null,
    //                     "tpa_benefit_code_spouse": null
    //                 }
    //             },
    //             "personal_information": {
    //                 "employee": {
    //                     "weight": 130,
    //                     "height_feet": 5,
    //                     "height_inches": 8,
    //                     "first_name": "YOLANDA",
    //                     "middle_initial": "",
    //                     "last_name": "SNMMMMTESTCASEIZ",
    //                     "gender": "M",
    //                     "dob": "1972-10-05T00:00:00.000+00:00",
    //                     "place_of_birth": "AL",
    //                     "ssn1": "948",
    //                     "ssn2": "90",
    //                     "ssn3": "0559",
    //                     "address_line1": "AXYZ",
    //                     "address_line2": "AXYZ",
    //                     "city": "Louisville",
    //                     "state": "AL",
    //                     "country": "USA",
    //                     "zip_code": "00612",
    //                     "other_country": "USA",
    //                     "email_address": "tharani_x_k@glic.com",
    //                     "home_phone1": "312",
    //                     "home_phone2": "123",
    //                     "home_phone3": "3121",
    //                     "cell_phone1": "312",
    //                     "cell_phone2": "345",
    //                     "cell_phone3": "3121",
    //                     "contact_method": "H",
    //                     "primary_phone_type": null,
    //                     "secondary_phone_type": null,
    //                     "salary": 100000.0,
    //                     "date_of_hire": "2019-01-05T00:00:00.000+00:00",
    //                     "employee_identifier": "948900559"
    //                 },
    //                 "spouse": null,
    //                 "child": null
    //             },
    //             "airline_occupation_information": null
    //         }
    //     },
    //     "benefit_coverage_info_status": "COMPLETED",
    //     "health_question_info_status":"ACTIVE",
    //     "health_question_info": {
    //         "critical_illness_status":"",
    //         "cancer_status":"",
    //         "knockout_question_status":"",
    //         "height_weight_status":"",
    //         "base_question_status":"ACTIVE",
    //         "active_question_status":"2",
    //         "life_entities": [
    //             {
    //                 "life_id": "1",
    //                 "life_desc": "EMPLOYEE",
    //                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                 "risk_types": "LIFE",
    //                 "vol_life_cond_qstn_ind": "3",
    //                 "life_base_qstn_req": true
    //             }
    //         ],
    //         "knock_out_question": [],
    //         "base_question": [
    //             {
    //                 "question_id": "1",
    //                 "question_code": "Q1GenericNewGenEOI",
    //                 "question_desc": "In the past 10 years have you been treated for or diagnosed as having any of the following?",
    //                 "answer_type": "PICKLIST",
    //                 "question_page_status": "ACTIVE",
    //                 "condition_info_required": true,
    //                 "health_condition": [
    //                     {
    //                         "condition_id": "1",
    //                         "condition_name": "Heart Condition",
    //                         "condition_desc": "Things like CAD, cardiomyopathy, tachycardia",
    //                         "answer_type": "PICKLIST",
    //                         "condition_page_status": "ACTIVE",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": true,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "Atrial fibrillation AFIB"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "2",
    //                                         "disclosure_desc": "Bradycardia"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "3",
    //                                         "disclosure_desc": "Cardiomyopathy"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "4",
    //                                         "disclosure_desc": "Congestive heart failure CHF"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "5",
    //                                         "disclosure_desc": "Coronary Artery Disease"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "6",
    //                                         "disclosure_desc": "Heart Attack",
    //                                         "disclosure_info_required": true,
    //                                         "answer_disclosure_info": true,
    //                                         "disclosure_page_status": "ACTIVE",
    //                                         "disclosure_question_info": [
    //                                             {
    //                                                 "disclosure_qstn_id": "1",
    //                                                 "disclosure_qstn_code": "2@Heart_Attack@TI",
    //                                                 "disclosure_qstn_type": "TEXT",
    //                                                 "disclosure_qstn_desc": "When did the condition start (month/year)?",
    //                                                 "disclosure_qstn_ans": "Any Text Answer"
    //                                             },
    //                                             {
    //                                                 "disclosure_qstn_id": "2",
    //                                                 "disclosure_qstn_code": "3@Heart_Attack@TI",
    //                                                 "disclosure_qstn_type": "TEXT",
    //                                                 "disclosure_qstn_desc": "What is the duration of your symptoms?",
    //                                                 "disclosure_qstn_ans": ""
    //                                             },
    //                                             {
    //                                                 "disclosure_qstn_id": "3",
    //                                                 "disclosure_qstn_code": "4@Heart_Attack@TI",
    //                                                 "disclosure_qstn_type": "TEXT",
    //                                                 "disclosure_qstn_desc": "What is your treatment?",
    //                                                 "disclosure_qstn_ans": ""
    //                                             },
    //                                             {
    //                                                 "disclosure_qstn_id": "4",
    //                                                 "disclosure_qstn_code": "5@Heart_Attack@TI",
    //                                                 "disclosure_qstn_type": "TEXT",
    //                                                 "disclosure_qstn_desc": "What is the degree of recovery?",
    //                                                 "disclosure_qstn_ans": ""
    //                                             }
    //                                         ],
    //                                         "phycisian_info": {
    //                                             "physician_attended_flag": true,
    //                                             "phycisians_choosen": [
    //                                                 {
    //                                                     "name": "PhysicianTwo",
    //                                                     "country": {
    //                                                         "description": "United States of America",
    //                                                         "code": "OLI_NATION_USA"
    //                                                     },
    //                                                     "state": {
    //                                                         "code": "OLI_USA_AK",
    //                                                         "description": "Alaska"
    //                                                     },
    //                                                     "speciality": "Heart Doctor",
    //                                                     "addressLine1": "Main Street",
    //                                                     "addressLine2": "Second Street",
    //                                                     "city": "Alask",
    //                                                     "zip": "54157",
    //                                                     "phone": "2124547891",
    //                                                     "lastVisitMonth": "04",
    //                                                     "lastVisitYear": "1987",
    //                                                     "lastVisitDateExplicitlyUnknown": false
    //                                                 }
    //                                             ]
    //                                         }
    //                                     },
    //                                     {
    //                                         "disclosure_id": "7",
    //                                         "disclosure_desc": "Heart bypass surgery"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "8",
    //                                         "disclosure_desc": "Heart Enlargement"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "9",
    //                                         "disclosure_desc": "Heart Failure"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "10",
    //                                         "disclosure_desc": "Heart valve stenosis or regurgitation"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "11",
    //                                         "disclosure_desc": "Heart valve surgery"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "12",
    //                                         "disclosure_desc": "High Blood Pressure"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "13",
    //                                         "disclosure_desc": "Irregular heartbeat"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "14",
    //                                         "disclosure_desc": "Premature venticular contraction PVC"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "15",
    //                                         "disclosure_desc": "Tachycardia"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "16",
    //                                         "disclosure_desc": "Other heart condition",
    //                                         "disclosure_page_status": "ACTIVE",
    //                                         "disclosure_question_info": [
    //                                             {
    //                                                 "disclosure_qstn_id": "1",
    //                                                 "disclosure_qstn_code": "2@Heart_Attack@TI",
    //                                                 "disclosure_qstn_type": "TEXT",
    //                                                 "disclosure_qstn_desc": "When did the condition start (month/year)?",
    //                                                 "disclosure_qstn_ans": "Any Text Answer"
    //                                             },
    //                                             {
    //                                                 "disclosure_qstn_id": "2",
    //                                                 "disclosure_qstn_code": "3@Heart_Attack@TI",
    //                                                 "disclosure_qstn_type": "TEXT",
    //                                                 "disclosure_qstn_desc": "What is the duration of your symptoms?",
    //                                                 "disclosure_qstn_ans": ""
    //                                             },
    //                                             {
    //                                                 "disclosure_qstn_id": "3",
    //                                                 "disclosure_qstn_code": "4@Heart_Attack@TI",
    //                                                 "disclosure_qstn_type": "TEXT",
    //                                                 "disclosure_qstn_desc": "What is your treatment?",
    //                                                 "disclosure_qstn_ans": ""
    //                                             },
    //                                             {
    //                                                 "disclosure_qstn_id": "4",
    //                                                 "disclosure_qstn_code": "5@Heart_Attack@TI",
    //                                                 "disclosure_qstn_type": "TEXT",
    //                                                 "disclosure_qstn_desc": "What is the degree of recovery?",
    //                                                 "disclosure_qstn_ans": ""
    //                                             }
    //                                         ],
    //                                         "phycisian_info": {
    //                                             "physician_attended_flag": true,
    //                                             "phycisians_choosen": [
    //                                                 {
    //                                                     "name": "PhysicianTwo",
    //                                                     "country": {
    //                                                         "description": "United States of America",
    //                                                         "code": "OLI_NATION_USA"
    //                                                     },
    //                                                     "state": {
    //                                                         "code": "OLI_USA_AK",
    //                                                         "description": "Alaska"
    //                                                     },
    //                                                     "speciality": "Heart Doctor",
    //                                                     "addressLine1": "Main Street",
    //                                                     "addressLine2": "Second Street",
    //                                                     "city": "Alask",
    //                                                     "zip": "54157",
    //                                                     "phone": "2124547891",
    //                                                     "lastVisitMonth": "04",
    //                                                     "lastVisitYear": "1987",
    //                                                     "lastVisitDateExplicitlyUnknown": false
    //                                                 }
    //                                             ]
    //                                         }
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q1HeartNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "2",
    //                         "condition_name": "Liver Condition",
    //                         "condition_desc": "Things like hepatitis, liver failure, liver cirrhosis",
    //                         "answer_type": "PICKLIST",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "Cirrhosis of the liver"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "2",
    //                                         "disclosure_desc": "Fatty liver"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "3",
    //                                         "disclosure_desc": "Hemochromatosis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "4",
    //                                         "disclosure_desc": "Hepatitis A"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "5",
    //                                         "disclosure_desc": "Hepatitis B"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "6",
    //                                         "disclosure_desc": "Hepatitis C"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "7",
    //                                         "disclosure_desc": "Liver Transplant"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "8",
    //                                         "disclosure_desc": "Other liver condition"
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q1LiverNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "3",
    //                         "condition_name": "Kidney condition",
    //                         "condition_desc": "Things like chronic kideney disease CKD,kidney failure",
    //                         "answer_type": "PICKLIST",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "Chronic kidney disease CKD"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "2",
    //                                         "disclosure_desc": "Kidney dialysis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "3",
    //                                         "disclosure_desc": "Kidney Disease"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "4",
    //                                         "disclosure_desc": "Kidney Failure"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "5",
    //                                         "disclosure_desc": "Kidney Infection"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "6",
    //                                         "disclosure_desc": "Kidney Stones"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "7",
    //                                         "disclosure_desc": "Kidney Transplant"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "8",
    //                                         "disclosure_desc": "Polycystic Kidney Disease"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "9",
    //                                         "disclosure_desc": "Other kidney condition"
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q1KidneyNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "4",
    //                         "condition_name": "Respiratory / lung condition",
    //                         "condition_desc": "",
    //                         "answer_type": "PICKLIST",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "Acute Bronchitis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "2",
    //                                         "disclosure_desc": "Asthma"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "3",
    //                                         "disclosure_desc": "Chronic obstructive pulmonary disease COPD"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "4",
    //                                         "disclosure_desc": "Cystic fibrosis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "5",
    //                                         "disclosure_desc": "Emphysema"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "6",
    //                                         "disclosure_desc": "Pneumonia"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "7",
    //                                         "disclosure_desc": "Other respiratory/lung conition"
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q1RespiratoryLungNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "5",
    //                         "condition_name": "Digestive system condition",
    //                         "condition_desc": "Of your esophagus,stomach,or intestines (things like Crohn's disease,esophagitis,ulcerative colitis)",
    //                         "answer_type": "PICKLIST",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "Acid reflux"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "2",
    //                                         "disclosure_desc": "Appendectomy"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "3",
    //                                         "disclosure_desc": "Appendicitis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "4",
    //                                         "disclosure_desc": "Barretts esophagus"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "5",
    //                                         "disclosure_desc": "Celiac disease"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "6",
    //                                         "disclosure_desc": "Colectomy"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "7",
    //                                         "disclosure_desc": "Crohn's Disease"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "8",
    //                                         "disclosure_desc": "Diverticulitis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "9",
    //                                         "disclosure_desc": "Esophagitis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "10",
    //                                         "disclosure_desc": "Gallstones"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "11",
    //                                         "disclosure_desc": "GERD"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "12",
    //                                         "disclosure_desc": "Inflammatory bowel disese"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "13",
    //                                         "disclosure_desc": "Irritable Bowel Syndrome"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "14",
    //                                         "disclosure_desc": "Pancreatitis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "15",
    //                                         "disclosure_desc": "Ulcer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "16",
    //                                         "disclosure_desc": "Ulcerative Colitis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "17",
    //                                         "disclosure_desc": "Other Digestive Disorder"
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q1DigestiveNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "6",
    //                         "condition_name": "Mental, nervous, emotional or neurological disorder or condition",
    //                         "condition_desc": "",
    //                         "answer_type": "PICKLIST",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "Alzheimers Disease"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "2",
    //                                         "disclosure_desc": "Amyotrophic Lateral Sclerosis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "3",
    //                                         "disclosure_desc": "Anxiety"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "4",
    //                                         "disclosure_desc": "Ataxia"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "5",
    //                                         "disclosure_desc": "Attention Deficit Disorder"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "6",
    //                                         "disclosure_desc": "Attention-deficit/hyperactivity disorder ADHD"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "7",
    //                                         "disclosure_desc": "Autism"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "8",
    //                                         "disclosure_desc": "Bells palsy"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "9",
    //                                         "disclosure_desc": "Bipolar disorder"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "10",
    //                                         "disclosure_desc": "Brain Tumor"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "11",
    //                                         "disclosure_desc": "Cerebral aneurysm"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "12",
    //                                         "disclosure_desc": "Cerebral Palsy"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "13",
    //                                         "disclosure_desc": "Depression"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "14",
    //                                         "disclosure_desc": "Epilepsy"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "15",
    //                                         "disclosure_desc": "Migraines"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "16",
    //                                         "disclosure_desc": "Parkinson's Disease"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "17",
    //                                         "disclosure_desc": "Post tramatic stress disorder PTSD"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "18",
    //                                         "disclosure_desc": "Schizoaffective disorder"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "19",
    //                                         "disclosure_desc": "Seizure"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "20",
    //                                         "disclosure_desc": "Other mental, nervous, emotional or neurological disorder or condition"
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q1MentalNervousNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "7",
    //                         "condition_name": "Auto immune disorder",
    //                         "condition_desc": "Things like celiac disease,Crohn's disease,rheumatoid arthritis",
    //                         "answer_type": "PICKLIST",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "Celiac disease"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "2",
    //                                         "disclosure_desc": "Crohn's Disease"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "3",
    //                                         "disclosure_desc": "Discoid lupus"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "4",
    //                                         "disclosure_desc": "Irritable Bowel Syndrome"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "5",
    //                                         "disclosure_desc": "Juvenile idiopathic arthritis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "6",
    //                                         "disclosure_desc": "Multiple Sclerosis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "7",
    //                                         "disclosure_desc": "psoriatic arthritis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "8",
    //                                         "disclosure_desc": "Rheumatoid Arthritis (RA)"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "9",
    //                                         "disclosure_desc": "Systemic Lupus"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "10",
    //                                         "disclosure_desc": "Ulcerative Colitis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "11",
    //                                         "disclosure_desc": "Other auto immune disorder"
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q1AutoImmuneNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "8",
    //                         "condition_name": "Diabetes",
    //                         "condition_desc": "",
    //                         "answer_type": "PICKLIST",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "Gestational diabetes"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "2",
    //                                         "disclosure_desc": "Prediabetes"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "3",
    //                                         "disclosure_desc": "Type 1 Diabetes"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "4",
    //                                         "disclosure_desc": "Type 2 Diabetes"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "5",
    //                                         "disclosure_desc": "Other diabetic disorder"
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q1DiabetesNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "9",
    //                         "condition_name": "Cancer",
    //                         "condition_desc": "",
    //                         "answer_type": "PICKLIST",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "Basal Cell Carcinoma"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "2",
    //                                         "disclosure_desc": "Bladder cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "3",
    //                                         "disclosure_desc": "Bone cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "4",
    //                                         "disclosure_desc": "Brain cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "5",
    //                                         "disclosure_desc": "Breast cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "6",
    //                                         "disclosure_desc": "Cervical cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "7",
    //                                         "disclosure_desc": "Colon cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "8",
    //                                         "disclosure_desc": "Endometrial cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "9",
    //                                         "disclosure_desc": "Gallbladder cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "10",
    //                                         "disclosure_desc": "Kidney Cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "11",
    //                                         "disclosure_desc": "Leukemia"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "12",
    //                                         "disclosure_desc": "Liver cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "13",
    //                                         "disclosure_desc": "Lung cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "14",
    //                                         "disclosure_desc": "Melanoma"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "15",
    //                                         "disclosure_desc": "Ovarian cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "16",
    //                                         "disclosure_desc": "pancreas cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "17",
    //                                         "disclosure_desc": "Prostate cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "18",
    //                                         "disclosure_desc": "rectal cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "19",
    //                                         "disclosure_desc": "Squamous Cell Carcinoma"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "20",
    //                                         "disclosure_desc": "Stomach cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "21",
    //                                         "disclosure_desc": "testicular cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "22",
    //                                         "disclosure_desc": "Throat cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "23",
    //                                         "disclosure_desc": "Thyroid cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "24",
    //                                         "disclosure_desc": "Uterine cancer"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "25",
    //                                         "disclosure_desc": "Other cancer"
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q1CancerNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "10",
    //                         "condition_name": "Stroke",
    //                         "condition_desc": "",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q1StrokeNewGenEO"
    //                     },
    //                     {
    //                         "condition_id": "11",
    //                         "condition_name": "None of the above",
    //                         "condition_desc": "",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ]
    //                     }
    //                 ]
    //             },
    //             {
    //                 "question_id": "2",
    //                 "question_code": "Q2GenericNewGenEOI",
    //                 "question_desc": "In the past  5 years do any of the following apply?",
    //                 "answer_type": "PICKLIST",
    //                 "condition_info_required": true,
    //                 "health_condition": [
    //                     {
    //                         "condition_id": "1",
    //                         "condition_name": "Illegal drugs",
    //                         "condition_desc": "Opiates,central nervous system depressant or stimulants,hallucinogens,cannabis",
    //                         "answer_type": "PICKLIST",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "Cannabis"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "2",
    //                                         "disclosure_desc": "Central nervous system depressants"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "3",
    //                                         "disclosure_desc": "Central nervous system stimulants"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "4",
    //                                         "disclosure_desc": "Hallucinogens"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "5",
    //                                         "disclosure_desc": "Opiates"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "6",
    //                                         "disclosure_desc": "Other drugs"
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q2IllegalDrugsNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "2",
    //                         "condition_name": "Medication other than as prescribed",
    //                         "condition_desc": "",
    //                         "answer_type": "PICKLIST",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "Medication"
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q2MedicationNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "3",
    //                         "condition_name": "Alcoholism or drug use treatment or dependency",
    //                         "condition_desc": "",
    //                         "answer_type": "PICKLIST",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "alcoholism or dependency"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "2",
    //                                         "disclosure_desc": "Drug use or dependency"
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q2AlcoholDrugNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "4",
    //                         "condition_name": "None of the above",
    //                         "condition_desc": "",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ]
    //                     }
    //                 ]
    //             },
    //             {
    //                 "question_id": "3",
    //                 "question_code": "Q4GenericNewGenEOI",
    //                 "question_desc": "In the past  12 months have you consulted or been examined, treated by a physician, practitioner or specialist for any illness, injury, disease or disorder not listed in the previous questions? (including routine physicals only when there is an existing or newly diagnosed condition)",
    //                 "answer_type": "PICKLIST",
    //                 "condition_info_required": true,
    //                 "health_condition": [
    //                     {
    //                         "condition_id": "1",
    //                         "condition_name": "Consultation in hospital or health care facility",
    //                         "condition_desc": "",
    //                         "answer_type": "ENTER_DETAILS",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "answer_disclosure_info_value": ""
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q4ConsultationNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "2",
    //                         "condition_name": "Prescribed medications",
    //                         "condition_desc": "Other than for cold,flu,or allergies",
    //                         "answer_type": "ENTER_DETAILS",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "answer_disclosure_info_value": ""
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q4PrescribedMedicationNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "3",
    //                         "condition_name": "Diagnostic testing",
    //                         "condition_desc": "Blood work,X-ray,CT scan,or MRI with positive results",
    //                         "answer_type": "PICKLIST",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "disclosure_types": [
    //                                     {
    //                                         "disclosure_id": "1",
    //                                         "disclosure_desc": "Biopsy"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "2",
    //                                         "disclosure_desc": "Blood work"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "3",
    //                                         "disclosure_desc": "CT Scan"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "4",
    //                                         "disclosure_desc": "Colonoscopy"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "5",
    //                                         "disclosure_desc": "Echocardiogram ECG"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "6",
    //                                         "disclosure_desc": "Electrocardiogram EKG"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "7",
    //                                         "disclosure_desc": "Endoscopy"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "8",
    //                                         "disclosure_desc": "HbA1c test"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "9",
    //                                         "disclosure_desc": "MRI"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "10",
    //                                         "disclosure_desc": "Mamogram(s)"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "11",
    //                                         "disclosure_desc": "PET scan"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "12",
    //                                         "disclosure_desc": "Pap smear"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "13",
    //                                         "disclosure_desc": "Ultrasound"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "14",
    //                                         "disclosure_desc": "X-ray"
    //                                     },
    //                                     {
    //                                         "disclosure_id": "15",
    //                                         "disclosure_desc": "Other diagnostic test"
    //                                     }
    //                                 ],
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ],
    //                         "condition_code": "Q4DiagnosticTestingNewGenEOI"
    //                     },
    //                     {
    //                         "condition_id": "4",
    //                         "condition_name": "None of the above",
    //                         "condition_desc": "",
    //                         "life_entities_dq": [
    //                             {
    //                                 "life_id": "1",
    //                                 "life_desc": "EMPLOYEE",
    //                                 "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                                 "answer_condition_info": false,
    //                                 "risk_types": "LIFE",
    //                                 "vol_life_cond_qstn_ind": "3",
    //                                 "life_base_qstn_req": false
    //                             }
    //                         ]
    //                     }
    //                 ]
    //             },
    //             {
    //                 "question_id": "4",
    //                 "question_code": "Q5GenericNewGenEOI",
    //                 "question_desc": "Have you ever tested positive for HIV (Human Immunodeficiency Virus) antibodies?",
    //                 "answer_type": "YES_NO",
    //                 "condition_info_required": false,
    //                 "life_entities_bq": [
    //                     {
    //                         "life_id": "1",
    //                         "life_desc": "EMPLOYEE",
    //                         "life_name": "SNMMMMTESTCASEIZ, YOLANDA",
    //                         "risk_types": "LIFE",
    //                         "vol_life_cond_qstn_ind": "3",
    //                         "life_base_qstn_req": false
    //                     }
    //                 ]
    //             }
    //         ],
    //         "has_knockout_question": false,
    //         "has_base_question": true
    //     },
    //     "updater_ip": "",
    //     "case_id": "6164"
    // }

    //},
    whoCovered: "",
    groupInfoResponse: {},
    activeQuestion: 0,
    activeCondition: 0,
    activeReflux: 0,
    countCondition: 0,
    reflexCount:0,
    totalQuestion: 0,
    createCase: {},
    checkInfo: "",
    showMediaIcons: "",
    backClick:false,
    clicked:false,
    workday:false,
    caConsent:{name:'',emailId:'',date:'',situsState:''}
};

export const reducer = (state, action) => {
    state = state || initialState;
    if (action.type === DISPLAY_MEDIA_ICONS) {
        return { ...state, showMediaIcons: action.uwStatus };
    }
    if (action.type === CHANGE_PAGE_STATUS) {
        return { ...state, prefillData: action.prefillData };
    }
    if(action.type === RESTORE){
        return { ...state, dataRestoration : action.data};
    }
    if (action.type === SET_REVIEW_PAGE_BYTEDATA) {

        var data = action.byteDataForPDF;
        var fileName = "ReviewPage.pdf";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
            var byteCharacters = atob(data);
            var byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], { type: 'application/pdf' });
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
        else { // much easier if not IE
            let link = document.createElement('a');
            link.href = 'data:application/octet-stream;base64,' + action.byteDataForPDF;
            link.download = "ReviewPage.pdf";
            link.click();
        }

        return {
            ...state,
            byteDataForPDF: action.byteDataForPDF,
        };
    }

    if (action.type === GET_PREFILL_DATA) {
        return { ...state, prefillData: action.prefillData };
    }
    if (action.type === ASSIGN_TRUSTED_STATUS) {
        return { ...state, trustedFlag: action.trustedPathStatus }
    }
    if (action.type === STORE_PREFILL_ID) {
        return { ...state, prefillId: action.prefillId, updatedID: action.updateID }
    }
    if (action.type === GET_STATE_CITY) {
        let physicianStateCity = {};
        if (action.pageInd === "physician") {
            physicianStateCity = action.stateCityResp;
        }
        return { ...state, statecityCodes: action.stateCityResp, cityFlag: action.cityFlag, physicianStateCity }
    }
    if (action.type === LINK) {
        return { ...state, link: action.link }
    }
    if (action.type === CHANGE_WELCOME_BACK_STATUS_TO_FALSE) {
        return { ...state, welcomeBackStatus: false }
    }
    if (action.type === GET_GROUP_INFO) {
        return {
            ...state, groupInfoResponse: action.groupInfoResponse,
            showRecaptchaError: action.showRecaptchaError,
            nonTrustedData: action.groupInfoResponse
        }
    }
    if (action.type === CLEAR_GET_GROUP_INFO_DATA) {
        return { ...state, groupInfoResponse: {} }
    }
    if (action.type === "CLEAR_RESPONSE_VALUE") {
        return { ...state, responseValue: "", showRecaptchaError: false }
    }
    if (action.type === "BOOLEAN_VALUE") {
        return { ...state, boolValue: !action.boolValue }
    }
    if (action.type === ASSIGN_TEMPLATE) {
        return { ...state, HQtemplate: action.template }
    }
    if (action.type === QUESTION_STATUS) {
        return { ...state, activeQuestion: action.currentQuestion }
    }
    if (action.type === CONDITION_STATUS) {
        return { ...state, activeCondition: action.currentCondition }
    }
    if (action.type === LIFE_STATUS) {
        return { ...state, activeLife: action.activeLife }
    }
    if (action.type === REFLUX_STATUS) {
        return { ...state, activeReflux: action.currentReflux }
    }
    if (action.type === TOTAL_QUESTION) {
        return { ...state, totalQuestion: action.totalQ }
    }
    if (action.type === SAVE_COVERAGE_DETAILS) {
        return { ...state, createCase: {} }
    }
    if (action.type === SAVE_WHO_COVRED) {
        return { ...state, whoCovered: action.whoCovered }
    }
    if (action.type === GET_KNOCKOUT_DATA) {
        //console.log("2222222222222222222",action)
        return { ...state, prefillData: action.knockoutData }
    }
    if(action.type === SAVE_CA_CONSTENT_DATA){
        return { ...state, caConsent: action.byteDataForPDF }
    }
    if (action.type === GET_RECAPTCHA_RESP_VALUE) {
        return { ...state, responseValue: action.responseValue, showRecaptchaError: action.showRecaptchaError }
    }
    if (action.type === CLEAR_PHYSICIAN_STATE_CITY_DETAILS) {
        let physicianStateCity = {};
        return { ...state, physicianStateCity }
    }
    if (action.type === "BOOLEAN_KEY") {
        return { ...state, isFlaged: action.boolValue }
    }
    if (action.type === "BOOLEAN_CHEVRON") {
        return { ...state, isChevron: action.boolChevron }
    }
    if (action.type === "NUM_RLID") {
        return { ...state, isRlid: action.refLid }
    }
    if (action.type === COUNT_CONDITION) {
        return { ...state, countCondition: action.conCount }
    }
    if (action.type === CLICK_BROWSER) {
        return { ...state, backClick: action.back }
    }
    if (action.type === CONTACT_BUTTON) {
        return { ...state, clicked: action.clicked }
    }
    if (action.type === EDIT_INFO) {
         return { ...state, checkInfo: action.info }
     }
     if (action.type === COUNT_REFLEX) {
        return { ...state, reflexCount: action.refCount }
    }
    if (action.type === WORKDAY_CHECK) {
        return { ...state, workday: action.workday }
    }
    if(action.type === GET_EMAIL_VALIDATE){
        return {...state, isEmailValid: action.emailValidateResp.is_valid_email}
    }
   
    return state;
}
import React from 'react';

function HomePageHeader(props) {
    
    return (
        <>
            <h1 class="h3">{props.header}</h1>
            <p className="lead">
                {props.subHeader}
            </p>   
        </>
    );
}

export default HomePageHeader;
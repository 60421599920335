

import { createBrowserHistory } from 'history';
import configureStore from '../store/configureStore';

const baseUrl = document.getElementsByTagName('base')[0] && document.getElementsByTagName('base')[0].getAttribute('href');
const junction = window.location.pathname.split("/");
export const history = createBrowserHistory({ basename: baseUrl+junction[1] });
export const store = configureStore(history);

export const alphaNumRegex = /^[A-Za-z0-9 ]+$/i
export const alphaRegex = /^[a-zA-Z ]+$/i
export const numericRegex = /^[0-9]+$/i

export const junctionGA = window.location.pathname.split("/")[1];

let subDomain= "", hostName = window.location.host,siteKey = "";
if (hostName === 'qa.guardiananytime.com' || hostName === 'www.qa.guardiananytime.com') {
    subDomain = '/'+junctionGA;
} else if (hostName === 'www.guardiananytime.com' || hostName === 'guardiananytime.com') {
    subDomain = '/'+junctionGA;
} else if(hostName === 'gasb.glic.com' || hostName === 'www.gasb.glic.com') {
    subDomain = '/'+junctionGA;
} else if (hostName === 'perf2.guardiananytime.com' || hostName === 'www.perf2.guardiananytime.com') {
    subDomain = '/'+junctionGA;

} else {
    subDomain = "";
}
if (hostName === 'qa.guardiananytime.com' || hostName === 'www.qa.guardiananytime.com') {
    siteKey = "6Lc8cNAaAAAAAGCAEp80IszfsBG6HVeKqC3QY5eX";
} else if(hostName === 'perf2.guardiananytime.com' || hostName === 'www.perf2.guardiananytime.com') {
    siteKey  = "6LeFog8cAAAAALmlNiKo0lPnAmmTnfQzDWC3ULsu";
} else if (hostName === 'www.guardiananytime.com' || hostName === 'guardiananytime.com') {
    siteKey  = "6Lf2gzwcAAAAABuKZxrCtfAsPicrvJgIqDbAhqLF";
} else if(hostName === 'gasb.glic.com' || hostName === 'www.gasb.glic.com') {
    siteKey  = "6LeFog8cAAAAALmlNiKo0lPnAmmTnfQzDWC3ULsu";
} else {
    siteKey = "6LeFog8cAAAAALmlNiKo0lPnAmmTnfQzDWC3ULsu"; 
}

if (hostName === 'qa.guardiananytime.com' || hostName === 'www.qa.guardiananytime.com') {
    siteKey = "6Lc8cNAaAAAAAGCAEp80IszfsBG6HVeKqC3QY5eX";
} else if (hostName === 'www.guardiananytime.com' || hostName === 'guardiananytime.com') {
    siteKey  = "6Lf2gzwcAAAAABuKZxrCtfAsPicrvJgIqDbAhqLF";
} else if(hostName === 'gasb.glic.com' || hostName === 'www.gasb.glic.com') {
    siteKey  = "6Lc8cNAaAAAAAGCAEp80IszfsBG6HVeKqC3QY5eX";
} else {
    siteKey = "6Lc8cNAaAAAAAGCAEp80IszfsBG6HVeKqC3QY5eX"; 
}

export const SUB_DOMAIN = subDomain;
export const SITE_KEY = siteKey;

export const trustedJunction = "grpeoia"
export const nonTrustedJunction = "grpeoiua"
export const workDayJunction = "empeoi"
export const statesUS = {
"AL": "Alabama",
 "AK":"Alaska",  
 "AZ":"Arizona",
 "AR":"Arkansas",
 "CA":"California",
 "CO":"Colorado",
 "CT":"Connecticut",
 "DE":"Delaware",
 "DC":"District of Columbia",
 "FL":"Florida",
 "GA":"Georgia",
 "HI":"Hawaii",
 "ID":"Idaho",
 "IL":"Illinois",
 "IN":"Indiana",
 "IA":"Iowa",
 "KS":"Kansas",
 "KY":"Kentucky",
 "LA":"Louisiana",
 "ME":"Maine",
 "MD":"Maryland",
 "MA":"Massachusetts",
 "MI":"Michigan",
 "MN":"Minnesota",
 "MS":"Mississippi",
 "MO":"Missouri",
 "MT":"Montana",
 "NE":"Nebraska",
 "NV":"Nevada",
 "NH":"New Hampshire",
 "NJ":"New Jersey",
 "NM":"New Mexico",
 "NY":"New York",
 "NC":"North Carolina",
 "ND":"North Dakota",
 "OH":"Ohio",
 "OK":"Oklahoma",
 "OR":"Oregon",
 "PA":"Pennsylvania",
 "RI":"Rhode Island",
 "SC":"South Carolina",
"SD":"South Dakota" ,
 "TN":"Tennessee",
 "TX":"Texas",
 "UT":"Utah",
 "VT":"Vermont",
 "VA":"Virginia",
 "WA":"Washington",
 "WV":"West Virginia",
 "WI":"Wisconsin",
 "WY":"Wyoming"
};



export const coverageDetails={ 
    "basic_life" : {"value":"Basic Life","url":"./images/life-insurance-icon.svg"} ,
    "basic_critical_illness" :{"value":"Basic Critical Illness","url":"./images/critical-illness-icon.svg"} ,
    "short_term_disability":{"value":"Short Term Disability","url":"./images/Injured.svg"} ,
    "long_term_disability":{"value":"Long Term Disability","url":"./images/Injured.svg"} ,
    "voluntary_long_term_disability":{"value":"Voluntary Long Term Disability","url":"./images/Injured.svg"} ,
    "voluntary_life":{"value":"Voluntary Life","url":"./images/life-insurance-icon.svg"} ,
    "cancer":{"value":"Cancer","url":"./images/Cancer.svg"} ,
    "voluntary_critical_illness":{"value":"Voluntary Critical Illness","url":"./images/critical-illness-icon.svg"}
};

export const statesCan = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon"
];

export const coveragesForMember=[
    "basic_life" ,
    "voluntary_life",
    "short_term_disability",
    "long_term_disability",
    "voluntary_long_term_disability",
];
export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store/HomePage/HomePageAction';
import { scrollUp, getActiveQuestionData,  moveToPreviousQuestion,getQuestionDescriptionWithBold } from '../../../utils/sharedFunction';
import _ from 'lodash';
import AlertBanner from "../../../components/AlertBanner/AlertBanner";
import Warning from '../../../components/Warning/Warning';
import SaveForLater from '../../../components/SaveForLater/SaveForLater';

class NoConditionMemberQ extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayBanner: false,
      answerBaseQuestion: "",
      checkedNo: "",
      previousPage:"",
      lifeID:0,
      isSaved:false,
      isOpened:false

    }
  }
  getInitialQuestionData = () => {
    let questionDataTemp = getActiveQuestionData(this.props)
    let questionData = JSON.parse(JSON.stringify(questionDataTemp))
    let lifeID = _.get(this.props, "location.state.lifeId")
    let checkStatus = ""
    questionData && questionData.health_condition && questionData.health_condition.map(condition => {
      condition.life_entities_dq.map(life => {
        if(life.life_id === lifeID && (life.answer_condition_info === true || life.answer_condition_info === false) ){
          checkStatus =  life.answer_condition_info === true ? "yes" : "no" ;
          // console.log(checkStatus,"status")
          life.answer_condition_info = String(life.answer_condition_info)
        }
        return life
      })
      return condition
    })
    
    this.setState({
      questionData: questionData,
      checkedNo:checkStatus
    })
    scrollUp()
    

  }
  componentDidMount() {
    this.getInitialQuestionData()
    let lifeId = _.get(this.props, "location.state.lifeId");
    // console.log(lifeId,"aa")
    let previousPage = _.get(this.props, "location.state.previousPage");
    let previous_state = ''
    if (previousPage === "review") {
      previous_state = true;
    }
    this.setState({
      previousPage: previous_state,
      lifeID: lifeId,
    })
    window.addEventListener("popstate", () => {
      this.props.history.goForward();
      this.props.bClick(true);
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      this.setState({
        questionData: {},
        checkedCondition: '',
        displayBanner: false
      })
      this.getInitialQuestionData()

    }

  }
  getQuestionDescription = (questionDesc, questionId) => {

    let splitArrayResult = getQuestionDescriptionWithBold(questionDesc)
    if (splitArrayResult && splitArrayResult.length === 2 && splitArrayResult[0].length > 0 && splitArrayResult[1].length > 0) {
      return (<p className="pt-3"><strong>{splitArrayResult[0]}</strong>{splitArrayResult[1]}</p>)
    }
    else {
      return <p className="pt-3">{questionDesc}</p>
    }

  }
  handleBrowser= () =>{
   this.props.bClick(false);
  }
  saveForLater = () => {
    this.setState({isSaved:true});
  }
  continueEOI = () => {
    this.setState({isSaved:false});
  }
  leave=()=> {
    this.props.history.push({
        pathname: `/eoi/success` })
  }
  handleNext = () => {
  let statusArray = [];
  // console.log(this.props,"props")
  if(this.state.previousPage){
    this.state.questionData.health_condition.map(condition => {
      condition.life_entities_dq.map(life => {
        if(this.state.previousPage && life.life_id === this.state.lifeID){
          statusArray.push(life.answer_condition_info)
        }
        return life
      })
      return condition
    })
  }
  else{
      this.state.questionData.health_condition.map(condition => {
        condition.life_entities_dq.map(life => {
          statusArray.push(life.answer_condition_info)
          return life
        })
        return condition
      })
    }
        if(statusArray.includes(undefined) === true){
          this.setState({
              displayBanner:true
            })
        }
        else{
          let finalQData = {...this.state.questionData}
          finalQData.health_condition.map(condition => {
            condition.life_entities_dq.map(life => {
              if (this.state.previousPage) {
                if (life.life_id === this.state.lifeID) {
                  life.answer_condition_info = this.changeToBoolean(life.answer_condition_info)
                }
              } else {
                life.answer_condition_info = this.changeToBoolean(life.answer_condition_info)
              }
              return life
            })
            return condition
          })
          let baseQuestionDet = _.get(this.props,"prefillData.eoi_data.health_question_info.base_question",[]) 
          let question = _.get(this.props,"location.pathname","").split('/')[3]
          let singleDisclosure;
          // console.log(this.props,"props")
          baseQuestionDet.map(eachQuestion => {
                if(eachQuestion.question_id === question){
                  Object.assign(eachQuestion,this.state.questionData)
                  Object.assign(eachQuestion.question_page_status = "ACTIVE")
                  eachQuestion.health_condition.map(condition=>{
                    condition.life_entities_dq && condition.life_entities_dq.map(life => {
                      if(this.state.previousPage){
                        // console.log("TRUEEEE", life.life_id ,this.state.lifeID )
                        if(life.life_id === this.state.lifeID){
                        if(life.disclosure_types && life.disclosure_types.length ===1){
                          singleDisclosure = true
                          condition.condition_page_status = "ACTIVE"
                          life.disclosure_types && life.disclosure_types.map(disclosure => {
                            disclosure.answer_disclosure_info = true
                            disclosure.disclosure_info_required = true
                            return disclosure
                          })
                        }
                        else{
                          singleDisclosure = false
                        }
                      }
                      } else{
                      if(life.disclosure_types && life.disclosure_types.length ===1){
                        singleDisclosure = true
                        condition.condition_page_status = "ACTIVE"
                        life.disclosure_types && life.disclosure_types.map(disclosure => {
                          disclosure.answer_disclosure_info = true
                          disclosure.disclosure_info_required = true
                          return disclosure
                        })
                      }
                      else{
                        singleDisclosure = false
                      }
                    }
                      return life
                    })
                    // console.log(condition, "CONDITION1")
                    return condition
                  })
                }
                else{
                    Object.assign(eachQuestion.question_page_status = "INACTIVE")
                }
                return eachQuestion
          })
          if(singleDisclosure || (this.state.previousPage && this.state.checkedNo === "yes" )){
            this.props.cCount(1)
            this.props.rCount(1)
            let a = Object.assign({}, this.props)
            a.activeCondition = 0
            a.activeReflux = 0
            _.set(this.props,"prefillData.eoi_data.health_question_info.base_question",baseQuestionDet)  
            // console.log(this.props,a,"aa") 
          this.props.saveAnswerBaseQuestionYesNo(_.get(a,"prefillData",{}),statusArray.every(data => data === "true" || data === true),a)
          }
          else{
            _.set(this.props,"prefillData.eoi_data.health_question_info.base_question",baseQuestionDet)   
            this.props.saveAnswerBaseQuestion(_.get(this.props,"prefillData",{}),statusArray.every(data => data === "true" || data === true),this.props)
          }
          
          if (this.state.previousPage === true && this.state.checkedNo === "no") {
            let a = Object.assign({}, this.props)
            a.activeCondition = 0
            a.activeReflux = 0
              this.props.isFlag1(true);
              const { id } = this.props.match.params;
              if (id && id) {
                this.props.chevron(true);
              }
              
              _.set(this.props,"prefillData.eoi_data.health_question_info.base_question",baseQuestionDet)   
              _.set(this.props, "history.location.state.previousPage", "reviewno")
              this.props.saveAnswerBaseQuestionYesNo(_.get(a,"prefillData",{}),false,a)
              this.props.history.push(`/eoi/review/${id}`)
              // this.props.history.push(`/eoi/review`)
            }
        }
        
    }

  changeToBoolean = (stringValue) => {
    if(stringValue === "true" || stringValue === true){
      return true
    }else{
      return false
    }
  }
  handlePrevious = () => {
    moveToPreviousQuestion(this.props)
  }
  
  radioButton = (e) => {
    let statusArray = []
    let checkSelected = ""
    // let checkedNo = ""
    let questionData = { ...this.state.questionData }
    questionData.health_condition.map(condition => {
      // console.log(condition, "CONDITION");
      condition.life_entities_dq.map((life, index) => {
        if(this.state.previousPage)  {
          if(life.life_id === this.state.lifeID){
          if ("yes" + life.life_id === e.target.id) {
            life.answer_condition_info = e.target.value
            checkSelected = "yes"
            // console.log("check",checkSelected)
          }
          if ("no" + life.life_id === e.target.id) {
            life.answer_condition_info = e.target.value
            checkSelected = "no"
            // console.log("check",checkSelected)
          }
        }
        }
        else{
          if ("yes" + life.life_id === e.target.id) {
            life.answer_condition_info = e.target.value
            
          }
          if ("no" + life.life_id === e.target.id) {
            life.answer_condition_info = e.target.value
            
          }
        }
          return life
        })
      
      return condition
    })
    this.setState({ questionData, checkedNo: checkSelected })
    this.state.questionData.health_condition.map(condition => {
      condition.life_entities_dq.map(life => {
        statusArray.push(life.answer_condition_info)
        return life
      })
      return condition
    })
    if(statusArray.includes(undefined) === false){
      this.setState({
        displayBanner:false
      })
    }

  }


  handleClose() {
    this.setState({ displayBanner: false })
  }
  render() {
    const {questionData} = this.state;
    return (
      <>
      {this.state.isSaved === true ? <SaveForLater continueEOI={()=>this.continueEOI()} leave = {()=> this.leave()}/> : null}
      {this.props.backClick === true ? <Warning handleBrowser = {() => this.handleBrowser()}/> : null}
        {this.state.displayBanner === true ?
          <AlertBanner
            handleClose={() => this.handleClose()}
            errorMessage="Please select an answer." /> : null}


        <main className="knockout">
          {questionData &&
            <div >
              <div className="row question-bg gray-bg">
                <div className="container-fluid">
                    <div className="prev-link">
                      <button className="btn btn-link" onClick={() => this.handlePrevious()}>
                        <i className="fa fa-arrow-left"></i>
                        {_.get(this.props, "activeQuestion", "") === 1 ? "Back" : "Previous question"}
                      </button>
                    </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7">
                      <h3 className='q-heading'>Question {questionData.question_id}/{_.get(this.props, "totalQuestion", "")}</h3>
                      <p className="pt-3">
                        {questionData.question_desc &&
                          this.getQuestionDescription(questionData.question_desc, questionData.question_id)
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="question-box">
                    {questionData && questionData.health_condition && questionData.health_condition.map(condition => (
                       this.state.previousPage ?
                      condition.life_entities_dq && condition.life_entities_dq.map((life, ind) => (
                      <>
                        <fieldset className="form-group" key={ind}>
                        {/* { console.log(life.life_id,this.state.lifeID,"id")} */}
                          { this.state.previousPage && life.life_id === this.state.lifeID ?
                          <>
                           {/* { console.log(life.life_id,this.state.lifeID,"id2")} */}
                          <div className="radio">
                            <input type="radio" id={"yes" + life.life_id} 
                            onChange={(e) => this.radioButton(e)}
                            checked={life.answer_condition_info === "true" || life.answer_condition_info === true}
                              value={true} name={life.life_id} />
                            <label htmlFor={"yes" + life.life_id}>
                              <span>Yes</span>
                            </label> 
                          </div>
                          <div className="radio">
                         
                            <input type="radio" id={"no" + life.life_id} 
                            onChange={(e) => this.radioButton(e)}
                            checked={life.answer_condition_info === "false" || life.answer_condition_info === false}
                               value={false} name={life.life_id} />
                            <label htmlFor={"no" + life.life_id}>
                              <span>No</span>
                            </label>
                          </div> 
                          </> :null }
                        </fieldset>
                        </>  
                      )) : 
                      condition.life_entities_dq && condition.life_entities_dq.map((life, ind) => (
                        <fieldset className="form-group" key={ind}>
                          {/* {questionData.life_entities_dq.length >1 && 
                          <legend className="data-label">
                            {life.life_desc.toLowerCase()==="spouse"?life.life_name.split(' ')[1]:null}
                          </legend>
                        } */}
                          <div className="radio">
                            <input type="radio" id={"yes" + life.life_id}
                              onChange={(e) => this.radioButton(e)}
                              checked={life.answer_condition_info === "true" || life.answer_condition_info === true}
                              value={true} name={life.life_id} />
                            <label htmlFor={"yes" + life.life_id}>
                              <span>Yes</span>
                            </label>
                          </div>
                          <div className="radio">
                            <input type="radio" id={"no" + life.life_id}
                              onChange={(e) => this.radioButton(e)}
                              checked={life.answer_condition_info === "false" || life.answer_condition_info === false}
                              value={false} name={life.life_id} />
                            <label htmlFor={"no" + life.life_id}>
                              <span>No</span>
                            </label>
                          </div>
                        </fieldset>
                      ))
                    ))}
                    </div>
                    <hr />
                    <div className="field-group  table-header-group">
                      {_.get(this.props, "trustedFlag", "") === true ?
                        <button className="btn btn-link" onClick={()=>this.saveForLater()}>
                          Save for later
                  </button>
                        :
                        null
                      }
                      <button className="btn btn-primary float-right" onClick={() => this.handleNext()}>
                      {this.state.previousPage === true && this.state.checkedNo === "no" ? "Return to review page" : "Next"}                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    }
        </main>
      </>
    );
  }
}

export default connect(
  (state) => state.eoiStore,
  (dispatch) => bindActionCreators(actionCreators, dispatch))(NoConditionMemberQ);
import React from "react";
import _ from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// import { parseISO } from 'date-fns'

function WhoAreWeCoveringForm({addOrEditStatus,handleShow,
    addOrEditHeader,handleNameChange,handleDateChange,handleDateBlur,handleOnOpen,handleOnClose,handleCancel,addOrEditSave,handleFieldBlur,
    whoAreWeCovering:{first_name,last_name,middle_initial,dob,gender,relationShip},
    firstNameError,lastNameError,dobError,genderError,blurError,data,index,isNotEmpty,prevData}) {
        let isVisible  = (isNotEmpty(prevData.first_name) && isNotEmpty(prevData.last_name) && isNotEmpty(prevData.dob) && isNotEmpty(prevData.gender))
        return (
        <div className="card add-dependent" >
            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <h6 className="mb-4 mt-2">{addOrEditHeader[index]} {relationShip}</h6>
                    {_.get(data, "trustedFlag", "") === true ?<>
                    {addOrEditStatus === "edit"}</> :
                  <>  {addOrEditStatus === "edit" &&  <button class="btn btn-link blue-link" onClick={(e) => handleShow(e)}>Remove</button>}
                   </> }
                </div>
                <div className="row form-group">
                    <div className="col-lg-9 col-md-10 col-sm-9">
                        <label htmlFor="first-name">First name</label>
                        {_.get(data, "trustedFlag", "") === true ?
                            <input id="first-name" type="text"
                                className={`form-control ${firstNameError[index] ? 'invalid' : ''}`}
                                maxLength={30}
                                value = {first_name}
                                onChange = {(e) => handleNameChange(e,"first_name")}
                                onBlur = {(e) => handleFieldBlur(e,"first_name")}
                                //Mani changes
                                //disabled={firstNameError[index] === false ? first_name : ''}
                                disabled = {isNotEmpty(prevData.first_name)}
                            /> :
                            <input id = "first-name" type="text"
                                className = {`form-control ${firstNameError ? 'invalid' : ''}`}
                                maxLength = {30}
                                value={first_name}
                                onChange={(e) => handleNameChange(e, "first_name")}
                                onBlur={(e) => handleFieldBlur(e, "first_name")}
                            />}
                        {firstNameError[index] === true ?
                            <span className="error"><i class="fas fa-exclamation-circle"></i>First name is required</span>
                            :
                            null}
                    </div>
                    <div className="col-lg-3 col-md-2 col-sm-3">
                        <label htmlFor="mi">MI</label>
                        {_.get(data, "trustedFlag", "") === true ?
                            <input className="form-control" id="mi" type="text"
                                maxLength={1}
                                value={middle_initial}
                                onChange={(e) => handleNameChange(e, "middle_initial")}
                                // disabled={middle_initial}
                            />
                            : <input className="form-control" id="mi" type="text"
                                maxLength={1}
                                value={middle_initial}
                                onChange={(e) => handleNameChange(e, "middle_initial")}
                            />
                        }
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="last-name">Last name</label>
                    {_.get(data, "trustedFlag", "") === true ?
                        <input id="last-name" type="text"
                            className={`form-control ${lastNameError[index] ? 'invalid' : ''}`}
                            maxLength={30}
                            value={last_name}
                            onChange={(e) => handleNameChange(e, "last_name")}
                            onBlur={(e) => handleFieldBlur(e, "last_name")}
                            //Mani changes
                            //disabled={lastNameError[index] === false ? last_name : ''}
                           disabled = {isNotEmpty(prevData.last_name)}
                        /> : <input id="last-name" type="text"
                            className={`form-control ${lastNameError ? 'invalid' : ''}`}
                            maxLength={30}
                            value={last_name}
                            onChange={(e) => handleNameChange(e, "last_name")}
                            onBlur={(e) => handleFieldBlur(e, "last_name")}
                        />}
                    {lastNameError[index] === true ?
                        <span className="error"><i class="fas fa-exclamation-circle"></i>Last name is required</span>
                        :
                        null}
                </div>

                <div className="row form-group">
                    <div className="col-lg-8 col-md-7 col-sm-9">
                        <label htmlFor="last-name">Date of birth</label>
                        {_.get(data, "trustedFlag", "") === true ?
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // label="Date of birth"
                                    placeholder="MM/DD/YYYY"
                                    id="date-picker-dialog"
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                   // value={dob == null ? null : parseISO(dob)}
                                    value={dob == null ? null : dob}
                                    onChange={handleDateChange}
                                    onBlur={handleDateBlur}
                                    autoOk={true}
                                    maxDate={new Date()}
                                    onOpen={() => handleOnOpen()}
             
                                    onClose={() => handleOnClose()}
                                    error={(blurError[index] || dobError[index]=== null || dobError[index] ) ? 'invalid' : ''}
                                    helperText={null}
                                    required={(dobError[index] === false || dobError[index] !== null )  ? '' : dobError}
                                    // disabled={check === true ?dob :''}
                                //disablePast={true}
                                disabled = {isNotEmpty(prevData.dob)}
                                />
                            </MuiPickersUtilsProvider> :
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    // label="Date of birth"
                                    placeholder="MM/DD/YYYY"
                                    id="date-picker-dialog"
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    value={dob}
                                    onChange={handleDateChange}
                                    onBlur={handleDateBlur}
                                    autoOk={true}
                                    maxDate={new Date()}
                                    onOpen={() => handleOnOpen()}
                                    onClose={() => handleOnClose()}
                                    error={blurError || dobError ? 'invalid' : ''}
                                    helperText={null}
                                    required = {dobError}
                                //disablePast={true}
                                />
                            </MuiPickersUtilsProvider>}
                        {blurError[index] ?
                            <span className="error"><i className="fas fa-exclamation-circle"></i>Date of birth is invalid</span>
                            :
                            dobError[index] ?
                                <span className="error"><i className="fas fa-exclamation-circle"></i>Date of birth is required</span>
                                : null }
                    </div>

                </div>
                <div className="form-group">
                    <label htmlFor="eoi-gender">Gender</label>
                    {_.get(data, "trustedFlag", "") === true ?
                        <select id="eoi-gender"
                            className={`form-control ${genderError[index] ? 'invalid' : ''}`}
                            value={gender}
                            onChange={(e) => handleNameChange(e, "gender")}
                            onBlur={(e) => handleFieldBlur(e, "gender")}
                            //disabled  = {genderError[index] === false ? '': gender}
                           disabled = {isNotEmpty(prevData.gender)}
                        >
                            <option defaultValue >Select</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select> :
                        <select id="eoi-gender"
                            className={`form-control ${genderError ? 'invalid' : ''}`}
                            value={gender}
                            onChange={(e) => handleNameChange(e, "gender")}
                            onBlur={(e) => handleFieldBlur(e, "gender")}
                        >
                            <option defaultValue >Select</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                    }
                    {genderError[index] ?
                        <span className="error"><i className="fas fa-exclamation-circle"></i>Gender is required</span>
                        :
                        null}
                </div>
                <div className="field-group">
                {_.get(data, "trustedFlag", "") === true ?
                <>
                {isVisible === true ? '':
                <button className="btn btn-primary float-right" onClick={(e)=>addOrEditSave(e)} onMouseDown={(e)=>e.preventDefault()}>Save</button>}
                </> :<>
                    <button className="btn btn-outline" onClick={()=>handleCancel()}>Cancel</button> 
                    <button className="btn btn-primary" onClick={(e)=>addOrEditSave(e)} onMouseDown={(e)=>e.preventDefault()}>Save</button>
                    {/* onMouseDown={(e)=>e.preventDefault()} */}
                    </>}
                </div>
            </div>
        </div>
    );
}

export default WhoAreWeCoveringForm;

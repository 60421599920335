import React,{Component} from 'react';

class Warning extends Component{
  
    render() {
        return (
    <>
        <div className="popup-bg">
            <div className="popup-box small">
                <div className="popup-header">
                    <button className="close hidden-text">
                        <i className="fas fa-times"><span>close button</span></i>
                    </button>
                </div>
                <div className="container check-popup">
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="lead mt-4" style={{color:"#0d3f5e"}}>Please use application back button.</p>
                        </div>
                    </div>
                    <div className="field-group mt-3 mb-3">
                        <div><button className="btn btn-primary m-2" onClick={this.props.handleBrowser}> OK </button></div>
                        {/* <div><button className="btn btn-primary float-right" onClick={this.props.cancelPopup}>Cancel</button></div> */}   
                    </div>
                </div>
            </div>
        </div>
    </>
        )
    }
}
export default Warning;
import React, { Component } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import _ from "lodash";
import { actionCreators } from "../../store/HomePage/HomePageAction";
import { scrollUp } from "../../utils/sharedFunction";
import { SITE_KEY } from "../../utils/constant";
import AlertRedBanner from "./AlertRedBanner";

let groupNumberNotFound = false;
let showBackButtoun = false;
let errorMsg = '';
let showErrorMsg = false;
let alertShow = false;
// let showRecaptchaError = false;

class FindYourGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupNumber: '',
            
        }
    }

    componentDidMount() {
        if (this.props.groupInfoResponse && this.props.groupInfoResponse.status ) {
            this.props.clearGetGroupInfoData();
        }
        //console.log("in mount");
        this.props.clearResponseValue();
        scrollUp()

    }

    onGroupNumberChange = (e) => {
        this.props.clearGetGroupInfoData();
        const regex = /^[0-9\b]+$/;
        if (e.target.value === '' || regex.test(e.target.value)) {
            errorMsg = '';
            showErrorMsg = false;
            this.setState({
                groupNumber : e.target.value
            })
        }
    }

    validateGroupNumber = (e) => {

        if (e.target.value.length === 6) {
            errorMsg = '';
            showErrorMsg = false;
            this.setState({
                groupNumber: '00' + e.target.value,

            })
        }

        else if (e.target.value.length === 0) {
            errorMsg = 'Group number is required';
            showErrorMsg = true
            this.setState({
                groupNumber: e.target.value
            })
        }

        else if (e.target.value.length < 8) {
            errorMsg = 'Group number must be 6 digits';
            showErrorMsg = true;
            this.setState({
                groupNumber: e.target.value
            })
        }

        else if (e.target.value.length === 8) {
            errorMsg = '';
            showErrorMsg = false;
            this.setState({
                groupNumber: e.target.value
            })
        }
    }

    onPrevious = () => {
        groupNumberNotFound = false;
        showBackButtoun = false;
        this.props.clearGetGroupInfoData();
        //console.log(this.props.groupInfoResponse);
        this.setState({ groupNumber: '' });

    }
    
    onChange = (value) => {
        //console.log("Captcha value:", value);
        this.props.verifyRecaptcha(value);
    }

    onNext = (groupNumberFound) => {
        if(_.get(this.props,"groupInfoResponse.eoi_data.group_info.group_situs_state","") === "CA" )
        {
            this.props.history.push({
                pathname: `/eoi/consent`,
                state: {"previousPage":"findYourGroup"}})
        }
        else if(groupNumberFound) {
            this.props.history.push(`/eoi/landingPage`);
        }
        let recaptchaRespValue = this.props && this.props.responseValue;
        //console.log("recaptchaRespValue", recaptchaRespValue);
        let showRecaptchaError = "";
        if(recaptchaRespValue === "success") {
            showRecaptchaError = false;
        } else {
            showRecaptchaError = true;
        }
        this.props.setRecaptchaErrorFlag(showRecaptchaError);


        if (this.state.groupNumber.length === 6) {
            errorMsg = '';
            showErrorMsg = false;
            // this.setState({
            //     groupNumber: '00' + e.target.value,

            // })
        }

        else if (this.state.groupNumber.length === 0) {
            errorMsg = 'Group number is required';
            showErrorMsg = true
            // this.setState({
            //     groupNumber: e.target.value
            // })
        }

        else if (this.state.groupNumber.length < 8) {
            errorMsg = 'Group number must be 6 digits';
            showErrorMsg = true;
            // this.setState({
            //     groupNumber: e.target.value
            // })
        }

        else if (this.state.groupNumber.length === 8) {
            errorMsg = '';
            showErrorMsg = false;
            // this.setState({
            //     groupNumber: e.target.value
            // })
        }
        showRecaptchaError = false
        if(showErrorMsg === false && showRecaptchaError === false) {
            this.props.clearGetGroupInfoData();
            this.props.getGroupInfo(this.state.groupNumber, showRecaptchaError);
        } else {
            this.setState({
                groupNumber: this.state.groupNumber
            })
        }
    }

    render() {
        
        let groupNumberFound = groupNumberNotFound
        let showBackbtn = showBackButtoun
        let showErrMsg = showErrorMsg
        let errMsg = errorMsg
        let showAlert = alertShow;
        let recaptchaError = "";

        if(this.props.showRecaptchaError === false) {
            recaptchaError = false;
        }
        if(this.props.showRecaptchaError) {
            recaptchaError = true;
        }
       
        //console.log("recaptchaError", recaptchaError);
        
        if (this.props.groupInfoResponse && this.props.groupInfoResponse.eoi_data) {
            groupNumberFound = true;
            showBackbtn = true;
        }

        else if (this.props.groupInfoResponse && this.props.groupInfoResponse.status && this.props.groupInfoResponse.status === 404) {
            showErrMsg = true;
            errMsg = 'Group not found. Please try again.';
        }

        else if (this.props.groupInfoResponse && this.props.groupInfoResponse.status && this.props.groupInfoResponse.status === 500) {
            
            showAlert = true; 
        }

        return (
            <>
                {showAlert && <AlertRedBanner handleClose={this.handleClose} />}
                
                <main>
                    {showBackbtn === true ?
                        <div className="container-fluid">
                            <div className="prev-link" >
                                <button className="btn btn-link" onClick={() => this.onPrevious()}><i className="fa fa-arrow-left"></i>Back</button>
                            </div>
                        </div> : null
                    }
                    <div className="container">

                        <div className="row">
                            < div className="col-lg-5 col-md-7">
                                <div className="intro">
                                    <h3>Find your group</h3>
                                    {groupNumberFound ? <p > We were able to match you with the following group. If this looks correct, click “Next” to keep going. If this isn’t your group, you can go back and enter a different group number.</p> : <p >  Enter your group number so we can connect you to the right plan. This is the plan number provided to you by your employer.</p>}
                                </div>

                                {groupNumberFound ? null :
                                <>
                                    <div className="form-group">
                                        <label>Group number</label>
                                        <input className={`form-control ${showErrMsg ? 'invalid' : ''}`}
                                        onChange={(e) => this.onGroupNumberChange(e)} 
                                        onBlur={(e) => this.validateGroupNumber(e)} value={this.state.groupNumber}  maxLength='8' 
                                        id="group-number" type="text" />
                                        {showErrMsg ?
                                        
                                            <span className="error"><i className="fas fa-exclamation-circle"></i>{errMsg}</span> : null}
                                    </div>
                                    <div className="form-group">
                                        <p>Please check the box below to continue.</p>
                                        <ReCAPTCHA 
                                        sitekey={SITE_KEY} 
                                        onChange={(e) => this.onChange(e)} />
                                        {recaptchaError ?
                                            <span className="error"><i className="fas fa-exclamation-circle"></i>Box must be checked to continue</span> : null}
                                    </div>
                                </>
                                }

                                {groupNumberFound ?
                                    <div className="data-box">
                                        <div className="data-box-list-item">
                                        <p className="list-item-label">Planholder:</p>
                                        <p className="list-item-data highlight"><strong>{this.props.groupInfoResponse.eoi_data.group_info.group_name}</strong></p>
                                        </div>
                                        <div className="data-box-list-item">
                                        <p className="list-item-label">Group number:</p>
                                        <p className="list-item-data">{this.props.groupInfoResponse.eoi_data.group_info.group_id}</p>
                                        </div>
                                    </div> : null
                                }
                                <hr />
                                <div className="field-group float-right">
                                    <div> <button className="btn btn-primary" onClick={() => this.onNext(groupNumberFound)} >Next</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                
            
            </>
        );
    }
}
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(FindYourGroup);

import React, { Component } from "react";
import LandingPopUp from "./LandingPopUp";

import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actionCreators } from "../../store/HomePage/HomePageAction";
import {
  nonTrustedJunction,
  trustedJunction,
  workDayJunction
} from "../../utils/constant";
import { scrollUp, imagePath } from "../../utils/sharedFunction";
import TermsConditions from "./TermsConditions";

let landingFlag2= null;

class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            landingFlag : null,
            isOpen: false
        }
        
    }
    
    onGetStarted = () => {
        
      	//this.props.history.push('/eoi/mib')
        if(_.get(this.props,"trustedFlag","")===true){

            // if(_.get(this.props,"groupInfoResponse.eoi_data.group_info.group_situs_state","") === "CA" || _.get(this.props,"groupInfoResponse.eoi_data.group_info.group_situs_state","") === "VA"  )
            // {
            //     this.props.history.push({
            //         pathname: `/eoi/trustedPrintedEOI`,
            //         state: {"previousPage":"mib"}})
            // }
            // else{
            //this.props.getprefillInfo(this.props.prefillId,'/eoi/mib'); //need to pass req params after API confirmation
            this.props.history.push('/eoi/mib')
            // }
        }
      //  else if (_.get(this.props,"trustedFlag","") !== true)
        //{
            //_.get(this.props,"groupInfoResponse.eoi_data.group_info.group_situs_state","") === "NY" ||
            //if( _.get(this.props,"groupInfoResponse.eoi_data.guid_enabled_ind","") === "Y")
            //{
                //this.props.history.push({
                //    pathname: `/eoi/nonTrustedPrintedEOI`,
              //      state: {"previousPage":"landingPage"}})
            //}
            //else{
              //  this.props.history.push('/eoi/homePage')
            //}
     //   }
        else{
            this.props.history.push('/eoi/homePage');
        }
    }

    LandingTerms = () =>
    {
        this.setState(
            {isOpen: true}
        )
    }

    acceptTerms = () =>
    {
        //console.log("landingFlag111",landingFlag2);
        landingFlag2 = true;
        this.setState({landingFlag : true})
    }

    declineTerms = () =>
    {
        if(this.props.trustedFlag)
        {
            this.props.history.push({
                pathname: `/eoi/trustedPrintedEOI`,
                state: {"previousPage":"landingPage"}})
            //this.props.history.push(`/eoi/trustedPrintedEOI`);
        }
        else
        {
            this.props.history.push({
                pathname: `/eoi/nonTrustedPrintedEOI`,
                state: {"previousPage":"landingPage"}})
            // this.props.history.push(`/eoi/nonTrustedPrintedEOI`);
        }
    }

    handlePopUp = () => {
        this.setState({ isOpen: false });
    }

    componentDidMount(){

        //console.log("before",this.props.location.search)      

        let decodeURL = new URLSearchParams(this.props.location.search);
        let perfillId = decodeURL.get('p');
        let updateId = decodeURL.get('u');
        let activeToken = decodeURL.get('at');
        let browserUrlv2 = window.location.href;
        if(_.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.source","") === "GWDY"){
            this.props.getprefillInfov2(perfillId,updateId,activeToken); 
        } else if(browserUrlv2.includes(trustedJunction) || browserUrlv2.includes(workDayJunction) || (browserUrlv2.includes(nonTrustedJunction) && perfillId && updateId))
        {
            this.props.getprefillInfov2(perfillId,updateId,activeToken);
        }

            scrollUp(); 
     }

    render() {
        // console.log( _.get(this.props,"groupInfoResponse.eoi_data.guid_enabled_ind"),"GUID")
        // console.log("this.props",this.props);
        
        // console.log(_.get(this.props,"prefillData.eoi_data.group_info.group_situs_state"),"check")
        if(_.get(this.props,"prefillData.eoi_data.group_info.group_situs_state","") === "CA" )
        {
            this.props.history.push({
                pathname: `/eoi/consent`
            })
        }
        //const {trustedFlag} = this.props ;
        landingFlag2 = this.state.landingFlag
        let browserUrl = window.location.href
        //console.log("this.props.prefillData",this.props.prefillData)
        let trustedValue=null;
        
        if(browserUrl.includes(trustedJunction) || browserUrl.includes(workDayJunction)){
            trustedValue=true;
            this.props.assignPath(true)
        }
        else if(browserUrl.includes(nonTrustedJunction) || browserUrl.includes(workDayJunction))
        {
            if(this.props.prefillData)
            {
                if(_.get(this.props,"prefillData.eoi_data.benefit_coverage_info.eoi_info.source","") === "GWDY"){
                    trustedValue=true;
                    this.props.assignPath(true)
                }
               else if (this.props.prefillData.tpa_source === true )
                {
                    trustedValue=true;
                    this.props.assignPath(true)
                }
                else if(this.props.prefillData.tpa_source === false)
                {
                    trustedValue=false;
                    this.props.assignPath(false)
                }
            }
            // else
            // {
            //     trustedValue=false;
            //     this.props.assignPath(false)
            // }
        }
        if(!landingFlag2)
        {

            if(this.props.prefillData){
                if(trustedValue)
                {
                    // if(this.props.prefillData.eoi_data.benefit_coverage_info_status === "ACTIVE" || this.props.prefillData.eoi_data.benefit_coverage_info_status === "COMPLETED"){
                    //     this.props.history.push('/eoi/homePage')
                    // }
                    if(this.props.prefillData && this.props.prefillData.eoi_data && this.props.prefillData.eoi_data.benefit_coverage_info_status === "START"){
                        if(this.props.prefillData.tpa_source)
                        {
                            if(this.props.prefillData.skip_ga_terms_page)
                            {
                                landingFlag2 = true;
                            }
                            else{
                                landingFlag2 = false;
                        
                            }
                        }
                        else{
                            landingFlag2 = true;
                        }
                    }
                    else{
                        this.props.history.push('/eoi/homePage') 
                    }
                    
                }
                else
                {
                    landingFlag2 = false;
                }
            }
            else
            {
                if(trustedValue)
                {
                    landingFlag2 = true;
                }
                else
                {
                    landingFlag2 = false;
                }
            }
        }

        // if(this.props.prefillData){
            return(
                <>
                {this.state.isOpen ?
                    <LandingPopUp handlePopUp={() => this.handlePopUp()} /> : null}
                 {landingFlag2 ?
                            <main>
                                <div className="container landing-page">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="intro">
                                                <h3>Evidence of Insurability</h3>
                                                <h5 className="info-blue">Let’s start you on the path to a decision! </h5>
                                                <p className="lead">We need to collect some health information first.{trustedValue !== true? " Be prepared to complete the form in one sitting.":null} You can do it securely online – in about the time it takes to enjoy a cup of coffee. <span><a href={this.LandingTerms} target="_blank" rel="noreferrer" onClick={this.LandingTerms}><u>(Read an important notice about Evidence of Insurability)</u></a></span></p>
                                                {/* <button className="btn btn-link" onClick={this.LandingTerms}>(Read an important notice about Evidence of Insurability)</button> */}
                                            </div>
                                            <h6 className="mt-4">
                                                What will I need?
                                            </h6> 
                                            {trustedValue === true ? 
                                            <p>For anyone requesting coverage, you'll provide:</p> : null }
            
                                            <div className="data-box">
                                                {trustedValue !== true ?
                                                <ul>
                                                    <li>Your Group ID / Plan number</li>
                                                    <li>The coverage(s) you’re requesting</li>
                                                    <li>The current insured amount and the additional amount requested</li>
                                                </ul> :null}
                                                {trustedValue === false ?
                                                <p className="small-label">For anyone requesting coverage, you'll also need:</p>:null}
                                                {trustedValue !== true ?
                                                <ul>
                                                    <li>Health history and doctor information</li>
                                                    <li>Height and weight</li>
                                                    <li>Date of birth</li>
                                                </ul> : 
            
                                                <ul>
                                                    <li>Health history</li>
                                                    <li>Doctor contact information</li>
                                                    <li>Height and weight</li>
                                                </ul> }
            
                                            </div>
                                            <div className="text-center-mobile">
                                                <button className="btn btn-secondary mt-5" onClick={()=>this.onGetStarted()}><div>Get started</div></button>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <img src={imagePath("./images/landingpage-illustration.svg")} className="illustration-bg" alt="illustration-bg" />
                            </main>
                            :
                            <TermsConditions acceptTerms={()=>this.acceptTerms()} declineTerms={this.declineTerms}/> 
                            
                        }
                            
                        </>

            )
    //     }
    //     else{
    //                 return(
    //             <div className="spinner-backdrop">
    //   <div className="spinner-modal">
    //       <img className="spinner-icon"
    //         src='./images/spinner-web.svg'
    //         alt="Loading Icon" />
    //       <div className="spinner-text">
    //           Please wait while your request is being processed
    //       </div>
    //   </div>
    // </div>
    //         )

    //     }
    //     if(this.props.prefillData){
    //         return(
    //             <>
    // {this.state.landingFlag ?
    //             <main>
    //                 <div className="container landing-page">
    //                     <div className="row">
    //                         <div className="col-lg-6">
    //                             <div className="intro">
    //                                 <h3>Evidence of Insurability</h3>
    //                                 <h5 className="info-blue">Let’s start you on the path to a decision! </h5>
    //                                 <p className="lead">We need to collect some health information first.{trustedFlag !== true? " Be prepared to complete the form in one sitting.":null} You can do it securely online – in about the time it takes to enjoy a cup of coffee.  </p>
    //                             </div>
    //                             <h6 className="mt-4">
    //                                 What will I need?
    //                             </h6> 
    //                             {this.props.trustedFlag === true ? 
    //                             <p>For anyone requesting coverage, you'll provide:</p> : null }

    //                             <div className="data-box">
    //                                 {trustedFlag !== true ?
    //                                 <ul>
    //                                     <li>Your Group ID / Plan number</li>
    //                                     <li>The coverage(s) you’re requesting</li>
    //                                     <li>The current insured amount and the additional amount requested</li>
    //                                 </ul> :null}
    //                                 {trustedFlag === false ?
    //                                 <p className="small-label">For anyone requesting coverage, you'll also need:</p>:null}
    //                                 {trustedFlag !== true ?
    //                                 <ul>
    //                                     <li>Health history and doctor information</li>
    //                                     <li>Height and weight</li>
    //                                     <li>Date of birth</li>
    //                                 </ul> : 

    //                                 <ul>
    //                                     <li>Health history</li>
    //                                     <li>Doctor contact information</li>
    //                                     <li>Height and weight</li>
    //                                 </ul> }

    //                             </div>
    //                                 <button className="btn btn-secondary mt-5" onClick={()=>this.onGetStarted()}>Get started</button>
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <img src={imagePath("./images/landingpage-illustration.svg")} className="illustration-bg" alt="illustration-bg" />
    //             </main>
    //             :
    //             <TermsConditions acceptTerms={()=>this.acceptTerms()} declineTerms={this.declineTerms}/> 
                
    //         }
                
    //         </>
    //         )
    //     }
        
    
    //     else{
    //         return(
    //             <div className="spinner-backdrop">
    //   <div className="spinner-modal">
    //       <img className="spinner-icon"
    //         src={imagePath('./images/spinner-web.svg')}
    //         alt="Loading Icon" />
    //       <div className="spinner-text">
    //           Please wait while your request is being processed
    //       </div>
    //   </div>
    // </div>
    //         )
    //     }



        // return (
        //     <>
        //     {landingFlag2 ? //this.state.landingFlag ?
        //         <main>
        //             <div className="container landing-page">
        //                 <div className="row">
        //                     <div className="col-lg-6">
        //                         <div className="intro">
        //                             <h3>Evidence of Insurability</h3>
        //                             <h5 className="info-blue">Let’s start you on the path to a decision! </h5>
        //                             <p className="lead">We need to collect some health information first.{trustedFlag !== true? " Be prepared to complete the form in one sitting.":null} You can do it securely online – in about the time it takes to enjoy a cup of coffee.  </p>
        //                         </div>
        //                         <h6 className="mt-4">
        //                             What will I need?
        //                         </h6> 
        //                         {this.props.trustedFlag === true ? 
        //                         <p>For anyone requesting coverage, you'll provide:</p> : null }

        //                         <div className="data-box">
        //                             {trustedFlag !== true ?
        //                             <ul>
        //                                 <li>Your Group ID / Plan number</li>
        //                                 <li>The coverage(s) you’re requesting</li>
        //                                 <li>The current insured amount and the additional amount requested</li>
        //                             </ul> :null}
        //                             {trustedFlag === false ?
        //                             <p className="small-label">For anyone requesting coverage, you'll also need:</p>:null}
        //                             {trustedFlag !== true ?
        //                             <ul>
        //                                 <li>Health history and doctor information</li>
        //                                 <li>Height and weight</li>
        //                                 <li>Date of birth</li>
        //                             </ul> : 

        //                             <ul>
        //                                 <li>Health history</li>
        //                                 <li>Doctor contact information</li>
        //                                 <li>Height and weight</li>
        //                             </ul> }

        //                         </div>
        //                             <button className="btn btn-secondary mt-5" onClick={()=>this.onGetStarted()}>Get started</button>
        //                     </div>
        //                 </div>
        //             </div>
        //             <img src={imagePath("./images/landingpage-illustration.svg")} className="illustration-bg" alt="illustration-bg" />
        //         </main>
        //         :
        //         <TermsConditions acceptTerms={()=>this.acceptTerms()} declineTerms={this.declineTerms}/> 
                
        //     }
                
        //     </>
            
        // );
    }
}

// export default LandingPage;
export default connect(
    (state) => state.eoiStore,
    (dispatch) => bindActionCreators(actionCreators, dispatch))(LandingPage);

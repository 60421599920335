import React from "react";

import { Route } from "react-router";

import ErrorPage from "./components/ErrorPage/ErrorPage";
import UnauthorizedError from "./components/ErrorPage/UnauthorizedError";
import Footer from "./components/Footer/Footer";
import PageHeader from "./components/PageHeader/Header";
import justAMoment from "./components/Processing/JustAMoment";
import SelectCoverage from "./container/AddBenefitsNCoverages/SelectCoverage";
import SelectCoverageMember
  from "./container/AddBenefitsNCoverages/SelectMemberCoverage";
import TellUsMore from "./container/AddBenefitsNCoverages/TellUsMore";
import WhoAreWeCovering
  from "./container/AddBenefitsNCoverages/WhoAreWeCovering";
import AllQuestions
  from "./container/HealthQuestionPage/HealthBaseQuestions/AllQuestions";
import NoConditionMemberQ
  from "./container/HealthQuestionPage/HealthBaseQuestions/NoConditionMemberQ";
import ConditionQ
  from "./container/HealthQuestionPage/HealthConditionQuestion/ConditionQ";
import MedicationForHQ
  from "./container/HealthQuestionPage/HealthConditionQuestion/MedicationForHQ";
import HealthHistory
  from "./container/HealthQuestionPage/HealthHistory/HealthHistory";
import HeightWeight
  from "./container/HealthQuestionPage/HeightWeight/HeightWeight";
import CancerKnockOutQ
  from "./container/HealthQuestionPage/KnockOutQuestions/CancerKnockOutQ";
import CriticalIllnessKnockOutQ
  from "./container/HealthQuestionPage/KnockOutQuestions/CriticalIllnessKnockOutQ";
import KnockOutQuestions
  from "./container/HealthQuestionPage/KnockOutQuestions/KnockOutQuestions";
import LetsTalkAbout
  from "./container/HealthQuestionPage/LetsTalkAbout/LetsTalkAbout";
import MoveOn from "./container/HealthQuestionPage/MoveOn/MoveOn";
import ReflexQuestion
  from "./container/HealthQuestionPage/ReflexQuestion/ReflexQuestion";
import HomePage from "./container/HomePage/HomePage";
import ESign from "./container/LandingPage/eSign";
import ESignDependent from "./container/LandingPage/eSignSpouse";
import FindYourGroup from "./container/LandingPage/FindYourGroup";
import GetLink from "./container/LandingPage/GetLink";
import LandingPage from "./container/LandingPage/LandingPage";
import Mib from "./container/LandingPage/Mib";
import PrintedEOINonTrusted from "./container/LandingPage/PrintedEOINonTrusted";
import PrintedEOITrusted from "./container/LandingPage/PrintedEOITrusted";
import ApprovedPage from "./container/Review/DecisionPage/ApprovedPage";
import DeclinedPage from "./container/Review/DecisionPage/DeclinedPage";
import UnderWritingPage from "./container/Review/DecisionPage/UnderWritingPage";
// import Review from "./container/Review/Review";
import ContactInfo from "./container/ReviewBenefitsNCoverages/ContactInfo";
import ReviewBenefit from "./container/ReviewBenefitsNCoverages/ReviewBenefit";
import ReviewPage from "./container/Review/ReviewPage";
import ESignChild from "./container/LandingPage/eSignChild";
import ReviewWhoAreWeCovering from "./container/ReviewBenefitsNCoverages/ReviewWhoAreWeCovering";
import WorkdayBenefits from './components/WorkdayError/WorkdayBenefits';
import SystemDown from './components/WorkdayError/SystemDown';
import Success from "./components/Success/Success";
import CAConstent from "./container/LandingPage/CAConstent";
import DuplicateSSN from "./components/DuplicateSSN/DuplicateSSN";
import PreviouslyDeclined from "./components/DuplicateSSN/PreviouslyDeclined";
import NoDecision from "./components/DuplicateSSN/NoDecision";
import DuplicatePopup from "./components/DuplicateSSN/DuplicatePopup";

const App = () => (
  <>
    <PageHeader />
    {
      process.env.ENVIRONMENT !== "prod" &&
      <Route exact path='/eoi/getLink' component={GetLink} />
    }
    <Route exact path='/eoi/homePage' component={HomePage} />
    <Route exact path='/eoi/benefits' component={ReviewBenefit} />
    <Route exact path='/eoi/tellUsMore' component={TellUsMore} />
    <Route exact path='/eoi/move' component={MoveOn} />
    <Route exact path='/eoi/healthHistory' component={HealthHistory} />
    {/* <Route exact path='/eoi/review' component={Review} /> */}
    <Route exact path='/eoi/landingPage' component={LandingPage} />
    <Route exact path='/eoi/nonTrustedPrintedEOI' component={PrintedEOINonTrusted} />
    <Route exact path='/eoi/trustedPrintedEOI' component={PrintedEOITrusted} />
    <Route exact path='/eoi/spinner' component={justAMoment} />
    <Route exact path='/eoi/mib' component={Mib} />
    <Route exact path='/eoi/contact' component={ContactInfo} />
    <Route exact path='/eoi/whoAreWeCovering' component={WhoAreWeCovering} />
    <Route exact path='/eoi/reviewWhoAreWeCovering' component={ReviewWhoAreWeCovering} />
    <Route exact path='/eoi/findYourGroup' component={FindYourGroup} />
    <Route exact path='/eoi/selectYourCoverage' component={SelectCoverage} />
    <Route exact path='/eoi/question/:QNo/:id?' component={AllQuestions} />
    <Route exact path='/eoi/letsMoveOn' component={MoveOn} />
    <Route exact path='/eoi/selectCoverageOnlyPerson' component={SelectCoverageMember} />
    <Route exact path='/eoi/otherKnockOutQ/:id?' component={KnockOutQuestions} />
    <Route exact path='/eoi/cancerKnockOutQ/:id?' component={CancerKnockOutQ} />
    <Route exact path='/eoi/criticalKnockOutQ/:id?' component={CriticalIllnessKnockOutQ} />
    <Route exact path='/eoi/heightAndweight/:id?' component={HeightWeight} />
    <Route exact path='/eoi/noConditionMember' component={NoConditionMemberQ} />
    <Route exact path='/eoi/question/:QNo/condition/:CNo/:id?' component={ConditionQ} />
    <Route exact path='/eoi/question/:QNo/medicationCondition/:CNo/:id?' component={MedicationForHQ} />
    <Route exact path='/eoi/question/:QNo/condition/:CNo/reflux/:RNo/:id?' component={ReflexQuestion} />
    <Route exact path='/eoi/question/:QNo/letsTalkAbout/:id?' component={LetsTalkAbout} />
    <Route exact path='/eoi/employeeSignature' component={ESign} />
    <Route exact path='/eoi/dependentSignature' component={ESignDependent} />
    <Route exact path='/eoi/dependentChild' component={ESignChild} />
    <Route exact path='/eoi/decisionApproved' component={ApprovedPage} />
    <Route exact path='/eoi/decisionDeclined' component={DeclinedPage} />
    <Route exact path='/eoi/decisionUnderWriting' component={UnderWritingPage} />
    <Route exact path='/eoi/review/:id?' component={ReviewPage} />
    <Route exact path='/eoi/errorPage' component={ErrorPage} />
    <Route exact path='/eoi/unauthorized' component={UnauthorizedError} />
    <Route exact path='/eoi/workdaynobenefits' component={WorkdayBenefits} />
    <Route exact path='/eoi/systemdown' component={SystemDown} />
    <Route exact path='/eoi/consent' component={CAConstent} />
    <Route exact path='/eoi/success' component={Success} />
    <Route exact path='/eoi/DuplicateSSN' component={DuplicateSSN} />
    <Route exact path='/eoi/PreviouslyDeclined' component={PreviouslyDeclined} />
    <Route exact path='/eoi/nodecision' component={NoDecision} />
    <Route exact path='/eoi/popup' componet={DuplicatePopup} />
    <Footer />
  </>
)

export default App
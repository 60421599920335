import React, { Component } from 'react';
class Success extends Component {
    
    render() {
        return (
            <>

                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-8">
                                <div className="intro">
                                    <h3>Success!</h3>
                                    <h6 className="pt-2"> You're progress is now saved. When you're ready, come back to complete your Evidence of Insurability(EOI).
                                    </h6>
                                </div>
                                
                                <div className="data-box mt-5">
                                    <h6>Stay connected anywhere you go</h6>
                                    <p className="lead">Use <a href="https://www.guardiananytime.com" target="_blank" rel="noopener noreferrer">Guardian Anytime</a> to file and check claims, view your benefits, and access your ID card.</p>
                                </div>
                                <h6 className="pt-3">Thank you for choosing Guardian!</h6>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default Success;